<template>
  <UploadFormPage v-if="accountReferral" :accountReferral="accountReferral" />
</template>

<script lang="ts">
import { LeadDetailsMixin } from "@/module/lead/mixin/lead-details.mixin";
import UploadFormPage from "@/shared/component/upload-form/UploadFormPage.vue";
import { AccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { LeadDetailsUploadFormRoute } from ".";

export default defineComponent({
  name: LeadDetailsUploadFormRoute,
  mixins: [LeadDetailsMixin],
  components: {
    UploadFormPage,
  },
  computed: {
    accountReferral(): AccountReferral | null {
      return this.lead?.referral ?? null;
    },
  },
});
</script>
