import { Api } from "@/service/api.service";

interface LeadReportResponse {
  count: number;
  buildCount: number;
  unqualifiedCount: number;
  pendingCount: number;
  dropoutCount: number;
  convertedCount: number;
  ifaCount: number;
  mortgageCount: number;
  organicCount: number;
  otherCount: number;
}

interface CashflowReportResponse {
  year: number;
  quarter: number;
  month: number;
  week: number;
  unpaidValue: number;
  unpaidCount: number;
  advisedValue: number;
  advisedCount: number;
  hostedValue: number;
  hostedCount: number;
  lpasOnlyValue: number;
  lpasOnlyCount: number;
  trustRegValue: number;
  trustRegCount: number;
  subscriptionCount: number;
  subscriptionValue: number;
  sumValue: number;
}
interface ComputeSummaryReportResponse {
  priorWeek: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
  };
  wtd: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
  };
  mtd: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
  };
  qtd: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
  };
  last12Months: {
    cash: CashflowReportResponse;
  };
}

export const ComputeSummaryReportHandler =
  async (): Promise<ComputeSummaryReportResponse> => {
    const response = await Api.resource(
      "portal",
      "/v2/portal/report/summary",
      {},
    )
      .method("get")
      .result();

    return response;
  };
