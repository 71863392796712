import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import { Between, FilterModelInternals, In, Like } from "@kinherit/orm";
import {
  DeepPartialArrayApiQuery,
  IIntroducerOutsource,
  IntroducerCompany,
  IntroducerOutsource,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadIntroducerOutsourceMessage {
  name?: string;
  type?: Array<string>;
  status?: Array<string>;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerOutsource;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerOutsourceResponse {
  introducerOutsources: Array<IntroducerOutsource>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerOutsourceHandler = async (
  message: ReadIntroducerOutsourceMessage,
): Promise<ReadIntroducerOutsourceResponse> => {
  const request = Api.resource("portal", "/v2/portal/introducer-outsource")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  const sharedConditions: QueryMask<typeof IntroducerOutsource> = {
    type: {
      id: In(message.type),
    },
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
  };
  const uniqueConditions: DeepPartialArrayApiQuery<
    FilterModelInternals<IntroducerOutsource>
  > = [];

  if (message.name) {
    uniqueConditions.push({
      clientName: Like(message.name),
      ...sharedConditions,
    });

    uniqueConditions.push({
      company: {
        profile: {
          organisationName: Like(message.name),
        },
      },
      ...sharedConditions,
    });
  }

  const query = request.buildQuery(IntroducerOutsource);

  if (uniqueConditions.length > 0) {
    query.where(uniqueConditions);
  } else {
    query.where(sharedConditions);
  }

  const response = await request.result();

  return {
    introducerOutsources: IntroducerOutsource.$inflate(
      response.introducerOutsource,
      message.sort,
      response.$rootIds,
    ),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    profiles: Profile.$inflate(response.profile),
    pagination: getPagination(response),
  };
};
