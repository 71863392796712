<template>
  <div class="sidebar-page">
    <slot name="above" />
    <PageHeader
      :has-context-menu="hasContextMenu"
      :name="name"
      :avatar-name="avatarName"
      :title="title"
      :subtitle="subtitle"
      @context-menu="$emit('context-menu')"
    />
    <slot name="below" />
    <Tabs
      v-if="tabs.length > 0"
      class="sidebar-page__tabs"
      :config="tabs"
      :is-fullwidth="false"
      v-model:tab="computedTab"
    />
    <div
      class="sidebar-page__sidebar--content"
      :style="{
        display: showSidebar ? 'grid' : 'block',
      }"
    >
      <div v-if="showSidebar" class="sidebar-page__sidebar">
        <slot name="sidebar" />
      </div>
      <div class="sidebar-page__content">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PageHeader from "@/shared/component/misc/PageHeader.vue";
import { TabOptions, Tabs } from "@kinherit/framework/component.display/tabs";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "SidebarPage",
  components: {
    PageHeader,
    Tabs,
  },
  props: {
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String as PropType<string | null>,
      default: null,
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    subtitle: {
      type: String as PropType<string | null>,
      default: null,
    },
    avatarName: {
      type: String as PropType<string | null>,
      default: null,
    },
    tabs: {
      type: Array as PropType<Array<TabOptions>>,
      default: () => [],
    },
    tab: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  emits: ["context-menu", "update:tab"],
  computed: {
    showSidebar(): boolean {
      return !!this.$slots.sidebar;
    },
    computedTab: {
      get(): number | undefined {
        return this.tab ?? undefined;
      },
      set(value: number | null): void {
        this.$emit("update:tab", value);
      },
    },
  },
});
</script>

<style lang="scss">
.sidebar-page {
  &__sidebar--content {
    display: grid;
    gap: $block-spacing;
    grid-template-areas: "sidebar" "content";
    margin-top: 1.5em; // $block-spacing;

    @include from($tablet) {
      grid-template-areas: "sidebar content";
      grid-template-columns: minmax(240px, 1fr) 5fr;
    }
  }

  &__sidebar {
    @include from($tablet) {
      grid-area: sidebar;
      position: sticky;
      top: 0;
      height: fit-content;
    }
  }
  &__content {
    grid-area: content;
    min-width: 0; //hack for chartjs canvas to not overflow
  }
}
</style>
