import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { CreateIntroducerOutsourceHandler } from "./create-introducer-outsource.action";
import { DeleteIntroducerOutsourceHandler } from "./delete-introducer-outsource.action";
import { ReadIntroducerOutsourceHandler } from "./read-introducer-outsource.action";
import { RecordIntroducerOutsourceHandler } from "./record-introducer-outsource.action";
import { UpdateIntroducerOutsourceHandler } from "./update-introducer-outsource.action";

export const Actions = {
  "introducer/outsource/read": ReadIntroducerOutsourceHandler,
  "introducer/outsource/create": CreateIntroducerOutsourceHandler,
  "introducer/outsource/update": UpdateIntroducerOutsourceHandler,
  "introducer/outsource/delete": DeleteIntroducerOutsourceHandler,
  "introducer/outsource/record": RecordIntroducerOutsourceHandler,
};

export const IntroducerOutsourceActionBus = DefineActionBus({
  name: "Introducer.Outsource",
  actions: {
    read: ReadIntroducerOutsourceHandler,
    create: CreateIntroducerOutsourceHandler,
    update: UpdateIntroducerOutsourceHandler,
    delete: DeleteIntroducerOutsourceHandler,
    record: RecordIntroducerOutsourceHandler,
  },
});
