import { CreateMfAuthMethod } from "@/config/model.config";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { MfAuthMethod } from "@kinherit/sdk";

export const CreateAuthMethodForm = (
  authMethod: MfAuthMethod = CreateMfAuthMethod(),
) =>
  defineForm({
    name: "auth-method-form",
    data: () => authMethod,
    formAreas: (data) => [
      defineFormArea({
        name: "auth-method-general",
        data,
        template: GridLayout([["type", "alias"], ["device"]]),
        components: () => ({
          type: [
            FormSelectField({
              reactive: true,
              props: {
                label: "Type",
                options: {
                  email: "Email",
                  totp: "TOTP",
                  sms: "SMS",
                },
                validators: ["required"],
              },
              models: {
                value: {
                  get: (data) => data.type,
                  set: (value, data) => {
                    data.type = value;
                    data.device = "";
                  },
                },
              },
            }),
          ],
          alias: [
            FormTextField({
              props: {
                label: "Alias",
                message: "E.g. Work Email, Personal Phone, etc.",
                validators: ["required"],
              },
              models: {
                value: "alias",
              },
            }),
          ],
          device: [
            FormEmailField({
              props: {
                vIf: (data) => data.type === "email",
                label: "Email Address",
                message: "E.g. email@address.com",
                validators: ["required"],
              },
              models: {
                value: "device",
              },
            }),
            FormPhoneNumberField({
              props: {
                vIf: (data) => data.type === "sms",
                label: "Phone Number",
                message: "E.g. +07123456789",
                validators: ["required"],
              },
              models: {
                value: "device",
              },
            }),
          ],
        }),
      }),
    ],
  });
