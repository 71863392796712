<template>
  <PageHeader htag="h2" text="Credit Notes">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        text="Create Credit Note"
        @click="$emit('create-credit-note')"
      />
    </template>
  </PageHeader>
  <MasterListPage
    :columns="columns"
    :rows="rows"
    is-nested
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      name: 'Name',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UpdateCreditNoteForm } from "@/module/core/forms/update-credit-note.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { CreditNote, ICreditNote, QueryMask } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CreditNotePage",
  mixins: [AuthService.mixin()],
  emits: ["create-credit-note"],
  props: {
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object as () => QueryMask<typeof CreditNote>,
      default: () => ({}),
    },
  },
  components: {
    MasterListPage,
    PageHeader,
    Button,
  },
  data: () => ({
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: CreditNote) => v.note.name,
      },
      {
        title: "Issued At",
        sort: true,
        map: (v: CreditNote) => v.issuedAt.formatDate,
      },
      {
        title: "Issued By",
        sort: true,
        map: (v: CreditNote) => v.issuedBy?.profile.fullName,
      },
      {
        title: "Amount",
        sort: true,
        map: (v: CreditNote) => v.remainingValue.format,
      },
    ] as Array<TableColumn>,
    rows: Array<CreditNote>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "name" as keyof ICreditNote,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      // const { creditNotes, pagination } = await window.Kernel.ActionBus.execute(
      //   "core/credit-note/read",
      //   {
      //     sort: this.sort,
      //     pagination: this.pagination,
      //     query: this.query,
      //   },
      // );
      const { creditNotes, pagination } =
        await window.Kernel.ActionBus2.core.creditNote.read({
          sort: this.sort,
          pagination: this.pagination,
          query: this.query,
        });

      this.rows = creditNotes;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async handleClick(row: CreditNote): Promise<void> {
      if (!this.hasWriteAccess) {
        return;
      }

      const item = await OpenContextMenu({
        items: [
          {
            title: "Update",
            line1: "Update this credit note",
            action: this.update,
            icon: StyleService.icon.notes.icon,
          },
          {
            title: "Delete",
            line1: "Delete this credit note",
            action: this.delete,
            icon: StyleService.icon.delete.icon,
          },
        ],
        trackingField: "title",
        line1Field: "line1",
        iconLeftField: "icon",
        titleField: "title",
      });

      await item.action(row);
    },
    async update(data: CreditNote): Promise<void> {
      try {
        await UpdateCreditNoteForm({ data }).dialog({
          dialog: {
            title: "Update Credit Note",
          },
        });
      } catch (e) {
        data.$restore();
        throw e;
      }

      // await window.Kernel.ActionBus.execute("core/credit-note/update", {
      //   creditNote: data,
      // });
      await window.Kernel.ActionBus2.core.creditNote.update({
        creditNote: data,
      });

      this.refresh();
    },
    async delete(data: CreditNote): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Credit Note",
          message: "Are you sure you want to delete this credit note?",
        },
      });
      // await window.Kernel.ActionBus.execute("core/credit-note/delete", {
      //   creditNote: data,
      // });
      await window.Kernel.ActionBus2.core.creditNote.delete({
        creditNote: data,
      });

      this.refresh();
    },
  },
});
</script>
