<template>
  <Card class="account-holder-summary">
    <template #heading>
      <Title size="is-4">
        <span v-if="null === name" class="has-text-warning">
          Awaiting Details
        </span>
        <span v-else>
          {{ name }} <small>({{ accountType }} Account Holder)</small>
        </span>
      </Title>
    </template>
    <div class="columns">
      <div class="column">
        <DataListWidget
          no-card
          is-compact
          :details="[
            {
              label: 'Marital Status',
              value: person.maritalStatus?.text,
              hide: null === person.maritalStatus,
            },
            {
              label: 'Relationship to Primary Account Holder',
              value: primaryRelationship,
              hide: accountType === 'Primary' || null === primaryRelationship,
            },
            {
              label: 'Relationship to Secondary Account Holder',
              value: secondaryRelationship,
              hide:
                accountType === 'Secondary' || null == secondaryRelationship,
            },
            {
              label: 'Date of Birth',
              value: person.dateOfBirth?.formatDate,
              hide: null === person.dateOfBirth,
            },
            {
              label: 'Date of Death',
              value: person.dateOfDeath?.formatDate,
              hide: null === person.dateOfDeath,
            },
            {
              label: 'Organisation',
              value: person.profile.organisationName,
              hide: null === person.profile.organisationName,
            },
          ]"
        />
      </div>
      <div class="column">
        <ContactDetailsWidget no-card :profile-id="person.profile.id" />
      </div>
    </div>
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
        class="edit-button"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<script lang="ts">
import ContactDetailsWidget from "@/shared/component/profile/ContactDetailsWidget.vue";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import Title from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Kintin, Person } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountHolderSummary",
  props: {
    person: {
      type: Person as PropType<Person>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  emits: ["edit"],
  components: { Card, ContactDetailsWidget, DataListWidget, Button, Title },
  computed: {
    name(): string {
      const person = this.person;
      const profile = person.profile;

      let name = profile.fullName;

      if (person.profile.knownAs) {
        if (null === name) {
          name = person.profile.knownAs;
        } else {
          name += ` (${person.profile.knownAs})`;
        }
      }

      return name as string;
    },
    accountType(): "Primary" | "Secondary" {
      return this.kintin.primaryPerson?.id === this.person.id
        ? "Primary"
        : "Secondary";
    },
    primaryRelationship() {
      if (this.kintin.primaryPerson?.id === this.person.id) {
        return null;
      }

      return this.person.relationToPrimaryPerson
        ? `${this.person.relationToPrimaryPerson.text.ucFirst()}`
        : {
            color: "has-text-warning" as const,
            text: "Not set",
          };
    },
    secondaryRelationship() {
      if (
        this.kintin.secondaryPerson?.id === this.person.id ||
        null === this.kintin.secondaryPerson
      ) {
        return null;
      }

      return this.person.relationToSecondaryPerson
        ? `${this.person.relationToSecondaryPerson.text.ucFirst()}`
        : {
            color: "has-text-warning" as const,
            text: "Not set",
          };
    },
  },
});
</script>
