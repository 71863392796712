import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Api } from "@/service/api.service";

interface RequestSessionMessage {
  username: string;
  password: string;
}

interface RequestSessionResponse {
  activeSession: ActiveSession;
}

export const RequestSessionHandler = async (
  message: RequestSessionMessage,
): Promise<RequestSessionResponse> => {
  const response = await Api.resource("auth", "/v2/auth/login")
    .method("put")
    .body(message)
    .result();

  const activeSession = new ActiveSession({
    username: message.username,
    ...response,
  });

  activeSession.$persist();

  if (false === activeSession.mfaRequired) {
    // await window.Kernel.ActionBus.execute("auth/session/complete", {
    //   activeSession,
    // });
    await window.Kernel.ActionBus2.auth.session.complete({
      activeSession,
    });
  }

  return { activeSession };
};
