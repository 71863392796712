<template>
  <Card title="Cashflow">
    <p>
      Average Order value and totals, should not be used for accounting
      purposes, merely cash flow indication
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <div
      v-if="datasets.length > 0"
      class="chart-container"
      style="position: relative; height: 40vh"
    >
      <BarChart
        :datasets="datasets"
        :labels="labels"
        :options="{
          maintainAspectRatio: false,
        }"
      />
    </div>
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :row-classes="rowClasses"
      :rows="results"
      :footer="footer"
      @loaded="load"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { CashflowReportRoute } from ".";

import BarChart from "@kinherit/framework/component.chart/bar-chart";
import Table from "@kinherit/framework/component.display/table";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { Currency } from "@kinherit/ts-common/index";
import { TCashflowReportResult } from "../action/compute-cashflow-report.action";
import { hasCurrentRowHighlighting } from "../data/helper-functions";
import { ReadCashflowReportFilters } from "../form/read-cashflow-report.form";

export default defineComponent({
  name: CashflowReportRoute,
  components: {
    Card,
    MasterListFilters,
    BarChart,
    Table,
  },
  data: () => ({
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadCashflowReportFilters(),

    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Pipeline",
        class: "cash-report-result",
        mapHtml: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.unpaidValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.unpaidCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "Advised (AVG)",
        class: "cash-report-result",
        mapHtml: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.advisedValue,
          });

          const averageOrderValue =
            results.advisedCount > 0
              ? new Currency({
                  amount: results.advisedValue / results.advisedCount,
                }).toFormattedString(true)
              : "-";

          return value.amount > 0
            ? `${value.toFormattedString()} <span class='has-text-grey'>(${averageOrderValue})</span> <span class='badge is-small is-compact is-light-grey'>${
                results.advisedCount
              }</span>`
            : "-";
        },
      },
      {
        title: "Hosted",
        class: "cash-report-result",
        mapHtml: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.hostedValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.hostedCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "LPAs Only",
        class: "cash-report-result",
        mapHtml: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.lpasOnlyValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.lpasOnlyCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "TruReg",
        class: "cash-report-result",
        mapHtml: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.trustRegValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.trustRegCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "Subs",
        class: "cash-report-result",
        mapHtml: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.subscriptionValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.subscriptionCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "Total",
        class: "has-text-weight-bold",
        map: (results: TCashflowReportResult) => {
          const value = new Currency({
            amount: results.sumValue,
          });
          return value.toFormattedString();
        },
      },
    ],
    footer: [
      "Total",
      // Pipeline
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const value = new Currency({
            amount: results.reduce((total, row) => total + row.unpaidValue, 0),
          });
          return value.toFormattedString();
        },
      },
      // Advised
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const totalAdvisedValue = results.reduce(
            (total, row) => total + row.advisedValue,
            0,
          );
          const totalAdvisedCount = results.reduce(
            (total, row) => total + row.advisedCount,
            0,
          );

          const averageOrderValue =
            totalAdvisedCount > 0
              ? new Currency({
                  amount: totalAdvisedValue / totalAdvisedCount,
                }).format
              : "-";

          const totalValue = new Currency({
            amount: totalAdvisedValue,
          });

          return `${totalValue.toFormattedString()} <span class='has-text-grey'>(${averageOrderValue})</span>`;
        },
      },
      // Hosted
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const value = new Currency({
            amount: results.reduce((total, row) => total + row.hostedValue, 0),
          });
          return value.toFormattedString();
        },
      },
      // LPAs Only
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const value = new Currency({
            amount: results.reduce(
              (total, row) => total + row.lpasOnlyValue,
              0,
            ),
          });
          return value.toFormattedString();
        },
      },
      // Trust Reg
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const value = new Currency({
            amount: results.reduce(
              (total, row) => total + row.trustRegValue,
              0,
            ),
          });
          return value.toFormattedString();
        },
      },
      // Subscriptions
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const value = new Currency({
            amount: results.reduce(
              (total, row) => total + row.subscriptionValue,
              0,
            ),
          });
          return value.toFormattedString();
        },
      },
      // Total
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowReportResult[]) => {
          const value = new Currency({
            amount: results.reduce((total, row) => total + row.sumValue, 0),
          });
          return value.toFormattedString();
        },
      },
    ],
  }),
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.execute(
      //   "report/cashflow",
      //   this.filters.localData,
      // );
      const result = await window.Kernel.ActionBus2.report.cashflow(
        this.filters.localData,
      );

      this.datasets = result.datasets;
      this.labels = result.labels;
      this.results = result.results;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>
