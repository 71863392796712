<template>
  <AddressesContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<script lang="ts">
import AddressesContainer from "@/module/kinvault.kintin/component/address-book/AddressesContainer.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookAddressesRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookAddressesRoute,
  components: {
    AddressesContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
});
</script>
