<template>
  <Card v-if="brandedKinvault" title="Activity">
    <p>
      @TODO: Add Log files filtered by frontend - i.e, logins, password resets,
      etc.
    </p>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsActivityRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsActivityRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: {
    Card,
  },
});
</script>
