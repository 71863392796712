export enum WillBuilderSections {
  FrontPage,
  Declaration,
  Revocation,
  Executors,
  Guardians,
  SpecificGifts,
  PropertyGifts,
  PetGifts,
  CashGifts,
  DefinitionOfEstate,
  DistributionOfResidue,
  ConditionalGifts,
  ExecutorAndTrusteePowers,
  Survivorship,
  Exclusions,
  StepPowers,
  AvoidanceOfDoubt,
  CharitiesClause,
  LegacyOfBusinessProperty,
  LegacyOfCombinedNRA,
  AbsoluteResiduaryGift,
  Trusts,
  ScheduleBusiness,
  ScheduleNRB,
  ScheduleIIP,
  ProvisionsBusiness,
  ProvisionsNRB,
  ProvisionsIIP,
  GeneralTrustPowers,
  OverridingPowers,
  PowerToRemove,
  BusinessClause,
  CharityGifts,
  ChattelGifts,
  MowIIP,
  MowNRB,
  MowBusiness,
  IsaGifts,
  SharesGifts,
  BankaccountGifts,
  LegacyOfRES,
  ScheduleRES,
  ProvisionsRES,
  MowRES,
  AnticipationOfMarriage,
  LetterOfWishes,
}

export const WillBuilderSectionTitles: Record<WillBuilderSections, string> = {
  [WillBuilderSections.FrontPage]: "Front Page",
  [WillBuilderSections.Declaration]: "Declaration",
  [WillBuilderSections.Revocation]: "Revocation",
  [WillBuilderSections.Executors]: "Executors",
  [WillBuilderSections.Guardians]: "Guardians",
  [WillBuilderSections.SpecificGifts]: "Specific Gifts",
  [WillBuilderSections.PropertyGifts]: "Property Gifts",
  [WillBuilderSections.PetGifts]: "Pet Gifts",
  [WillBuilderSections.CashGifts]: "Cash Gifts",
  [WillBuilderSections.DefinitionOfEstate]: "Definition Of Estate",
  [WillBuilderSections.DistributionOfResidue]: "Distribution Of Residue",
  [WillBuilderSections.ConditionalGifts]: "Conditional Gifts",
  [WillBuilderSections.ExecutorAndTrusteePowers]: "Executor And Trustee Powers",
  [WillBuilderSections.Survivorship]: "Survivorship",
  [WillBuilderSections.Exclusions]: "Exclusions",
  [WillBuilderSections.StepPowers]: "Step Powers",
  [WillBuilderSections.AvoidanceOfDoubt]: "Avoidance Of Doubt",
  [WillBuilderSections.CharitiesClause]: "Charities Clause",
  [WillBuilderSections.LegacyOfBusinessProperty]: "Legacy Of Business Property",
  [WillBuilderSections.LegacyOfCombinedNRA]: "Legacy Of Combined NRA",
  [WillBuilderSections.AbsoluteResiduaryGift]: "Absolute Residuary Gift",
  [WillBuilderSections.Trusts]: "Trusts",
  [WillBuilderSections.ScheduleBusiness]: "Schedule Business",
  [WillBuilderSections.ScheduleNRB]: "Schedule NRB",
  [WillBuilderSections.ScheduleIIP]: "Schedule IIP",
  [WillBuilderSections.ProvisionsBusiness]: "Provisions Business",
  [WillBuilderSections.ProvisionsNRB]: "Provisions NRB",
  [WillBuilderSections.ProvisionsIIP]: "Provisions IIP",
  [WillBuilderSections.GeneralTrustPowers]: "General Trust Powers",
  [WillBuilderSections.OverridingPowers]: "Overriding Powers",
  [WillBuilderSections.PowerToRemove]: "Power To Remove",
  [WillBuilderSections.BusinessClause]: "Business Clause",
  [WillBuilderSections.CharityGifts]: "Charity Gifts",
  [WillBuilderSections.ChattelGifts]: "Chattel Gifts",
  [WillBuilderSections.MowIIP]: "MOW IIP",
  [WillBuilderSections.MowNRB]: "MOW NRB",
  [WillBuilderSections.MowBusiness]: "MowBusiness",
  [WillBuilderSections.IsaGifts]: "ISA Gifts",
  [WillBuilderSections.SharesGifts]: "Shares Gifts",
  [WillBuilderSections.BankaccountGifts]: "Bank Account Gifts",
  [WillBuilderSections.LegacyOfRES]: "Legacy Of RES",
  [WillBuilderSections.ScheduleRES]: "Schedule RES",
  [WillBuilderSections.ProvisionsRES]: "Provisions RES",
  [WillBuilderSections.MowRES]: "MOW RES",
  [WillBuilderSections.AnticipationOfMarriage]: "Anticipation Of Marriage",
  [WillBuilderSections.LetterOfWishes]: "Letter Of Wishes",
};

export const WillBuilderOrderedSections = [
  WillBuilderSections.Revocation,
  WillBuilderSections.AnticipationOfMarriage,
  WillBuilderSections.Executors,
  WillBuilderSections.Guardians,
  WillBuilderSections.SpecificGifts,
  WillBuilderSections.BankaccountGifts,
  WillBuilderSections.SharesGifts,
  WillBuilderSections.IsaGifts,
  WillBuilderSections.CashGifts,
  WillBuilderSections.PropertyGifts,
  WillBuilderSections.CharityGifts, // 10% Clause
  WillBuilderSections.PetGifts,
  WillBuilderSections.LegacyOfBusinessProperty,
  WillBuilderSections.BusinessClause,
  WillBuilderSections.LegacyOfCombinedNRA,
  WillBuilderSections.ChattelGifts, // Letter of Wishes
  WillBuilderSections.DefinitionOfEstate,
  WillBuilderSections.DistributionOfResidue,
  WillBuilderSections.AbsoluteResiduaryGift,
  WillBuilderSections.LegacyOfRES,
  WillBuilderSections.ConditionalGifts,
  WillBuilderSections.CharitiesClause, // Charity officers clause
  WillBuilderSections.ExecutorAndTrusteePowers,
  WillBuilderSections.Survivorship,
  WillBuilderSections.Exclusions,
  WillBuilderSections.Trusts,
  WillBuilderSections.ScheduleBusiness,
  WillBuilderSections.ScheduleNRB,
  WillBuilderSections.ScheduleRES,
  WillBuilderSections.ScheduleIIP,
  WillBuilderSections.ProvisionsBusiness,
  WillBuilderSections.ProvisionsNRB,
  WillBuilderSections.ProvisionsRES,
  WillBuilderSections.ProvisionsIIP,
  WillBuilderSections.GeneralTrustPowers,
  WillBuilderSections.OverridingPowers,
  WillBuilderSections.PowerToRemove,
  WillBuilderSections.StepPowers,
  WillBuilderSections.AvoidanceOfDoubt,
  WillBuilderSections.MowIIP,
  WillBuilderSections.MowNRB,
  WillBuilderSections.MowRES,
  WillBuilderSections.MowBusiness,
];

export const WillBuilderOverridableSections = [
  WillBuilderSections.Revocation,
  WillBuilderSections.Executors,
  WillBuilderSections.Guardians,
  WillBuilderSections.CharityGifts,
  WillBuilderSections.ChattelGifts,
  WillBuilderSections.SpecificGifts,
  WillBuilderSections.PropertyGifts,
  WillBuilderSections.PetGifts,
  WillBuilderSections.CashGifts,
  WillBuilderSections.DistributionOfResidue,
  WillBuilderSections.ConditionalGifts,
  WillBuilderSections.Survivorship,
  WillBuilderSections.Exclusions,
  WillBuilderSections.BusinessClause,
  WillBuilderSections.CharitiesClause,
  WillBuilderSections.LegacyOfBusinessProperty,
  WillBuilderSections.LegacyOfCombinedNRA,
  WillBuilderSections.LegacyOfRES,
  WillBuilderSections.AbsoluteResiduaryGift,
  WillBuilderSections.Trusts,
  WillBuilderSections.ScheduleBusiness,
  WillBuilderSections.ScheduleNRB,
  WillBuilderSections.ScheduleRES,
  WillBuilderSections.ScheduleIIP,
  WillBuilderSections.ProvisionsBusiness,
  WillBuilderSections.ProvisionsNRB,
  WillBuilderSections.ProvisionsRES,
  WillBuilderSections.ProvisionsIIP,
  WillBuilderSections.GeneralTrustPowers,
  WillBuilderSections.OverridingPowers,
  WillBuilderSections.MowIIP,
  WillBuilderSections.MowNRB,
  WillBuilderSections.MowRES,
  WillBuilderSections.MowBusiness,
  WillBuilderSections.BankaccountGifts,
  WillBuilderSections.SharesGifts,
  WillBuilderSections.IsaGifts,
  WillBuilderSections.DefinitionOfEstate,
];
