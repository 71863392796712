import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import { Between, Equal, Like } from "@kinherit/orm";
import {
  BrandedKinvaultSubscriptionReferralCode,
  IBrandedKinvaultSubscriptionReferralCode,
} from "@kinherit/sdk";

interface ReadBrandedKinvaultSubscriptionReferralCodeMessage {
  name?: string;
  code?: string;
  brandedKinvault: string;
  created?: null | [number, number];
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort: {
    by: keyof IBrandedKinvaultSubscriptionReferralCode;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCodes: BrandedKinvaultSubscriptionReferralCode[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadBrandedKinvaultSubscriptionReferralCodeHandler = async (
  message: ReadBrandedKinvaultSubscriptionReferralCodeMessage,
): Promise<ReadBrandedKinvaultSubscriptionReferralCodeResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral-code",
  )
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(BrandedKinvaultSubscriptionReferralCode).where({
    brandedKinvault: Equal(message.brandedKinvault),
    name: Like(message.name),
    code: Like(message.code),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  const brandedKinvaultSubscriptionReferralCodes =
    BrandedKinvaultSubscriptionReferralCode.$inflate(
      response.brandedKinvaultSubscriptionReferralCode,
      undefined,
      response.$rootIds,
    );

  return {
    brandedKinvaultSubscriptionReferralCodes,
    pagination: getPagination(response),
  };
};
