<template>
  <EmailLogPage v-if="introducerCompany" :owner="introducerCompany" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsEmailLogRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";

export default defineComponent({
  name: IntroducerCompanyDetailsEmailLogRoute,
  mixins: [IntroducerCompanyDetailsMixin],
  components: {
    EmailLogPage,
  },
});
</script>
