import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  LessThan,
  Like,
} from "@kinherit/orm/index";
import { Api, EmailAddress, IProfile, Profile, QueryMask } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export interface ReadProfileMessage {
  // general
  name?: string;
  emailAddress?: string;
  created?: null | [number, number];
  query?: QueryMask<typeof Profile>;
  newsletterCampaigns?: null | boolean;
  // introducer contact options
  introducerContactStatus?: string[];
  introducerContactCompanyStatus?: string[];
  introducerContactCompanyStage?: string[];
  introducerContactCompanyType?: string[];
  introducerContactCompanyNetwork?: string[];
  introducerContactTags?: string[];
  introducerContactCompanyTags?: string[];
  introducerContactCpdSessionType?: string[];
  introducerContactCompany?: string[];
  // introducer company options
  introducerCompanyStatus?: string[];
  introducerCompanyStage?: string[];
  introducerCompanyType?: string[];
  introducerCompanyNetwork?: string[];
  introducerCompanyTags?: string[];
  introducerContactNewsletter?: null | boolean;
  // kinvault
  kinvaultNoRecentLogins?: null | boolean;
  // lead
  leadStatus?: string[];
  leadReferralCode?: string[];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IProfile;
    direction: "asc" | "desc";
  };
}

interface ReadProfileResponse {
  profiles: Array<Profile>;
  emailAddresses: EmailAddress[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadProfileHandler = async (
  message: ReadProfileMessage,
): Promise<ReadProfileResponse> => {
  const request = Api.resource("portal", "/v2/portal/email-campaign/profile")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(Profile).where({
    newsletterCampaigns: Equal(message.newsletterCampaigns),
    fullName: Like(message.name),
    emails: {
      primary: Equal(true),
      email: Like(message.emailAddress),
    },
    person: {
      primaryOwnerOfKintin: {
        id: message.kinvaultNoRecentLogins ? IsNotNull() : undefined,
        assignedUsers: {
          accessType: message.kinvaultNoRecentLogins
            ? Equal("owner")
            : undefined,
          user: {
            credential: {
              lastLoggedIn: message.kinvaultNoRecentLogins
                ? LessThan(new DateTime().sub(1, "year"))
                : undefined,
            },
          },
        },
      },
    },
    introducerContact: {
      status: {
        id: In(message.introducerContactStatus),
      },
      cpds: {
        session: {
          type: {
            value: In(message.introducerContactCpdSessionType),
          },
        },
      },
      tags: {
        id: In(message.introducerContactTags),
      },
      companies: {
        id: In(message.introducerContactCompany),
        status: {
          id: In(message.introducerContactCompanyStatus),
        },
        stage: {
          id: In(message.introducerContactCompanyStage),
        },
        type: {
          id: In(message.introducerContactCompanyType),
        },
        network: {
          id: In(message.introducerContactCompanyNetwork),
        },
        tags: {
          id: In(message.introducerContactCompanyTags),
        },
      },
      newsLetter: Equal(message.introducerContactNewsletter),
    },
    introducerCompany: {
      status: {
        id: In(message.introducerCompanyStatus),
      },
      stage: {
        id: In(message.introducerCompanyStage),
      },
      type: {
        id: In(message.introducerCompanyType),
      },
      network: {
        id: In(message.introducerCompanyNetwork),
      },
      tags: {
        id: In(message.introducerCompanyTags),
      },
    },
    lead: {
      status: {
        id: In(message.leadStatus),
      },
      referral: {
        referralCode: {
          code: In(message.leadReferralCode),
        },
      },
    },
    createdAt: Between(message.created),
    ...message.query,
  });

  const response = await request.result();

  return {
    profiles: Profile.$inflate(
      response.profile,
      message.sort,
      response.$rootIds,
    ),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    pagination: getPagination(response),
  };
};
