import { OptionsSelectField } from "@/config/form.config";
import { CreatePhysicalStorageFileLog } from "@/config/model.config";
import { AuthService } from "@/service/auth.service";
import { FormTable } from "@kinherit/framework/component.display/table";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { PhysicalStorageFile, PhysicalStorageFileLog } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export const CreatePhysicalStorageFileLogForm = (
  physicalStorageFile: PhysicalStorageFile,
) =>
  defineForm({
    name: "create-physical-storage-file-log-form",
    data: () => ({
      physicalStorageFile,
      physicalStorageFileLog: CreatePhysicalStorageFileLog({
        file: physicalStorageFile.id,
        createdBy: AuthService.loggedInUser?.profile.firstName ?? undefined,
        requestedAt: new DateTime().formatMachine,
      }),
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-physical-storage-file-log-form-area",
        data,
        template: GridLayout([
          ["formTitle"],
          ["date", "requestedBy", "actions"],
          ["notes"],
          ["table"],
        ]),
        components: () => ({
          formTitle: [
            FormTitle({
              props: {
                text: "Create new request",
                isEmphasised: true,
                htag: "h4",
              },
            }),
          ],
          table: [
            FormTitle({
              props: {
                text: "File History",
                isEmphasised: true,
                htag: "h4",
              },
            }),
            FormTable({
              props: {
                columns: [
                  {
                    title: "Date",
                    map: (row: PhysicalStorageFileLog) =>
                      row.createdAt?.formatDate,
                  },
                  {
                    title: "Requested By",
                    map: (row: PhysicalStorageFileLog) => row.createdBy,
                  },
                  {
                    title: "Confirmed By",
                    mapHtml: (row: PhysicalStorageFileLog) =>
                      row.confirmedBy && row.completedAt
                        ? `${row.confirmedBy} <small>${row.completedAt.formatDate}</small>`
                        : "<em>Not Yet Completed</em>",
                  },
                  {
                    title: "Action",
                    map: (row: PhysicalStorageFileLog) =>
                      row.confirmedBy && row.completedAt
                        ? (row.logAction.data?.past as string)
                        : (row.logAction?.text as string),
                  },
                ],
                rows: (data) =>
                  data.physicalStorageFile.logs.filter(
                    (log) => log.id !== data.physicalStorageFileLog.id,
                  ),
              },
            }),
          ],
          date: [
            FormDateField({
              props: {
                label: "Date",
                message: "Date of Action",
                validators: ["required"],
              },
              models: {
                value: "physicalStorageFileLog.requestedAt",
              },
            }),
          ],
          requestedBy: [
            FormTextField({
              props: {
                label: "Requested By",
                validators: ["required"],
              },
              models: {
                value: "physicalStorageFileLog.createdBy",
              },
            }),
          ],
          actions: [
            OptionsSelectField({
              group: "physicalStorageFileLogActions",
              vModel: "physicalStorageFileLog.logAction",
              props: {
                validators: ["required"],
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
              },
              models: {
                value: "physicalStorageFileLog.notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
