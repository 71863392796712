<template>
  <Card v-if="$data.form" :title="$data.form.localData.fullName ?? undefined">
    <FormControls
      :form="$data.form"
      mode="update"
      @cancel="$router.back"
      @save="save"
      :delete-button="false"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
</template>

<script lang="ts">
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserDetailsUpdateProfile",
  mixins: [
    AuthService.mixin({ sessionRequired: true, permissions: ["user:write"] }),
    UserDetailsMixin,
  ],
  components: {
    FormControls,
    Card,
  },
  data: () => ({
    form: null as null | FormBuilder<() => Profile, string>,
  }),
  mounted(): void {
    this.form = UpdateProfileForm(this.profile as Profile);
  },
  methods: {
    async save(): Promise<void> {
      if (null === this.user || null === this.profile) {
        return;
      }

      // await window.Kernel.ActionBus.execute("admin/user/profile/update", {
      //   user: this.user,
      // });
      await window.Kernel.ActionBus2.adminUser.profile.update({
        user: this.user,
      });

      this.$router.back();
    },
    cancel(): void {
      this.$router.back();
    },
    reset(): void {
      this.form?.localData.$restore();
    },
  },
});
</script>
