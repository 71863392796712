import { SharedFilterProps } from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadBrandedKinvaultInviteBillingForm = () => {
  return MasterListFiltersForm({
    name: "filter-brandedKinvaultInvitation-form",
    data: () => ({
      year: new Date().getFullYear().toString(),
      granularity: "quarter" as "month" | "quarter",
    }),
    props: SharedFilterProps,
    updateUrlQuery: false,
  })({
    basicComponents: () => ({
      year: [
        FormSelectField({
          props: {
            label: "Year",
            options: () => {
              const options: Record<string, string> = {};

              const currentYear = new Date().getFullYear();

              for (let i = 2023; i <= currentYear; i++) {
                options[i.toString()] = i.toString();
              }

              return options;
            },
          },
          models: {
            value: "year",
          },
        }),
      ],
      granularity: [
        FormSelectField({
          props: {
            label: "Granularity",
            options: {
              month: "Month",
              quarter: "Quarter",
            },
          },
          models: {
            value: "granularity",
          },
        }),
      ],
    }),
  });
};
