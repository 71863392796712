<template>
  <div>
    <PageHeader htag="h2" text="Notes">
      <template #buttons>
        <Button
          v-if="showAccountButton"
          @click="openAccount"
          text="Go To Account"
        />
        <Button
          v-if="hasWritePermission && isIntroducerNote && introducerCompany"
          @click="createKPP"
          text="Add KPP"
        />
        <Button v-if="hasWritePermission" @click="create" text="Add Note" />
      </template>
    </PageHeader>
    <component :is="component" class="notes-page">
      <MasterListFilters
        @submit="search"
        @change="change"
        :is-nested="true"
        :filters="$data.filters"
      >
      </MasterListFilters>
      <span :key="renderKey">
        <p
          v-if="pagination.count === 0"
          :class="{
            'is-size-5': true,
            'has-text-centered': true,
          }"
        >
          No Notes Found
        </p>

        <NoteSummary
          v-else
          class="nested-card"
          v-for="note in notes"
          :key="`note-${note.id}`"
          :note="note as never"
          :has-context-menu="hasWritePermission && note.type !== 'callLogNote'"
          @context-menu="() => contextMenu(note as never)"
        />

        <Pagination
          v-if="pagination.count > 1"
          :current="pagination.currentPage"
          :total="pagination.lastPage"
          @update:current="changePage"
        />
      </span>
    </component>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateIntroducerNote } from "@/config/model.config";
import NoteSummary from "@/module/core/component/note/NoteSummary.vue";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { StyleService } from "@/service/style.service";
import { ReadNoteForm } from "@/shared/form/note/read-note.form";
import { UpdateNoteForm } from "@/shared/form/note/update-note.form";
import { Pagination } from "@kinherit/framework/component.display/pagination";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import {
  IntroducerCompany,
  IntroducerContact,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotesPage",
  components: {
    NoteSummary,
    Card,
    Pagination,
    Button,
    MasterListFilters,
    PageHeader,
  },
  props: {
    hasWritePermission: {
      type: Boolean,
      required: true,
    },
    isNote: {
      type: Boolean,
      default: true,
    },
    isIntroducerNote: {
      type: Boolean,
      default: false,
    },
    kintin: {
      type: Object as PropType<Kintin | null>,
      default: null,
    },
    lead: {
      type: Object as PropType<Lead | null>,
      default: null,
    },
    introducerCompany: {
      type: Object as PropType<IntroducerCompany | null>,
      default: null,
    },
    introducerContact: {
      type: Object as PropType<IntroducerContact | null>,
      default: null,
    },
    person: {
      type: Object as PropType<Person | null>,
      default: null,
    },
    introducerOutsource: {
      type: Object as PropType<IntroducerOutsource | null>,
      default: null,
    },
    hideCard: {
      type: Boolean,
      default: false,
    },
    showAccountButton: {
      type: Boolean,
      default: false,
    },
    noteTypes: {
      type: Array as PropType<
        (
          | "note"
          | "introducerNote"
          | "kintinCheck"
          | "kintinCheckNote"
          | "kintinCheckFollowUp"
          | "creditNote"
          | "communicationNote"
          | "callLogNote"
          | "kinvaultCheck"
          | "kintinStatusChange"
        )[]
      >,
      default: () => [],
    },
  },
  computed: {
    component(): typeof Card | "div" {
      return this.hideCard ? "div" : Card;
    },
  },
  data: (ctx) => ({
    notes: Array<Note>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      count: 0,
    },
    sort: {
      direction: "desc" as "desc" | "asc",
    },
    filters: ReadNoteForm({
      types: ctx.noteTypes,
    }),
    renderKey: 0,
  }),
  mounted(): void {
    this.loadNotes();
  },
  methods: {
    async change(): Promise<void> {
      if (this.pagination.perPage <= 25) {
        this.pagination.currentPage = 1;
        await this.loadNotes();
      }
    },
    async search(): Promise<void> {
      this.pagination.currentPage = 1;
      await this.loadNotes();
    },
    async changePage(page: number): Promise<void> {
      this.pagination.currentPage = page;
      await this.loadNotes();
    },
    async contextMenu(note: Note): Promise<void> {
      if (!this.hasWritePermission) {
        return;
      }

      const option = await OpenContextMenu({
        items: [
          {
            title: "Edit",
            line1: "Make changes to this note",
            icon: StyleService.icon.edit.icon,
            action: "edit" as const,
          },
          {
            title: "Delete",
            line1: "Remove this note",
            icon: StyleService.icon.delete.icon,
            action: "delete" as const,
          },
        ],
        trackingField: "title",
        titleField: "title",
        line1Field: "line1",
        iconLeftField: "icon",
      });

      await this[option.action](note);
    },
    async loadNotes(): Promise<void> {
      console.log("loadNotes", this.kintin);
      // const { notes, pagination } = await window.Kernel.ActionBus.execute(
      //   "core/note/read",
      //   {
      //     ...this.filters.localData,
      //     sort: {
      //       // @ts-ignore
      //       by: "pinned,createdAt",
      //       direction: this.sort.direction,
      //     },
      //     pagination: this.pagination,
      //     kintin: this.kintin ?? undefined,
      //     lead: this.lead ?? undefined,
      //     introducerCompany: this.introducerCompany ?? undefined,
      //     introducerContact: this.introducerContact ?? undefined,
      //     // @ts-ignore
      //     introducerOutsource: this.introducerOutsource ?? undefined,
      //     person: this.person ?? undefined,
      //   },
      // );

      const { notes, pagination } =
        await window.Kernel.ActionBus2.core.note.read({
          ...this.filters.localData,
          sort: {
            // @ts-ignore
            by: "pinned,createdAt",
            direction: this.sort.direction,
          },
          pagination: this.pagination,
          kintin: this.kintin ?? undefined,
          lead: this.lead ?? undefined,
          introducerCompany: this.introducerCompany ?? undefined,
          introducerContact: this.introducerContact ?? undefined,
          // @ts-ignore
          introducerOutsource: this.introducerOutsource ?? undefined,
          person: this.person ?? undefined,
        });

      let sortedNotes = notes.sortBy("createdAt", this.sort.direction);

      sortedNotes = sortedNotes.sortBy("pinned", "desc");

      this.notes = sortedNotes;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.renderKey++;
    },
    async edit(note: Note): Promise<void> {
      const form = UpdateNoteForm({
        data: note,
        kintin: this.kintin ?? undefined,
        lead: this.lead ?? undefined,
      });

      try {
        await form.dialog({
          dialog: {
            title: `Edit note "${note.name}"`,
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        note.$restore();
        if ("introducerNote" in note) {
          note.introducerNote?.$restore();
        }

        throw e;
      }

      // await window.Kernel.ActionBus.execute("core/note/update", {
      //   kintin: this.kintin,
      //   lead: this.lead,
      //   introducerCompany: this.introducerCompany,
      //   introducerContact: this.introducerContact,
      //   introducerOutsource: this.introducerOutsource,
      //   note: note,
      //   person: this.person,
      // } as never);
      await window.Kernel.ActionBus2.core.note.update({
        kintin: this.kintin,
        lead: this.lead,
        introducerCompany: this.introducerCompany,
        introducerContact: this.introducerContact,
        introducerOutsource: this.introducerOutsource,
        note: note,
        person: this.person,
      } as never);

      await this.loadNotes();
    },
    async delete(note: Note): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `Delete "${note.name}"`,
          message: "Are you sure you want to delete this note?",
        },
        button: {
          ok: {
            text: "Delete",
          },
        },
      });

      // await window.Kernel.ActionBus.execute("core/note/delete", {
      //   kintin: this.kintin,
      //   lead: this.lead,
      //   introducerCompany: this.introducerCompany,
      //   introducerContact: this.introducerContact,
      //   introducerOutsource: this.introducerOutsource,
      //   note: note,
      //   person: this.person,
      // } as never);
      await window.Kernel.ActionBus2.core.note.delete({
        kintin: this.kintin,
        lead: this.lead,
        introducerCompany: this.introducerCompany,
        introducerContact: this.introducerContact,
        introducerOutsource: this.introducerOutsource,
        note: note,
        person: this.person,
      } as never);

      await this.loadNotes();
    },
    create() {
      if (!this.hasWritePermission) {
        return;
      }

      if (this.isIntroducerNote) {
        this.createDialog("create-introducer-note");
      } else {
        this.createDialog("create-note");
      }
    },
    async createKPP() {
      const SALES_TEAM_LEAD_REQUIRED_MSG =
        "You need to assign a Sales Team Lead to the company before you can add the Kinherit Partner Program Reminders.";
      const KPP_CONFIRM_MSG =
        "Are you sure you want to add the Kinherit Partner Program Reminders?";
      const KPP_TITLE = "Create KPP";
      const NOTE_TITLE = "Kinherit Partner Programme";

      if (!this.introducerCompany) {
        return;
      }

      if (!this.introducerCompany.seniorAdviser) {
        await DangerDialog({
          dialog: {
            title: KPP_TITLE,
            message: SALES_TEAM_LEAD_REQUIRED_MSG,
          },
          button: {
            ok: {
              text: "OK",
            },
          },
        });
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: KPP_TITLE,
          message: KPP_CONFIRM_MSG,
        },
        button: {
          ok: {
            text: "Yes Create",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      const createNote = (notes: string, weeks: number) => {
        const note = CreateIntroducerNote();
        note.dueAtNotifySales = true;
        note.note.name = NOTE_TITLE;
        note.note.notes = notes;
        note.note.dueAt = new DateTime().add(weeks, "week");
        return note;
      };

      const notesToCreate = [
        createNote("Chase to set up welcome to Kinherit meeting", 1),
        createNote("Check lead volumes / campaign kit", 5),
        createNote("Check CPD attendance", 9),
        createNote("Request peer referrals / intros", 19),
      ];

      try {
        for (const introducerNote of notesToCreate) {
          // await window.Kernel.ActionBus.execute("core/note/create", {
          //   note: introducerNote.note,
          //   introducerCompany: this.introducerCompany,
          // });
          await window.Kernel.ActionBus2.core.note.create({
            note: introducerNote.note,
            introducerCompany: this.introducerCompany,
          });
        }
        await this.loadNotes();
      } catch (error) {
        console.error("Error creating KPP notes:", error);
      }
    },
    openAccount() {
      if (undefined !== this.kintin && null !== this.kintin) {
        const params: KinvaultKintinDetailsParams = {
          kintin: this.kintin.id,
        };

        window.Kernel.visitRoute({
          name: KinvaultKintinDetailsRoute,
          params,
        });
      }
    },
    async createDialog(
      type: "create-note" | "create-introducer-note",
    ): Promise<void> {
      const form = UpdateNoteForm({
        data: type,
        kintin: this.kintin ?? undefined,
        lead: this.lead ?? undefined,
      });

      try {
        const note = await form.dialog({
          dialog: {
            title: "Create new note",
          },
          button: {
            ok: {
              text: "Create",
            },
          },
        });

        // await window.Kernel.ActionBus.execute("core/note/create", {
        //   kintin: this.kintin,
        //   lead: this.lead,
        //   introducerCompany: this.introducerCompany,
        //   introducerContact: this.introducerContact,
        //   introducerOutsource: this.introducerOutsource,
        //   note: note,
        //   person: this.person,
        // } as never);
        await window.Kernel.ActionBus2.core.note.create({
          kintin: this.kintin,
          lead: this.lead,
          introducerCompany: this.introducerCompany,
          introducerContact: this.introducerContact,
          introducerOutsource: this.introducerOutsource,
          note: note,
          person: this.person,
        } as never);

        await this.loadNotes();
      } catch {
        form.localData.$delete({
          introducerNote: true,
        });
      }
    },
  },
});
</script>
