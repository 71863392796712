<template>
  <CallScriptSection :title="title">
    <RichTextField v-model:value="computedValue" />
    <div class="buttons is-right">
      <Button color="is-positive" @click="save" text="Save" />
      <Button
        v-if="hasContinue"
        color="is-positive"
        @click="saveAndContinue"
        text="Save and Continue"
      />
    </div>
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import Button from "@kinherit/framework/component.input/button";
import RichTextField from "@kinherit/framework/component.input/rich-text-field";
import { Kintin } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "SectionNotes",
  components: { CallScriptSection, RichTextField, Button },
  props: {
    value: {
      type: String as PropType<string | null>,
      nullable: true,
    },
    title: {
      type: String,
      default: "Specialist Notes for this section",
    },
    kintin: {
      type: Kintin,
      required: true,
    },
    hasContinue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value", "save-continue"],
  computed: {
    computedValue: {
      get(): string | null {
        return this.value ?? null;
      },
      set(value: string | null) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    async save(): Promise<void> {
      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/call-script/update",
      //   {
      //     kintin: this.kintin,
      //   },
      // );
      await window.Kernel.ActionBus2.kinvaultKintin.callScript.update({
        kintin: this.kintin,
      });
    },
    async saveAndContinue(): Promise<void> {
      await this.save();
      this.$emit("save-continue");
    },
  },
});
</script>
