import {
  IntroducerCompanyField,
  IntroducerNetworkField,
  OptionsAutoCompleteField,
  TagsField,
} from "@/config/form.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";

export const ReadIntroducerContactProfileComponent = () =>
  FormElement({
    props: {
      is: "div",
      style: {
        borderBottom: `1px dashed hsl(0,0%,71%)`,
      },
      class: "mb-4 pb-3",
    },
    slots: {
      default: {
        template: GridLayout([
          ["title"],
          [
            "cpdSessionType",
            "contactTags",
            "contactStatus",
            "company",
            "newsletter",
          ],
          [
            "companyTags",
            "companyNetwork",
            "companyStatus",
            "companyStage",
            "companyType",
          ],
        ]),
        components: {
          title: [
            FormTitle({
              props: {
                text: "Introducers Contacts",
                size: "is-4",
              },
            }),
          ],
          contactStatus: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Contact status",
              },
              group: "introducerStatus",
              vModel: "introducerContactStatus",
              simplifyData: true,
            }),
          ],
          companyStatus: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Company status",
              },
              group: "introducerStatus",
              vModel: "introducerContactCompanyStatus",
              simplifyData: true,
            }),
          ],
          company: [
            IntroducerCompanyField({
              props: {
                isMultiSelect: true,
                label: "Company",
                vModel: "introducerContactCompany",
              },
              simplifyData: true,
            }),
          ],
          newsletter: [
            FormCheckboxField({
              props: {
                label: "Newsletter Subscriber",
              },
              models: {
                value: "introducerContactNewsletter",
              },
            }),
          ],
          companyStage: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Company Stage",
              },
              group: "introducerStage",
              vModel: "introducerContactCompanyStage",
              simplifyData: true,
            }),
          ],
          companyType: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Company type",
              },
              group: "introducerType",
              vModel: "introducerContactCompanyType",
              simplifyData: true,
            }),
          ],
          companyNetwork: [
            IntroducerNetworkField({
              props: {
                label: "Company Network",
                isMultiSelect: true,
                vModel: "introducerContactCompanyNetwork",
              },
              simplifyData: true,
            }),
          ],
          cpdSessionType: [
            OptionsAutoCompleteField({
              props: {
                label: "CPD Session Type",
                isMultiSelect: true,
              },
              group: "introducerCpdType",
              vModel: "introducerContactCpdSessionType",
              simplifyData: true,
            }),
          ],
          contactTags: [
            TagsField({
              props: {
                label: "Contact Tags",
                isMultiSelect: true,
                vModel: "introducerContactContactTags",
              },
              simplifyData: true,
            }),
          ],
          companyTags: [
            TagsField({
              props: {
                label: "Company Tags",
                isMultiSelect: true,
                vModel: "introducerContactCompanyTags",
              },
              simplifyData: true,
            }),
          ],
        },
      },
    },
  });
