<template>
  <Card :title="role.who" title-size="is-4" isNested class="executor-summary">
    <DataListWidget
      no-card
      is-compact
      :details="[
        {
          label: `Relationship`,
          value: role.relationship,
          hide: null === role.relationship || `` === role.relationship,
        },
        {
          label: `Excluded By`,
          value: role.excludedBy?.profile.fullName,
          hide: undefined === role.excludedBy?.profile.fullName,
        },
        {
          label: `Why`,
          value: role.why,
          hide: null === role.why || `` === role.why,
        },
      ]"
    />
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
      />
      <Button
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="$emit('delete', $event)"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Exclusion } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExecutorSummary",
  props: {
    role: {
      type: Exclusion as PropType<Exclusion>,
      required: true,
    },
  },
  components: { Card, DataListWidget, Button },
  emits: ["edit", "delete"],
});
</script>
