import { SharedFormProps } from "@/config/form.config";
import { OptionService } from "@/module/core/service/option.service";
import { FormCalendar } from "@kinherit/framework/component.display/calendar";
import { FormField } from "@kinherit/framework/component.form/field";
import { FormDropDownMenu } from "@kinherit/framework/component.input/drop-down-menu";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Lead, Option } from "@kinherit/sdk";

const mainLeadStatusProgressOptions = [
  "new",
  "junk",
  "build",
  "potential",
  "inprogress",
  "inprogress2",
  "nurture",
  "rebook",
  "uc_no_tel",
  "uc_with_tel",
];

const mainLeadStatusQoOptions = [
  "deadlead",
  "donotcall",
  "duplicate",
  "qualifiedout",
];

const mainLeadStatusCompleteOptions = [
  // "converted", <-- don't allow move to converted via this modal
  "enquiry",
  "willreview",
];

const ShowOptionsMatrix = {
  nextAction: (data: Lead) => data.status?.value !== "closed",
  closedReason: (data: Lead) => data.status?.value === "closed",
};

export const UpdateLeadStatusForm = (lead: Lead) => {
  const mainStatuses = OptionService.getOptions("leadStatus", true);
  return defineForm({
    name: "update-lead-status-form",
    props: SharedFormProps,
    data: () => lead,
    formAreas: (data) => [
      defineFormArea({
        name: "update-lead-status-form-area",
        data,
        template: {
          attr: {
            class: "columns",
          },
          children: [
            {
              attr: {
                class: "column columns",
              },
              slot: "stage_status_progress",
            },
            {
              attr: {
                class: "column columns",
              },
              slot: "stage_status_qo",
              children: [
                {
                  slot: "stage_status_complete",
                },
              ],
            },
            {
              attr: {
                class: "column",
                style: {
                  // minWidth: "270px",
                },
              },
              children: [
                {
                  slot: "calendar",
                },
              ],
            },
          ],
        },
        components: () => ({
          stage_status_progress: [
            FormField({
              props: {
                label: "Progress",
                class: "column",
              },
              slots: {
                default: [
                  FormDropDownMenu({
                    props: {
                      noSort: true,
                      isMultiSelect: false,
                      includeSelectedInOptions: false,
                      options: () =>
                        mainLeadStatusProgressOptions.map((s) =>
                          mainStatuses.find((status) => status.value === s),
                        ) ?? [],
                      showMoreButton: false,
                      mapOptions: {
                        value: "id",
                        label: "text",
                        line1: "description",
                      },
                      isStatic: true,
                      show: true,
                      isScrollable: false,
                      rowClasses: () => (item: Option) => {
                        console.log("item", item);
                        return `has-background-${item?.class}-light has-border-${item?.class}`;
                      },
                      reference: "status",
                      forceBackground: true,
                    },
                    models: {
                      value: {
                        get: (lead) => [lead.status],
                        set: (value, data, controls) => {
                          data.status = value ?? data.status;
                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          stage_status_qo: [
            FormField({
              props: {
                label: "Qualified Out",
                class: "column",
              },
              slots: {
                default: [
                  FormDropDownMenu({
                    props: {
                      noSort: true,
                      isMultiSelect: false,
                      includeSelectedInOptions: false,
                      options: () =>
                        mainLeadStatusQoOptions.map((s) =>
                          mainStatuses.find((status) => status.value === s),
                        ) ?? [],
                      showMoreButton: false,
                      mapOptions: {
                        value: "id",
                        label: "text",
                        line1: "description",
                      },
                      isStatic: true,
                      show: true,
                      isScrollable: false,
                      rowClasses: () => (item: Option) =>
                        `has-background-${item?.class}-light has-border-${item?.class}`,
                      reference: "status",
                      forceBackground: true,
                    },
                    models: {
                      value: {
                        get: (lead) => [lead.status],
                        set: (value, data, controls) => {
                          data.status = value ?? data.status;
                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          stage_status_complete: [
            FormField({
              props: {
                label: "Completed",
                class: "column",
              },
              slots: {
                default: [
                  FormDropDownMenu({
                    props: {
                      noSort: true,
                      isMultiSelect: false,
                      includeSelectedInOptions: false,
                      options: () =>
                        mainLeadStatusCompleteOptions.map((s) =>
                          mainStatuses.find((status) => status.value === s),
                        ) ?? [],
                      showMoreButton: false,
                      mapOptions: {
                        value: "id",
                        label: "text",
                        line1: "description",
                      },
                      isStatic: true,
                      show: true,
                      isScrollable: false,
                      rowClasses: () => (item: Option) =>
                        `has-background-${item?.class}-light has-border-${item?.class}`,
                      reference: "status",
                      forceBackground: true,
                    },
                    models: {
                      value: {
                        get: (lead) => [lead.status],
                        set: (value, data, controls) => {
                          data.status = value ?? data.status;
                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          calendar: [
            FormField({
              props: {
                vIf: (lead) => ShowOptionsMatrix.nextAction(lead),
                label: "Next Action Due",
                class: "mb-4",
              },
              slots: {
                default: [
                  FormCalendar({
                    props: {
                      isWidget: true,
                      isDatePicker: true,
                      showSuggestions: true,
                      reference: "nextActionAt",
                    },
                    models: {
                      value: {
                        get: (lead) => lead.referral?.nextActionAt,
                        set: (value, data) => {
                          if (!data.referral) {
                            return;
                          }

                          data.referral.nextActionAt = value;
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
