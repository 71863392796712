import { Api } from "@/service/api.service";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  LessThanOrEqual,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import { IOrder, Order } from "@kinherit/sdk";
import { DateTime, Time } from "@kinherit/ts-common";

export interface DownloadIntroducerFeeKintinCsvMessage {
  referralCode: Array<string>;
  agent: Array<string>;
  period: null | [number, number];
  reconciled: null | boolean;
  showOrders: "waitingForPayment" | "within21DayCoolOffPeriod" | null;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IOrder;
    direction: "asc" | "desc";
  };
}

export const DownloadIntroducerFeeKintinCsvHandler = async (
  message: DownloadIntroducerFeeKintinCsvMessage,
): Promise<void> => {
  const request = Api.resource("portal", "/v2/portal/introducer-fee/kintin/csv")
    .method("get")
    .paginate({
      currentPage: 1,
      perPage: 1000,
    })
    .sort({
      sortBy: message.sort?.by ?? "paidAt",
      sortDirection: message.sort?.direction ?? "desc",
    });

  let paidAt = undefined;

  if (message.period) {
    paidAt = Between([
      DateTime.fromDate(new Date(message.period[1], message.period[0] - 1, 1))
        .timestamp,
      DateTime.fromDate(new Date(message.period[1], message.period[0], 1))
        .timestamp,
    ]);
  }

  // Day 21 -> onwards after payment
  if (message.showOrders === "waitingForPayment") {
    paidAt = LessThanOrEqual(
      DateTime.fromDate(new Date())
        .sub(Time.fromArray(0, 0, 0, 0, 21))
        .setTime(0, 0, 0, 0).timestamp,
    );
  }
  // Day 0 -> 21 after payment
  if (message.showOrders === "within21DayCoolOffPeriod") {
    paidAt = MoreThanOrEqual(
      DateTime.fromDate(new Date())
        .sub(Time.fromArray(0, 0, 0, 0, 21))
        .setTime(0, 0, 0, 0).timestamp,
    );
  }

  let kintin = undefined;

  if (message.agent.length > 0) {
    kintin = {
      referral: {
        referralCode: {
          company: {
            introducedBy: {
              id: In(message.agent),
            },
          },
        },
      },
    };
  } else {
    kintin = {
      referral: {
        referralCode: {
          id: In(message.referralCode),
        },
      },
    };
  }

  request.buildQuery(Order).where({
    kintin,
    paidAt,
    feesConfirmed: Equal(message.reconciled),
    xeroIntroducerBillId: IsNotNull(),
  });

  await request.download({
    fileName: "fees.csv",
    encoding: "text/csv",
  });
};
