import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { ReadAppointmentHandler } from "./read-appointment.action";
import { UpdateAppointmentHandler } from "./update-appointment.action";

export const Actions = {
  "appointment/read": ReadAppointmentHandler,
  "appointment/update": UpdateAppointmentHandler,
};

export const AppointmentActionBus = DefineActionBus({
  name: "Appointment",
  actions: {
    read: ReadAppointmentHandler,
    update: UpdateAppointmentHandler,
  },
});
