import { SharedFormProps, XeroContactField } from "@/config/form.config";

import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";

import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Currency } from "@kinherit/ts-common/index";

import { FormTitle } from "@kinherit/framework/component.display/title";

export const CreateXeroRepeatingInvoice = (data: {
  formTitle?: string;
  price: Currency;
  subscriptionId: string;
  xeroContactId?: string;
  xeroContactName?: string;
  quantity?: number;
  description?: string;
  accountCode?: string;
  searchTerm?: string;
}) => {
  return defineForm({
    name: "create-xero-repeating-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-xero-repeating-form-area",
        data,
        template: GridLayout([
          ["title"],
          ["price", "quantity", "description"],
          [
            {
              is: "hr",
            },
          ],
          ["xeroContactId"],
        ]),
        components: () => ({
          title: [
            FormTitle({
              props: {
                text: data.formTitle,
                size: "is-5",
              },
            }),
          ],
          price: [
            FormCurrencyField({
              props: {
                label: "Price",
                validators: ["required"],
                message: "Value of the subscription",
                showClearButton: false,
              },
              models: {
                value: "price",
              },
            }),
          ],
          quantity: [
            FormNumberField({
              props: {
                label: "Quantity",
                message: "Should always be 1 for now",
              },
              models: {
                value: "quantity",
              },
            }),
          ],
          description: [
            FormTextField({
              props: {
                label: "Description",
                message: "Description of the lineitem",
              },
              models: {
                value: "description",
              },
            }),
          ],
          xeroContactId: [
            // FormButton({
            //   reactive: true,
            //   props: {
            //     text: "Select Xero Contact",
            //   },
            //   emits: {
            //     click: async (value: string, _, controls) => {
            //       console.log("xeroContactId", value);

            //       const { contacts } = await window.Kernel.ActionBus.execute(
            //         "external/xero/contacts/read",
            //         {
            //           type: "KINHERIT",
            //           searchTerm: "",
            //         },
            //       );
            //       const contact: (typeof contacts)[number] =
            //         (await OpenAutocompleteDialog({
            //           list: {
            //             options: () => contacts,
            //             mapOptions: {
            //               label: "name",
            //               value: "contactID",
            //               line1: "emailAddress",
            //             },
            //           },
            //           asyncFilter: async (searchTerm: string | null) => {
            //             if (!searchTerm) {
            //               return contacts;
            //             }

            //             const { contacts: filterContacts } =
            //               await window.Kernel.ActionBus.execute(
            //                 "external/xero/contacts/read",
            //                 {
            //                   type: "KINHERIT",
            //                   summaryOnly: true,
            //                   searchTerm,
            //                 },
            //               );

            //             return filterContacts;
            //           },
            //           dialog: {
            //             title: "Select Contact",
            //           },
            //         })) as never;
            //       console.log("contact", contact);
            //       data.xeroContactId = contact.contactID;
            //       data.xeroContactName = contact.name;
            //       controls.rebuildForm();
            //     },
            //   },
            // }),
            // FormElement({
            //   props: {
            //     vIf: () =>
            //       undefined !== data.xeroContactId &&
            //       undefined !== data.xeroContactName,
            //     html: (data) => `
            //     <div>
            //       <p>${data.xeroContactName} - ${data.xeroContactId}</p>
            //     </div>
            //     `,
            //   },
            // }),
            XeroContactField({
              props: {
                label: "Xero Contact",
                message: "Select the contact to invoice",
                vModel: "xeroContactId",
              },
              simplifyData: true,
            }),
          ],
        }),
      }),
    ],
  });
};
