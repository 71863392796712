import { RouteInterface } from "@kinherit/framework/core/route-config";

export const AdminLogIndexRoute = "AdminLogIndex";
export const FailedLoginAttemptsMasterListRoute =
  "FailedLoginAttemptsMasterList";

export const EmailLogMasterListRoute = "EmailLogMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: AdminLogIndexRoute,
    path: "/admin/log",
    component: () => import(/* webpackChunkName: "Index" */ "./Index.vue"),
    children: [
      {
        name: FailedLoginAttemptsMasterListRoute,
        path: "/admin/log/failed-login-attempts",
        component: () =>
          import(
            /* webpackChunkName: "FailedLoginAttemptsMasterList" */ "./FailedLoginAttemptsMasterList.vue"
          ),
      },
      {
        name: EmailLogMasterListRoute,
        path: "/admin/log/email-log",
        component: () =>
          import(
            /* webpackChunkName: "EmailLogMasterList" */ "./EmailLogMasterList.vue"
          ),
      },
    ],
  },
];
