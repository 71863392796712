<template>
  <div v-if="data && sections.enabled" class="will will-highlight">
    <!-- FrontPage -->
    <Section
      v-show="showSection(sections.ids.FrontPage)"
      :section-id="sections.ids.FrontPage"
      :state="getSectionState(sections.ids.FrontPage)"
      @update:state="(state) => (sectionStates[sections.ids.FrontPage] = state)"
      @edit="editSection(sections.ids.FrontPage)"
      :custom="overrides[sections.ids.FrontPage] ?? null"
      tag="section"
    >
      <FrontPage />
    </Section>
    <Section
      v-for="section in sections.ordered.filter((s) =>
        Object.keys(components).includes(s.toString()),
      )"
      :key="section"
      v-show="showSection(section)"
      :section-id="section"
      :state="getSectionState(section)"
      @update:state="(state) => (sectionStates[section] = state)"
      @edit="editSection(section)"
      :custom="overrides[section] ?? null"
      tag="section"
    >
      <component :is="components[section as keyof typeof components]" />
    </Section>
  </div>
</template>

<script lang="ts">
import AbsoluteResiduaryGift from "@/module/kinvault.kintin/component/will-builder/section/AbsoluteResiduaryGift.vue";
import AnticipationOfMarriage from "@/module/kinvault.kintin/component/will-builder/section/AnticipationOfMarriage.vue";
import AvoidanceOfDoubt from "@/module/kinvault.kintin/component/will-builder/section/AvoidanceOfDoubt.vue";
import BankaccountGifts from "@/module/kinvault.kintin/component/will-builder/section/BankaccountGifts.vue";
import BusinessClause from "@/module/kinvault.kintin/component/will-builder/section/BusinessClause.vue";
import CashGifts from "@/module/kinvault.kintin/component/will-builder/section/CashGifts.vue";
import CharitiesClause from "@/module/kinvault.kintin/component/will-builder/section/CharitiesClause.vue";
import CharityGifts from "@/module/kinvault.kintin/component/will-builder/section/CharityGifts.vue";
import ChattelGifts from "@/module/kinvault.kintin/component/will-builder/section/ChattelGifts.vue";
import ConditionalGifts from "@/module/kinvault.kintin/component/will-builder/section/ConditionalGifts.vue";
import Declaration from "@/module/kinvault.kintin/component/will-builder/section/Declaration.vue";
import DefinitionOfEstate from "@/module/kinvault.kintin/component/will-builder/section/DefinitionOfEstate.vue";
import DistributionOfResidue from "@/module/kinvault.kintin/component/will-builder/section/DistributionOfResidue.vue";
import Exclusions from "@/module/kinvault.kintin/component/will-builder/section/Exclusions.vue";
import ExecutorAndTrusteePowers from "@/module/kinvault.kintin/component/will-builder/section/ExecutorAndTrusteePowers.vue";
import Executors from "@/module/kinvault.kintin/component/will-builder/section/Executors.vue";
import FrontPage from "@/module/kinvault.kintin/component/will-builder/section/FrontPage.vue";
import GeneralTrustPowers from "@/module/kinvault.kintin/component/will-builder/section/GeneralTrustPowers.vue";
import Guardians from "@/module/kinvault.kintin/component/will-builder/section/Guardians.vue";
import IsaGifts from "@/module/kinvault.kintin/component/will-builder/section/IsaGifts.vue";
import LegacyOfBusinessProperty from "@/module/kinvault.kintin/component/will-builder/section/LegacyOfBusinessProperty.vue";
import LegacyOfNrb from "@/module/kinvault.kintin/component/will-builder/section/LegacyOfNrb.vue";
import LegacyOfRes from "@/module/kinvault.kintin/component/will-builder/section/LegacyOfRes.vue";
import LetterOfWishes from "@/module/kinvault.kintin/component/will-builder/section/LetterOfWishes.vue";
import OverridingPowers from "@/module/kinvault.kintin/component/will-builder/section/OverridingPowers.vue";
import PetGifts from "@/module/kinvault.kintin/component/will-builder/section/PetGifts.vue";
import PowerToRemove from "@/module/kinvault.kintin/component/will-builder/section/PowerToRemove.vue";
import PropertyGifts from "@/module/kinvault.kintin/component/will-builder/section/PropertyGifts.vue";
import ProvisionsBusiness from "@/module/kinvault.kintin/component/will-builder/section/ProvisionsBusiness.vue";
import ProvisionsIip from "@/module/kinvault.kintin/component/will-builder/section/ProvisionsIip.vue";
import ProvisionsNrb from "@/module/kinvault.kintin/component/will-builder/section/ProvisionsNrb.vue";
import ProvisionsRes from "@/module/kinvault.kintin/component/will-builder/section/ProvisionsRes.vue";
import Revocation from "@/module/kinvault.kintin/component/will-builder/section/Revocation.vue";
import ScheduleBusiness from "@/module/kinvault.kintin/component/will-builder/section/ScheduleBusiness.vue";
import ScheduleIip from "@/module/kinvault.kintin/component/will-builder/section/ScheduleIip.vue";
import ScheduleNrb from "@/module/kinvault.kintin/component/will-builder/section/ScheduleNrb.vue";
import ScheduleRes from "@/module/kinvault.kintin/component/will-builder/section/ScheduleRes.vue";
import SharesGifts from "@/module/kinvault.kintin/component/will-builder/section/SharesGifts.vue";
import SpecificGifts from "@/module/kinvault.kintin/component/will-builder/section/SpecificGifts.vue";
import StepPowers from "@/module/kinvault.kintin/component/will-builder/section/StepPowers.vue";
import Survivorship from "@/module/kinvault.kintin/component/will-builder/section/Survivorship.vue";
import Trusts from "@/module/kinvault.kintin/component/will-builder/section/Trusts.vue";
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { defineComponent, markRaw } from "vue";

import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import CollapsableWarningWidget from "@/shared/component/widget/CollapsableWarningWidget.vue";
import Section from "../Section.vue";

export default defineComponent({
  name: "WillDocument",
  mixins: [WillBuilderService.mixin],
  components: {
    CollapsableWarningWidget,
    AnticipationOfMarriage,
    AbsoluteResiduaryGift,
    FrontPage,
    ProvisionsBusiness,
    GeneralTrustPowers,
    ProvisionsIip,
    AvoidanceOfDoubt,
    Guardians,
    ProvisionsNrb,
    BankaccountGifts,
    IsaGifts,
    ProvisionsRes,
    BusinessClause,
    LegacyOfBusinessProperty,
    Revocation,
    CashGifts,
    LegacyOfNrb,
    ScheduleBusiness,
    CharitiesClause,
    LegacyOfRes,
    ScheduleIip,
    CharityGifts,
    ScheduleNrb,
    ChattelGifts,
    ScheduleRes,
    ConditionalGifts,
    SharesGifts,
    SpecificGifts,
    DefinitionOfEstate,
    StepPowers,
    DistributionOfResidue,
    OverridingPowers,
    Survivorship,
    Exclusions,
    PetGifts,
    Trusts,
    ExecutorAndTrusteePowers,
    PowerToRemove,
    Executors,
    PropertyGifts,
    Section,
  },
  emits: {
    "edit-section": (section: {
      section: WillBuilderSections;
      custom?: string;
      generated: string;
    }) => !!section,
  },
  data: () => ({
    sectionStates: {} as Partial<
      Record<WillBuilderSections, "generated" | "difference" | "custom">
    >,
    components: {
      [WillBuilderSections.Declaration]: markRaw(Declaration),
      [WillBuilderSections.Revocation]: markRaw(Revocation),
      [WillBuilderSections.Executors]: markRaw(Executors),
      [WillBuilderSections.Guardians]: markRaw(Guardians),
      [WillBuilderSections.SpecificGifts]: markRaw(SpecificGifts),
      [WillBuilderSections.PropertyGifts]: markRaw(PropertyGifts),
      [WillBuilderSections.PetGifts]: markRaw(PetGifts),
      [WillBuilderSections.CashGifts]: markRaw(CashGifts),
      [WillBuilderSections.DefinitionOfEstate]: markRaw(DefinitionOfEstate),
      [WillBuilderSections.DistributionOfResidue]: markRaw(
        DistributionOfResidue,
      ),
      [WillBuilderSections.ConditionalGifts]: markRaw(ConditionalGifts),
      [WillBuilderSections.ExecutorAndTrusteePowers]: markRaw(
        ExecutorAndTrusteePowers,
      ),
      [WillBuilderSections.Survivorship]: markRaw(Survivorship),
      [WillBuilderSections.Exclusions]: markRaw(Exclusions),
      [WillBuilderSections.StepPowers]: markRaw(StepPowers),
      [WillBuilderSections.AvoidanceOfDoubt]: markRaw(AvoidanceOfDoubt),
      [WillBuilderSections.CharitiesClause]: markRaw(CharitiesClause),
      [WillBuilderSections.LegacyOfBusinessProperty]: markRaw(
        LegacyOfBusinessProperty,
      ),
      [WillBuilderSections.LegacyOfCombinedNRA]: markRaw(LegacyOfNrb),
      [WillBuilderSections.AbsoluteResiduaryGift]: markRaw(
        AbsoluteResiduaryGift,
      ),
      [WillBuilderSections.Trusts]: markRaw(Trusts),
      [WillBuilderSections.ScheduleBusiness]: markRaw(ScheduleBusiness),
      [WillBuilderSections.ScheduleNRB]: markRaw(ScheduleNrb),
      [WillBuilderSections.ScheduleIIP]: markRaw(ScheduleIip),
      [WillBuilderSections.ProvisionsBusiness]: markRaw(ProvisionsBusiness),
      [WillBuilderSections.ProvisionsNRB]: markRaw(ProvisionsNrb),
      [WillBuilderSections.ProvisionsIIP]: markRaw(ProvisionsIip),
      [WillBuilderSections.GeneralTrustPowers]: markRaw(GeneralTrustPowers),
      [WillBuilderSections.OverridingPowers]: markRaw(OverridingPowers),
      [WillBuilderSections.PowerToRemove]: markRaw(PowerToRemove),
      [WillBuilderSections.BusinessClause]: markRaw(BusinessClause),
      [WillBuilderSections.CharityGifts]: markRaw(CharityGifts),
      [WillBuilderSections.ChattelGifts]: markRaw(ChattelGifts),
      [WillBuilderSections.IsaGifts]: markRaw(IsaGifts),
      [WillBuilderSections.SharesGifts]: markRaw(SharesGifts),
      [WillBuilderSections.BankaccountGifts]: markRaw(BankaccountGifts),
      [WillBuilderSections.LegacyOfRES]: markRaw(LegacyOfRes),
      [WillBuilderSections.ScheduleRES]: markRaw(ScheduleRes),
      [WillBuilderSections.ProvisionsRES]: markRaw(ProvisionsRes),
      [WillBuilderSections.AnticipationOfMarriage]: markRaw(
        AnticipationOfMarriage,
      ),
      [WillBuilderSections.LetterOfWishes]: markRaw(LetterOfWishes),
    },
  }),
  methods: {
    editSection(section: WillBuilderSections) {
      this.$emit("edit-section", {
        section: section,
        custom: this.overrides[section],
        generated: this.getSectionContent(section, "generated"),
      });
    },
    getSectionState(
      section: WillBuilderSections,
    ): "generated" | "difference" | "custom" {
      const override = this.sectionStates[section];
      if (override) {
        return override;
      }

      if (section.toString() in this.overrides) {
        return "custom";
      }

      return "generated";
    },
    showSection(section: WillBuilderSections): boolean {
      return this.sections.enabled?.[section] ?? false;
    },
  },
});
</script>
