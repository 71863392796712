import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

import { FormTextField } from "@kinherit/framework/component.input/text-field";

export const ReadIntroducerOutsourceForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-outsource-form",
    data: () => ({
      name: "",
      type: Array<string>(),
      status: Array<string>(),
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Client or Company",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "introducerOutsourceTypes",
          props: {
            label: "Type",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "introducerOutsourceStatuses",
          props: {
            label: "Status",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
