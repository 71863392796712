<template>
  <div v-if="tag">
    <SidebarPage
      :name="tag.name"
      :title="tag.name"
      :subtitle="tag.description"
      :tabs="tabs.all"
      v-model:tab="tabs.current"
      :has-context-menu="showContextMenu"
      @context-menu="openContextMenu"
    >
      <template #default>
        <AppointmentMasterList
          v-if="tabs.current === tabs.indexes.Appointments"
          :tag="tag"
          hide-title
        />
        <IntroducerCompanyMasterList
          v-if="tabs.current === tabs.indexes.Companies"
          :tag="tag"
          hide-title
        />
        <IntroducerContactMasterList
          v-if="tabs.current === tabs.indexes.People"
          :tag="tag"
          hide-title
        />
        <KintinMasterList
          v-else-if="tabs.current === tabs.indexes.Kintins"
          :tag="tag"
          hide-title
        />
        <LeadMasterList
          v-else-if="tabs.current === tabs.indexes.Leads"
          :tag="tag"
          hide-title
        />
      </template>
    </SidebarPage>
  </div>
  <PageNotFound v-else-if="!loading" />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import AppointmentMasterList from "@/module/appointment/page/AppointmentMasterList.vue";
import IntroducerCompanyMasterList from "@/module/introducer.company/page/IntroducerCompanyMasterList.vue";
import IntroducerContactMasterList from "@/module/introducer.contact/page/IntroducerContactMasterList.vue";
import KintinMasterList from "@/module/kinvault.kintin/page/KintinMasterList.vue";
import LeadMasterList from "@/module/lead/page/LeadMasterList.vue";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { defineComponent } from "vue";
import { TagDetailsParams, TagDetailsRoute, TagUpdateRoute } from ".";
import { TagDetailsMixin } from "../mixin/tag.mixin";

export default defineComponent({
  name: TagDetailsRoute,
  components: {
    PageNotFound,
    SidebarPage,
    IntroducerCompanyMasterList,
    KintinMasterList,
    LeadMasterList,
    IntroducerContactMasterList,
    AppointmentMasterList,
  },
  mixins: [TagDetailsMixin],
  computed: {
    showContextMenu(): boolean {
      return (
        AuthService.hasPermission("tag:write") ||
        AuthService.hasPermission("tag:delete")
      );
    },
  },
  data: () => ({
    tabs: {
      all: Array<{ label: string }>(),
      current: 0,
      indexes: {} as Record<
        "Appointments" | "Companies" | "Kintins" | "Leads" | "People",
        number
      >,
    },
    loading: true,
  }),
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.execute(
    //   "admin/tag/record",
    //   to.params as TagDetailsParams,
    // );
    await window.Kernel.ActionBus2.adminTag.record(
      to.params as TagDetailsParams,
    );
  },
  async mounted(): Promise<void> {
    this.loading = false;

    if ((this.tag?.$data.appointmentCount ?? 0) > 0) {
      this.tabs.indexes["Appointments"] =
        this.tabs.all.push({
          label: "Appointments",
        }) - 1;
    }

    if ((this.tag?.$data.introducerCompanyCount ?? 0) > 0) {
      this.tabs.indexes["Companies"] =
        this.tabs.all.push({
          label: "Companies",
        }) - 1;
    }

    if ((this.tag?.$data.kintinCount ?? 0) > 0) {
      this.tabs.indexes["Kintins"] =
        this.tabs.all.push({
          label: "Kintins",
        }) - 1;
    }

    if ((this.tag?.$data.leadCount ?? 0) > 0) {
      this.tabs.indexes["Leads"] =
        this.tabs.all.push({
          label: "Leads",
        }) - 1;
    }

    if ((this.tag?.$data.introducerContactCount ?? 0) > 0) {
      this.tabs.indexes["People"] =
        this.tabs.all.push({
          label: "People",
        }) - 1;
    }
  },
  methods: {
    edit(): void {
      window.Kernel.Router.push({
        name: TagUpdateRoute,
        params: {
          tag: this.tag?.id,
        },
      });
    },
    async delete(): Promise<void> {
      if (!this.tag) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Tag",
          message: `Are you sure you want to delete the '${this.tag.name}' tag?`,
        },
      });

      // await window.Kernel.ActionBus.execute("admin/tag/delete", this.tag);
      await window.Kernel.ActionBus2.adminTag.delete(this.tag);

      this.$router.back();
    },
    async openContextMenu(): Promise<void> {
      if (!this.tag) {
        return;
      }

      const contextMenu = AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit Tag" as const,
              line1: `Edit '${this.tag.name}' Tag`,
              iconLeft: StyleService.icon.edit.icon,
              permission: "tag:write",
              action: this.edit,
            },
            {
              title: "Delete Tag" as const,
              line1: `Delete '${this.tag.name}' tag`,
              iconLeft: StyleService.icon.delete.icon,
              permission: "tag:write",
              action: this.delete,
            },
          ],
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "iconLeft",
          trackingField: "title",
        },
        path: "permission",
        filter: "all",
      });

      (await contextMenu()).action();
    },
  },
});
</script>
