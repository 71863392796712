<template>
  <div v-if="knowledgeBaseCategory">
    <KnowledgeBaseDocumentMasterList
      :key="refreshKey"
      :category-id="knowledgeBaseCategory?.id"
      :has-context-menu="hasWritePermission"
      :has-create-button="hasWritePermission"
      @context-menu="contextMenu"
      @create="createDocument"
    >
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="update-category"
          size="is-normal"
          @click="updateCategory"
        >
          Update Category
        </Button>
        <Button
          v-if="hasWritePermission"
          class="delete-category"
          size="is-normal"
          @click="deleteCategory"
        >
          Delete Category
        </Button>
      </template>
    </KnowledgeBaseDocumentMasterList>
  </div>
  <PageNotFound v-else />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateKnowledgeBaseDocument } from "@/config/model.config";
import KnowledgeBaseDocumentMasterList from "@/module/knowledge-base/component/KnowledgeBaseDocumentMasterList.vue";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Button } from "@kinherit/framework/component.input/button";
import { defineComponent } from "vue";
import {
  KnowledgeBaseCategoryDetailsRoute,
  KnowledgeBaseCategoryMasterListRoute,
} from ".";
import { UpdateKnowledgeBaseCategoryForm } from "../form/update-knowledge-base-category.form";
import { UpdateKnowledgeBaseDocumentForm } from "../form/update-knowledge-base-document.form";
import { KnowledgeBaseCategoryMixin } from "../mixin/knowledge-base-category.mixin";

export default defineComponent({
  name: KnowledgeBaseCategoryDetailsRoute,
  mixins: [
    AuthService.mixin(),
    KnowledgeBaseCategoryMixin(),
    StyleService.mixin,
  ],
  components: { PageNotFound, KnowledgeBaseDocumentMasterList, Button },
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("knowledge-base-category:write");
    },
  },
  data: () => ({
    refreshKey: 0,
  }),
  methods: {
    contextMenu(): void {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          titleField: "title",
          trackingField: "title",
          iconLeftField: "icon",
          line1Field: "line1",
          items: [
            {
              title: "Update",
              icon: StyleService.icon.edit.icon,
              line1: "Update this category",
              permission: "knowledge-base-category:write",
              action: this.updateCategory,
            },
            {
              title: "Delete",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this category",
              permission: "knowledge-base-category:delete",
              action: this.deleteCategory,
            },
          ],
        },
        path: "permission",
        filter: "all",
        callback: (item) => item.action(),
      });

      contextMenu();
    },
    async updateCategory(): Promise<void> {
      const knowledgeBaseCategory = this.knowledgeBaseCategory;
      if (!knowledgeBaseCategory) {
        return;
      }

      try {
        await UpdateKnowledgeBaseCategoryForm(knowledgeBaseCategory).dialog({
          dialog: {
            title: "Update Knowledge Base Category",
          },
        });
      } catch (error) {
        knowledgeBaseCategory.$restore();
        throw error;
      }

      // await window.Kernel.ActionBus.execute("knowledge-base/category/update", {
      //   knowledgeBaseCategory,
      // });
      await window.Kernel.ActionBus2.knowledgeBase.category.update({
        knowledgeBaseCategory,
      });
    },
    async deleteCategory(): Promise<void> {
      if (!this.knowledgeBaseCategory) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Knowledge Base Category",
          message: `Are you sure you want to delete this category?`,
        },
      });

      // await window.Kernel.ActionBus.execute("knowledge-base/category/delete", {
      //   knowledgeBaseCategory: this.knowledgeBaseCategory,
      // });
      await window.Kernel.ActionBus2.knowledgeBase.category.delete({
        knowledgeBaseCategory: this.knowledgeBaseCategory,
      });

      this.$router.push({ name: KnowledgeBaseCategoryMasterListRoute });
    },
    async createDocument(): Promise<void> {
      if (!this.knowledgeBaseCategory) {
        return;
      }

      const document = CreateKnowledgeBaseDocument();
      document.addCategories(this.knowledgeBaseCategory);

      const knowledgeBaseDocument = await UpdateKnowledgeBaseDocumentForm(
        document,
      ).dialog({
        dialog: {
          title: "Create Knowledge Base Document",
        },
      });

      // await window.Kernel.ActionBus.execute("knowledge-base/document/create", {
      //   knowledgeBaseDocument,
      // });
      await window.Kernel.ActionBus2.knowledgeBase.document.create({
        knowledgeBaseDocument,
      });

      this.refreshKey++;
    },
  },
});
</script>
