<template>
  <Card title="Leads By EP">
    <p>
      This table uses the Lead creation date to group by week/month, not the
      date that the account was converted/created. NB % calculations are based
      on the number of converted leads the EP receives.
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :rows="results"
      :footer="footer"
      @loaded="load"
    >
      <template #details="{ row, index }">
        <div class="columns">
          <div class="column">
            <p>Lead Statuses</p>
            <ul>
              <li
                v-for="(leadStatus, ll) in row.leadStatuses"
                :key="`${index}-${ll}`"
              >
                {{ leadStatus.statusName }}: {{ leadStatus.count }}
              </li>
            </ul>
          </div>
          <div class="column">
            <p>Kintin Stages</p>
            <ul>
              <li
                v-for="(kintinStage, kk) in row.kintinStages"
                :key="`${index}-${kk}`"
              >
                {{ kintinStage.statusName }}:
                {{ kintinStage.count }}
              </li>
            </ul>
          </div>
          <div class="column">
            <p>Kintin Statuses</p>
            <ul>
              <li
                v-for="(kintinStatus, k) in row.kintinStatuses"
                :key="`${index}-${k}`"
              >
                {{ kintinStatus.statusName }}:
                {{ kintinStatus.count }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { LeadsByEpReportRoute } from ".";

import {
  ComputeLeadsByEpReportResponse,
  TLeadsByEpReportResult,
} from "@/module/report/action/compute-leads-by-ep.action";
import { ReadLeadsByEpReportFilters } from "@/module/report/form/read-leads-by-ep.report.form";
import Table from "@kinherit/framework/component.display/table";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { Option } from "@kinherit/sdk";
import { quickPercentage } from "../data/helper-functions";

export default defineComponent({
  name: LeadsByEpReportRoute,
  components: {
    Card,
    MasterListFilters,
    Table,
  },
  data: () => ({
    leadStatuses: Option.$findBy({
      group: "leadStatus",
    }),
    kintinStatuses: Option.$findBy({
      group: "kintinStatus",
    }),
    kintinStages: Option.$findBy({
      group: "kintinStage",
    }),
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string | number>(),
    results: [] as any[],
    filters: ReadLeadsByEpReportFilters(),
    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "EP",
        mapHtml: (results: TLeadsByEpReportResult) => {
          return results.estatePlanner;
        },
      },
      {
        title: "Total", // the total number of leads
        class: "has-text-weight-bold",
        map: (results: TLeadsByEpReportResult) => results.lead.count,
      },
      {
        title: "Pending", // the number of leads that are in some sort of pending status, i.e new bld nurt rebk pot inp inp2 wr
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(results.lead.pendingCount, results.lead.count);
        },
      },
      {
        title: "Dropout", // the number of leads that are in some sort of dropout status, i.e. QO UC1 UC2 junk
        class: "has-text-danger",
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(results.lead.dropoutCount, results.lead.count);
        },
      },
      {
        title: "Converted", // the number of leads that are in some sort of converted status, i.e. Enq Conv
        class: "has-text-success has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(
            results.lead.convertedCount,
            results.lead.count,
          );
        },
      },
      {
        title: "Acc", // the number of accounts
        map: (results: TLeadsByEpReportResult) => results.account.count,
      },
      {
        title: "Unpaid", // the number of accounts which don't have a paid order
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(
            results.account.unpaidCount,
            results.account.count,
          );
        },
      },
      {
        title: "Not Now", // the number of accounts which don't have a paid order
        class: "has-text-warning-dark has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(
            results.account.notNowCount,
            results.account.count,
          );
        },
      },
      {
        title: "Dropout", // the number of accounts which qualified out
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(
            results.account.dropoutCount,
            results.account.count,
          );
        },
      },
      {
        title: "Paid", // the number of accounts paid
        class: "has-text-success has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult) => {
          return quickPercentage(
            results.account.paidCount,
            results.account.count,
          );
        },
      },
    ],
    footer: [
      "Total",
      // Total
      {
        class: "has-text-weight-bold",
        map: (results: TLeadsByEpReportResult[]) => {
          return results.reduce((total, row) => total + row.lead.count, 0);
        },
      },
      // Pending
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.lead.pendingCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Dropout
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.lead.dropoutCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Converted
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.lead.convertedCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.lead.count, 0),
          );
        },
      },
      // Account
      {
        class: "has-text-weight-bold",
        map: (results: TLeadsByEpReportResult[]) => {
          return results.reduce((total, row) => total + row.account.count, 0);
        },
      },
      // Unpaid
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.unpaidCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.account.count, 0),
          );
        },
      },
      // Not Now
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.notNowCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.account.count, 0),
          );
        },
      },
      // Dropout
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.dropoutCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.account.count, 0),
          );
        },
      },
      // Paid
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TLeadsByEpReportResult[]) => {
          const total = results.reduce(
            (total, row) => total + row.account.paidCount,
            0,
          );
          return quickPercentage(
            total,
            results.reduce((total, row) => total + row.account.count, 0),
          );
        },
      },
    ],
  }),
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.execute(
      //   "report/leads/by-ep",
      //   this.filters.localData,
      // );
      const result = await window.Kernel.ActionBus2.report.leadsByEp(
        this.filters.localData,
      );

      this.datasets = result.datasets;
      this.labels = result.labels;
      this.results = this.parseResults(result.results);
    },

    parseResults(results: ComputeLeadsByEpReportResponse["results"]) {
      return results.map((row) => {
        // Process each type of status
        const leadStatuses = this.ensureAllStatuses(
          row.leadStatuses,
          this.leadStatuses as Option[],
        );
        const kintinStatuses = this.ensureAllStatuses(
          row.kintinStatuses,
          this.kintinStatuses as Option[],
        );
        const kintinStages = this.ensureAllStatuses(
          row.kintinStages,
          this.kintinStages as Option[],
        );

        return {
          ...row,
          leadStatuses,
          kintinStatuses,
          kintinStages,
        };
      });
    },

    // Helper method to ensure all statuses are represented
    ensureAllStatuses(
      rowStatuses: {
        status: string;
        count: number;
      }[],
      allStatuses: Option[],
    ) {
      return allStatuses.map((status) => {
        const rowStatus = rowStatuses.find(
          (rowStatus) => rowStatus.status === status.id,
        );
        // console.log("rowStatus", rowStatus, status);
        return rowStatus
          ? {
              statusId: status.id,
              statusName: status.text,
              count: rowStatus.count,
            }
          : {
              statusId: status.id,
              statusName: status.text,
              count: 0,
            };
      });
    },
  },
});
</script>
