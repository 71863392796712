<template>
  <PageHeader htag="h1" text="Call Logs" />
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      duration: 'Duration',
      callDate: 'Recieved',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #actions="{ row }">
      <Button text="Details" @click="handleClick(row, $event)" />
      <Button
        v-if="row.kintin"
        text="Profile"
        icon-left="Person"
        @click="viewKintin(row.kintin, $event)"
      />
      <Button
        v-else-if="row.person"
        text="Profile"
        icon-left="Person"
        @click="viewPerson(row.person, $event)"
      />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadCallLogForm } from "@/module/admin.call-log/form/read-call-logs.form";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  KinvaultOfficerDetailsParams,
  KinvaultOfficerDetailsRoute,
} from "@/module/kinvault.officer/page/details";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { CallLog, ICallLog, Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  CallLogDetailsParams,
  CallLogDetailsRoute,
  CallLogMasterListRoute,
} from ".";

export default defineComponent({
  name: CallLogMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  data: () => ({
    filters: ReadCallLogForm(),
    columns: [
      {
        title: "Candidate",
        sort: true,
        map: (v: CallLog) =>
          v.kintin?.friendlyName ?? v.person?.profile.fullName ?? "Unknown",
      },
      {
        title: "Status",
        sort: true,
        map: (v: CallLog) => (v.answered ? "Answered" : "Missed"),
      },
      {
        title: "Username",
        sort: true,
        field: "username",
      },
      {
        title: "Direction",
        sort: true,
        map: (v: CallLog) => v.direction.text,
      },
      {
        title: "Destination",
        sort: true,
        map: (v: CallLog) => v.destination,
      },
      {
        title: "Duration",
        sort: true,
        map: (v: CallLog) => v.duration.toString({ ms: false }),
      },
      {
        title: "Tel",
        sort: true,
        map: (v: CallLog) => v.tel,
      },
      {
        title: "Recieved",
        sort: true,
        map: (v: CallLog) => v.callDate.formatDateTime,
      },
      {
        title: "Actions",
        sort: false,
        slot: "actions",
      },
    ] as Array<TableColumn>,
    rows: Array<CallLog>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ICallLog,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    handleClick(calllog: CallLog, event: MouseEvent): void {
      const params: CallLogDetailsParams = { id: calllog.id };

      window.Kernel.visitRoute(
        {
          name: CallLogDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadCallLogForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "admin/call-log/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data = await window.Kernel.ActionBus2.adminCallLog.read({
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.callLogs;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    viewKintin(kintin: Kintin, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params: {
            kintin: kintin.id,
          } satisfies KinvaultKintinDetailsParams,
        },
        event.ctrlKey,
      );
    },
    viewPerson(person: Person, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultOfficerDetailsRoute,
          params: {
            officer: person.id,
          } satisfies KinvaultOfficerDetailsParams,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>
