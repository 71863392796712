<template>
  <div class="branded-kinvault-subsciptions-page">
    <Tabs :config="tabs" />
    <RouterChildView />
  </div>
</template>

<script lang="ts">
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  BrandedKinvaultDetailsSubscriptionsIndexRoute,
  BrandedKinvaultDetailsSubscriptionsReferralCodesRoute,
  BrandedKinvaultDetailsSubscriptionsRoute,
} from ".";

export default defineComponent({
  name: BrandedKinvaultDetailsSubscriptionsIndexRoute,
  components: { Tabs, RouterChildView },
  data: () => ({
    tabs: [
      {
        label: `Subscriptions`,
        route: { name: BrandedKinvaultDetailsSubscriptionsRoute },
      },
      {
        label: `Referral Codes`,
        route: { name: BrandedKinvaultDetailsSubscriptionsReferralCodesRoute },
      },
    ],
  }),
});
</script>
