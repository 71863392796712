<template>
  <AttorneysContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<script lang="ts">
import { CreateAttorney } from "@/config/model.config";
import AttorneysContainer from "@/module/kinvault.kintin/component/address-book/AttorneysContainer.vue";
import { UpdateAttorneyForm } from "@/module/kinvault.kintin/form/update-attorney.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Attorney, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookAttorneysRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookAttorneysRoute,
  components: {
    AttorneysContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  emits: ["reload"],
  data: () => ({
    filtered: {
      attorneys: {
        main: Array<Attorney>(),
        reserve: Array<Attorney>(),
        certProv: Array<Attorney>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson?.profile.fullName) {
        names.push(this.kintin?.primaryPerson?.profile.fullName);
      }
      if (this.kintin?.secondaryPerson?.profile.fullName) {
        names.push(this.kintin?.secondaryPerson?.profile.fullName);
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const attorneys = selectedPerson?.appointedAttorney ?? [
        ...(this.kintin?.primaryPerson?.appointedAttorney ?? []),
        ...(this.kintin?.secondaryPerson?.appointedAttorney ?? []),
      ];

      this.filtered.attorneys = attorneys.groupBy({
        main: (b) => !b.isReserve && !b.isCertProv,
        reserve: (b) => b.isReserve && !b.isCertProv,
        certProv: (b) =>
          b.isCertProv !== null &&
          b.isCertProv !== undefined &&
          b.isCertProv === true,
      });

      this.filtered.people = attorneys
        .pluck("person")
        .unique("id")
        .filter(Boolean)
        .cast<Person>()
        .sortBy("profile.fullName");
    },
    async createAttorney() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const attorney = CreateAttorney({
        forPerson,
      });

      try {
        await UpdateAttorneyForm({
          data: attorney,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create attorney",
          },
        });
      } catch {
        attorney.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/attorney/create", {
      //   attorney,
      //   kintin: this.kintin,
      // });

      await window.Kernel.ActionBus2.kinvaultKintin.attorney.create({
        attorney,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
