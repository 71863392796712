import { PersonField, SharedFormProps } from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";

import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In, NotEqual } from "@kinherit/orm";
import { Attorney, Kintin } from "@kinherit/sdk";
import { AddKintinPersonButton } from "./shared.form";

export const UpdateAttorneyForm = ({
  data,
  kintin,
}: {
  data: Attorney;
  kintin: Kintin;
}) => {
  return defineForm({
    name: "update-attorney-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "attorney-form-area",
        data,
        template: GridLayout([
          ["person", "forPerson"],
          { is: "hr" },
          ["isHealthWealth", "isPropFinance"],
          ["isReserve", "isCertProv"],
          ["notes"],
        ]),
        components: () => ({
          person: [
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.primaryPerson,
                label: "Who is the attorney?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.primaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.secondaryPerson,
                label: "Who is the attorney?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.secondaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
          ],
          forPerson: [
            PersonField({
              reactive: true,
              props: {
                label: (data) =>
                  `Who is ${
                    data.person?.profile?.fullName ?? `this person`
                  } an attorney for?`,
                validators: ["required"],
                vModel: {
                  get: (data) => data.forPerson,
                  set: (value, data) => {
                    data.forPerson = value;

                    if (data.forPerson?.id === data.person?.id) {
                      data.person = null as any;
                    }
                  },
                },
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
          isReserve: [
            FormCheckboxField({
              reactive: true,
              props: {
                label: "Reserve",
                size: "is-medium",
              },
              models: {
                value: {
                  get: (data) => data.isReserve,
                  set: (value, data) => {
                    data.isCertProv = false;
                    data.isReserve = value;
                  },
                },
              },
            }),
          ],
          isHealthWealth: [
            FormCheckboxField({
              reactive: true,
              props: {
                label: "Health & Wealth",
                size: "is-medium",
              },
              models: {
                value: {
                  get: (data) => data.isHealthWealth,
                  set: (value, data) => {
                    data.isCertProv = false;
                    data.isHealthWealth = value;
                  },
                },
              },
            }),
          ],
          isPropFinance: [
            FormCheckboxField({
              reactive: true,
              props: {
                label: "Property Finance",
                size: "is-medium",
              },
              models: {
                value: {
                  get: (data) => data.isPropFinance,
                  set: (value, data) => {
                    data.isCertProv = false;
                    data.isPropFinance = value;
                  },
                },
              },
            }),
          ],
          isCertProv: [
            FormCheckboxField({
              reactive: true,
              props: {
                label: "Certificate Provider",
                size: "is-medium",
              },
              models: {
                value: {
                  get: (data) => data.isCertProv,
                  set: (value, data) => {
                    data.isCertProv = value;
                    data.isHealthWealth = false;
                    data.isPropFinance = false;
                    data.isReserve = false;
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
