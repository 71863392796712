import { ChangeTokenPasswordHandler } from "@/module/auth/action/change-password/change-token-password.action";
import { UniqueUsernameAction } from "@/module/auth/action/unique-username.action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { CompleteMultiFactorAuthHandler } from "./multi-factor/complete-multi-factor-auth.action";
import { RequestMultiFactorAuthHandler } from "./multi-factor/request-multi-factor-auth.action";
import { CompleteSessionHandler } from "./session/complete-session.action";
import { LogoutSessionHandler } from "./session/logout-session.action";
import { RequestSessionHandler } from "./session/request-session.action";

export const Actions = {
  "auth/multi-factor/request": RequestMultiFactorAuthHandler,
  "auth/multi-factor/complete": CompleteMultiFactorAuthHandler,
  "auth/session/request": RequestSessionHandler,
  "auth/session/complete": CompleteSessionHandler,
  "auth/session/logout": LogoutSessionHandler,
  "auth/change-password": ChangeTokenPasswordHandler,
  "auth/unique-username": UniqueUsernameAction,
};

export const AuthActionBus = DefineActionBus({
  name: "Auth",
  actions: {
    multiFactor: DefineActionBus({
      name: "Auth.MultiFactor",
      actions: {
        request: RequestMultiFactorAuthHandler,
        complete: CompleteMultiFactorAuthHandler,
      },
    }),
    session: DefineActionBus({
      name: "Auth.Session",
      actions: {
        request: RequestSessionHandler,
        complete: CompleteSessionHandler,
        logout: LogoutSessionHandler,
      },
    }),
    changePassword: ChangeTokenPasswordHandler,
    uniqueUsername: UniqueUsernameAction,
  },
});
