import { ReadIntroducerCompanyAccountReferralsHandler } from "@/module/introducer.company/action/referral-code/read-introducer-company-referral.action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { CreateIntroducerBillingContactHandler } from "./introducer-billing-contact/create-introducer-billing-contact.action";
import { DeleteIntroducerBillingContactHandler } from "./introducer-billing-contact/delete-introducer-billing-contact.action";
import { ReadIntroducerBillingContactHandler } from "./introducer-billing-contact/read-introducer-billing-contact.action";
import { RecordIntroducerBillingContactHandler } from "./introducer-billing-contact/record-introducer-billing-contact.action";
import { UpdateIntroducerBillingContactProfileHandler } from "./introducer-billing-contact/update-introducer-billing-contact-profile.action";
import { UpdateIntroducerBillingContactHandler } from "./introducer-billing-contact/update-introducer-billing-contact.action";
import { ComputeIntroducerCompanyStatsHandler } from "./introducer-company/compute-introducer-company-stats.action";
import { CreateIntroducerCompanyPartnerPortalPermissionsHandler } from "./introducer-company/create-introducer-company-partner-portal.action";
import { CreateIntroducerCompanyHandler } from "./introducer-company/create-introducer-company.action";
import { DeleteIntroducerCompanyHandler } from "./introducer-company/delete-introducer-company.action";
import { DownloadIntroducerCompanyStatsHandler } from "./introducer-company/download-introducer-company-stats.action";
import { ReadIntroducerCompanyHandler } from "./introducer-company/read-introducer-company.action";
import { RecordIntroducerCompanyHandler } from "./introducer-company/record-introducer-company.action";
import { UpdateIntroducerCompanyPartnerPortalPermissionsHandler } from "./introducer-company/update-introducer-company-partner-portal.action";
import { UpdateIntroducerCompanyProfileHandler } from "./introducer-company/update-introducer-company-profile.action";
import { UpdateIntroducerCompanyHandler } from "./introducer-company/update-introducer-company.action";
import { CreateIntroducerContactPartnerPortalPermissionsHandler } from "./introducer-contact/create-introducer-contact-partner-portal.action";
import { DeleteIntroducerContactPartnerPortalHandler } from "./introducer-contact/delete-introducer-contact-partner-portal.action";
import { DeleteIntroducerContactHandler } from "./introducer-contact/delete-introducer-contact.action";
import { ReadIntroducerCompanyContactsHandler } from "./introducer-contact/read-introducer-company-contacts.action";
import { UpdateIntroducerContactPartnerPortalPermissionsHandler } from "./introducer-contact/update-introducer-contact-partner-portal.action";
import { CreateIntroducerCompanyIntroducerContractHandler } from "./introducer-contract/create-introducer-company-introducer-contract.action";
import { DeleteIntroducerCompanyIntroducerContractHandler } from "./introducer-contract/delete-introducer-company-introducer-contract.action";
import { ReadIntroducerCompanyContractsHandler } from "./introducer-contract/read-introducer-company-contracts.action";
import { UpdateIntroducerCompanyIntroducerContractHandler } from "./introducer-contract/update-introducer-company-introducer-contract.action";
import { ReadIntroducerCompanyAccountReferralCodesHandler } from "./referral-code/read-introducer-company-referral-codes.action";
import { ReadIntroducerCompanyAccountReferralKintinsHandler } from "./referral-code/read-introducer-company-referral-kintins.action";
import { ReadIntroducerCompanyAccountReferralLeadsHandler } from "./referral-code/read-introducer-company-referral-leads.action";

export const Actions = {
  "introducer/company/read": ReadIntroducerCompanyHandler,
  "introducer/company/record": RecordIntroducerCompanyHandler,
  "introducer/company/update": UpdateIntroducerCompanyHandler,
  "introducer/company/create": CreateIntroducerCompanyHandler,
  "introducer/company/delete": DeleteIntroducerCompanyHandler,
  "introducer/company/profile/update": UpdateIntroducerCompanyProfileHandler,
  "introducer/company/contacts/read": ReadIntroducerCompanyContactsHandler,
  "introducer/company/referral-codes/read":
    ReadIntroducerCompanyAccountReferralCodesHandler,
  "introducer/company/contracts/read": ReadIntroducerCompanyContractsHandler,
  "introducer/company/compute/stats": ComputeIntroducerCompanyStatsHandler,
  "introducer/company/download/referral-report":
    DownloadIntroducerCompanyStatsHandler,
  "introducer/company/referral/read":
    ReadIntroducerCompanyAccountReferralsHandler,
  "introducer/company/referral/leads/read":
    ReadIntroducerCompanyAccountReferralLeadsHandler,
  "introducer/company/referral/kintins/read":
    ReadIntroducerCompanyAccountReferralKintinsHandler,
  "introducer/company/contract/create":
    CreateIntroducerCompanyIntroducerContractHandler,
  "introducer/company/contract/delete":
    DeleteIntroducerCompanyIntroducerContractHandler,
  "introducer/company/contract/update":
    UpdateIntroducerCompanyIntroducerContractHandler,
  "introducer/company/billing-contacts/read":
    ReadIntroducerBillingContactHandler,
  "introducer/company/billing-contacts/create":
    CreateIntroducerBillingContactHandler,
  "introducer/company/billing-contacts/update":
    UpdateIntroducerBillingContactHandler,
  "introducer/company/billing-contacts/delete":
    DeleteIntroducerBillingContactHandler,
  "introducer/company/billing-contacts/record":
    RecordIntroducerBillingContactHandler,
  "introducer/company/billing-contacts/profile/update":
    UpdateIntroducerBillingContactProfileHandler,
  "introducer/company/contact/delete": DeleteIntroducerContactHandler,
  "introducer/company/partner-portal-permissions/update":
    UpdateIntroducerCompanyPartnerPortalPermissionsHandler,
  "introducer/company/partner-portal-permissions/create":
    CreateIntroducerCompanyPartnerPortalPermissionsHandler,
  "introducer/contact/partner-portal-permissions/update":
    UpdateIntroducerContactPartnerPortalPermissionsHandler,
  "introducer/contact/partner-portal-permissions/create":
    CreateIntroducerContactPartnerPortalPermissionsHandler,
  "introducer/contact/partner-portal-permissions/delete":
    DeleteIntroducerContactPartnerPortalHandler,
};

export const IntroducerCompanyActionBus = DefineActionBus({
  name: "Introducer.Company",
  actions: {
    company: DefineActionBus({
      name: "Introducer.Company.Company",
      actions: {
        read: ReadIntroducerCompanyHandler,
        record: RecordIntroducerCompanyHandler,
        update: UpdateIntroducerCompanyHandler,
        create: CreateIntroducerCompanyHandler,
        delete: DeleteIntroducerCompanyHandler,
        partnerPortalPermissions: DefineActionBus({
          name: "Introducer.Company.Company.PartnerPortalPermissions",
          actions: {
            update: UpdateIntroducerCompanyPartnerPortalPermissionsHandler,
            create: CreateIntroducerCompanyPartnerPortalPermissionsHandler,
          },
        }),
      },
    }),
    profile: DefineActionBus({
      name: "Introducer.Company.Profile",
      actions: {
        update: UpdateIntroducerCompanyProfileHandler,
      },
    }),
    contacts: DefineActionBus({
      name: "Introducer.Company.Contacts",
      actions: {
        read: ReadIntroducerCompanyContactsHandler,
        delete: DeleteIntroducerContactHandler,

        partnerPortalPermissions: DefineActionBus({
          name: "Introducer.Company.Contacts.PartnerPortalPermissions",
          actions: {
            update: UpdateIntroducerContactPartnerPortalPermissionsHandler,
            create: CreateIntroducerContactPartnerPortalPermissionsHandler,
            delete: DeleteIntroducerContactPartnerPortalHandler,
          },
        }),
      },
    }),
    referralCodes: DefineActionBus({
      name: "Introducer.Company.ReferralCodes",
      actions: {
        read: ReadIntroducerCompanyAccountReferralCodesHandler,
      },
    }),
    contracts: DefineActionBus({
      name: "Introducer.Company.Contracts",
      actions: {
        read: ReadIntroducerCompanyContractsHandler,
        create: CreateIntroducerCompanyIntroducerContractHandler,
        update: UpdateIntroducerCompanyIntroducerContractHandler,
        delete: DeleteIntroducerCompanyIntroducerContractHandler,
      },
    }),
    computeStats: ComputeIntroducerCompanyStatsHandler,
    downloadReferralReport: DownloadIntroducerCompanyStatsHandler,
    referral: DefineActionBus({
      name: "Introducer.Company.Referral",
      actions: {
        read: ReadIntroducerCompanyAccountReferralsHandler,
        readLeads: ReadIntroducerCompanyAccountReferralLeadsHandler,
        readKintins: ReadIntroducerCompanyAccountReferralKintinsHandler,
      },
    }),
    billingContacts: DefineActionBus({
      name: "Introducer.Company.BillingContacts",
      actions: {
        read: ReadIntroducerBillingContactHandler,
        create: CreateIntroducerBillingContactHandler,
        update: UpdateIntroducerBillingContactHandler,
        delete: DeleteIntroducerBillingContactHandler,
        record: RecordIntroducerBillingContactHandler,
        updateProfile: UpdateIntroducerBillingContactProfileHandler,
      },
    }),
  },
});
