import {
  AccountReferralCodeField,
  BrandedKinvaultField,
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  TagsField,
  UserField,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { In, NotIn } from "@kinherit/orm/index";
import { Option } from "@kinherit/sdk";

const localData = {
  search: "",
  status: Array<string>(),
  stage: Array<string>(),
  assignedUser: Array<string>(),
  tags: Array<string>(),
  created: null as null | [number, number],
  organisation: Array<string>(),
  referral: Array<string>(),
  process: Array<string>(),
  isActive: null as null | boolean,
  isEscalated: null as null | boolean,
  dueBy: null as null | "today" | "tomorrow" | "overdue",
};

export const ReadKintinForm = (data: Partial<typeof localData> = {}) => {
  const dueByFilteredStatuses = Option.$findBy({
    group: "kintinStatus",
    value: NotIn(["closed", "complete"]),
  }).pluck("id");

  const dueByFilteredStages = Option.$findBy({
    group: "kintinStage",
    value: NotIn(["h", "z2"]),
  }).pluck("id");

  return MasterListFiltersForm({
    name: "filter-kintin-form",
    data: () => Object.assign({}, localData, data),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name / Email / KTRef",
            showClearButton: true,
          },
          models: {
            value: "search",
          },
        }),
      ],
      assignedUser: [
        UserField({
          props: {
            label: "Assigned to",
            vModel: "assignedUser",
            isMultiSelect: true,
          },
          query: {
            roles: {
              role: In(["staff", "admin"]),
            },
            displayUserInPortal: true,
          },
          simplifyData: true,
        }),
      ],
      stage: [
        OptionsAutoCompleteField({
          vModel: "stage",
          group: "kintinStage",
          simplifyData: true,
          props: {
            label: "Stage",
            isMultiSelect: true,
          },
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "kintinStatus",
          simplifyData: true,
          props: {
            label: "Status",
            isMultiSelect: true,
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      process: [
        OptionsAutoCompleteField({
          props: {
            label: "Process",
            isMultiSelect: true,
          },
          vModel: "process",
          group: "process",
          simplifyData: true,
        }),
      ],
      organisation: [
        BrandedKinvaultField({
          props: {
            label: "Organisation",
            vModel: "organisation",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
      referral: [
        AccountReferralCodeField<any>({
          props: {
            vModel: "referral",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
      tags: [
        TagsField({
          props: {
            vModel: "tags",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      isActive: [
        FormSelectField({
          props: {
            label: "Is Active",
            options: {
              null: "Any",
              yes: "Yes",
              no: "No",
            },
            showClearButton: false,
          },
          models: {
            value: {
              get: (data) => {
                if (data.isActive === null) {
                  return `null`;
                }

                return data.isActive ? "yes" : "no";
              },
              set: (value, data) => {
                data.isActive =
                  value === "yes" ? true : value === "no" ? false : null;
              },
            },
          },
        }),
      ],
      isEscalated: [
        FormSelectField({
          props: {
            label: "Is Escalated",
            options: {
              null: "Any",
              yes: "Yes",
              no: "No",
            },
            showClearButton: false,
          },
          models: {
            value: {
              get: (data) => {
                if (data.isEscalated === null) {
                  return `null`;
                }

                return data.isEscalated ? "yes" : "no";
              },
              set: (value, data) => {
                data.isEscalated =
                  value === "yes" ? true : value === "no" ? false : null;
              },
            },
          },
        }),
      ],
      dueBy: [
        FormSelectField({
          props: {
            label: "Due by",
            options: {
              today: "Today",
              tomorrow: "Tomorrow",
              overdue: "Overdue",
            },
          },
          models: {
            value: {
              get: (data) => data.dueBy,
              set: (value, data, controls) => {
                data.dueBy = value;
                data.status = dueByFilteredStatuses;
                data.stage = dueByFilteredStages;
                controls.incrementFormRenderKey();
              },
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
