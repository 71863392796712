<template>
  <NotePage
    v-if="kintin"
    :has-write-permission="hasWritePermission"
    :kintin="kintin"
    :note-types="[
      'note',
      'introducerNote',
      'kintinCheckNote',
      'kintinCheckFollowUp',
      'communicationNote',
    ]"
  />
  <NotePage
    v-else-if="lead"
    :has-write-permission="hasWritePermission"
    :lead="lead"
    :note-types="['note', 'introducerNote', 'communicationNote']"
  />
</template>

<script lang="ts">
import NotePage from "@/module/core/component/note/NotePage.vue";
import { LeadDetailsMixin } from "@/module/lead/mixin/lead-details.mixin";
import { AuthService } from "@/service/auth.service";
import { Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { LeadDetailsNotesRoute } from ".";

export default defineComponent({
  name: LeadDetailsNotesRoute,
  mixins: [LeadDetailsMixin],
  components: { NotePage },
  computed: {
    hasWritePermission(): boolean {
      return (
        AuthService.hasPermission("lead:write") &&
        this.lead?.status?.value !== "converted"
      );
    },
    kintin(): Kintin | null {
      return this.lead?.referral.kintin ?? null;
    },
  },
});
</script>
