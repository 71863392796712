<template>
  <Card v-if="$data.form">
    <FormControls
      :form="$data.form"
      mode="update"
      :title="$data.form.localData.fullName ?? undefined"
      @cancel="$router.back"
      @save="save"
      :delete-button="false"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
  <PageNotFound v-else-if="!brandedKinvault" />
</template>

<script lang="ts">
import { BrandedKinvaultDetailsMixin } from "@/module/admin.branded-kinvault/mixin/branded-kinvault-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsUpdateProfileRoute } from ".";

export default defineComponent({
  name: BrandedKinvaultDetailsUpdateProfileRoute,
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
      permissions: ["branded-kinvault:write"],
    }),
    BrandedKinvaultDetailsMixin,
  ],
  components: {
    PageNotFound,
    Card,
    FormControls,
  },
  data: () => ({
    form: null as null | FormBuilder<() => Profile, string>,
    profile: null as null | Profile,
  }),
  mounted(): void {
    if (null === this.brandedKinvault) {
      return;
    }

    this.profile = this.brandedKinvault.profile;
    this.form = UpdateProfileForm(this.profile as Profile);
  },
  methods: {
    async save(): Promise<void> {
      if (null === this.brandedKinvault || null === this.profile) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/profile/update",
      //   {
      //     brandedKinvault: this.brandedKinvault,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.profile.update({
        brandedKinvault: this.brandedKinvault,
      });

      this.$router.back();
    },
    cancel(): void {
      this.$router.back();
    },
    reset(): void {
      this.form?.localData.$restore();
    },
  },
});
</script>
