import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadNoteForm = ({
  types,
}: {
  types: (
    | "note"
    | "introducerNote"
    | "kintinCheck"
    | "kintinCheckNote"
    | "kintinCheckFollowUp"
    | "creditNote"
    | "communicationNote"
    | "callLogNote"
    | "kinvaultCheck"
    | "kintinStatusChange"
  )[];
}): FormBuilder<
  () => {
    name: string;
    createdAt: [number, number] | null;
    pinned: boolean | null;
    type: string | null;
  },
  string
> => {
  const typeOptions = {
    note: "Note",
    introducerNote: "Introducer Note",
    kintinCheck: "Kintin Check",
    kintinCheckNote: "Kintin Check Note",
    kintinCheckFollowUp: "Kintin Check Follow Up",
    creditNote: "Credit Note",
    communicationNote: "Communication Note",
    callLogNote: "Call Log Note",
    kinvaultCheck: "Kinvault Check",
    kintinStatusChange: "Kintin Status Change",
  };

  Object.keys(typeOptions).forEach((key) => {
    if (!types.includes(key as any)) {
      delete typeOptions[key as keyof typeof typeOptions];
    }
  });

  return MasterListFiltersForm({
    name: "read-note-form",
    data: () => ({
      name: "",
      createdAt: null as null | [number, number],
      pinned: null as null | boolean,
      type: null as null | string,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Search...",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      type: [
        FormSelectField({
          props: {
            label: "Type",
            size: "is-small",
            options: typeOptions,
          },
          models: {
            value: "type",
          },
        }),
      ],
      createdAt: [
        CreatedAtField({
          props: {
            label: "Created At",
            vModel: "createdAt",
            size: "is-small",
          },
        }),
      ],
      pinned: [
        FormSelectField({
          props: {
            label: "Pinned",
            size: "is-small",
            options: {
              true: "Yes",
              false: "No",
            },
            placeholder: "Any",
          },
          models: {
            value: {
              get: (data) =>
                data.pinned === null ? "" : data.pinned.toString(),
              set: (value, data) =>
                (data.pinned = ["", null].includes(value)
                  ? null
                  : value === "true"),
            },
          },
        }),
      ],
    }),
  });
};
