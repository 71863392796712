<template>
  <EmailLogPage v-if="introducerContact" :owner="introducerContact" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IntroducerContactDetailsEmailLogRoute } from ".";
import { IntroducerContactDetailsMixin } from "../../../mixin/introducer-contact-details.mixin";
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";

export default defineComponent({
  name: IntroducerContactDetailsEmailLogRoute,
  mixins: [IntroducerContactDetailsMixin],
  components: {
    EmailLogPage,
  },
});
</script>
