import { EmailAddressField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm/index";
import { EmailAddress } from "@kinherit/sdk/index";

export const selectOverrideEmailAddressForm = () =>
  defineForm({
    name: "send-email",
    data: () => ({
      email: null as null | EmailAddress,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "email",
        data,
        template: GridLayout(["default"]),
        components: () => ({
          default: [
            EmailAddressField({
              props: {
                vModel: "email",
              },
              query: {
                profile: {
                  user: {
                    displayUserInPortal: Equal(true),
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
