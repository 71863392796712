<template>
  <HistoryPage type="user" :id="$params.user" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserDetailsMixin } from "../../mixin/user-details.mixin";
import { AdminUserDetailsActivityRoute } from ".";
import HistoryPage from "@/shared/component/history/HistoryPage.vue";

export default defineComponent({
  name: AdminUserDetailsActivityRoute,
  components: { HistoryPage },
  mixins: [UserDetailsMixin],
});
</script>
