import {
  OptionsAutoCompleteField,
  SharedFormProps,
} from "@/config/form.config";
import { CreateThemeCssVar } from "@/config/model.config";
import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import CssVarNames from "@kinherit/framework/css-var-names";
import { Option, ThemeKinvault } from "@kinherit/sdk";

import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { reactive } from "vue";

export const UpdateKinvaultThemeSettingsForm = (
  themeKinvault: ThemeKinvault,
) => {
  const localData = reactive({
    tab: 0,
    import: null as string | null,
  });

  return defineForm({
    name: "update-branded-kinvault-form",
    props: SharedFormProps,
    data: () => themeKinvault,
    formAreas: (data) => [
      defineFormArea({
        name: "branded-kinvault-form-area",
        data,
        components: () => ({
          default: [
            FormTabs({
              props: {
                isFullwidth: false,
                isLazy: true,
                config: [
                  {
                    label: "General",
                  },
                  {
                    label: "Login",
                  },
                  {
                    label: "Header",
                  },
                  {
                    label: "Footer",
                  },
                  {
                    label: "Advanced",
                  },
                ],
                reference: "tabs",
              },
              models: {
                tab: {
                  get: () => localData.tab,
                  set: (value) => (localData.tab = value as number),
                },
              },
              slots: {
                general: {
                  template: GridLayout([
                    ["favIcon", "brand"],
                    ["theme", "navbarType"],
                  ]),
                  components: {
                    favIcon: [
                      FormUrlField({
                        props: {
                          label: "Fav Icon",
                          message:
                            "Default: static.kinherit.co.uk/favicons/favicon_blue.png",
                        },
                        models: {
                          value: "favIcon",
                        },
                      }),
                    ],
                    brand: [
                      FormTextField({
                        props: {
                          label: "Brand",
                        },
                        models: {
                          value: "brand",
                        },
                      }),
                    ],
                  },
                },
                login: {
                  template: GridLayout(["screenImg", "customScreenImage"]),
                  components: {
                    screenImg: [
                      OptionsAutoCompleteField({
                        reactive: true,
                        group: "kinvaultThemeLoginScreenImage",
                        vModel: {
                          get: () => data.login.screenImg,
                          set: (value, data) => {
                            data.login.screenImg = value;
                            if (null !== value) {
                              data.login.customScreenImage = null;
                            }
                          },
                        },
                        props: {
                          state: (data) =>
                            data.login.customScreenImage === null
                              ? undefined
                              : "disabled",
                        },
                      }),
                    ],
                    customScreenImage: [
                      FormUrlField({
                        reactive: true,
                        props: {
                          label: "Custom Screen Image",
                          state: (data) =>
                            data.login.screenImg === null
                              ? undefined
                              : "disabled",
                          showClearButton: true,
                        },
                        models: {
                          value: {
                            get: () => data.login.customScreenImage,
                            set: (value, data) => {
                              data.login.customScreenImage = [
                                "",
                                "http://",
                                "https://",
                              ].includes(value)
                                ? null
                                : value;
                              if (null !== value) {
                                data.login.screenImg = null;
                              }
                            },
                          },
                        },
                      }),
                    ],
                  },
                },
                header: {
                  template: GridLayout([
                    ["type", "strap"],
                    ["defaultLogo", "defaultLogoClass"],
                    ["loginLogo", "loginLogoClass"],
                  ]),
                  components: {
                    type: [
                      OptionsAutoCompleteField({
                        group: "kinvaultThemeHeader",
                        vModel: "header.type",
                      }),
                    ],
                    defaultLogo: [
                      FormUrlField({
                        props: {
                          label: "Logo 1",
                          message:
                            "Default: static.kinherit.co.uk/brand/kinvault/Kinvault-Logo-dark.svg",
                        },
                        models: {
                          value: "header.defaultLogo",
                        },
                      }),
                    ],
                    defaultLogoClass: [
                      OptionsAutoCompleteField({
                        props: {
                          message: "Default: h-70p",
                        },
                        group: "kinvaultThemeHeaderLogoClass",
                        vModel: {
                          get: (data) =>
                            null === data.header.defaultLogoClass
                              ? null
                              : Option.$findOneByOrThrow({
                                  group: "kinvaultThemeHeaderLogoClass",
                                  value: data.header.defaultLogoClass,
                                }),
                          set: (value: Option, data) =>
                            (data.header.defaultLogoClass = (value?.value ??
                              null) as
                              | null
                              | "h-120p"
                              | "h-90p"
                              | "h-70p"
                              | "h-50p"
                              | "h-35p"
                              | "h-25p"
                              | "h-15p"),
                        },
                      }),
                    ],
                    loginLogo: [
                      FormUrlField({
                        props: {
                          label: "Logo 2",
                          message:
                            "Default: static.kinherit.co.uk/brand/kinvault/Kinvault-Logo-dark.svg",
                        },
                        models: {
                          value: "header.loginLogo",
                        },
                      }),
                    ],
                    loginLogoClass: [
                      OptionsAutoCompleteField({
                        props: {
                          message: "Default: h-70p",
                        },
                        group: "kinvaultThemeHeaderLogoClass",
                        vModel: {
                          get: (data) =>
                            null === data.header.loginLogoClass
                              ? null
                              : Option.$findOneByOrThrow({
                                  group: "kinvaultThemeHeaderLogoClass",
                                  value: data.header.loginLogoClass,
                                }),
                          set: (value: Option, data) =>
                            (data.header.loginLogoClass = (value?.value ??
                              null) as
                              | null
                              | "h-120p"
                              | "h-90p"
                              | "h-70p"
                              | "h-50p"
                              | "h-35p"
                              | "h-25p"
                              | "h-15p"),
                        },
                      }),
                    ],
                    strap: [
                      FormTextField({
                        props: {
                          label: "Strap",
                        },
                        models: {
                          value: "header.strap",
                        },
                      }),
                    ],
                  },
                },
                footer: {
                  template: GridLayout([
                    ["type", "class"],
                    ["leftLogo", "leftLogoClass"],
                    ["rightLogo", "rightLogoClass"],
                    "textLine1",
                    "textLine2",
                    ["twitter", "facebook"],
                    ["linkedIn", "youtube"],
                  ]),
                  components: {
                    class: [
                      OptionsAutoCompleteField({
                        group: "kinvaultThemeFooterClass",
                        vModel: "footer.class",
                      }),
                    ],
                    type: [
                      OptionsAutoCompleteField({
                        group: "kinvaultThemeFooter",
                        vModel: "footer.type",
                      }),
                    ],
                    textLine1: [
                      FormRichTextField({
                        props: {
                          label: "Footer Line 1",
                        },
                        models: {
                          value: "footer.textLine1",
                        },
                      }),
                    ],
                    textLine2: [
                      FormRichTextField({
                        props: {
                          label: "Footer Line 2",
                        },
                        models: {
                          value: "footer.textLine2",
                        },
                      }),
                    ],
                    leftLogoClass: [
                      OptionsAutoCompleteField({
                        props: {
                          label: `Left logo class`,
                          message: "Default: h-50p",
                        },
                        group: "kinvaultThemeFooterLogoClass",
                        vModel: {
                          get: (data) =>
                            null === data.footer.leftLogoClass
                              ? null
                              : Option.$findOneByOrThrow({
                                  group: "kinvaultThemeFooterLogoClass",
                                  value: data.footer.leftLogoClass,
                                }),
                          set: (value: Option, data) =>
                            (data.footer.leftLogoClass = (value?.value ??
                              null) as
                              | null
                              | "h-120p"
                              | "h-90p"
                              | "h-70p"
                              | "h-50p"
                              | "h-35p"
                              | "h-25p"
                              | "h-15p"),
                        },
                      }),
                    ],
                    leftLogo: [
                      FormUrlField({
                        props: {
                          label: "Left logo",
                          message:
                            "Default: static.kinherit.co.uk/brand/kinvault/Kinvault-Logo-White-no-strapline.svg",
                        },
                        models: {
                          value: "footer.leftLogo",
                        },
                      }),
                    ],
                    rightLogoClass: [
                      OptionsAutoCompleteField({
                        props: {
                          label: `Right logo class`,
                        },
                        group: "kinvaultThemeFooterLogoClass",
                        vModel: {
                          get: (data) => {
                            return null === data.footer.rightLogoClass
                              ? null
                              : Option.$findOneByOrThrow({
                                  group: "kinvaultThemeFooterLogoClass",
                                  value: data.footer.rightLogoClass,
                                });
                          },
                          set: (value: Option, data) => {
                            console.log(value.value);
                            return (data.footer.rightLogoClass =
                              (value?.value ?? null) as
                                | null
                                | "h-120p"
                                | "h-90p"
                                | "h-70p"
                                | "h-50p"
                                | "h-35p"
                                | "h-25p"
                                | "h-15p");
                          },
                        },
                      }),
                    ],
                    rightLogo: [
                      FormUrlField({
                        props: {
                          label: "Right logo",
                        },
                        models: {
                          value: "footer.rightLogo",
                        },
                      }),
                    ],
                    twitter: [
                      FormUrlField({
                        props: {
                          label: "Twitter",
                        },
                        models: {
                          value: "footer.twitter",
                        },
                      }),
                    ],
                    facebook: [
                      FormUrlField({
                        props: {
                          label: "Facebook",
                        },
                        models: {
                          value: "footer.facebook",
                        },
                      }),
                    ],
                    linkedIn: [
                      FormUrlField({
                        props: {
                          label: "LinkedIn",
                        },
                        models: {
                          value: "footer.linkedIn",
                        },
                      }),
                    ],
                    youtube: [
                      FormUrlField({
                        props: {
                          label: "YouTube",
                        },
                        models: {
                          value: "footer.youtube",
                        },
                      }),
                    ],
                  },
                },
                advanced: [
                  FormTextField({
                    props: {
                      isTextArea: true,
                      label: "Paste from clipboard",
                      message:
                        "Examples: --site-header-background-color, --footer-link-color, --footer-background, --footer-border-color etc",
                    },
                    models: {
                      value: {
                        get: () => localData.import,
                        set: (value) => {
                          localData.import = value;
                        },
                      },
                    },
                    slots: {
                      right: [
                        FormButton<InstanceType<typeof ThemeKinvault>>({
                          props: {
                            isDisabled: () => null === localData.import,
                            text: "Import",
                          },
                          emits: {
                            click: (_, data, controls) => {
                              if (null === localData.import) {
                                return;
                              }
                              const properties: Record<string, string> = {};

                              const lines = localData.import.split("\n");
                              for (const line of lines) {
                                const [key, value] = line.split(":");

                                if (!key || !value) {
                                  continue;
                                }

                                properties[key.trim()] = value.trim();

                                if (properties[key.trim()] === "") {
                                  delete properties[key.trim()];
                                }

                                if (properties[key.trim()].endsWith(";")) {
                                  properties[key.trim()] = properties[
                                    key.trim()
                                  ].slice(0, -1);
                                }
                              }

                              for (const [key, value] of Object.entries(
                                properties,
                              )) {
                                const cssVar = data.cssVars.find(
                                  (cssVar) => cssVar.key === key,
                                );
                                if (cssVar) {
                                  cssVar.value = value;
                                } else {
                                  data.addCssVars(
                                    CreateThemeCssVar({
                                      key,
                                      value,
                                      theme: data.id,
                                    }),
                                  );
                                  // .$persist();
                                }
                              }

                              localData.import = null;

                              controls.rebuildForm();
                            },
                          },
                        }),
                      ],
                    },
                  }),
                  ...data.cssVars.map((cssVar) =>
                    FormTextField<InstanceType<typeof ThemeKinvault>>({
                      props: {
                        placeholder: "Value",
                      },
                      models: {
                        value: {
                          get: () => cssVar.value,
                          set: (value) => {
                            cssVar.value = value;
                          },
                        },
                      },
                      slots: {
                        left: [
                          FormAutoCompleteField({
                            props: {
                              options: CssVarNames.map((value) => ({ value })),
                              placeholder: "Key",
                              mapOptions: {
                                value: "value",
                                label: "value",
                              },
                              showClearButton: false,
                            },
                            models: {
                              value: {
                                get: () => ({ value: cssVar.key }),
                                set: (key) => {
                                  cssVar.key = key.value;
                                },
                              },
                            },
                          }),
                        ],
                        right: [
                          FormButton({
                            props: {
                              iconLeft: "Delete",
                              ariaLabel: "Delete",
                            },
                            emits: {
                              click: (_, data, controls) => {
                                data.removeCssVars(cssVar);
                                cssVar.$delete();
                                controls.rebuildForm();
                              },
                            },
                          }),
                        ],
                      },
                    }),
                  ),
                  FormButton({
                    props: {
                      text: "Add CSS Variable",
                    },
                    emits: {
                      click: (_, data, controls) => {
                        data.addCssVars(
                          CreateThemeCssVar({
                            key: "",
                            value: "",
                            theme: data.id,
                          }),
                          // .$persist()
                        );
                        controls.rebuildForm();
                      },
                    },
                  }),
                ],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
