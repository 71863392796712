import { EmailCampaignDetailsParams } from "@/module/admin.email-campaign/page/details";
import { AuthService } from "@/service/auth.service";
import { EmailCampaign } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const EmailCampaignDetailsMixin = defineComponent({
  name: "emaill-campaign-details-mixin",
  computed: {
    $params(): EmailCampaignDetailsParams {
      return this.$route.params as EmailCampaignDetailsParams;
    },
    emailCampaign(): EmailCampaign | null {
      return EmailCampaign.$findOne(this.$params.emailCampaign);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("email-campaign:write");
    },
  },
  methods: {
    async $refresh(): Promise<void> {
      if (!this.emailCampaign) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/email-campaign/campaign/record",
      //   {
      //     emailCampaign: this.emailCampaign,
      //   },
      // );

      await window.Kernel.ActionBus2.adminEmailCampaign.campaign.record({
        emailCampaign: this.emailCampaign,
      });
    },
  },
});
