import { AdminAccountReferralCodeActionBus } from "@/module/admin.account-referral-code/action";
import { AdminBrandedKinvaultActionBus } from "@/module/admin.branded-kinvault/action";
import { AdminCallLogActionBus } from "@/module/admin.call-log/action";
import { AdminEmailCampaignActionBus } from "@/module/admin.email-campaign/action";
import { AdminEmailTemplateActionBus } from "@/module/admin.email-template/action";
import { AdminLogActionBus } from "@/module/admin.log/action";
import { AdminTagActionBus } from "@/module/admin.tag/action";
import { AdminUserActionBus } from "@/module/admin.user/action";
import { AppointmentActionBus } from "@/module/appointment/action";
import { AuthActionBus } from "@/module/auth/action";
import { CoreActionBus } from "@/module/core/action";
import { CpdActionBus } from "@/module/cpd/action";
import { DashboardActionBus } from "@/module/dashboard/action";
import { ExternalActionBus } from "@/module/external/action";
import { IntroducerBillingContactActionBus } from "@/module/introducer.billing-contact/action";
import { IntroducerCompanyActionBus } from "@/module/introducer.company/action";
import { IntroducerContactActionBus } from "@/module/introducer.contact/action";
import { IntroducerContractActionBus } from "@/module/introducer.contract/action";
import { IntroducerFeeKintinActionBus } from "@/module/introducer.fee.kintin/action";
import { IntroducerFeesActionBus } from "@/module/introducer.fees/action";
import { IntroducerNetworkActionBus } from "@/module/introducer.network/action";
import { IntroducerOutsourceActionBus } from "@/module/introducer.outsource/action";
import { KinvaultKintinActionBus } from "@/module/kinvault.kintin/action";
import { KinvaultOfficerActionBus } from "@/module/kinvault.officer/action";
import { KinvaultStorageRequestActionBus } from "@/module/kinvault.storage-request/action";
import { KinvaultSubscriptionActionBus } from "@/module/kinvault.subscription/action";
import { KinvaultWillRevisionActionBus } from "@/module/kinvault.will-revision/action";
import { KnowledgeBaseActionBus } from "@/module/knowledge-base/action";
import { LeadActionBus } from "@/module/lead/action";
import { OrderActionBus } from "@/module/order/action";
import { ReportActionBus } from "@/module/report/action";
import { SettingsActionBus } from "@/module/settings/action";
import { SharedActionBus } from "@/shared/action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";

export const ActionBus = DefineActionBus({
  name: "ActionBus",
  actions: {
    adminAccountReferralCode: AdminAccountReferralCodeActionBus,
    adminBrandedKinvault: AdminBrandedKinvaultActionBus,
    adminCallLog: AdminCallLogActionBus,
    adminEmailCampaign: AdminEmailCampaignActionBus,
    adminEmailTemplate: AdminEmailTemplateActionBus,
    adminLog: AdminLogActionBus,
    adminTag: AdminTagActionBus,
    adminUser: AdminUserActionBus,
    appointment: AppointmentActionBus,
    auth: AuthActionBus,
    core: CoreActionBus,
    cpd: CpdActionBus,
    dashboard: DashboardActionBus,
    external: ExternalActionBus,
    introducerCompany: IntroducerCompanyActionBus,
    introducerContact: IntroducerContactActionBus,
    introducerContract: IntroducerContractActionBus,
    introducerFeeKintin: IntroducerFeeKintinActionBus,
    introducerFees: IntroducerFeesActionBus,
    introducerNetwork: IntroducerNetworkActionBus,
    introducerOutsource: IntroducerOutsourceActionBus,
    introducerBillingContact: IntroducerBillingContactActionBus,
    kinvaultKintin: KinvaultKintinActionBus,
    kinvaultStorageRequest: KinvaultStorageRequestActionBus,
    kinvaultSubscription: KinvaultSubscriptionActionBus,
    kinvaultWillRevision: KinvaultWillRevisionActionBus,
    kinvaultOfficer: KinvaultOfficerActionBus,
    knowledgeBase: KnowledgeBaseActionBus,
    lead: LeadActionBus,
    order: OrderActionBus,
    report: ReportActionBus,
    settings: SettingsActionBus,
    shared: SharedActionBus,
  },
});
