import { RouteInterface } from "@kinherit/framework/core/route-config";
import { SettingsBreadCrumb } from "../breadcrumbs";

export const SettingsRoute = "Settings";

export const Routes: Array<RouteInterface> = [
  {
    name: SettingsRoute,
    path: "/settings",
    component: () =>
      import(/* webpackChunkName: "Settings" */ "./Settings.vue"),
    meta: {
      breadcrumbs: () => [SettingsBreadCrumb],
    },
  },
  {
    name: "file-log",
    path: "/settings/file-log",
    component: () => import(/* webpackChunkName: "FileLog" */ "./FileLog.vue"),
    meta: {
      breadcrumbs: () => [SettingsBreadCrumb],
    },
  },
];
