<template>
  <PageHeader htag="h1" text="Tags">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create Tag
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      name: 'Name',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<script lang="ts">
import { ReadTagForm } from "@/module/admin.tag/form/read-tag.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { ITag, Tag } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  TagCreateRoute,
  TagDetailsParams,
  TagDetailsRoute,
  TagMasterListRoute,
} from ".";

export default defineComponent({
  name: TagMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  mixins: [AuthService.mixin()],
  data: () => ({
    filters: ReadTagForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        field: "name",
      },
      {
        title: "Companies",
        sort: true,
        map: (v: Tag) => v.$data.introducerCompanyCount,
      },
      {
        title: "People",
        sort: true,
        map: (v: Tag) => v.$data.introducerContactCount,
      },
      {
        title: "Kintins",
        sort: true,
        map: (v: Tag) => v.$data.kintinCount,
      },
      {
        title: "Leads",
        sort: true,
        map: (v: Tag) => v.$data.leadCount,
      },
      {
        title: "Appointments",
        sort: true,
        map: (v: Tag) => v.$data.appointmentCount,
      },
    ] as Array<TableColumn>,
    rows: Array<Tag>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "name" as keyof ITag,
      direction: "asc" as "asc" | "desc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("tag:write");
    },
  },
  methods: {
    handleClick(tag: Tag, event: MouseEvent): void {
      const params: TagDetailsParams = { tag: tag.id };

      window.Kernel.visitRoute(
        {
          name: TagDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadTagForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute("admin/tag/read", {
      //   ...formData,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });

      const data = await window.Kernel.ActionBus2.adminTag.read({
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.tags;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    create(): void {
      window.Kernel.visitRoute({
        name: TagCreateRoute,
      });
    },
  },
});
</script>
