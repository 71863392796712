import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsClientProgressRoute =
  "IntroducerCompanyDetailsClientProgress";
export const IntroducerCompanyDetailsClientProgressURI =
  "/introducer/company/:introducerCompany/client-progress";
export type IntroducerCompanyDetailsClientProgressParams = RouteParamObject<
  typeof IntroducerCompanyDetailsClientProgressURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsClientProgressRoute,
    path: IntroducerCompanyDetailsClientProgressURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsClientProgress" */ "./IntroducerCompanyDetails.ClientProgress.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Client progress",
          route: {
            name: IntroducerCompanyDetailsClientProgressRoute,
            params,
          },
        },
      ],
    },
  },
];
