<template>
  <Card title="Email Campaign"> </Card>
</template>

<script lang="ts">
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { EmailCampaignDetailsSummaryRoute } from ".";

export default defineComponent({
  name: EmailCampaignDetailsSummaryRoute,
  components: {
    Card,
  },
  mixins: [EmailCampaignDetailsMixin],
});
</script>
