import { ReadDashboardStatisticEPHandler } from "@/module/dashboard/action/dashboard-statistic/read-dashboard-statistic-ep.action";
import { ReadDashboardStatisticLAHandler } from "@/module/dashboard/action/dashboard-statistic/read-dashboard-statistic-la.action";
import { ReadSearchProfileHandler } from "@/module/dashboard/action/global-search/read-search-profile.action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { ReadNotificationsHandler } from "./notification/read-notifications.action";
import { ModifyPdfHandler } from "./pdf-utils/modify-pdf.action";

export const Actions = {
  "dashboard/notifications/read": ReadNotificationsHandler,
  "dashboard/global-search/profile/read": ReadSearchProfileHandler,
  "dashboard/dashboard-statistic-ep/read": ReadDashboardStatisticEPHandler,
  "dashboard/dashboard-statistic-la/read": ReadDashboardStatisticLAHandler,
  "dashboard/document-utils/modify": ModifyPdfHandler,
};

export const DashboardActionBus = DefineActionBus({
  name: "Dashboard",
  actions: {
    notifications: DefineActionBus({
      name: "Dashboard.Notifications",
      actions: {
        read: ReadNotificationsHandler,
      },
    }),
    globalSearch: DefineActionBus({
      name: "Dashboard.GlobalSearch",
      actions: {
        profile: DefineActionBus({
          name: "Dashboard.GlobalSearch.Profile",
          actions: {
            read: ReadSearchProfileHandler,
          },
        }),
      },
    }),
    dashboardStatistic: DefineActionBus({
      name: "Dashboard.DashboardStatistic",
      actions: {
        ep: DefineActionBus({
          name: "Dashboard.DashboardStatistic.EP",
          actions: {
            read: ReadDashboardStatisticEPHandler,
          },
        }),
        la: DefineActionBus({
          name: "Dashboard.DashboardStatistic.LA",
          actions: {
            read: ReadDashboardStatisticLAHandler,
          },
        }),
      },
    }),
    documentUtils: DefineActionBus({
      name: "Dashboard.DocumentUtils",
      actions: {
        modify: ModifyPdfHandler,
      },
    }),
  },
});
