<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
    }"
  >
    <PageHeader htag="h2" text="Follow-Up">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>
    <CallScriptSection title="Pre-call" :speech="false">
      <em>
        [Ensure you have the 4.1 Client summary document to hand, ideally on
        another screen.]
      </em>
    </CallScriptSection>
    <CallScriptSection title="Preamble">
      <b>
        Hello, is that {{ primarySalutation }}
        {{
          isJoint
            ? `/
        ${secondarySalutation}`
            : ""
        }}?
      </b>
      <br /><em>[Pause]</em> <br /><br />
      <b>
        It's {{ $auth.loggedInUser?.profile.firstName }} from Kinherit. We had a
        follow-up call booked, is now still a good time?
      </b>
      <span v-if="isJoint && !secondaryPerson">
        <br /><br /><b>And is {{ secondarySalutation }} with you today? </b>
        <br /><em>[Respond]</em><br />
        - <b>Great, pleased to meet you</b> <em>[OR]</em><br />
        - <b>Ok not a problem, I will need to speak to them at some point.</b>
        <br /><em>[SPECIALIST ADD NOTE AND SKIP COERCION BELOW]</em>
      </span>
      <br /><br />
      <b>
        Great. Before we start, did you manage to watch the Kinvault video link
        I sent you?<br />
      </b>
      <em>
        [Engage/ Discuss / If didn’t watch “No problem, maybe after… I only push
        because it can really help people visualise … but we will cover it all
        regardless”]
      </em>
    </CallScriptSection>
    <CallScriptSecuritySection
      :kintin="kintin"
      start="I sent you a recommendations report as an attachment, but before we get into that, can I start with the usual security check. Can you please confirm your primary email:"
      step="6.0.2"
      :checks="checks"
      :kintinChecks="kintinChecks"
    />
    <CallScriptSection v-if="isJoint" title="Coercion">
      <b>
        And {{ secondarySalutation }}, as you weren't on our previous call, can
        I run through some specific checks with you:
      </b>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.0dd"
        :kintin="kintin"
        is-reversed
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.00"
        :kintin="kintin"
        is-reversed
        :on="{
          yes: {
            flag: [
              'Take details put it in a follow-up note and discuss with Ben.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.0aa"
        :kintin="kintin"
        is-reversed
        :on="{
          no: {
            flag: [
              'Take details put it in a follow-up note and discuss with Ben.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.0bb"
        :kintin="kintin"
        is-reversed
        :on="{
          no: {
            flag: [
              'Take details put it in a follow-up note and discuss with Ben.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.0cc"
        :kintin="kintin"
        is-reversed
        :on="{
          yes: {
            flag: [
              'Take details put it in a follow-up note and discuss with Ben.',
            ],
          },
        }"
      />
      <br /><br />
      <b> Great, thank you - we have to ask that at least once. </b>
    </CallScriptSection>
    <CallScriptSection title="Anyone else?">
      <b>
        And per our first call, can I ask is anyone else potentially able to
        overhear this conversation?
      </b>
      <br /><em
        >Take comment, and if yes make sure you understand and write it in the
        portal</em
      ><b> <br /><br />Great. </b> <br /><br />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.0.3"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSection title="Open Customer Summary Doc">
      <b>
        OK so as I explained last time, the purpose of today's call is to
        hopefully reach some decisions about what you want.
        <br /><br />
        Do you have the summary document I sent open in front of you?
      </b>
      <br /><em
        >[Check have it open / tell them the password if needed to help them
        open it]</em
      >
      <br /><br />
      <b>
        OK so if you can open the document and turn to page 2, I have tried to
        lay out what this document is – which is my recommendations based on
        your situation.
        <br /><br />
        And just to re-iterate one point – today if we are using casual names to
        refer to someone, that is fine – we have a process to add full legal
        names, addresses, and let you double-check the details, so if there are
        typos don’t worry too much. OK?
      </b>
      <br /><br />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.1.1"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSection title="Key decisions">
      <b>
        Turning to page 3, you will see I have listed a summary of your
        situation.
        <br /><br />
        This information tells me what you want, but it also helps me identify
        the risks in your situation.
        <br /><br />
        Can we just step through some of your key decisions… what you want to
        happen to your assets first
      </b>
      <Title htag="h5" is-emphasised>Gifts</Title>
      <GiftRecipientsContainer :kintin="kintin" />
      <Title htag="h5" is-emphasised>Beneficiaries</Title>
      <BeneficiariesContainer :kintin="kintin" />
      <Title htag="h5" is-emphasised>Exclusions</Title>
      <ExclusionsContainer :kintin="kintin" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.1.2"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSection title="Trusted People">
      <b>OK so moving on to your trusted people.</b>
      <Title htag="h5" is-emphasised>Executors</Title>
      <ExecutorsContainer :kintin="kintin" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.1.4"
        :kintin="kintin"
      />
      <span
        v-if="
          checkIsYes('2.5.0a') &&
          checkIsYes('2.5.0d') &&
          hasChildrenOrDependents
        "
      >
        <Title htag="h5" is-emphasised> Guardians </Title>
        <GuardiansContainer :kintin="kintin" />
        <em>[Talk through choices]</em>
        <br /><em
          >[Prompt for additional Guardians if have only 1 or 2 listed]</em
        >
        <br /><br />
        <b>
          And last time I mentioned guardians get just £18.00 a week. Would you
          like to specify an alternative arrangement?
        </b>
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="4.0.0b"
          :kintin="kintin"
        />
      </span>
      <span v-if="checkIsYes('4.0.0b')">
        <br />
        <em>
          Most common options:
          <br />
          a) An allowance per child eg trustees to consider £12k per annum per
          child <br />
          b) The use of assets eg trustees to consider use of trust funds to
          support larger house / buy house <br />
          c) Some other support eg payment of bills / school fees / holidays /
          tuition <br />
          [Specialist to: write down what is of interest in English. This can be
          checked for legal-sense before Will printing]
        </em>
        <br /><br />
      </span>
      <RichTextField
        class="mb-4"
        label="Memorandum of Wishes guardian section"
        message="This can be checked for legal-sense before Will printing"
        v-if="checkIsYes('4.0.0b')"
        v-model:value="callScript.mowGuardians"
      />
      <span v-if="checkIsYes('4.0.0b')">
        <strong>Example Values</strong>
        <small>
          <br />Upfront sum <br />[AMOUNT] <br />Per year per child amount
          <br />[AMOUNT] <br />Use of trust funds until Children >18
          <br />[AMOUNT] <br />Other <br />[Text Notes]
        </small>
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        v-if="
          checkIsYes('2.5.0a') && checkIsYes('2.5.0d') && childOrDependentList
        "
        step="6.1.3"
        :kintin="kintin"
      />
      <Title v-if="checkIsYes('4.7.1e')" htag="h5" is-emphasised>
        Attorneys
      </Title>
      <AttorneysContainer :kintin="kintin" />
      <Title htag="h5" is-emphasised>Trustees</Title>
      <TrusteesContainer :kintin="kintin" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.1.5"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSection title="Your current risks">
      <b>
        OK so turning over the page, I have tried to identify the key risks that
        we want to solve with your end-of-life planning.
      </b>
      <span v-if="checkIsNo('5.2.0')" class="is-block mt-3">
        <strong class="has-text-success">[No Will]</strong><br />
        <b>
          First up is your Will. I guess you know you need a Will to avoid
          potentially dying “intestate”,
        </b>
      </span>
      <span v-if="checkIsYes('5.2.0')" class="is-block mt-3">
        <strong class="has-text-success">[Need to update Will]</strong><br />
        <b>
          First up is your Will. I guess you know you need to update it, because
          your situation has changed.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.4')" class="is-block mt-3">
        <strong class="has-text-success">[Don’t have EOLP]</strong><br />
        <b>
          Next up – end of life planning. We have a saying – “technology has
          made life easier but death harder”.
          <br /><br />
          A simple example is an online-only ISA… if you don’t TELL your
          executor you have it, then it probably won’t go to your family.
          <br /><br />
          But it is more this, its your utilities, insurance, car, pets, listing
          who your friends are, … end-of-life planning is just thinking through
          what your executors need. A simple will leaves far too much un-said.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.1')" class="is-block mt-3">
        <strong class="has-text-success">[Kids no guardian]</strong><br />
        <b>
          I have listed a key risk as the need to appoint a guardian for your
          children. Arguably this is the number 1 risk, and a reason to really
          prioritise getting a Will done. The downside of kids into care is just
          … severe.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.5')" class="is-block mt-3">
        <strong class="has-text-success">
          [Unmarried want partner to benefit]
        </strong>
        <br />
        <b>
          We talked through how the law does not recognise unmarried partners.
          <br /><br />
          With a Will you can state your beneficiaries.
          <br /><br />
          I should add, unmarried partners don’t get the tax benefits of being
          married, and a Will cannot fix that – it’s something called spousal
          exemption, which means inheritance tax is essentially paid on first
          death and not deferred to second death. If this is a concern, we
          should put a flag to come back to lifetime planning after the death
          planning is done <em>IF YES PUT FLAG</em>
        </b>
      </span>
      <span v-if="checkIsYes('5.2.2')" class="is-block mt-3">
        <strong class="has-text-success">[No LPAs]</strong><br />
        <b>
          We talked through Lasting Powers of Attorney. In a slightly selfish
          way, care less about what happens after I die - than if I am
          incapacitated somewhere.
        </b>
      </span>
      <span v-if="checkIsNo('5.2.2')" class="is-block mt-3">
        <strong class="text-success">[No LPA Health]</strong><br />
        <b>
          We talked through a Health - Lasting Power of Attorney. In a slightly
          selfish way, I care less about what happens after I die - than if I am
          incapacitated somewhere.
        </b>
      </span>
      <span v-if="checkIsMaybe('5.2.2')" class="is-block mt-3">
        <strong class="has-text-success">[No LPA Wealth]</strong><br />
        <b>
          We talked through a Wealth - Lasting Power of Attorney. This is just
          part of financial planning.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.6')" class="is-block mt-3">
        <strong class="has-text-success">[Children prior relationship]</strong
        ><br />
        <b>
          Moving on, we discussed your children from a prior relationship, and
          making sure they are specifically looked after.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.7')" class="is-block mt-3">
        <strong class="has-text-success">[Divorced or separated]</strong><br />
        <b>
          You also said you had separated from your spouse, so updating your
          Will is necessary to exclude them and state who you want to benefit
          instead.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.8')" class="is-block mt-3">
        <strong class="has-text-success">[R2R loved ones]</strong><br />
        <b>
          We talked through the right to reside concept – this is key to let
          someone continue to stay in the family home when they wont own it.
        </b>
      </span>
      <span v-if="checkIsNo('5.2.1')" class="is-block mt-3">
        <strong class="has-text-success">[Guardians to update]</strong><br />
        <b>You understand the importance of updating your guardians</b>
      </span>
      <span
        v-if="
          checkIsYes('5.2.3') || checkIsNo('5.2.3') || checkIsMaybe('5.2.3')
        "
        class="is-block mt-3"
      >
        <strong class="has-text-success"
          >[Business assets but no planning]</strong
        ><br />
        <b>
          We talked through the need to reflect business assets in a Will, to
          take advantage of the exemptions… else there could be inheritance tax,
          which is just annoying if avoidable.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.9')">
        <strong class="has-text-success">[Protect family home]</strong><br />
        <b>
          I have flagged your family home as something we would want to protect.
        </b>
      </span>
      <span v-if="checkIsYes('5.2.10')" class="is-block mt-3">
        <strong class="has-text-success">[Keep money in family]</strong><br />
        <b>
          I have listed “Keep money in the family” as this is a very common
          motivation. Unless you are giving to charity, you probably want
          whoever benefits to not then lose it to divorce!
        </b>
      </span>
      <span v-if="checkIsYes('5.2.11')" class="is-block mt-3">
        <strong class="has-text-success">[Higher tax rates]</strong><br />
        <b>
          I have also just put a reminder about inheritance tax and planning.
        </b>
      </span>
    </CallScriptSection>
    <CallScriptSection title="My recommendations" v-if="checkIsYes('5.3.0')">
      <h5 class="title is-emphasised">Kinherit Will &amp; Kinvault</h5>
      <b>
        Firstly, a Kinherit Will and Kinvault. This is our core offer – the Will
        to sort the legal side, and the Kinvault planning to cover all the other
        practical considerations. It is the kinvault part that lets us say we
        deliver Better outcomes for families – a standalone Will can only do so
        much.<br /><em
          >[Specialist discuss with client, get to confirm sale]</em
        >
      </b>
    </CallScriptSection>
    <CallScriptSection
      title="Lasting Power of Attorney"
      v-if="checkIsYes('5.3.1') || checkIsYes('5.3.2') || checkIsYes('5.3.3')"
    >
      <b>
        I have listed Lasting Powers of Attorney, and we have discussed the
        risks they are to prevent.
      </b>
      <br /><br />
      <CallScriptTickCross
        :value="checkIsYes('4.7.1d')"
        question="Has LPAs setup?"
        yes="Has LPA/EPAs setup already"
        no="No existing LPAs/EPAs"
      />
      <CallScriptTickCross
        :value="checkIsYes('4.7.1e')"
        question="Interested in LPAs?"
        yes="Interested in LPAs"
        no="Not Interested in LPAs"
      />
      <CallScriptTickCross
        :value="checkIsYes('3.0.3b')"
        question="Has Financial Adviser?"
        yes="Has Financial Adviser"
        no="No Financial Adviser"
      />
      <br />
      <b> We touched on Lasting Powers of Attorney last time. </b>
      <br /><br />
      Interested in Powers of Attorney
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1e"
        :kintin="kintin"
      />
      <span v-if="checkIsNo('4.7.1e')">
        <br />
        <b>
          You mentioned that you
          {{
            checkIsYes("4.7.1d")
              ? `DO have these set up and DON'T want to make any changes at this time`
              : `DON'T have these set up but DON'T want to set them up at this time`
          }}
        </b>
      </span>
      <span v-if="checkIsYes('4.7.1e')">
        <br />
        <b>
          You're welcome to come back to us if you ever want to put new ones in
          place. The forms are a bit of a pain, and we help people resign
          existing LPAs, manage signing and link advisers, things like that
        </b>
      </span>
      <span v-if="checkIsYes('4.7.1e')">
        <br />
        <b>
          You mentioned you may want to put new ones in place, to let people you
          trust act for you if you are incapacitated.
        </b>
      </span>
      <span v-if="checkIsYes('4.7.1e') && checkIsYes('3.0.3b')">
        <br />
        <b>
          You also have a Financial Adviser, and a Lasting Power of Attorney is
          technically needed to allow them to continue to take instructions if
          you are incapacitated.
        </b>
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1g"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e') && checkIsYes('3.0.3b')"
      />
      <span v-if="checkIsYes('4.7.1e') && isJoint">
        <br />
        <b>
          As a couple, I would assume that you would want your partner to speak
          for the other, if one of you were incapacitated?
        </b>
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1h"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e') && isJoint"
      />
      <span v-if="checkIsYes('4.7.1e') && isJoint">
        <br />
        <em>[If one manages house finances]</em>
        <b>
          <br />
          If {{ primarySalutation }} was to be incapacitated and
          {{ secondarySalutation }} had to take over the household finances, I
          assume you would want them to be able to deal with banks and utility
          providers.
        </b>
      </span>
      <span v-if="checkIsYes('4.7.1e')">
        <br />
        <b>
          To set you your LPA in the way you want, there are a couple of
          questions:
        </b>
        <br />
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1a"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
        :on="{
          yes: {
            ask: [
              'We recommend Joint and Several so that your LPA can still work even if one of your attorneys is unable to act. Otherwise everything essentially needs both to confirm which is a lot less practical. ',
            ],
          },
          no: {
            action: [
              'Decide: Jointly for some decisions OR jointly and severally for other decisions: NB this will NOT be reflected in the generated LPA (for now)',
            ],
            ask: [
              'There is a 3rd option to appoint some attorneys jointly on some decisions and severally on others, however the OPG does not recommend this we would want to make sure you took legal advice on it.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1b"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
        :on="{
          yes: {
            ask: [
              'If you choose this option, your attorneys can speak to doctors on your behalf as if they were you.',
            ],
          },
          no: {
            ask: [
              'If you choose this option, your doctors will take into account the views of the attorneys and of people who are interested in your welfare as well as any written statement you may have made, where it is practical and appropriate.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1c"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
        :on="{
          yes: {
            say: [
              'Giving authority upfront is normal to avoid an Attorney having to prove incapacitation every time, and this option is even encouraged on the LPA form. But for completeness, I need to highlight that there is a risk your Attorneys abuse that trust …. And we do have customers who revoke these powers when circumstances change.',
            ],
          },
          no: {
            ask: [
              'Only when I don’t have mental capacity: Be careful – this can make your LPA a lot less useful. Your attorneys might be asked to prove you do not have mental capacity each time they try to use this LPA.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1f"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
        :on="{
          yes: {
            say: ['Can I check the best number to give to the OPG?'],
            action: [
              'Write a note detailing the telephone number(s) and whether one person is paying for both or seperately.',
            ],
          },
          no: {
            say: [
              'Details of who to make the cheque out to will be in the cover letter we send',
            ],
          },
        }"
      />
      <span v-if="checkIsYes('4.7.1e')">
        <em>
          [Specialist be aware: Donors can write additional PREFERENCES or
          INSTRUCTIONS for how powers should be applied. If these exist, write a
          note and make the comments directly onto the LPA output.]
        </em>
      </span>
      <span v-if="checkIsYes('4.7.1e')">
        <b>
          Great. We have enough to structure your documents.
          <br /><br />
          One final point is about process. LPA forms involve quite a lot of
          signing. You need to sign bits, your certificate provider signs, your
          Attorneys all sign, you need to sign again. And all these signings
          need witnessing which is a bit of a pain.
          <br /><br />
          We give detailed signing instructions to follow, but we have found a
          bit of guidance directly to Attorneys can help.
          <br /><br />
          So assuming you are happy with this, when your LPA docs get posted to
          you, we will also send your Attorneys some basic info to help with
          their signing bit.
        </b>
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1i"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
      />
      <span v-if="checkIsYes('4.7.1e')">
        <br />
        <em>
          [Specialist to ask customer if they may have to look after someone
          else and would benefit from having an LPA in place. Write a follow up
          note if yes].
        </em>
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.3.1"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
      />
    </CallScriptSection>
    <CallScriptSection
      v-if="checkIsYes('5.3.4')"
      title="Estate Protection Trust"
    >
      <b>
        The Estate Protection Trust is arguably the most powerful protection, as
        they are designed to last for 125 years.
        <br /><br />
        If you have ever wondered how wealthy families go through divorces
        without the wealth … it is because of trusts.
        <br /><br />
        And the law is very complex, but if they are set up well they are
        actually pretty simple. Your beneficiaries won’t notice much difference,
        unless one gets divorced in which case money you have left for them is
        not lost.
        <br /><br />
        They also encourage a responsible attitude to money, and have a few
        other softer benefits:
      </b>
      <ul class="mt-2">
        <li v-if="marriedOrCivilPartnership" class="mt-3">
          <em>[Guardian support]</em>
          <b>
            You mentioned an interest in providing financial support to a
            guardian. The trust gives flexibility to do this.
          </b>
        </li>
        <li v-if="checkIsYes('4.3.1h')" class="mt-3">
          <em>[Child travel]</em>
          <b>
            You mentioned supporting your children's travel aspirations. Only
            really possible with a trust.
          </b>
        </li>
        <li v-if="checkIsYes('4.3.1e')" class="mt-3">
          <em>[Beneficiary age >25]</em>
          <b>
            You mentioned wanting to defer the age at which kids get your assets
            beyond 25, which does require a trust.
          </b>
        </li>
        <li v-if="checkIsYes('2.5.0b')" class="mt-3">
          <em>[children from prior relationship]</em>
          <b>
            You have kids from a prior relationship. It is the option that helps
            deal with wishes in this area the best.
          </b>
        </li>
      </ul>
      <b>
        That is quite a lot to take in, but could the Estate Protection Trust
        add-on be of interest?
      </b>
      <br /><br />
      <em>[If say no + higher value customer]</em><br />
      <b>
        can sort a Will without it now, and you can just pay the difference to
        convert later if you change your mind.
      </b>
      <br />
      <RadioField
        label="Interest in Estate Protection or Basic EOLP with Will"
        :options="{
          1: 'Will with Estate Protection',
          0: 'Will Only',
        }"
        size="is-medium"
        class="mb-6"
        is-button
        v-model:value="callScript.requiresAssetProtection"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.4.1"
        :kintin="kintin"
      />
      <span v-if="checkIsYes('5.3.5') || checkIsYes('5.3.6')">
        <Title htag="h5" is-emphasised>Deed of Severance / Conveyance</Title>
        <p class="has-text-warning is-strong">
          <span v-if="checkIsYes('2.4.4b')">
            <br />Sole Jurisdiction is England &amp; Wales
          </span>
          <span v-else>
            <br />Sole Jurisdiction is NOT England &amp; Wales! <br />Deed of
            Severance does not work in Scotland.
          </span>
        </p>
        <em>[IF COUPLE with home and jointly] [If DoS appropriate]</em>
        <br />
        <b>
          I checked the land registry and you own your property jointly. This is
          fine most of the time but if one of you were to be incapacitated the
          local authority could force the sale to cover care fees. A fairly
          inexpensive way to fix this is via a Deed of Severance… it converts
          ownership to true-50:50, which give quite strong protection.
        </b>
        <RadioField
          label="Requires Property Products (DOS/Conveyancing)"
          :options="{
            1: 'Yes',
            0: 'No',
          }"
          size="is-medium"
          class="mb-5 mt-3"
          is-button
          v-model:value="callScript.requiresDeedOfSev"
        />
        <em>[ELSE IF OWN HOME and SINGLY] [If Conveyancing appropriate]</em
        ><br />
        <b>
          There is an option to conveyance your family home into both of your
          names, to hold it on a Tenants in Common basis. Done early, it is
          powerful protection from the risk one of you goes into care and
          care-fees consume the property.
        </b>
        <br /><br />
        <em>[IF SINGLE AND OWN HOME] [If Living Will appropriate]</em><br />
        <b>
          There is an option to put your assets into a Trust in your lifetime,
          to help protect your family home against challenges?
        </b>
        <ConfirmCheck
          @update="updateCheckValue"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="6.4.1"
          :kintin="kintin"
        />
      </span>
      <span v-if="checkIsYes('5.3.7')">
        <Title htag="h5" is-emphasised>Property Preservation Trust</Title>
        Text to come
      </span>
      <span v-if="checkIsYes('5.3.8')">
        <Title htag="h5" is-emphasised>
          Dealing with your Business Assets: Clause or Trust
        </Title>
        Text to come
      </span>
      <span v-if="checkIsYes('5.3.9')">
        <Title htag="h5" is-emphasised>
          Lasting Power of Attorney Finance for Business assets
        </Title>
        Text to come
      </span>
      <span v-if="checkIsYes('5.3.10')">
        <Title htag="h5" is-emphasised>
          Property, Lifetime &amp; Gift Trust
        </Title>
        Text to come
      </span>
      <!--ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.2.2"
        :kintin="kintin"
        v-if="checkIsYes('5.3.11')"
      /-->
    </CallScriptSection>
    <CallScriptSection title="Order Confirmation">
      <b>OK So let us have a look at what we have discussed</b>
      <br /><br />
      <br />The Will <br />The APT <br />Etc….
      <br />
      <br /><b
        >That is what we have talked through to cover off the major risks
        affecting your situation.</b
      >
      <br />
      <br />
      <b>
        Do you have questions on those? <br />
        Looking at cost.. <br />
        [The Will £350 <br />
        The EPT … <br />
        ….] <br />
        That all comes to £XM
      </b>
      <h5 class="title is-emphasised mb-5 mt-6">Orders</h5>
      <OrderList :kintin="kintin" />
      <br />
      <div class="buttons">
        <Button
          text="Add Order"
          color="is-success"
          class="mb-4"
          @click="$emit('create-order')"
        />
        <Button
          text="Send Order Payment Email"
          color="is-positive"
          class="mb-4 ml-2"
          @click="sendOrderPaymentEmail"
        />
      </div>
      <br /><em
        >Once you’ve confirmed products and amount (not storage yet)…</em
      >
      <br /><b>“Okay fab, are you happy to make that payment now?”</b>
      <br />
      <br /><em>[yes]</em> <br /><b
        >“Perfect, what I’ll do is email you a link to make the payment.. let me
        know when that comes through”</b
      >
      <br />
      <br /><em>[that’s come through..]</em> <br /><b
        >“If you click on that link, it should give you the option to pay either
        online or via BACS, whichever is easier for you (small pause).. and then
        once you’ve done that, I should get a notification and I can send you
        the next steps for you to complete…… etc”</b
      >
      <br />
      <br /><em>[once payment is received] <br />NOW mention storage –</em>
      <br /><b
        >“Lovely, that’s all gone through. Can I just check that you’re happy we
        store your original signed documents so you’ve got peace of mind
        everything is in a safe place?”</b
      >
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.2.2"
        :kintin="kintin"
        v-if="checkIsYes('5.3.11')"
      />
      <br /><em>[yes]</em> <br /><b
        >“Fab, what I’ll do then is include the direct debit set up for the £50
        per year in the next steps for you, which I’ll send across now but
        please note we don’t require the £50 from you now – this will be taken
        once we are physically storing your original documents for you”</b
      >
      <br />
      <br /><em
        >And finally.. <br />Mention you’ve sent them the post payment items and
        let them know what to complete, then you can draft, etc.</em
      >
    </CallScriptSection>
    <!--
      type === 'joint' && advihtestatesitu === 1 && requiresassetprotection
    -->
    <CallScriptSection title="IIP Opt out">
      <b
        >By default, our Will+Trust documents for couples contain two trusts per
        Will. This is to cover any wealth one person has that is over the
        tax-free threshold of 325,000. If you are happy you will not be over
        this threshold, you can simplify the documents and exclude the second
        trust. Let me know if you’d like this option.</b
      >

      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.6.2"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSection title="Cooling Off Period">
      <b
        >By law we're required to offer a 14 day cooling off period, but this
        doesn't make much sense to us as it just delays you getting your
        documents; if you do want it, let me know, but otherwise I'll assume
        you're happy to waive it. <br
      /></b>
      <em
        >[PAUSE] [if they want the cooling off period, ensure tickbox is
        unchecked in order]</em
      >
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.6.1"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSection title="Closing">
      <b>
        OK so I think that is everything for today.
        <br /><br />
        If you can arrange for payment, I will send out the information
        verification so that you can complete and confirm the accuracy of what
        will go into your Will.
        <br /><br />
        We will then prepare, print and post it to you for signing. Now the law
        on Will-signing is very specific in terms of who can witness,
        <span v-if="checkIsYes('5.1.4d')"
          >but as we're going to video-witness your signing we can talk you
          through.</span
        ><span v-else
          >but there will be signing instructions included with the Will.</span
        >
        <br /><br />
        And once your Will is signed, we move on to your Kinvault planning
        stage. Don’t worry for now, I will talk you through everything at the
        time.
        <br /><br />
        I need to touch on officer calling…
        <br /><br />
        We give better outcomes for people’s families by making sure your
        Kinvault plan is available, when needed… but we need to be told if
        something happens to you. So we reach out to the people with roles in
        your will and just make sure they know to contact us… it is pretty
        simple, and obviously your wishes are not disclosed. People find this
        bit really reassuring – to know there is a plan and someone to contact.
        <br /><br />
        Before we end for today, did you have any other questions for me?
        <br /><em>[pause]</em> <br /><br />
        That is everything from me. The payment email will be with you now, and
        as mentioned once that is sorted we move on to confirming information
        and getting you protected.
        <br /><br />
        Thanks again for your time, and we will speak soon. Bye.
      </b>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import AttorneysContainer from "@/module/kinvault.kintin/component/address-book/AttorneysContainer.vue";
import BeneficiariesContainer from "@/module/kinvault.kintin/component/address-book/BeneficiariesContainer.vue";
import ExclusionsContainer from "@/module/kinvault.kintin/component/address-book/ExclusionsContainer.vue";
import ExecutorsContainer from "@/module/kinvault.kintin/component/address-book/ExecutorsContainer.vue";
import GiftRecipientsContainer from "@/module/kinvault.kintin/component/address-book/GiftRecipientsContainer.vue";
import GuardiansContainer from "@/module/kinvault.kintin/component/address-book/GuardiansContainer.vue";
import TrusteesContainer from "@/module/kinvault.kintin/component/address-book/TrusteesContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import OrderList from "@/module/kinvault.kintin/component/call-script/shared/OrderList.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSecuritySection from "@/module/kinvault.kintin/component/call-script/shared/SecuritySection.vue";
import CallScriptTickCross from "@/module/kinvault.kintin/component/call-script/shared/TickCross.vue";
import { SendOrderPaymentEmailForm } from "@/module/kinvault.kintin/form/call-script.form/send-order-payment-email.form";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Title from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import RadioField from "@kinherit/framework/component.input/radio-field";
import RichTextField from "@kinherit/framework/component.input/rich-text-field";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptRoute } from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    PageHeader,
    Button,
    CallScriptSection,
    CallScriptSecuritySection,
    ConfirmCheck,
    Title,
    RichTextField,
    CallScriptTickCross,
    RadioField,
    GiftRecipientsContainer,
    BeneficiariesContainer,
    ExclusionsContainer,
    AttorneysContainer,
    TrusteesContainer,
    OrderList,
    GuardiansContainer,
    ExecutorsContainer,
  },
  data: () => ({
    isFocused: false,
  }),
  methods: {
    saveHandler() {
      // @todo
      this.$snackBar.success.saved();
    },
    async sendOrderPaymentEmail() {
      if (!this.kintin) {
        return;
      }

      const result = await SendOrderPaymentEmailForm(this.kintin).dialog({
        dialog: {
          title: "Send Order Payment Email",
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/order/payment-email/send",
      //   {
      //     order: result.order as string,
      //     person: result.person as string,
      //   },
      // );

      await window.Kernel.ActionBus2.kinvaultKintin.order.sendPaymentEmail({
        order: result.order as string,
        person: result.person as string,
      });
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
