<template>
  <RouterChildView :key="renderKey" @reload="load">
    <KinvaultKintinDetailsAddressAccountHolders />
  </RouterChildView>
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookRoute } from ".";
import KinvaultKintinDetailsAddressAccountHolders from "./KintinDetails.AddressBook.AccountHolders.vue";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    RouterChildView,
    KinvaultKintinDetailsAddressAccountHolders,
  },
  data: () => ({
    renderKey: 0,
    actionBusListeners: [] as string[],
  }),
  async beforeMount(): Promise<void> {
    await this.load();
  },
  methods: {
    async load(): Promise<void> {
      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/address-book/record",
      //   this.$params,
      // );
      await window.Kernel.ActionBus2.kinvaultKintin.addressBook.record(
        this.$params,
      );

      this.$forceUpdate();
    },
  },
});
</script>
