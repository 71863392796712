import {
  IntroducerCompanyField,
  OptionsSelectField,
} from "@/config/form.config";
import { CreateIntroducerContact } from "@/config/model.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormSwitchField } from "@kinherit/framework/component.input/switch-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import {
  EmailAddress,
  IntroducerContact,
  Option,
  PhoneNumber,
} from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";

export const CreateIntroducerContactForm = (
  introducerContact: IntroducerContact = CreateIntroducerContact(),
) => {
  introducerContact.callStatus = Option.$findOneByOrThrow({
    group: "introducerCpdCallStatus",
    value: "tbc",
  });

  introducerContact.status = Option.$findOneByOrThrow({
    group: "introducerContactStatus",
    value: "new",
  });

  return defineForm({
    name: "create-introducer-contact-form",
    data: () => introducerContact,
    formAreas: (data) => [
      defineFormArea({
        name: "create-introducer-contact-form-area",
        data,
        template: GridLayout([
          ["subtitle1"],
          ["firstName", "lastName"],
          ["jobTitle"],
          ["email", "phone"],
          ["status", "callStatus"],
          //["newsLetter"],
          // ["subtitle2"],
          // ["company"],
        ]),
        components: () => ({
          subtitle1: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "Details",
              },
            }),
          ],
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
              },
              models: {
                value: "profile.firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
              },
              models: {
                value: "profile.lastName",
              },
            }),
          ],
          jobTitle: [
            FormTextField({
              props: {
                label: "Job Title",
              },
              models: {
                value: "profile.jobTitle",
              },
            }),
          ],
          email: [
            FormEmailField({
              reactive: true,
              props: {
                label: "Email",
                showGenerateButton: false,
                generatedPrefix: (data) => data.profile.lastName?.trim(),
              },
              models: {
                value: {
                  get: (data) => data.profile.emails.first()?.email ?? "",
                  set: (value, data) => {
                    const email = data.profile.emails.first();

                    if (email) {
                      email.email = value;
                      email.$persist();
                    } else {
                      data.profile.addEmails(
                        new EmailAddress({
                          id: Uuid.generate(),
                          email: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.profile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          phone: [
            FormPhoneNumberField({
              reactive: true,
              props: {
                label: "Phone",
              },
              models: {
                value: {
                  get: (data) => data.profile.phoneNumbers.first()?.tel ?? "",
                  set: (value, data) => {
                    const phone = data.profile.phoneNumbers.first();

                    if (phone) {
                      phone.tel = value;
                      phone.$persist();
                    } else {
                      data.profile.addPhoneNumbers(
                        new PhoneNumber({
                          id: Uuid.generate(),
                          tel: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.profile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          status: [
            OptionsSelectField({
              group: "introducerContactStatus",
              vModel: "status",
              props: {
                label: "Status",
                validators: ["required"],
              },
            }),
          ],
          callStatus: [
            OptionsSelectField({
              group: "introducerCpdCallStatus",
              vModel: "callStatus",
              props: {
                label: "Call Status",
                validators: ["required"],
              },
            }),
          ],
          newsLetter: [
            FormSwitchField({
              props: {
                label: "News Letter",
              },
              models: {
                value: "newsLetter",
              },
            }),
          ],
          subtitle2: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "Company",
              },
            }),
          ],
          company: [
            IntroducerCompanyField({
              props: {
                label: undefined,
                vModel: "companies",
                isMultiSelect: true,
              },
            }),
          ],
        }),
      }),
    ],
  });
};
