import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadIntroducerBillingContactForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-billing-contact-form",
    data: () => ({
      name: "",
      created: null as null | [number, number],
      isGlobal: null as null | boolean,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      isGlobal: [
        FormSelectField({
          props: {
            label: "Global",
            options: {
              null: "Any",
              yes: "Yes",
              no: "No",
            },
            showClearButton: false,
          },
          models: {
            value: {
              get: (data) => {
                if (data.isGlobal === null) {
                  return `null`;
                }

                return data.isGlobal ? "yes" : "no";
              },
              set: (value, data) => {
                data.isGlobal =
                  value === "yes" ? true : value === "no" ? false : null;
              },
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
