<template>
  <div class="history-page">
    <PageHeader htag="h2" :text="title" />
    <MasterListPage
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      is-nested
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :is-clickable="false"
      :sort-by-options="{
        date: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #details="{ row }: { row: EntityHistory }">
        <HistoryPageRow :row="row" />
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ActivityLogTableColumns } from "@/config/table-columns.config";
import { ReadActivityLogForm } from "@/shared/form/activity-log/read-activity-log.form";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { In } from "@kinherit/orm/index";
import { EntityHistory } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import HistoryPageRow from "./HistoryPageRow.vue";

export default defineComponent({
  name: "HistoryPage",
  components: { MasterListPage, PageHeader, HistoryPageRow },
  props: {
    type: {
      type: String as PropType<"user" | "kintin" | "lead">,
      default: null,
    },
    id: {
      type: String as PropType<string>,
      default: null,
    },
  },
  data: (ctx) => {
    const forUser = ctx.type === "user";
    const filters = ReadActivityLogForm(forUser);

    return {
      filters: filters,
      columns: ActivityLogTableColumns(filters, forUser),
      rows: Array<EntityHistory>(),
      pagination: {
        currentPage: 1,
        lastPage: 0,
        perPage: 15,
        count: 0,
      },
      sort: {
        by: "date" as const,
        direction: "desc" as "desc" | "asc",
      },
    };
  },
  computed: {
    title(): string {
      switch (this.type) {
        case "user":
          return "Activity Logs";
        default:
          return "Change History";
      }
    },
  },
  methods: {
    // async refresh(formData: any): Promise<void> {
    //   let action:
    //     | "admin/user/activity/read"
    //     | "kinvault/kintin/history/read"
    //     | "lead/history/read";
    //   const data = {} as any;

    //   switch (this.type) {
    //     case "user":
    //       action = "admin/user/activity/read";
    //       data.user = this.id;
    //       break;
    //     case "kintin":
    //       action = "kinvault/kintin/history/read";
    //       data.kintin = this.id;
    //       break;
    //     case "lead":
    //       action = "lead/history/read";
    //       data.lead = this.id;
    //       break;
    //   }

    //   const { logs, pagination } = await window.Kernel.ActionBus.execute(
    //     action,
    //     {
    //       sort: this.sort,
    //       pagination: this.pagination,
    //       ...formData,
    //       ...data,
    //     },
    //   );

    //   const userIds = logs?.pluck("$data.user") ?? [];

    //   if (userIds.length > 0 && this.type !== "user") {
    //     await window.Kernel.ActionBus.execute("core/select/user/read", {
    //       query: {
    //         id: In(userIds),
    //       },
    //     });
    //   }

    //   this.rows = logs;
    //   this.pagination.currentPage = pagination.currentPage;
    //   this.pagination.lastPage = pagination.lastPage;
    //   this.pagination.count = pagination.count;
    //   this.$forceUpdate();
    // },
    async refresh(formData: any): Promise<void> {
      switch (this.type) {
        case "user":
          return this.refreshUser(formData);
        case "kintin":
          return this.refreshKintin(formData);
        case "lead":
          return this.refreshLead(formData);
      }
    },
    async refreshUser(formData: any): Promise<void> {
      const { logs, pagination } =
        await window.Kernel.ActionBus2.adminUser.activity.read({
          sort: this.sort,
          pagination: this.pagination,
          ...formData,
          user: this.id,
        });

      const userIds = logs?.pluck("$data.user") ?? [];

      if (userIds.length > 0) {
        await window.Kernel.ActionBus2.core.select.user.read({
          query: {
            id: In(userIds),
          },
        });
      }

      this.rows = logs;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async refreshKintin(formData: any): Promise<void> {
      const { logs, pagination } =
        await window.Kernel.ActionBus2.kinvaultKintin.history.read({
          sort: this.sort,
          pagination: this.pagination,
          ...formData,
          kintin: this.id,
        });

      this.rows = logs;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async refreshLead(formData: any): Promise<void> {
      const { logs, pagination } =
        await window.Kernel.ActionBus2.lead.history.read({
          sort: this.sort,
          pagination: this.pagination,
          ...formData,
          lead: this.id,
        });

      this.rows = logs;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

<style lang="scss">
.history-page {
  table td {
    display: table-cell !important;
  }
}
</style>
