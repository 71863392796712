<template>
  <component
    :is="tag"
    :class="{
      'section-container': true,
      [`is-${state}`]: true,
    }"
    :section="sectionId"
  >
    <SectionControls
      :state="state"
      :has-custom-content="null !== custom"
      @edit="$emit('edit')"
      @update:state="$emit('update:state', $event)"
    />
    <section>
      <span
        v-show="isGenerated"
        class="section-content section-content-generated"
      >
        <slot />
      </span>
      <span
        v-show="isCustom"
        class="section-content section-content-custom"
        v-html="custom"
      />
      <span
        v-if="isDifference"
        class="section-content section-content-difference"
        v-html="difference"
      />
    </section>
  </component>
</template>

<script lang="ts">
import * as HtmlDiff from "@ali-tas/htmldiff-js";
import { PropType, defineComponent } from "vue";
import SectionControls from "./SectionControls.vue";

export default defineComponent({
  name: "WillBuilderSection",
  props: {
    state: {
      type: String as PropType<"custom" | "generated" | "difference">,
      required: true,
    },
    tag: {
      type: String as PropType<string>,
      default: "li",
    },
    custom: {
      type: String as PropType<string | null>,
      default: null,
    },
    sectionId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: ["edit", "update:state"],
  computed: {
    isGenerated(): boolean {
      return this.state === "generated";
    },
    isDifference(): boolean {
      return this.state === "difference";
    },
    isCustom(): boolean {
      return this.state === "custom";
    },
    difference(): string {
      const generated =
        this.$el?.querySelector(".section-content-generated")?.innerHTML ?? "";

      const custom = this.custom ?? "";

      return HtmlDiff.Diff.execute(generated, custom, {
        combineWords: false,
        ignoreWhiteSpaceDifferences: true,
      });
    },
  },
  components: { SectionControls },
});
</script>
