<template>
  <div class="gift-recipients">
    <GiftRecipientSummary
      v-for="gift in gifts.sortBy('sortOrder', 'asc')"
      :key="`gift-${gift.id}`"
      :kintin="kintin"
      :gift="gift"
      @edit="edit(gift)"
      @delete="remove(gift)"
    />
    <Card v-if="!gifts.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import GiftRecipientSummary from "@/module/kinvault.kintin/component/summary-cards/GiftRecipientSummary.vue";
import { UpdateGiftForm } from "@/module/kinvault.kintin/form/update-gift.form";
import Card from "@kinherit/framework/component.layout/card";
import { Gift, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GiftRecipientList",
  components: { GiftRecipientSummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    gifts: {
      type: Array as () => Gift[],
      required: true,
    },
  },
  methods: {
    async edit(gift: Gift): Promise<void> {
      try {
        await UpdateGiftForm({
          data: gift,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Gift",
          },
        });
      } catch {
        gift.$restore();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/gift/update", {
      //   gift,
      //   kintin,
      // });

      await window.Kernel.ActionBus2.kinvaultKintin.gift.update({
        gift,
      });

      this.$emit("reload");
    },
    async remove(gift: Gift): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Gift",
          message: "Are you sure you want to delete this gift?",
        },
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/gift/delete", {
      //   gift,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.gift.delete({
        gift,
      });

      this.$emit("reload");
    },
  },
});
</script>
