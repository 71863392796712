import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadBrandedKinvaultInvitationForm = () => {
  return MasterListFiltersForm({
    name: "filter-brandedKinvaultInvitation-form",
    data: () => ({
      search: "",
      created: null as null | [number, number],
      status: [] as string[],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Search",
          },
          models: {
            value: "search",
          },
        }),
      ],
      status: [
        FormAutoCompleteField({
          props: {
            label: "Status",
            options: {
              notsent: "Not Sent",
              sent: "Sent",
              complete: "Complete",
              failed: "Failed",
              declined: "Declined",
            },
            isMultiSelect: true,
          },
          models: {
            value: "status",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
