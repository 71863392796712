<template>
  <div :key="key" class="user-details" v-if="user && !loading">
    <ProfileSummary
      :profile-id="user.$data.profile"
      :has-context-menu="showContextMenu"
      @context-menu="contextMenu"
      :widgets="{
        contactDetails: true,
      }"
      :tabs="filteredTabs"
    >
      <RouterChildView @create:mf-auth-method="createAuthentication">
        <UserDetailsSummary />
      </RouterChildView>
    </ProfileSummary>
  </div>
  <PageNotFound v-else-if="!loading" />
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { StyleService } from "@/service/style.service";
import ProfileSummary from "@/shared/component/profile/ProfileSummary.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import {
  AdminUserDetailsActivityRoute,
  AdminUserDetailsAssignKintinAccessRoute,
  AdminUserDetailsCreate2FactorAuthRoute,
  // AdminUserDetailsCreditNotesRoute,
  AdminUserDetailsJoinBrandedKinvaultRoute,
  AdminUserDetailsManage2FactorAuthRoute,
  AdminUserDetailsParams,
  AdminUserDetailsRoute,
  AdminUserDetailsUpdateProfileRoute,
  UserDetailsBrandedKinvaultsRoute,
  UserDetailsKintinsRoute,
  UserDetailsSummaryRoute,
} from ".";
import { ResetPasswordForm } from "../../form/reset-password.form";
import { UpdateUserForm } from "../../form/update-user.form";
import { UserDetailsMixin } from "../../mixin/user-details.mixin";
import UserDetailsSummary from "./UserDetails.Summary.vue";

export default defineComponent({
  name: AdminUserDetailsRoute,
  mixins: [AuthService.mixin({ sessionRequired: true }), UserDetailsMixin],
  components: {
    PageNotFound,
    ProfileSummary,
    RouterChildView,
    UserDetailsSummary,
  },
  data: (ctx) => ({
    loading: true,
    key: 0,
    tabs: [
      {
        label: "Summary",
        icon: StyleService.icon.summary.icon,
        route: {
          name: UserDetailsSummaryRoute,
          params: ctx.$params,
        },
        nestedRoutes: [
          AdminUserDetailsRoute,
          AdminUserDetailsUpdateProfileRoute,
        ],
      },
      {
        label: "Kinvaults",
        icon: StyleService.icon.kintin.icon,
        route: {
          name: UserDetailsKintinsRoute,
          params: ctx.$params,
        },
        nestedRoutes: [AdminUserDetailsAssignKintinAccessRoute],
      },
      {
        label: "Branded Kinvaults",
        icon: StyleService.icon.organisation.icon,
        route: {
          name: UserDetailsBrandedKinvaultsRoute,
          params: ctx.$params,
        },
        nestedRoutes: [AdminUserDetailsJoinBrandedKinvaultRoute],
      },
      {
        label: "Authentication",
        route: {
          name: AdminUserDetailsManage2FactorAuthRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.lock.icon,
        nestedRoutes: [AdminUserDetailsCreate2FactorAuthRoute],
        permission: "user:write",
      },
      // {
      //   label: "Credit Notes",
      //   icon: StyleService.icon.creditNote.icon,
      //   route: {
      //     name: AdminUserDetailsCreditNotesRoute,
      //     params: ctx.$params,
      //   },
      //   nestedRoutes: [],
      // },
      {
        label: "Activity",
        icon: StyleService.icon.history.icon,
        route: {
          name: AdminUserDetailsActivityRoute,
          params: ctx.$params,
        },
        nestedRoutes: [],
      },
    ],
  }),
  computed: {
    filteredTabs(): Array<
      TabOptions & {
        permission?: string;
      }
    > {
      return this.tabs.filter(this.$auth.filterAllPermissions("permission"));
    },
    showContextMenu(): boolean {
      return (
        this.$auth.hasPermission("user:write") ||
        this.$auth.hasPermission("kintin:write") ||
        this.isLoggedInUser
      );
    },
    isLoggedInUser(): boolean {
      return AuthService.loggedInUser?.id === this.user?.id;
    },
    allowPortalPasswordReset(): boolean {
      return this.user?.displayUserInPortal ?? false;
    },
  },
  async beforeRouteEnter(to) {
    // await window.Kernel.ActionBus.execute(
    //   "admin/user/record",
    //   to.params as AdminUserDetailsParams,
    // );
    await window.Kernel.ActionBus2.adminUser.record(
      to.params as AdminUserDetailsParams,
    );
  },
  async beforeRouteUpdate(to): Promise<void> {
    // await window.Kernel.ActionBus.execute(
    //   "admin/user/record",
    //   to.params as AdminUserDetailsParams,
    // );
    await window.Kernel.ActionBus2.adminUser.record(
      to.params as AdminUserDetailsParams,
    );
    this.key++;
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      const openContextMenu = AuthService.openContextMenu({
        request: {
          items: [
            {
              label: "Edit Profile",
              message: "Edit this user's Profile details",
              icon: StyleService.icon.profile.icon,
              permission: "user:write",
              action: this.editProfile,
            },
            {
              label: "Settings",
              message: "Edit this user's Settings",
              icon: StyleService.icon.edit.icon,
              permission: "user:write",
              action: this.editSettings,
            },
            {
              label: "Kinvault",
              message: "Assign a Kinvault to this user",
              icon: StyleService.icon.kintin.icon,
              permission: ["user:write", "kintin:write"],
              action: this.assignKinvault,
            },
            {
              label: "Branded Kinvaults",
              message: "Assign an branded kinvaults to this user",
              icon: StyleService.icon.organisation.icon,
              permission: "user:write",
              action: this.assignBrandedKinvaults,
            },
            {
              label: "Authentication",
              message: "Create a new authentication method",
              icon: StyleService.icon.lock.icon,
              permission: "user:write",
              action: this.createAuthentication,
            },
            {
              label: "Delete",
              message: "Delete this user",
              icon: StyleService.icon.delete.icon,
              permission: "user:write",
              action: this.deleteUser,
            },
            ...(this.isLoggedInUser
              ? [
                  {
                    label: "Reset Portal Password",
                    message: "Reset your password",
                    icon: StyleService.icon.lock.icon,
                    action: this.resetPassword,
                  },
                ]
              : []),
            ...(!this.isLoggedInUser && this.allowPortalPasswordReset
              ? [
                  {
                    label: "Send Portal Password Reset",
                    message: "Request a portal password reset for this user",
                    icon: StyleService.icon.lock.icon,
                    permission: "user:write",
                    action: this.requestPasswordReset,
                  },
                ]
              : []),
          ],
          trackingField: "label",
          titleField: "label",
          line1Field: "message",
          iconLeftField: "icon",
        },
        path: "permission",
        filter: "some",
        callback: (option) => option.action(),
      });

      await openContextMenu();
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: AdminUserDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
    assignKinvault(): void {
      window.Kernel.Router.push({
        name: AdminUserDetailsAssignKintinAccessRoute,
        params: this.$params,
      });
    },
    assignBrandedKinvaults(): void {
      window.Kernel.Router.push({
        name: AdminUserDetailsJoinBrandedKinvaultRoute,
        params: this.$params,
      });
    },
    createAuthentication(): void {
      window.Kernel.Router.push({
        name: AdminUserDetailsCreate2FactorAuthRoute,
        params: this.$params,
      });
    },
    async editSettings(): Promise<void> {
      const user = this.user;

      if (!user) {
        return;
      }

      try {
        await UpdateUserForm(user).dialog({
          dialog: {
            title: "Edit User Settings",
          },
        });
      } catch (error) {
        user.$restore();
        throw error;
      }

      // await window.Kernel.ActionBus.execute("admin/user/update", {
      //   user,
      // });
      await window.Kernel.ActionBus2.adminUser.update({
        user,
      });

      this.key++;
    },
    async resetPassword(): Promise<void> {
      if (!this.user) {
        return;
      }

      const data = await ResetPasswordForm(false).dialog({
        dialog: {
          title: "Reset Password",
        },
      });

      // await window.Kernel.ActionBus.execute("admin/user/change-my-password", {
      //   newPassword: data.newPassword,
      // });
      await window.Kernel.ActionBus2.adminUser.changeMyPassword({
        newPassword: data.newPassword,
      });

      SnackBarService.toast({
        text: "Password reset successfully",
        type: "success",
      });
    },
    async requestPasswordReset(): Promise<void> {
      if (!this.user) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: `Confirm`,
          message: `Are you sure you want to send a password reset email to this user?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/user/request-password-change",
      //   {
      //     user: this.user,
      //   },
      // );
      await window.Kernel.ActionBus2.adminUser.requestPasswordChange({
        user: this.user,
      });

      SnackBarService.toast({
        text: "Password reset request sent",
        type: "success",
      });
    },
    async deleteUser(): Promise<void> {
      if (!this.user) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: `Confirm`,
          message: `Are you sure you want to delete this user?`,
        },
      });

      // await window.Kernel.ActionBus.execute("admin/user/delete", {
      //   user: this.user,
      // });
      await window.Kernel.ActionBus2.adminUser.delete({
        user: this.user,
      });

      SnackBarService.toast({
        text: "User deleted successfully",
        type: "success",
      });

      window.Kernel.Router.push({
        name: AdminUserDetailsRoute,
      });
    },
  },
});
</script>

<style lang="scss">
.user-details {
  .tabs {
    > ul {
      margin-top: 0;
    }
  }
}
</style>
