<template>
  <EmailLogPage v-if="$data.officer" :owner="$data.officer" />
</template>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { OfficerDetailsEmailLogRoute } from ".";
import { KinvaultOfficerDetailsMixin } from "../../../mixin/kinvault-officer-details.mixin";

export default defineComponent({
  name: OfficerDetailsEmailLogRoute,
  mixins: [KinvaultOfficerDetailsMixin],
  components: {
    EmailLogPage,
  },
});
</script>
