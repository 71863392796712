<template>
  <div class="introducercompany-accounting-page">
    <Tabs :is-fullwidth="false" :config="tabs" />
    <RouterChildView
      @create-billing-contact="$emit('create-billing-contact')"
    />
  </div>
</template>

<script lang="ts">
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsAccountingIndexRoute,
  IntroducerCompanyDetailsAccountingRoute,
  IntroducerCompanyDetailsBillingRoute,
} from ".";
import { StyleService } from "@/service/style.service";

export default defineComponent({
  name: IntroducerCompanyDetailsAccountingIndexRoute,
  mixins: [StyleService.mixin],
  components: { Tabs, RouterChildView },
  data: () => ({
    tabs: [
      {
        label: `Xero`,
        icon: StyleService.icon.accounting.icon,
        route: { name: IntroducerCompanyDetailsAccountingRoute },
      },
      {
        label: `Billing Contacts`,
        icon: StyleService.icon.person.icon,
        route: { name: IntroducerCompanyDetailsBillingRoute },
      },
    ],
  }),
});
</script>
