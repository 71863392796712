<template>
  <div class="will will-highlight">
    <!-- MowIIP -->
    <Section
      v-show="showSection(sections.ids.MowIIP)"
      :section-id="sections.ids.MowIIP"
      :state="getSectionState(sections.ids.MowIIP)"
      @update:state="(state) => (sectionStates[sections.ids.MowIIP] = state)"
      @edit="editSection(sections.ids.MowIIP)"
      :custom="overrides[sections.ids.MowIIP] ?? null"
      tag="section"
    >
      <MowIip />
    </Section>
  </div>
</template>

<script lang="ts">
import MowIip from "@/module/kinvault.kintin/component/will-builder/section/MowIip.vue";
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { defineComponent } from "vue";

import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import Section from "../Section.vue";

export default defineComponent({
  name: "MowIipDocument",
  mixins: [WillBuilderService.mixin],
  components: {
    MowIip,
    Section,
  },
  emits: {
    "edit-section": (section: {
      section: WillBuilderSections;
      custom?: string;
      generated: string;
    }) => !!section,
  },
  data: () => ({
    sectionStates: {} as Partial<
      Record<WillBuilderSections, "generated" | "difference" | "custom">
    >,
  }),
  methods: {
    editSection(section: WillBuilderSections) {
      this.$emit("edit-section", {
        section: section,
        custom: this.overrides[section],
        generated: this.getSectionContent(section, "generated"),
      });
    },
    getSectionState(
      section: WillBuilderSections,
    ): "generated" | "difference" | "custom" {
      const override = this.sectionStates[section];
      if (override) {
        return override;
      }

      if (section.toString() in this.overrides) {
        return "custom";
      }

      return "generated";
    },
    showSection(section: WillBuilderSections): boolean {
      return this.sections.enabled?.[section] ?? false;
    },
  },
});
</script>
