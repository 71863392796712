import { Module } from "@/config/module.config";
import { Routes } from "./page";

Module.register({
  name: "introducer.fee.kintin",
  routes: Routes,
  actions: {},
  session: true,
  permissions: [],
});

export default {};
