<template>
  <DataListWidget title="Details" :details="ownershipDetails" />
  <DataListWidget title="Status" :details="statusDetails" />
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import {
  IntroducerOutsourceDetailsRoute,
  type IntroducerOutsourceDetailsParams,
} from ".";
import { IntroducerOutsourceDetailsMixin } from "../../mixin/introducer-outsource-details.mixin";

export default defineComponent({
  name: `${IntroducerOutsourceDetailsRoute}.sidebar`,
  mixins: [IntroducerOutsourceDetailsMixin],
  components: {
    DataListWidget,
  },
  data: () => ({
    key: 0,
    loading: true,
  }),
  computed: {
    $params(): IntroducerOutsourceDetailsParams {
      return this.$route.params as IntroducerOutsourceDetailsParams;
    },
    ownershipDetails(): Array<DataListWidgetDetail> {
      return [
        {
          label: "Created",
          value: {
            text: this.introducerOutsource?.createdAt?.formatDate,
          },
        },
        {
          label: "Assigned To",
          value: {
            text: this.introducerOutsource?.assignedTo
              ? `${this.introducerOutsource?.assignedTo?.profile.fullName}`
              : "Unassigned",
            color: this.introducerOutsource?.assignedTo?.profile.fullName
              ? "has-text-grey"
              : "has-text-danger",
          },
        },
      ];
    },
    statusDetails(): Array<DataListWidgetDetail> {
      return [
        {
          label: "Status",
          value: {
            text: this.introducerOutsource?.status?.value,
          },
        },
        {
          label: "Next Action",
          hide: ["completed"].includes(
            this.introducerOutsource?.status?.value ?? "",
          ),
          value: {
            text: this.introducerOutsource?.nextActionAt?.formatDate,
          },
        },
      ];
    },
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {},
});
</script>
