import { OptionService } from "@/module/core/service/option.service";
import { Check, Option, Role, Tag, User } from "@kinherit/sdk";
import { nextTick } from "vue";
import { ActiveSession } from "../../model/active-session.model";

interface CompleteSessionMessage {
  activeSession: ActiveSession;
}

interface CompleteSessionResponse {
  [key: string]: never;
}

export const CompleteSessionHandler = async (
  message: CompleteSessionMessage,
): Promise<CompleteSessionResponse> => {
  Option.$purge();
  Role.$purge();
  Tag.$purge();
  Check.$purge();
  User.$purge();

  await Promise.all([
    OptionService.loadOptions(),
    // window.Kernel.ActionBus.execute("core/select/role/read", {}),
    await window.Kernel.ActionBus2.core.select.role.read({}),
    // window.Kernel.ActionBus.execute("core/select/tag/read", {}),
    await window.Kernel.ActionBus2.core.select.tag.read({}),
    // window.Kernel.ActionBus.execute("core/select/check/read", {}),
    await window.Kernel.ActionBus2.core.select.check.read({}),
    // window.Kernel.ActionBus.execute("core/session/read", {}),
    await window.Kernel.ActionBus2.core.session.read({}),
  ]);

  nextTick(() => {
    window.Kernel.ComponentOptions.Navbar.callRefresh();
  });

  return {};
};
