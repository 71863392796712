<template>
  <PageHeader htag="h1" text="Referrals">
    <template #buttons>
      <Button
        v-if="hasWriteAccess"
        class="create"
        size="is-normal"
        @click="create"
      >
        Create Referral
      </Button>
    </template>
  </PageHeader>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      code: 'Code',
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #sacrificeFee="{ row }">
      <Icon v-if="row.isFeeSacrifice" icon="Tick" class="has-text-success" />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>

    <template #salarySacrifice="{ row }">
      <Icon v-if="row.isSalarySacrifice" icon="Tick" class="has-text-success" />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
    <template #ff30="{ row }">
      <Icon
        v-if="row.isFriendsAndFamily"
        icon="Tick"
        class="has-text-success"
      />
      <Icon v-else icon="Cross" class="has-text-danger" />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadAccountReferralCodeForm } from "@/module/admin.account-referral-code/form/read-account-referral-code.form";
import { AuthService } from "@/service/auth.service";
import Icon from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { AccountReferralCode, IAccountReferralCode } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { ReferralCodeMasterListRoute } from ".";
import { UpdateAccountReferralCodeForm } from "../form/update-account-referral-code.form";
import {
  AccountReferralCodeDetailsParams,
  AccountReferralCodeDetailsRoute,
} from "./details";

export default defineComponent({
  name: ReferralCodeMasterListRoute,
  components: { MasterListPage, PageHeader, Button, Icon },
  mixins: [AuthService.mixin()],
  data: () => ({
    filters: ReadAccountReferralCodeForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: AccountReferralCode) => v.name,
      },
      {
        title: "Code",
        sort: true,
        map: (v: AccountReferralCode) => v.code,
      },
      {
        title: "Category",
        sort: true,
        map: (v: AccountReferralCode) => v.category?.text,
      },
      {
        title: "Usage",
        sort: true,
        map: (v: AccountReferralCode) => v.referralCount,
      },
      {
        title: "Sacrifice Fee",
        slot: "sacrificeFee",
      },
      {
        title: "Salary Sacrifice",
        slot: "salarySacrifice",
      },
      {
        title: "F&F 30%",
        slot: "ff30",
      },
      {
        title: "Notes",
        sort: true,
        map: (v: AccountReferralCode) => v.notes,
        style: {
          whiteSpace: "normal",
        },
      },
    ] as Array<TableColumn>,
    rows: Array<AccountReferralCode>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferralCode,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("account-referral-code:write");
    },
  },
  methods: {
    async create(): Promise<void> {
      const accountReferralCode = await UpdateAccountReferralCodeForm().dialog({
        dialog: {
          title: "Create Referral Code",
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/account-referral-code/create",
      //   {
      //     accountReferralCode,
      //   },
      // );
      await window.Kernel.ActionBus2.adminAccountReferralCode.create({
        accountReferralCode,
      });

      this.refresh(this.filters.localData);
    },
    handleClick(referral: AccountReferralCode, event: MouseEvent): void {
      const params: AccountReferralCodeDetailsParams = {
        accountReferralCode: referral.id,
      };

      window.Kernel.visitRoute(
        {
          name: AccountReferralCodeDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadAccountReferralCodeForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "admin/account-referral-code/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data = await window.Kernel.ActionBus2.adminAccountReferralCode.read(
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.accountReferralCodes;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
