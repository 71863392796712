<template>
  <PageHeader htag="h1" text="Introducer Billing Contacts" />
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
      'profile.fullName': 'Name',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
  </MasterListPage>
</template>

<script lang="ts">
import { ReadIntroducerBillingContactForm } from "@/module/introducer.billing-contact/form/read-introducer-billing-contact.form";
import {
  IntroducerCompanyDetailsBillingParams,
  IntroducerCompanyDetailsBillingRoute,
} from "@/module/introducer.company/page/details/accounting";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  IIntroducerBillingContact,
  IntroducerBillingContact,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerBillingContactMasterListRoute } from ".";

export default defineComponent({
  name: IntroducerBillingContactMasterListRoute,
  components: { MasterListPage, PageHeader },
  data: () => ({
    filters: ReadIntroducerBillingContactForm(),
    columns: [
      {
        title: "Name",
        map: (v: IntroducerBillingContact) =>
          v.profile.fullName ??
          v.profile.organisationName ??
          "-> Unknown profile?",
      },
      {
        title: "Company",
        map: (v: IntroducerBillingContact) =>
          v.company?.profile?.organisationName ?? "-> Unknown company?",
      },
      {
        title: "Global",
        map: (v: IntroducerBillingContact) => (v.isGlobal ? "Yes" : "No"),
      },
      {
        title: "Introducer",
        map: (v: IntroducerBillingContact) =>
          v.introducerRemittanceType?.text ?? "-",
      },
      {
        title: "Agent",
        map: (v: IntroducerBillingContact) =>
          v.agentRemittanceType?.text ?? "-",
      },
      {
        title: "Email",
        map: (v: IntroducerBillingContact) =>
          v.profile?.emails.map((email) => email.email).join(", "),
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerBillingContact>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerBillingContact,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    hasValue(value: string | null | undefined | number) {
      return value !== null && value !== "";
    },

    handleClick(
      introducerBillingContact: IntroducerBillingContact,
      event: MouseEvent,
    ): void {
      const params: IntroducerCompanyDetailsBillingParams = {
        introducerCompany: introducerBillingContact.company?.id,
      };

      window.Kernel.visitRoute(
        {
          name: IntroducerCompanyDetailsBillingRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<
        typeof ReadIntroducerBillingContactForm
      >["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "introducer/billing-contact/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data = await window.Kernel.ActionBus2.introducerBillingContact.read(
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.introducerBillingContacts;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
