import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import { Between } from "@kinherit/orm";
import { EmailCampaignLog, IEmailCampaign } from "@kinherit/sdk";

export interface ReadEmailCampaignLogMessage {
  scheduledToSendAt?: null | [number, number];
  created?: null | [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IEmailCampaign;
    direction: "asc" | "desc";
  };
}

export interface ReadEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadEmailCampaignLogHandler = async (
  message: ReadEmailCampaignLogMessage,
): Promise<ReadEmailCampaignLogResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/email-campaign-log",
    message,
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(EmailCampaignLog).where({
    scheduledToSendAt: Between(message.scheduledToSendAt),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    emailCampaignLog: EmailCampaignLog.$inflate(
      response.emailCampaignLog,
      undefined,
      response.$rootIds,
    ),
    pagination: getPagination(response),
  };
};
