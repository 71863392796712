<template>
  <MasterListPage
    v-if="brandedKinvault"
    :filters="$data.form"
    :columns="columns"
    :rows="rows"
    is-nested
    title="Default Referral Codes"
    subtitle="Manage which referral codes use this branded kinvault as default kinvault"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #tableControls>
      <Button
        v-if="$auth.hasPermission('branded-kinvault-subscription:write')"
        @click="addReferralCode"
        class="add-item"
        icon-left="Plus"
        size="is-small"
        color="is-plain"
        aria-label="Add referral code"
        tooltip="Add referral code"
      />
    </template>
    <template #actions="{ row }">
      <Button
        v-if="$auth.hasPermission('branded-kinvault-subscription:write')"
        @click="editReferralCode(row as DefaultBrandedKinvaultReferralCode)"
        icon-left="Edit"
        size="is-small"
        color="is-plain"
        class="edit-item"
        aria-label="Edit referral code"
        tooltip="Edit referral code"
      />
      <Button
        v-if="$auth.hasPermission('branded-kinvault-subscription:write')"
        @click="deleteReferralCode(row as DefaultBrandedKinvaultReferralCode)"
        icon-left="Trash"
        size="is-small"
        color="is-plain"
        class="delete-item"
        aria-label="Delete referral code"
        tooltip="Delete referral code"
      />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
//import { CreateDefaultBrandedKinvaultReferralCode } from "@/config/model.config";
//import { UpdateDefaultBrandedKinvaultReferralCodeForm } from "@/module/admin.branded-kinvault/form/update-branded-kinvault-subscription-referral-code.form";
import { DangerDialog } from "@/config/dialog.config";
import { CreateDefaultBrandedKinvaultReferralCode } from "@/config/model.config";
import { ReadDefaultBrandedKinvaultReferralCodeForm } from "@/module/admin.branded-kinvault/form/read-default-branded-kinvault-referral-code.form";
import { UpdateDefaultBrandedKinvaultReferralCodeForm } from "@/module/admin.branded-kinvault/form/update-default-branded-kinvault-referral-code.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  DefaultBrandedKinvaultReferralCode,
  IDefaultBrandedKinvaultReferralCode,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsDefaultReferralRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsDefaultReferralRoute,
  mixins: [BrandedKinvaultDetailsMixin, AuthService.mixin()],
  components: { MasterListPage, Button },
  data: () => ({
    form: ReadDefaultBrandedKinvaultReferralCodeForm(),
    columns: [
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Code",
        field: "code",
      },
      {
        title: "Created at",
        map: (row) => row.createdAt.formatYMD,
      },
      {
        slot: "actions",
      },
    ] satisfies TableColumn<DefaultBrandedKinvaultReferralCode>[],
    rows: Array<DefaultBrandedKinvaultReferralCode>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IDefaultBrandedKinvaultReferralCode,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh({});
  },
  methods: {
    async refresh(
      formData?: any,
      by?: string,
      direction?: "desc" | "asc",
    ): Promise<void> {
      this.sort = {
        by: (by as keyof IDefaultBrandedKinvaultReferralCode) ?? this.sort.by,
        direction: direction ?? this.sort.direction,
      };

      // const { defaultBrandedKinvaultReferralCodes, pagination } =
      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/default-referral-code/read",
      //   {
      //     brandedKinvault: this.$params.brandedKinvault,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );
      const { defaultBrandedKinvaultReferralCodes, pagination } =
        await window.Kernel.ActionBus2.adminBrandedKinvault.defaultReferralCode.read(
          {
            brandedKinvault: this.$params.brandedKinvault,
            sort: this.sort,
            pagination: this.pagination,
          },
        );
      this.rows = defaultBrandedKinvaultReferralCodes;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async editReferralCode(referralCode: DefaultBrandedKinvaultReferralCode) {
      try {
        await UpdateDefaultBrandedKinvaultReferralCodeForm(referralCode).dialog(
          {
            dialog: {
              title: `Update Default Referral Code`,
            },
          },
        );
      } catch {
        referralCode.$restore();
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/default-referral-code/update",
      //   {
      //     defaultBrandedKinvaultReferralCode: referralCode,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.defaultReferralCode.update(
        {
          defaultBrandedKinvaultReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
    async addReferralCode() {
      const referralCode = CreateDefaultBrandedKinvaultReferralCode({
        brandedKinvault: this.$params.brandedKinvault,
      });

      try {
        await UpdateDefaultBrandedKinvaultReferralCodeForm(referralCode).dialog(
          {
            dialog: {
              title: `Create Default Referral Code`,
            },
          },
        );
      } catch {
        referralCode.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/default-referral-code/create",
      //   {
      //     defaultBrandedKinvaultReferralCode: referralCode,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.defaultReferralCode.create(
        {
          defaultBrandedKinvaultReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
    async deleteReferralCode(
      referralCode: DefaultBrandedKinvaultReferralCode,
    ): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `Are you sure you want to delete ${referralCode.name} (${referralCode.code})?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/default-referral-code/delete",
      //   {
      //     defaultBrandedKinvaultReferralCode: referralCode,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.defaultReferralCode.delete(
        {
          defaultBrandedKinvaultReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
  },
});
</script>
