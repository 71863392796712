<template>
  <DataListWidget v-if="kintin" :details="details" class="kintin-details">
    <template #above>
      <div class="kintin-details__ref">
        {{ kintin.ref }}
        <Badge
          size="is-small"
          is-compact
          :color="kintin.type?.value === 'joint' ? 'is-joint' : 'is-single'"
        >
          {{ kintin.type?.value.ucFirst() }}
        </Badge>
      </div>
      <div class="kintin-details__users mt-4">
        <div class="kintin-details__user">
          <Avatar
            hide-tooltip
            :name="kintin.primaryPerson?.profile.fullName ?? undefined"
          >
            {{ kintin.primaryPerson?.profile.fullName }}
            <small v-if="kintin.primaryPerson?.profile.knownAs"
              >({{ kintin.primaryPerson?.profile.knownAs }})</small
            >
          </Avatar>
        </div>
        <div v-if="kintin.type?.value === 'joint'" class="kintin-details__user">
          <Avatar
            hide-tooltip
            :name="kintin.secondaryPerson?.profile.fullName ?? undefined"
          >
            <span v-if="kintin.secondaryPerson?.profile.fullName">
              {{ kintin.secondaryPerson?.profile.fullName }}
              <small v-if="kintin.secondaryPerson?.profile.knownAs"
                >({{ kintin.secondaryPerson?.profile.knownAs }})</small
              >
            </span>
            <span v-else class="has-text-warning">Awaiting Details</span>
          </Avatar>
        </div>
      </div>
    </template>
    <template #below>
      <hr />
      <div class="has-text-right">Joined {{ kintin.createdAt.formatDate }}</div>
    </template>
  </DataListWidget>
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Badge } from "@kinherit/framework/component.display/badge";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KinvaultKintinDetailsSidebar",
  components: {
    Avatar,
    DataListWidget,
    Badge,
  },

  mixins: [KinvaultKintinDetailsMixin],
  computed: {
    details(): Array<DataListWidgetDetail> {
      const kintin = this.kintin;

      if (!kintin) {
        return [];
      }

      const details: Array<DataListWidgetDetail> = [];

      if (
        kintin.$data.process ||
        kintin.$data.estatePlanners.isNotEmpty() ||
        (kintin.referral && kintin.referral?.referralCode?.code)
      ) {
        details.push({
          break: true,
        });
      }

      if (kintin.estatePlanners.isNotEmpty()) {
        details.push({
          label: "EP",
          value: kintin.estatePlanners
            .pluck("profile")
            .pluck("fullName")
            .join(", "),
        });
      }

      if (kintin.legalAssistants.isNotEmpty()) {
        details.push({
          label: "LA: ",
          value: kintin.legalAssistants
            .pluck("profile")
            .pluck("fullName")
            .join(", "),
        });
      }

      if (kintin.referral && kintin.referral?.referralCode?.code) {
        details.push({
          label: "Referral",
          value: kintin.referral.referralCode.code,
        });
      }

      if (kintin.referral?.$data.lead) {
        details.push({
          label: "Source Lead",
          value: {
            text: "Go to Lead",
            to: {
              name: LeadDetailsRoute,
              params: {
                lead: kintin.referral?.$data.lead,
              } as LeadDetailsParams,
            },
          },
        });
      }

      if (kintin.$data.process) {
        details.push({
          label: "Process",
          value: kintin.process?.text,
        });
      }

      return details;
    },
  },
});
</script>

<style lang="scss" scoped>
.kintin-details {
  &__ref {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
  }
  &__users {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  &__user {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
</style>
