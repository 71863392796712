import { Api } from "@/service/api.service";
import { IntroducerOutsource } from "@kinherit/sdk";

export interface UpdateIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource;
}

interface UpdateIntroducerOutsourceResponse {
  introducerOutsources: Array<IntroducerOutsource>;
}

export const UpdateIntroducerOutsourceHandler = async (
  message: UpdateIntroducerOutsourceMessage,
): Promise<UpdateIntroducerOutsourceResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-outsource/{introducerOutsource}",
    {
      introducerOutsource: message.introducerOutsource,
    },
  )
    .method("patch")
    .body({
      status: message.introducerOutsource.$data.status,
      type: message.introducerOutsource.$data.type,
      company: message.introducerOutsource.$data.company,
      expectedValue: message.introducerOutsource.$data.expectedValue,
      paidValue: message.introducerOutsource.$data.paidValue,
      paidAt: message.introducerOutsource.$data.paidAt,
      nextActionAt: message.introducerOutsource.$data.nextActionAt,
      assignedTo: message.introducerOutsource.$data.assignedTo,
      datedAt: message.introducerOutsource.$data.datedAt,
    })

    .result();

  return {
    introducerOutsources: IntroducerOutsource.$inflate(
      response.introducerOutsource,
      undefined,
      response.$rootIds,
    ),
  };
};
