<template>
  <PageHeader text="Answerphone Message Script" htag="h2" />

  <Message
    title="Script"
    size="is-medium"
    color="is-primary"
    shade="is-light"
    drop-shadow
    body-classes="py-6"
  >
    <div class="columns is-mobile">
      <div class="column is-narrow p-0">
        <Icon
          :icon="$style.icon.speechBubble.icon"
          class="ml-1 mr-3"
          size="is-large"
        />
      </div>
      <div class="column p-0">
        <div
          :style="{
            color: 'var(--color-text)',
          }"
          class="has-text-weight-bold"
        >
          Hello {{ officer?.profile.firstName }}, <br /><br />My name is
          {{ specialistName }} and I am calling from Kinherit. <br /><br />We
          recently helped
          {{ primaryFullName }}
          <span v-if="officer?.kintin?.type.value === 'joint'"
            >/{{ secondaryFullName }}</span
          >
          put their Will and End of Life Planning in place and they chose you to
          be part of their planning on their death. <br /><br />I sent you an
          email explaining what has taken place and who we are on [a few days
          ago] and am just following that email up with this call.
          <br /><br />If you have any questions please give me a call on 0117
          302 1888 or equally if you did not get the email please let me know
          and I will resend it. <br /><br />Once again my name is
          {{ specialistName }} and I was calling with regards to the will
          planning we have put in place for {{ primaryFullName }}
          <span v-if="isJoint">/{{ secondaryFullName }}</span
          >. <br /><br />Our number one more time is 0117 302 1888.
          <br /><br />Thanks for listening to this message
        </div>
      </div>
    </div>
  </Message>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Icon } from "@kinherit/framework/component.display/icon";
import { Message } from "@kinherit/framework/component.display/message";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KinvaultOfficerDetailsAnswerphoneMessageScriptRoute } from ".";
import { KinvaultOfficerDetailsMixin } from "../../../mixin/kinvault-officer-details.mixin";

export default defineComponent({
  name: KinvaultOfficerDetailsAnswerphoneMessageScriptRoute,
  mixins: [
    KinvaultOfficerDetailsMixin,
    StyleService.mixin,
    AuthService.mixin(),
  ],
  components: { Icon, Message, PageHeader },
  computed: {
    specialistName(): string {
      return this.$auth.loggedInUser?.profile.firstName as string;
    },
    primaryFullName(): string {
      return this.officer?.kintin?.primaryPerson?.profile?.fullName as string;
    },
    secondaryFullName(): string {
      return this.officer?.kintin?.secondaryPerson?.profile?.fullName as string;
    },
    isJoint(): boolean {
      return this.officer?.kintin?.type.value === "joint";
    },
  },
});
</script>
