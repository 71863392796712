import { Api } from "@/service/api.service";
import {
  BrandedKinvault,
  Kintin,
  KintinAccess,
  Profile,
  User,
} from "@kinherit/sdk";

interface CreateKintinMessage {
  kintin: Kintin;
  users: {
    primary: User;
    secondary: User | null;
  };
  access: {
    primary: KintinAccess;
    secondary: KintinAccess | null;
  };
  brandedKinvault: BrandedKinvault | null;
}

interface CreateKintinResponse {
  kintin: Kintin;
  profiles: Array<Profile>;
}

export const CreateKintinHandler = async (
  message: CreateKintinMessage,
): Promise<CreateKintinResponse> => {
  const primaryProfile = message.kintin.primaryPerson?.profile;
  const secondaryProfile = message.kintin.secondaryPerson?.profile;

  const response = await Api.resource("portal", "/v2/portal/kintin", {
    kintin: message.kintin,
  })
    .method("post")

    .body({
      process: message.kintin.$data.process,
      type: message.kintin.$data.type,
      status: message.kintin.$data.status,
      stage: message.kintin.$data.stage,
      callScript: {},
      referral: {},
      permissions: {},
      primaryPerson: {
        profile: {
          firstName: primaryProfile.$data.firstName,
          lastName: primaryProfile.$data.lastName,
          phoneNumbers: primaryProfile.phoneNumbers.map((phoneNumber) => ({
            tel: phoneNumber.tel,
          })),
          emails: primaryProfile.emails.map((email) => ({
            email: email.email,
          })),
        },
        type: "person",
        notifyOnDeath: false,
      },
      secondaryPerson: secondaryProfile
        ? {
            profile: {
              firstName: secondaryProfile.$data.firstName,
              lastName: secondaryProfile.$data.lastName,
              phoneNumbers: secondaryProfile.phoneNumbers.map(
                (phoneNumber) => ({
                  tel: phoneNumber.tel,
                }),
              ),
              emails: secondaryProfile.emails.map((email) => ({
                email: email.email,
              })),
            },
            type: "person",
            notifyOnDeath: false,
          }
        : undefined,
    })
    .result();

  const kintin = Kintin.$inflate(response.kintin).first();

  if (!kintin) {
    throw new Error("Failed to create kintin");
  }

  await ["primary" as const, "secondary" as const].forEachAsync(async (key) => {
    const user = message.users[key];
    const access = message.access[key];

    if (!user || !access) {
      return;
    }

    // const { user: newUser } = await window.Kernel.ActionBus.execute(
    //   "kinvault/kintin/users/create",
    //   {
    //     kintin,
    //     user,
    //     accessType: "readonly",
    //   },
    // );
    const { user: newUser } =
      await window.Kernel.ActionBus2.kinvaultKintin.user.create({
        kintin,
        user,
        accessType: "readonly",
      });

    access.user = newUser;
    access.kintin = kintin;

    // await window.Kernel.ActionBus.execute(
    //   "kinvault/kintin/kintin-user-access/create",
    //   {
    //     kintin,
    //     kintinAccess: access,
    //   },
    // );
    await window.Kernel.ActionBus2.kinvaultKintin.kintinUserAccess.create({
      kintin,
      kintinAccess: access,
    });

    if (message.brandedKinvault) {
      // await window.Kernel.ActionBus.execute(
      //   "admin/user/branded-kinvault-membership/create",
      //   {
      //     brandedKinvault: message.brandedKinvault,
      //     user: newUser,
      //   },
      // );
      await window.Kernel.ActionBus2.adminUser.brandedKinvaultMembership.create(
        {
          brandedKinvault: message.brandedKinvault,
          user: newUser,
        },
      );
    }
  });

  message.kintin.$delete();
  message.access.primary.$delete();
  message.access.secondary?.$delete();
  message.users.primary.$delete();
  message.users.secondary?.$delete();

  return {
    kintin,
    profiles: Profile.$inflate(response.profile),
  };
};
