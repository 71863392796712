<template>
  <MasterListPage
    v-if="brandedKinvault"
    :filters="$data.form"
    :columns="columns"
    :rows="rows"
    is-nested
    title="Referral Codes"
    subtitle="Manage referral codes which can be used in the subscription process"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #tableControls>
      <Button
        v-if="$auth.hasPermission('branded-kinvault-subscription:write')"
        @click="addReferralCode"
        class="add-item"
        icon-left="Plus"
        size="is-small"
        color="is-plain"
        aria-label="Add referral code"
        tooltip="Add referral code"
      />
    </template>
    <template #actions="{ row }">
      <Button
        v-if="$auth.hasPermission('branded-kinvault-subscription:write')"
        @click="
          editReferralCode(row as BrandedKinvaultSubscriptionReferralCode)
        "
        icon-left="Edit"
        size="is-small"
        color="is-plain"
        class="edit-item"
        aria-label="Edit referral code"
        tooltip="Edit referral code"
      />
      <Button
        v-if="$auth.hasPermission('branded-kinvault-subscription:write')"
        @click="
          deleteReferralCode(row as BrandedKinvaultSubscriptionReferralCode)
        "
        icon-left="Trash"
        size="is-small"
        color="is-plain"
        class="delete-item"
        aria-label="Delete referral code"
        tooltip="Delete referral code"
      />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateBrandedKinvaultSubscriptionReferralCode } from "@/config/model.config";
import { ReadSubscriptionReferralCodeForm } from "@/module/admin.branded-kinvault/form/read-subscription-referral-code.form";
import { UpdateBrandedKinvaultSubscriptionReferralCodeForm } from "@/module/admin.branded-kinvault/form/update-branded-kinvault-subscription-referral-code.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  BrandedKinvaultSubscriptionReferralCode,
  IBrandedKinvaultSubscriptionReferralCode,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsSubscriptionsReferralCodesRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsSubscriptionsReferralCodesRoute,
  mixins: [BrandedKinvaultDetailsMixin, AuthService.mixin()],
  components: { MasterListPage, Button },
  data: () => ({
    form: ReadSubscriptionReferralCodeForm(),
    columns: [
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Code",
        field: "code",
      },
      {
        title: "Created at",
        map: (row) => row.createdAt.formatYMD,
      },
      {
        slot: "actions",
      },
    ] satisfies TableColumn<BrandedKinvaultSubscriptionReferralCode>[],
    rows: Array<BrandedKinvaultSubscriptionReferralCode>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvaultSubscriptionReferralCode,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh({});
  },
  methods: {
    async refresh(
      formData?: any,
      by?: string,
      direction?: "desc" | "asc",
    ): Promise<void> {
      this.sort = {
        by:
          (by as keyof IBrandedKinvaultSubscriptionReferralCode) ??
          this.sort.by,
        direction: direction ?? this.sort.direction,
      };

      // const { brandedKinvaultSubscriptionReferralCodes, pagination } =
      //   await window.Kernel.ActionBus.execute(
      //     "admin/branded-kinvault/subscription-referral-code/read",
      //     {
      //       ...this.form.localData,
      //       brandedKinvault: this.$params.brandedKinvault,
      //       sort: this.sort,
      //       pagination: this.pagination,
      //     },
      //   );

      const { brandedKinvaultSubscriptionReferralCodes, pagination } =
        await window.Kernel.ActionBus2.adminBrandedKinvault.subscriptionReferralCode.read(
          {
            ...this.form.localData,
            brandedKinvault: this.$params.brandedKinvault,
            sort: this.sort,
            pagination: this.pagination,
          },
        );
      this.rows = brandedKinvaultSubscriptionReferralCodes;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async editReferralCode(
      referralCode: BrandedKinvaultSubscriptionReferralCode,
    ) {
      try {
        await UpdateBrandedKinvaultSubscriptionReferralCodeForm(
          referralCode,
        ).dialog({
          dialog: {
            title: `Update Invitation Referral Code`,
          },
        });
      } catch {
        referralCode.$restore();
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/subscription-referral-code/update",
      //   {
      //     brandedKinvaultSubscriptionReferralCode: referralCode,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.subscriptionReferralCode.update(
        {
          brandedKinvaultSubscriptionReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
    async addReferralCode() {
      const referralCode = CreateBrandedKinvaultSubscriptionReferralCode({
        brandedKinvault: this.$params.brandedKinvault,
      });

      try {
        await UpdateBrandedKinvaultSubscriptionReferralCodeForm(
          referralCode,
        ).dialog({
          dialog: {
            title: `Create Invitation Referral Code`,
          },
        });
      } catch {
        referralCode.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/subscription-referral-code/create",
      //   {
      //     brandedKinvaultSubscriptionReferralCode: referralCode,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.subscriptionReferralCode.create(
        {
          brandedKinvaultSubscriptionReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
    async deleteReferralCode(
      referralCode: BrandedKinvaultSubscriptionReferralCode,
    ): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `Are you sure you want to delete ${referralCode.name} (${referralCode.code})?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/subscription-referral-code/delete",
      //   {
      //     brandedKinvaultSubscriptionReferralCode: referralCode,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.subscriptionReferralCode.delete(
        {
          brandedKinvaultSubscriptionReferralCode: referralCode,
        },
      );

      await this.refresh();
    },
  },
});
</script>
