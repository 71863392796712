<template>
  <EmailLogPage
    v-if="brandedKinvault"
    :owner="brandedKinvault"
    no-legacy-emails
  />
</template>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsEmailLogRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsEmailLogRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: {
    EmailLogPage,
  },
});
</script>
