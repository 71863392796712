import { CreateUser } from "@/config/model.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { EmailAddress, PhoneNumber, User } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";

export const CreateKintinUserForm = (user: User = CreateUser()) =>
  defineForm({
    name: "create-kintin-user-form",
    data: () => ({
      user,
      accessType: "owner" as "owner" | "edit" | "readonly" | "adviser" | "none",
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-kintin-user-form-area",
        data,
        template: GridLayout([
          ["subtitle1"],
          ["firstName", "lastName"],
          ["email", "phone"],
          ["access"],
        ]),
        components: () => ({
          subtitle1: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "User Details",
              },
            }),
          ],
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
              },
              models: {
                value: "user.profile.firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
              },
              models: {
                value: "user.profile.lastName",
              },
            }),
          ],
          email: [
            FormEmailField({
              reactive: true,
              props: {
                label: "Email",
                validators: ["required"],
                message:
                  "Your Email - this will used for signing in, correspondence and notifications.",
              },
              models: {
                value: {
                  get: (data) => data.user.profile.emails.first()?.email ?? "",
                  set: (value, data, controls) => {
                    const email = data.user.profile.emails.first();

                    data.user.credential.username = value;

                    if (["", null, undefined].includes(value)) {
                      if (email) {
                        data.user.profile.removeEmails(email);
                        email.$delete();
                      }
                    } else if (email) {
                      email.email = value;
                      email.$persist();
                    } else {
                      data.user.profile.addEmails(
                        new EmailAddress({
                          id: Uuid.generate(),
                          email: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.user.profile.$persist();
                    }
                    controls.rebuildTemplateBindings();
                  },
                },
              },
            }),
          ],
          phone: [
            FormPhoneNumberField({
              reactive: true,
              props: {
                label: "Phone",
              },
              models: {
                value: {
                  get: (data) =>
                    data.user.profile.phoneNumbers.first()?.tel ?? "",
                  set: (value, data, controls) => {
                    const phone = data.user.profile.phoneNumbers.first();

                    if (["", null, undefined].includes(value)) {
                      if (phone) {
                        data.user.profile.removePhoneNumbers(phone);
                        phone.$delete();
                      }
                    } else if (phone) {
                      phone.tel = value;
                      // phone.$persist();
                    } else {
                      data.user.profile.addPhoneNumbers(
                        new PhoneNumber({
                          id: Uuid.generate(),
                          tel: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.user.profile.$persist();
                    }
                    controls.rebuildTemplateBindings();
                  },
                },
              },
            }),
          ],
          access: [
            FormSelectField({
              props: {
                options: {
                  owner: "Owner",
                  edit: "Edit",
                  readonly: "Read only",
                  adviser: "Adviser",
                  none: "None",
                },
                label: "Access",
              },
              models: {
                value: {
                  get: (data) => data.accessType,
                  set: (value, data, controls) => {
                    data.accessType = value;
                    controls.rebuildTemplateBindings();
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
