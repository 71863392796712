import { Api } from "@/service/api.service";
import { Address, CpdSession, Website } from "@kinherit/sdk";

export interface UpdateCpdSessionMessage {
  cpdSession: CpdSession;
}

export interface UpdateCpdSessionResponse {
  cpdSessions: Array<CpdSession>;
  addresses: Array<Address>;
  websites: Array<Website>;
}

export const UpdateCpdSessionHandler = async (
  message: UpdateCpdSessionMessage,
): Promise<UpdateCpdSessionResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/cpd-session/{cpdSession}",
    {
      cpdSession: message.cpdSession.$data.id,
    },
  )
    .method("patch")

    .body({
      address:
        null === message.cpdSession.address
          ? null
          : {
              line1: message.cpdSession.address.$data.line1,
              line2: message.cpdSession.address.$data.line2,
              city: message.cpdSession.address.$data.city,
              country: message.cpdSession.address.$data.country,
              postcode: message.cpdSession.address.$data.postcode,
              state: message.cpdSession.address.$data.state,
            },
      allowBookings: message.cpdSession.$data.allowBookings,
      date: message.cpdSession.$data.date,
      duration: message.cpdSession.$data.duration,
      outcomes: message.cpdSession.$data.outcomes,
      title: message.cpdSession.$data.title,
      type: message.cpdSession.$data.type,
      inviteDescription: message.cpdSession.$data.inviteDescription,
      isPrivate: message.cpdSession.$data.isPrivate,
    });

  const response = await request.result();

  const websites = message.cpdSession.websites;

  const localWebsiteIds = websites.pluck("id");
  const remoteWebsiteIds = response.website?.pluck("id") ?? [];
  const newWebsiteIds = localWebsiteIds.remove(...remoteWebsiteIds);
  const deletedWebsiteIds = remoteWebsiteIds.remove(...localWebsiteIds);

  for (const websiteId of newWebsiteIds) {
    const website = websites.find((website) => website.$data.id === websiteId);

    if (undefined === website) {
      throw new Error("Website not found");
    }

    await Api.resource(
      "portal",
      "/v2/portal/cpd-session/{cpdSession}/websites",
      {
        cpdSession: message.cpdSession.$data.id,
      },
    )
      .method("post")

      .body({
        url: website.$data.url,
      })
      .result();

    website.$persist();
  }

  for (const websiteId of deletedWebsiteIds) {
    await Api.resource(
      "portal",
      "/v2/portal/cpd-session/{cpdSession}/websites/{website}",
      {
        cpdSession: message.cpdSession.$data.id,
        website: websiteId,
      },
    )
      .method("delete")
      .result();
  }

  // return await window.Kernel.ActionBus.execute(
  //   "cpd/session/record",
  //   message,
  // );
  return await window.Kernel.ActionBus2.cpd.session.record(message);
};
