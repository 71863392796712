<template>
  <div class="kintin-details-address-book-guardians">
    <PageHeader htag="h2" text="Guardians" v-if="kintin">
      <template #buttons>
        <Button
          text="Add Guardian"
          @click="createGuardian"
          class="add-button"
        />
        <Button
          :text="sorting ? 'Done' : 'Sort'"
          @click="togglingSorting"
          class="sort-button"
          v-if="!isPeopleTab"
        />
      </template>
    </PageHeader>
    <div v-if="kintin && dependents.length > 0">
      <Tabs
        v-if="!sorting"
        :config="tabConfig"
        v-model:tab="computedTab"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4"
      />
      <div v-if="!isPeopleTab">
        <Title size="is-6" is-emphasised>Main Guardians</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.guardians.main"
        />
        <GuardianList
          v-else
          :kintin="kintin"
          :guardians="$data.filtered.guardians.main"
          @reload="filter"
        />
        <Title size="is-6" is-emphasised>Reserve Guardians</Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.guardians.reserve"
        />
        <GuardianList
          v-else
          :kintin="kintin"
          :guardians="$data.filtered.guardians.reserve"
          @reload="filter"
        />
      </div>
      <div v-else>
        <PersonList
          :key="renderKey"
          v-if="isPeopleTab"
          :kintin="kintin"
          :people="$data.filtered.people"
          @reload="filter"
        />
      </div>
      <Json v-if="isDevMode" :data="filtered" />
    </div>
    <div v-else>
      <Message title="Unable to display" title-size="is-5" color="is-warning">
        No dependents / children under 18 found: ensure at least one person has
        either <em>Is under 18 for planning purposes</em> or
        <em>Requires specialist care</em> ticked.
      </Message>
    </div>
  </div>
</template>

<script lang="ts">
import { CreateGuardian } from "@/config/model.config";
import GuardianList from "@/module/kinvault.kintin/component/lists/GuardianList.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdateGuardianForm } from "@/module/kinvault.kintin/form/update-guardian.form";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import Message from "@kinherit/framework/component.display/message";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Title } from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Guardian, Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import SortRoles from "./SortRoles.vue";

export default defineComponent({
  name: `GuardiansContainer`,
  components: {
    Tabs,
    Json,
    GuardianList,
    Button,
    PageHeader,
    PersonList,
    Message,
    Title,
    SortRoles,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      guardians: {
        main: Array<Guardian>(),
        reserve: Array<Guardian>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    sorting: false,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    renderKey: 0,
  }),
  computed: {
    dependents(): Array<Person> {
      return (
        this.kintin?.people.filter((person) =>
          [person.under18ForPlanningPurposes, person.requiresCare].includes(
            true,
          ),
        ) ?? []
      );
    },
    tabConfig(): Array<{ label: string; person?: Person; icon: any }> {
      if (!this.kintin) {
        return [];
      }

      return [
        ...this.kintin.people
          .filter((person) =>
            [person.under18ForPlanningPurposes, person.requiresCare].includes(
              true,
            ),
          )
          .map((person) => ({
            label: person.profile.fullName as string,
            person,
            icon: StyleService.icon.person.icon,
          })),
        {
          label: "People",
          icon: StyleService.icon.person.icon,
        },
      ];
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });
      const guardians = Guardian.$findBy({
        forPerson: {
          id:
            selectedPerson?.id ??
            In(
              [
                this.kintin.primaryPerson.id,
                this.kintin.secondaryPerson?.id,
              ].filter(Boolean),
            ),
        },
      });

      this.filtered.guardians = {
        main: guardians.filter((g) => !g.isReserve),
        reserve: guardians.filter((g) => g.isReserve),
      };

      this.filtered.guardians.main =
        this.filtered.guardians.main.sortBy("sortOrder");
      this.filtered.guardians.reserve =
        this.filtered.guardians.reserve.sortBy("sortOrder");

      this.filtered.people = Person.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
      }).filter((person) => person.guardianFor.isNotEmpty());

      this.renderKey++;

      this.$emit("reload");
    },
    async createGuardian() {
      if (!this.kintin) {
        return;
      }

      const guardian = CreateGuardian();

      try {
        await (
          await UpdateGuardianForm({
            data: guardian,
            kintin: this.kintin,
          })
        ).dialog({
          dialog: {
            title: "Create guardian",
          },
        });
      } catch {
        guardian.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/guardian/create", {
      //   guardian,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.guardian.create({
        guardian,
        kintin: this.kintin,
      });

      this.filter();
    },
    async togglingSorting() {
      if (this.sorting) {
        await [
          ...this.$data.filtered.guardians.main,
          ...this.$data.filtered.guardians.reserve,
        ].forEachAsync((guardian) =>
          // window.Kernel.ActionBus.execute("kinvault/kintin/guardian/update", {
          //   guardian,
          //   kintin: this.kintin,
          // }),
          window.Kernel.ActionBus2.kinvaultKintin.guardian.update({
            guardian,
            kintin: this.kintin,
          }),
        );
      }

      this.sorting = !this.sorting;
    },
  },
});
</script>
