import {
  AccountReferralCodeField,
  OptionsAutoCompleteField,
} from "@/config/form.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";

export const ReadLeadProfileComponent = () =>
  FormElement({
    props: {
      is: "div",
      style: {
        display: "inline-block",
        borderRight: `1px dashed hsl(0,0%,71%)`,
      },
      class: "mr-4 pr-4",
    },
    slots: {
      default: {
        template: GridLayout([["title"], ["leadStatus", "leadReferralCode"]]),
        components: {
          title: [
            FormTitle({
              props: {
                text: "Leads",
                size: "is-4",
              },
            }),
          ],
          leadStatus: [
            OptionsAutoCompleteField({
              group: "leadStatus",
              vModel: "leadStatus",
              props: {
                label: "Status",
                isMultiSelect: true,
              },
              simplifyData: true,
            }),
          ],
          leadReferralCode: [
            AccountReferralCodeField({
              props: {
                vModel: "leadReferralCode",
                isMultiSelect: true,
              },
              simplifyData: true,
            }),
          ],
        },
      },
    },
  });
