import { Api } from "@/service/api.service";
import {
  BrandedKinvault,
  IntroducerBillingContact,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
  Profile,
  User,
} from "@kinherit/sdk";

export interface UpdateLeadProfileMessage {
  lead: Lead;
}

export interface UpdateLeadProfileResponse {
  lead: Lead;
}

export interface UpdateBrandedKinvaultProfileMessage {
  brandedKinvault: BrandedKinvault;
}

export interface UpdateBrandedKinvaultProfileResponse {
  brandedKinvault: BrandedKinvault;
}

export interface UpdateUserProfileMessage {
  user: User;
}

export interface UpdateUserProfileResponse {
  user: User;
}

export interface UpdateIntroducerCompanyProfileMessage {
  introducerCompany: IntroducerCompany;
}

export interface UpdateIntroducerCompanyProfileResponse {
  introducerCompany: IntroducerCompany;
}

export interface UpdateIntroducerContactProfileMessage {
  introducerContact: IntroducerContact;
}

export interface UpdateIntroducerContactProfileResponse {
  introducerContact: IntroducerContact;
}

export interface UpdateKintinPersonProfileMessage {
  person: Person;
  kintin: Kintin;
}

export interface UpdateKintinPersonProfileResponse {
  person: Person;
}

export interface UpdateOfficerProfileMessage {
  officer: Person;
}

export interface UpdateOfficerProfileResponse {
  officer: Person;
}

export interface UpdateIntroducerBillingContactProfileMessage {
  introducerCompany: IntroducerCompany;
  introducerBillingContact: IntroducerBillingContact;
}

export interface UpdateIntroducerBillingContactProfileResponse {
  introducerBillingContact: IntroducerBillingContact;
}

type Message =
  | UpdateLeadProfileMessage
  | UpdateBrandedKinvaultProfileMessage
  | UpdateUserProfileMessage
  | UpdateIntroducerCompanyProfileMessage
  | UpdateIntroducerContactProfileMessage
  | UpdateKintinPersonProfileMessage
  | UpdateOfficerProfileMessage
  | UpdateIntroducerBillingContactProfileMessage;
type Response =
  | UpdateLeadProfileResponse
  | UpdateBrandedKinvaultProfileResponse
  | UpdateUserProfileResponse
  | UpdateIntroducerCompanyProfileResponse
  | UpdateIntroducerContactProfileResponse
  | UpdateKintinPersonProfileResponse
  | UpdateOfficerProfileResponse
  | UpdateIntroducerBillingContactProfileResponse;

type JsName =
  | "lead"
  | "brandedKinvault"
  | "proPartner"
  | "user"
  | "introducerCompany"
  | "introducerContact"
  | "person"
  | "officer"
  | "trustReg"
  | "introducerBillingContact";
type UrlPath =
  | "lead"
  | "branded-kinvault"
  | "pro-partner"
  | "user"
  | "introducer-company"
  | "introducer-contact"
  | "kintin"
  | "officer"
  | "trust-reg"
  | "introducer-billing-contact";

export const UpdateProfileHandler = async (
  message: Message,
): Promise<Response> => {
  let jsName: JsName;
  let urlPath: UrlPath;
  let params: any;

  if ("lead" in message) {
    jsName = "lead";
    urlPath = "lead";
    params = {
      lead: message.lead.id,
      profile: message.lead.profile,
    };
  } else if ("brandedKinvault" in message) {
    jsName = "brandedKinvault";
    urlPath = "branded-kinvault";
    params = {
      brandedKinvault: message.brandedKinvault.id,
      profile: message.brandedKinvault.profile,
    };
  } else if ("user" in message) {
    jsName = "user";
    urlPath = "user";
    params = {
      user: message.user.id,
      profile: message.user.profile,
    };
  } else if (
    "introducerCompany" in message &&
    !("introducerBillingContact" in message)
  ) {
    jsName = "introducerCompany";
    urlPath = "introducer-company";
    params = {
      introducerCompany: message.introducerCompany.id,
      profile: message.introducerCompany.profile,
    };
  } else if ("introducerContact" in message) {
    jsName = "introducerContact";
    urlPath = "introducer-contact";
    params = {
      introducerContact: message.introducerContact.id,
      profile: message.introducerContact.profile,
    };
  } else if ("person" in message) {
    jsName = "person";
    urlPath = "kintin";
    params = {
      person: message.person.id,
      kintin: message.kintin.id,
      profile: message.person.profile,
    };
  } else if ("officer" in message) {
    jsName = "officer";
    urlPath = "officer";
    params = {
      officer: message.officer.id,
      profile: message.officer.profile,
    };
  } else if ("introducerBillingContact" in message) {
    jsName = "introducerBillingContact";
    urlPath = "introducer-billing-contact";
    params = {
      introducerCompany: message.introducerCompany.id,
      introducerBillingContact: message.introducerBillingContact.id,
      profile: message.introducerBillingContact.profile,
    };
  } else {
    throw new Error("Invalid message");
  }

  // @ts-ignore
  const profile = message[jsName].profile as Profile;

  await Api.resource(
    "portal",
    `/v2/portal/${urlPath}/{${jsName}}/profile/{profile}/replace` as `/v2/portal/lead/{lead}/profile/{profile}/replace`,
    params,
  )
    .method("put")
    .body({
      title: profile.title?.id,
      firstName: profile.firstName,
      middleNames: profile.middleNames,
      lastName: profile.lastName,
      knownAs: profile.knownAs,
      newsletterCampaigns: profile.newsletterCampaigns,
      suffix: profile.suffix,
      organisationName: profile.organisationName,
      organisationNumber: profile.organisationNumber,
      jobTitle: profile.jobTitle,
      addresses: profile.addresses.map((address) => ({
        id: address.id,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        country: address.country,
        state: address.state,
        postcode: address.postcode,
        primaryMailing: address.primaryMailing,
        primaryResidential: address.primaryResidential,
      })),
      emails: profile.emails.map((email) => ({
        id: email.id,
        email: email.email,
        primary: email.primary,
      })),
      phoneNumbers: profile.phoneNumbers.map((phoneNumber) => ({
        id: phoneNumber.id,
        tel: phoneNumber.tel,
        primary: phoneNumber.primary,
      })),
      websites: profile.websites.map((website) => ({
        id: website.id,
        url: website.url,
      })),
    })
    .result();

  if ("lead" in message) {
    await window.Kernel.ActionBus2.lead.lead.record({
      lead: message.lead.id,
    });
  } else if ("brandedKinvault" in message) {
    await window.Kernel.ActionBus2.adminBrandedKinvault.brandedKinvault.record({
      brandedKinvault: message.brandedKinvault.id,
    });
  } else if ("user" in message) {
    await window.Kernel.ActionBus2.adminUser.record({
      user: message.user.id,
    });
  } else if (
    "introducerCompany" in message &&
    !("introducerBillingContact" in message)
  ) {
    await window.Kernel.ActionBus2.introducerCompany.company.record({
      introducerCompany: message.introducerCompany.id,
    });
  } else if ("introducerContact" in message) {
    await window.Kernel.ActionBus2.introducerContact.contact.record({
      introducerContact: message.introducerContact.id,
    });
  } else if ("person" in message) {
    await window.Kernel.ActionBus2.kinvaultKintin.person.record({
      person: message.person.id,
      kintin: message.kintin.id,
    });
  } else if ("officer" in message) {
    await window.Kernel.ActionBus2.kinvaultOfficer.record({
      officer: message.officer.id,
    });
  } else if ("introducerBillingContact" in message) {
    await window.Kernel.ActionBus2.introducerCompany.billingContacts.record({
      introducerBillingContact: message.introducerBillingContact,
      introducerCompany: message.introducerCompany,
    });
  } else {
    throw new Error("Invalid message");
  }

  profile.$restore({
    addresses: true,
    emails: true,
    phoneNumbers: true,
    websites: true,
  });

  if ("lead" in message) {
    return { lead: Lead.$findOneOrThrow(message.lead.id) };
  } else if ("brandedKinvault" in message) {
    return {
      brandedKinvault: BrandedKinvault.$findOneOrThrow(
        message.brandedKinvault.id,
      ),
    };
  } else if ("user" in message) {
    return { user: User.$findOneOrThrow(message.user.id) };
  } else if ("introducerCompany" in message) {
    return {
      introducerCompany: IntroducerCompany.$findOneOrThrow(
        message.introducerCompany.id,
      ),
    };
  } else if ("introducerContact" in message) {
    return {
      introducerContact: IntroducerContact.$findOneOrThrow(
        message.introducerContact.id,
      ),
    };
  } else if ("person" in message) {
    return {
      person: Person.$findOneOrThrow(message.person.id),
    };
  } else if ("officer" in message) {
    return {
      officer: Person.$findOneOrThrow(message.officer.id),
    };
  }

  throw new Error("Invalid message");
};
