import { ReadLeadHistoryHandler } from "@/module/lead/action/history/read-lead-changes.action";
import { UpdateLeadSettingsHandler } from "@/module/lead/action/lead/update-lead-settings.action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { ConvertLeadToKintinHandler } from "./convert/convert-lead-to-kintin.action";
import { CreateLeadHandler } from "./lead/create-lead.action";
import { DeleteLeadHandler } from "./lead/delete-lead.action";
import { ReadLeadHandler } from "./lead/read-lead.action";
import { RecordLeadHandler } from "./lead/record-lead.action";
import { UpdateLeadProfileHandler } from "./profile/update-lead-profile.action";

export const Actions = {
  "lead/read": ReadLeadHandler,
  "lead/record": RecordLeadHandler,
  "lead/delete": DeleteLeadHandler,
  "lead/create": CreateLeadHandler,
  "lead/profile/update": UpdateLeadProfileHandler,
  "lead/convert/kintin": ConvertLeadToKintinHandler,
  "lead/settings/update": UpdateLeadSettingsHandler,
  "lead/history/read": ReadLeadHistoryHandler,
};

export const LeadActionBus = DefineActionBus({
  name: "Lead",
  actions: {
    lead: DefineActionBus({
      name: "Lead.Lead",
      actions: {
        read: ReadLeadHandler,
        record: RecordLeadHandler,
        delete: DeleteLeadHandler,
        create: CreateLeadHandler,
      },
    }),
    history: DefineActionBus({
      name: "Lead.History",
      actions: {
        read: ReadLeadHistoryHandler,
      },
    }),
    profile: DefineActionBus({
      name: "Lead.Profile",
      actions: {
        update: UpdateLeadProfileHandler,
      },
    }),
    convertToKintin: DefineActionBus({
      name: "Lead.ConvertToKintin",
      actions: {
        convert: ConvertLeadToKintinHandler,
      },
    }),
    settings: DefineActionBus({
      name: "Lead.Settings",
      actions: {
        update: UpdateLeadSettingsHandler,
      },
    }),
  },
});
