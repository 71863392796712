import { CreateIntroducerFeePaymentItemHandler } from "@/module/introducer.fees/action/introducer-fee-payment-item/create-introducer-fee-payment-item.action";
import { DeleteIntroducerFeePaymentItemHandler } from "@/module/introducer.fees/action/introducer-fee-payment-item/delete-introducer-fee-payment-item.action";
import { ReadIntroducerFeePaymentItemHandler } from "@/module/introducer.fees/action/introducer-fee-payment-item/read-introducer-fee-payment-item.action";
import { RecordIntroducerFeePaymentItemHandler } from "@/module/introducer.fees/action/introducer-fee-payment-item/record-introducer-fee-payment-item.action";
import { UpdateIntroducerFeePaymentItemHandler } from "@/module/introducer.fees/action/introducer-fee-payment-item/update-introducer-fee-payment-item.action";
import { ApproveIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/approve-introducer-fee-payment-run.action";
import { CreateIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/create-introducer-fee-payment-run.action";
import { CreateIntroducerFeePaymentsHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/create-introducer-fee-payments.action";
import { DeleteIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/delete-introducer-fee-payment-run.action";
import { PublishIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/publish-introducer-fee-payment-run.action";
import { ReadIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/read-introducer-fee-payment-run.action";
import { RecordIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/record-introducer-fee-payment-run.action";
import { SendIntroducerFeePaymentRunRemittanceHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/send-introducer-fee-payment-run-remittance.action";
import { UpdateIntroducerFeePaymentRunHandler } from "@/module/introducer.fees/action/introducer-fee-payment-run/update-introducer-fee-payment-run.action";
import { CreateIntroducerFeePaymentHandler } from "@/module/introducer.fees/action/introducer-fee-payment/create-introducer-fee-payment.action";
import { DeleteIntroducerFeePaymentHandler } from "@/module/introducer.fees/action/introducer-fee-payment/delete-introducer-fee-payment.action";
import { DownloadIntroducerFeePaymentRemittanceHandler } from "@/module/introducer.fees/action/introducer-fee-payment/download-introducer-fee-payment-remittance.action";
import { ReadIntroducerFeePaymentHandler } from "@/module/introducer.fees/action/introducer-fee-payment/read-introducer-fee-payment.action";
import { RecordIntroducerFeePaymentHandler } from "@/module/introducer.fees/action/introducer-fee-payment/record-introducer-fee-payment.action";
import { UpdateIntroducerFeePaymentHandler } from "@/module/introducer.fees/action/introducer-fee-payment/update-introducer-fee-payment.action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";

export const Actions = {
  // introducer-fee-payment-run
  "introducer/fees/payment-run/read": ReadIntroducerFeePaymentRunHandler,
  "introducer/fees/payment-run/record": RecordIntroducerFeePaymentRunHandler,
  "introducer/fees/payment-run/delete": DeleteIntroducerFeePaymentRunHandler,
  "introducer/fees/payment-run/create": CreateIntroducerFeePaymentRunHandler,
  "introducer/fees/payment-run/update": UpdateIntroducerFeePaymentRunHandler,
  "introducer/fees/payments/create": CreateIntroducerFeePaymentsHandler,
  "introducer/fees/payment-run/approve": ApproveIntroducerFeePaymentRunHandler,
  "introducer/fees/payment-run/publish": PublishIntroducerFeePaymentRunHandler,
  "introducer/fees/payment-run/send":
    SendIntroducerFeePaymentRunRemittanceHandler,
  // introducer-fee-payment
  "introducer/fees/payment/read": ReadIntroducerFeePaymentHandler,
  "introducer/fees/payment/record": RecordIntroducerFeePaymentHandler,
  "introducer/fees/payment/delete": DeleteIntroducerFeePaymentHandler,
  "introducer/fees/payment/create": CreateIntroducerFeePaymentHandler,
  "introducer/fees/payment/update": UpdateIntroducerFeePaymentHandler,
  "introducer/fees/payment/remittance/download":
    DownloadIntroducerFeePaymentRemittanceHandler,
  // introducer-fee-payment-item
  "introducer/fees/payment-item/read": ReadIntroducerFeePaymentItemHandler,
  "introducer/fees/payment-item/record": RecordIntroducerFeePaymentItemHandler,
  "introducer/fees/payment-item/delete": DeleteIntroducerFeePaymentItemHandler,
  "introducer/fees/payment-item/create": CreateIntroducerFeePaymentItemHandler,
  "introducer/fees/payment-item/update": UpdateIntroducerFeePaymentItemHandler,
};

export const IntroducerFeesActionBus = DefineActionBus({
  name: "Introducer.Fees",
  actions: {
    paymentRun: DefineActionBus({
      name: "Introducer.Fees.PaymentRun",
      actions: {
        read: ReadIntroducerFeePaymentRunHandler,
        record: RecordIntroducerFeePaymentRunHandler,
        delete: DeleteIntroducerFeePaymentRunHandler,
        create: CreateIntroducerFeePaymentRunHandler,
        update: UpdateIntroducerFeePaymentRunHandler,
        createPayments: CreateIntroducerFeePaymentsHandler,
        approve: ApproveIntroducerFeePaymentRunHandler,
        publish: PublishIntroducerFeePaymentRunHandler,
        send: SendIntroducerFeePaymentRunRemittanceHandler,
      },
    }),
    payment: DefineActionBus({
      name: "Introducer.Fees.Payment",
      actions: {
        read: ReadIntroducerFeePaymentHandler,
        record: RecordIntroducerFeePaymentHandler,
        delete: DeleteIntroducerFeePaymentHandler,
        create: CreateIntroducerFeePaymentHandler,
        update: UpdateIntroducerFeePaymentHandler,
        downloadRemittance: DownloadIntroducerFeePaymentRemittanceHandler,
      },
    }),
    paymentItem: DefineActionBus({
      name: "Introducer.Fees.PaymentItem",
      actions: {
        read: ReadIntroducerFeePaymentItemHandler,
        record: RecordIntroducerFeePaymentItemHandler,
        delete: DeleteIntroducerFeePaymentItemHandler,
        create: CreateIntroducerFeePaymentItemHandler,
        update: UpdateIntroducerFeePaymentItemHandler,
      },
    }),
  },
});
