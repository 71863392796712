import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import { Between, Like } from "@kinherit/orm";
import { IIntroducerNetwork, IntroducerNetwork } from "@kinherit/sdk";

export interface ReadIntroducerNetworkMessage {
  name?: string;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerNetwork;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerNetworkResponse {
  introducerNetworks: Array<IntroducerNetwork>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerNetworkHandler = async (
  message: ReadIntroducerNetworkMessage,
): Promise<ReadIntroducerNetworkResponse> => {
  const request = Api.resource("portal", "/v2/portal/introducer-network")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerNetwork).where({
    name: Like(`%${message.name}%`),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    introducerNetworks: IntroducerNetwork.$inflate(
      response.introducerNetwork,
      message.sort,
      response.$rootIds,
    ),

    pagination: getPagination(response),
  };
};
