<template>
  <div class="branded-kinvault-invitations">
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :persist-state="false"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      title="Invitations"
      subtitle="Manage invitations for this branded kinvault: invitations are where the partner is paying and inviting their clients"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :is-clickable="false"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #tableControls>
        <Button
          v-if="$auth.hasPermission('branded-kinvault-invitation:write')"
          @click="visitBulkUpload"
          class="upload-button"
          :icon-left="$style.icon.upload.icon"
          size="is-small"
          color="is-plain"
          aria-label="Upload CSV"
          tooltip="Upload CSV"
        />
        <Button
          v-if="$auth.hasPermission('branded-kinvault-invitation:write')"
          @click="createInvitation"
          class="create-button"
          :icon-left="$style.icon.add.icon"
          size="is-small"
          color="is-plain"
          aria-label="Create Invitation"
          tooltip="Create Invitation"
        />
        <Button
          v-if="$auth.hasPermission('branded-kinvault-invitation:write')"
          @click="createEmailCampaign"
          class="create-email-campaign-button"
          :icon-left="$style.icon.email.icon"
          size="is-small"
          color="is-plain"
          aria-label="Create Email Campaign"
          tooltip="Create Email Campaign"
        />
      </template>
      <template #actions="{ row }: { row: BrandedKinvaultInvitation }">
        <Button
          v-if="$auth.hasPermission('branded-kinvault-invitation:write')"
          @click="handleEditClick(row)"
          icon-left="Edit"
          size="is-small"
          color="is-plain"
          aria-label="Edit Settings"
        />
        <Button
          v-if="$auth.hasPermission('branded-kinvault-invitation:write')"
          @click="openContextMenu(row)"
          class="edit-button"
          :icon-left="$style.icon.options.icon"
          size="is-small"
          color="is-plain"
          :aria-label="`${row.profile?.fullName} invitations options`"
        />
      </template>
      <template #client="{ row }: { row: BrandedKinvaultInvitation }">
        <b>
          <a
            v-if="row.$data.kintin"
            class="client-link"
            @click="(e) => visitAccount(row.$data.kintin as string, e)"
          >
            {{ row.profile?.fullName }}
          </a>
          <span v-else>
            {{ row.profile?.fullName }}
          </span>
        </b>
        <small v-if="row.externalRef"> ({{ row.externalRef }})</small>
        <small v-if="row.profile?.emails.first()?.email" class="is-block pt-1">
          {{ row.profile?.emails.first()?.email }}
        </small>
      </template>
      <template #status="{ row }: { row: BrandedKinvaultInvitation }">
        <span
          v-if="row.status === 'notsent'"
          class="badge is-rounded is-warning"
        >
          Not Sent
        </span>
        <span
          v-else-if="row.status === 'sent'"
          class="badge is-rounded is-primary"
        >
          Sent
        </span>
        <span
          v-else-if="row.status === 'complete'"
          class="badge is-rounded is-success"
        >
          Complete
        </span>
        <span
          v-else-if="row.status === 'failed'"
          class="badge is-rounded is-warning"
        >
          Failed
        </span>
        <span
          v-else-if="row.status === 'declined'"
          class="badge is-rounded is-danger"
        >
          Declined
        </span>
        <span
          v-else-if="row.status === 'cancelled'"
          class="badge is-rounded is-danger"
        >
          Cancelled
        </span>
      </template>
      <template #billable="{ row }: { row: BrandedKinvaultInvitation }">
        <span v-if="row.isBillable" class="badge is-success is-rounded">
          Billable
        </span>
        <span v-else class="badge is-warning is-rounded"> Non-Billable </span>
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateBrandedKinvaultInvitationForm } from "@/module/admin.branded-kinvault/form/create-branded-kinvault-invitation.form";
import { ReadBrandedKinvaultInvitationForm } from "@/module/admin.branded-kinvault/form/read-branded-kinvault-invitation.form";
import { UpdateBrandedKinvaultInvitationForm } from "@/module/admin.branded-kinvault/form/update-branded-kinvault-invitation.form";
import { BrandedKinvaultDetailsMixin } from "@/module/admin.branded-kinvault/mixin/branded-kinvault-details.mixin";
import {
  EmailCampaignDetailsParams,
  EmailCampaignDetailsRoute,
} from "@/module/admin.email-campaign/page/details";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import {
  OpenAlertDialog,
  OpenTextDialog,
} from "@kinherit/framework/global/dialog";
import { ClipBoardService } from "@kinherit/framework/service/clip-board-service";
import {
  BrandedKinvaultInvitation,
  IBrandedKinvaultInvitation,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import {
  AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
  AdminBrandedKinvaultDetailsInvitationsRoute,
} from ".";
export default defineComponent({
  name: AdminBrandedKinvaultDetailsInvitationsRoute,
  mixins: [
    AuthService.mixin(),
    StyleService.mixin,
    BrandedKinvaultDetailsMixin,
  ],
  components: { MasterListPage, Button },
  data: () => ({
    filters: ReadBrandedKinvaultInvitationForm(),
    columns: [
      {
        title: "Client",
        sort: true,
        slot: "client",
      },
      {
        title: "Referral code",
        sort: true,
        map: (v) => v.referral?.referralCode?.code,
      },
      {
        title: "Introducer",
        sort: true,
        map: (v) => v.introducerContact?.profile.fullName,
      },
      {
        title: "Status",
        sort: true,
        slot: "status",
      },
      {
        title: "Billable",
        slot: "billable",
      },
      {
        title: "Created",
        sort: true,
        map: (v: BrandedKinvaultInvitation) => v.createdAt.formatDate,
      },
      {
        title: "Sent",
        sort: true,
        map: (v: BrandedKinvaultInvitation) => v.sentAt?.formatDate,
      },
      {
        title: "Completed",
        sort: true,
        map: (v: BrandedKinvaultInvitation) => v.completedAt?.formatDate,
      },
      {
        title: "Cancelled",
        sort: true,
        map: (v: BrandedKinvaultInvitation) => v.cancelledAt?.formatDate,
      },
      {
        slot: "actions",
        class: "is-narrow",
        blockClicks: true,
      },
    ] as Array<TableColumn<BrandedKinvaultInvitation>>,
    rows: Array<BrandedKinvaultInvitation>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvaultInvitation,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    useBrandedKinvaultInvitations(): boolean {
      return this.brandedKinvault?.useBrandedKinvaultInvitations ?? false;
    },
  },
  methods: {
    async handleEditClick(
      brandedKinvaultInvitation: BrandedKinvaultInvitation,
    ): Promise<void> {
      if (!this.$auth.hasPermission("branded-kinvault-invitation:write")) {
        return;
      }

      try {
        await UpdateBrandedKinvaultInvitationForm(
          brandedKinvaultInvitation,
        ).dialog({
          dialog: {
            title: "Invitation",
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        brandedKinvaultInvitation.$restore();
        throw e;
      }

      brandedKinvaultInvitation.$persist();

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/update",
      //   {
      //     brandedKinvaultInvitation,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.update({
        brandedKinvaultInvitation,
      });

      await this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<
        typeof ReadBrandedKinvaultInvitationForm
      >["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/read",
      //   {
      //     ...formData,
      //     brandedKinvault: this.$params.brandedKinvault,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data =
        await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.read({
          ...formData,
          brandedKinvault: this.$params.brandedKinvault,
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = data.brandedKinvaultInvitations;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async openContextMenu(
      invitation: BrandedKinvaultInvitation,
    ): Promise<void> {
      const items = [];

      if (invitation.status === "notsent") {
        items.push({
          label: "Send",
          action: this.send,
        });
      }

      if (invitation.status === "sent") {
        items.push(
          {
            label: "Resend",
            action: this.send,
          },
          {
            label: "Send Follow up 1",
            action: this.sendFollowup1,
          },
          {
            label: "Send Follow up 2",
            action: this.sendFollowup2,
          },
        );
      }
      items.push(
        {
          label: "Copy Invitation Link",
          action: this.copyInvitationLink,
        },
        {
          label: "Mark Sent",
          action: this.markSent,
        },
        {
          label: "Mark Declined",
          action: this.markDeclined,
        },
        {
          label: "Mark Cancelled",
          action: this.markCancelled,
        },
        {
          label: "Delete",
          action: this.delete,
        },
      );
      const result = await OpenContextMenu({
        items,
        trackingField: "label",
        titleField: "label",
      });

      await result.action(invitation);
    },
    async send(brandedKinvaultInvitation: BrandedKinvaultInvitation) {
      const isResend = brandedKinvaultInvitation.status === "sent";

      await DangerDialog({
        dialog: {
          title: `${isResend ? `Res` : `S`}end Email`,
          message: `Are you sure you want to ${
            isResend ? `re` : ``
          }send this invitation?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/send",
      //   {
      //     brandedKinvaultInvitation,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.send({
        brandedKinvaultInvitation,
      });

      await this.refresh(this.filters.localData);
    },
    async sendFollowup1(brandedKinvaultInvitation: BrandedKinvaultInvitation) {
      if (brandedKinvaultInvitation.status !== "sent") {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Are you sure?`,
          message: `Are you sure you want to send Invitation Followup 1?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/send/followup1",
      //   {
      //     brandedKinvaultInvitation,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.sendFollowup1(
        {
          brandedKinvaultInvitation,
        },
      );

      await this.refresh(this.filters.localData);
    },
    async sendFollowup2(brandedKinvaultInvitation: BrandedKinvaultInvitation) {
      if (brandedKinvaultInvitation.status !== "sent") {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Are you sure?`,
          message: `Are you sure you want to send Invitation Followup 2?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/send/followup2",
      //   {
      //     brandedKinvaultInvitation,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.sendFollowup2(
        {
          brandedKinvaultInvitation,
        },
      );

      await this.refresh(this.filters.localData);
    },
    async copyInvitationLink(invitation: BrandedKinvaultInvitation) {
      const url = `https://${this.brandedKinvault?.frontendUrl}/accept-invite/${invitation.signUpToken}`;
      ClipBoardService.copyTextToClipBoard(url, {
        wrapLinks: false,
        showSnackbar: true,
      });
    },
    async markSent(invitation: BrandedKinvaultInvitation) {
      await DangerDialog({
        dialog: {
          title: "Mark sent",
          message: "Are you sure you want to mark this invitation as sent?",
        },
      });

      invitation.status = "sent";

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/update",
      //   {
      //     brandedKinvaultInvitation: invitation,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.update({
        brandedKinvaultInvitation: invitation,
      });
    },
    async markDeclined(invitation: BrandedKinvaultInvitation) {
      await DangerDialog({
        dialog: {
          title: "Mark declined",
          message: "Are you sure you want to mark this invitation as declined?",
        },
      });

      invitation.status = "declined";
      invitation.declinedAt = new DateTime();

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/update",
      //   {
      //     brandedKinvaultInvitation: invitation,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.update({
        brandedKinvaultInvitation: invitation,
      });
    },
    async markCancelled(invitation: BrandedKinvaultInvitation) {
      await DangerDialog({
        dialog: {
          title: "Mark cancelled",
          message:
            "Are you sure you want to mark this invitation as cancelled?",
        },
      });

      invitation.status = "cancelled";
      invitation.cancelledAt = new DateTime();

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/update",
      //   {
      //     brandedKinvaultInvitation: invitation,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.update({
        brandedKinvaultInvitation: invitation,
      });
    },
    async delete(invitation: BrandedKinvaultInvitation) {
      await DangerDialog({
        dialog: {
          title: "Delete",
          message: "Are you sure you want to delete this invitation?",
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/delete",
      //   {
      //     brandedKinvaultInvitation: invitation,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.delete({
        brandedKinvaultInvitation: invitation,
      });

      await this.refresh(this.filters.localData);
    },
    visitBulkUpload() {
      window.Kernel.visitRoute({
        name: AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
        params: this.$params,
      });
    },
    visitAccount(kintin: string, event: MouseEvent): void {
      const params: KinvaultKintinDetailsParams = { kintin: kintin };

      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async createEmailCampaign() {
      const campaignName = await OpenTextDialog({
        dialog: {
          title: "Create Email Campaign",
          message: `This will create an email campaign for all clients that apply to the current filters. Please enter a name for the campaign:`,
        },
        button: {
          ok: {
            text: "Create",
          },
        },
        input: {},
        value: "",
      });

      // const { emailCampaign } = await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/email-campaign/create",
      //   {
      //     campaignName,
      //     ...this.filters.localData,
      //     brandedKinvault: this.$params.brandedKinvault,
      //   },
      // );

      const { emailCampaign } =
        await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.emailCampaign.create(
          {
            campaignName,
            ...this.filters.localData,
            brandedKinvault: this.$params.brandedKinvault,
          },
        );

      await OpenAlertDialog({
        dialog: {
          title: "Email Campaign Created",
          message: `The email campaign has been created. Would you like to view it now?`,
        },
        button: {
          ok: {
            text: "View",
          },
        },
      });

      await window.Kernel.visitRoute({
        name: EmailCampaignDetailsRoute,
        params: {
          emailCampaign: emailCampaign.id,
        } satisfies EmailCampaignDetailsParams,
      });
    },
    async createInvitation() {
      const data = await CreateBrandedKinvaultInvitationForm().dialog({
        dialog: {
          title: "Create Invitation",
        },
        button: {
          ok: {
            text: "Create",
          },
        },
      });

      const createdBy = this.$auth.loggedInUser?.credential;

      if (!createdBy) {
        throw new Error("No logged in user");
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/invitation/create",
      //   {
      //     ...data,
      //     createdBy,
      //     brandedKinvaultOverride: data.brandedKinvaultOverride,
      //     brandedKinvault: this.$params.brandedKinvault,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.invitation.create({
        ...data,
        createdBy,
        brandedKinvaultOverride: data.brandedKinvaultOverride,
        brandedKinvault: this.$params.brandedKinvault,
      });

      await this.refresh(this.filters.localData);
    },
  },
});
</script>
