<template>
  <Card :title="renderedSubject">
    <span v-html="renderedBody" />
  </Card>
</template>

<script lang="ts">
import { EmailTemplateDetailsMixin } from "@/module/admin.email-template/mixin/email-template-details.mixin";
import Card from "@kinherit/framework/component.layout/card";
import { EmailTemplateService } from "@kinherit/ts-common";
import { marked } from "marked";
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmailTemplateCodeSummary",
  mixins: [EmailTemplateDetailsMixin],
  components: { Card },
  data: () => ({
    params: {
      recipients: "Joe & Sally",
      recipientsfullname: "Joe & Sally Bloggs",
      hasHave: "hasHave",
      currentuser: "Kenedi Cross",
      currentuserfirstname: "Kenidi",
      estateplanner: "Natasha Jones",
      estateplannerfirstname: "Natasha",
      legalassistant: "Kenidi Cross",
      legalassistantfirstname: "Kenidi",
      specialist: "Natasha Jones",
      aboutkinherit: "aboutkinherit [deprecated]",
      primaryfirstname: "Joe",
      secondaryfirstname: "Sally",
      primaryfullname: "Joe Bloggs",
      secondaryfullname: "Sally Bloggs",
      yourOrName: "yourOrName",
      officername: "Richard Dawkins",
      testatorfullname: "Joe Bloggs",
      testatorfirstname: "Joe",
      leadfirstname: "Joe",
      introducerfirstname: "Edward",
      introducername: "Edward McKenzie",
      introducercompany: "McKenzie & Co",
      trname: "Trust Registration Example",
      trpin: "230123",
      trref: "TR00000000",
      kinvaultloginurl: "https://app.kinvault.com",
    },
    renderedSubject: "",
    renderedBody: "",
  }),
  async mounted() {
    this.renderedSubject = await this.renderSubject();
    this.renderedBody = await this.renderBody();
  },
  methods: {
    async renderSubject(): Promise<string> {
      if (!this.emailTemplate) {
        return "";
      }
      return await marked(
        EmailTemplateService.compile({
          template: this.emailTemplate.subject,
          params: this.params,
          markupParameters: true,
          throwOnMissing: false,
        }),
        {
          pedantic: false,
          gfm: true,
        },
      );
    },
    async renderBody(): Promise<string> {
      if (!this.emailTemplate) {
        return "";
      }
      return await marked(
        EmailTemplateService.compile({
          template: this.emailTemplate.body,
          params: this.params,
          markupParameters: true,
          throwOnMissing: false,
        }),
        {
          pedantic: false,
          gfm: true,
        },
      );
    },
  },
});
</script>
