import { UserField } from "@/config/form.config";
import { FormButton } from "@kinherit/framework/component.input/button";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";
import { User } from "@kinherit/sdk";

export const ReadOverviewForm = () =>
  defineForm({
    name: "read-overview-form",
    data: () => ({
      selected: null as User | null,
      timeFrame: "today" as "today" | "tomorrow" | "overdue",
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "read-overview-form-area",
        data,
        template: GridLayout([["timeFrame", "selected"]]),
        components: () => ({
          timeFrame: [
            FormButton({
              props: {
                text: "Today",
                class: (data) =>
                  data.timeFrame === "today" ? "is-primary" : "",
              },
              emits: {
                click: (_, data, controls) => {
                  data.timeFrame = "today";
                  controls.incrementFormRenderKey();
                },
              },
            }),
            FormButton({
              props: {
                text: "Tomorrow",
                class: (data) =>
                  data.timeFrame === "tomorrow" ? "is-primary" : "",
              },
              emits: {
                click: (_, data, controls) => {
                  data.timeFrame = "tomorrow";
                  controls.incrementFormRenderKey();
                },
              },
            }),
            FormButton({
              props: {
                text: "Overdue",
                class: (data) =>
                  data.timeFrame === "overdue" ? "is-primary" : "",
              },
              emits: {
                click: (_, data, controls) => {
                  data.timeFrame = "overdue";
                  controls.incrementFormRenderKey();
                },
              },
            }),
          ],
          selected: [
            UserField({
              props: {
                label: "",
                vModel: "selected",
                size: "is-small" as const,
                isScrollable: true,
                autoCollapseLabel: true,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
        }),
      }),
    ],
  });
