<template>
  <HistoryPage type="kintin" :id="$params.kintin" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";
import { KinvaultKintinDetailsChangeLogRoute } from ".";
import HistoryPage from "@/shared/component/history/HistoryPage.vue";

export default defineComponent({
  name: KinvaultKintinDetailsChangeLogRoute,
  components: { HistoryPage },
  mixins: [KinvaultKintinDetailsMixin],
});
</script>
