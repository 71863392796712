import {
  AccountReferralCodeField,
  BrandedKinvaultField,
} from "@/config/form.config";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { AccountReferralCode, BrandedKinvault } from "@kinherit/sdk";

export const UploadBrandedKinvaultInvitationCsvForm = () =>
  defineForm({
    name: `upload-branded-kinvault-invitation-csv`,
    data: () => ({
      defaultReferralCode: null as AccountReferralCode | null,
      brandOverride: null as BrandedKinvault | null,
      sendInvitations: null as null | boolean,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: `upload-branded-kinvault-invitation-csv`,
        data,
        template: GridLayout([
          ["defaultReferralCode", "brandOverride"],
          ["sendInvitations"],
        ]),
        components: () => ({
          defaultReferralCode: [
            AccountReferralCodeField({
              props: {
                vModel: "defaultReferralCode",
                label: "Default Referral Code",
                message:
                  "If no referral code provided, use this as the default",
                validators: ["required"],
              },
            }),
          ],
          brandOverride: [
            BrandedKinvaultField({
              props: {
                vModel: "brandOverride",
                label: "Branded Kinvault Override",
                message:
                  "This should match the branded kinvault you wish to send an invite for",
              },
            }),
          ],
          sendInvitations: [
            FormRadioField({
              props: {
                direction: "is-vertical",
                label: "Send Invitation Email?",
                options: ["Yes", "No"],
                validators: ["required"],
              },
              models: {
                value: {
                  get: (data) => {
                    console.log("get", data.sendInvitations);
                    if (null === data.sendInvitations) {
                      return null;
                    }

                    return data.sendInvitations ? 0 : 1;
                  },
                  set: (value: 0 | 1, data) => {
                    console.log("set", value);
                    data.sendInvitations = value === 0;
                  },
                },
              },
              slots: {
                label: [
                  FormElement({
                    props: {
                      html: (_, controls) => {
                        const option = controls.templateScope.option as
                          | "Yes"
                          | "No";
                        const yes = `
                          <b>Yes, send the invitation email</b>
                          <p class='mt-0'>Suitable if you wish us to email the user with an invitation link</p>
                        `;

                        const no = `
                          <b>No, I'll do this myself</b>
                          <p class='mt-0'>Suitable if you wish to email generated invitation links via your own mail system</p>
                        `;

                        return option === "Yes" ? yes : no;
                      },
                    },
                  }),
                ],
              },
            }),
          ],
        }),
      }),
    ],
  });
