<template>
  <div
    v-if="showBefore"
    style="text-align: left; display: grid; padding-left: 0; padding-right: 0"
  >
    <b class="is-block">Before</b>
    <pre :style="styles.pre">{{ oldValues }}</pre>
  </div>
  <div
    v-if="showAfter"
    style="text-align: left; display: grid; padding-left: 0; padding-right: 0"
  >
    <b class="is-block">After</b>
    <pre :style="styles.pre">{{ newValues }}</pre>
  </div>
  <div
    v-if="showAdditionalData"
    style="text-align: left; display: grid; padding-left: 0; padding-right: 0"
  >
    <b class="is-block">Additional Data</b>
    <pre :style="styles.pre">{{ additionalData }}</pre>
  </div>
</template>

<script lang="ts">
import { EntityHistory } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "HistoryPageRow",
  props: {
    row: {
      type: Object as PropType<EntityHistory>,
      required: true,
    },
  },
  data: () => ({
    styles: {
      pre: {
        display: "list-item",
      },
    },
  }),
  computed: {
    showBefore(): boolean {
      return this.oldValues !== "";
    },
    showAfter(): boolean {
      return !(this.row.action === "REMOVE" || this.newValues === "");
    },
    showAdditionalData(): boolean {
      return this.additionalData !== "";
    },
    oldValues(): string {
      if (!this.row.oldValues) {
        return ``;
      }

      return this.objectToString(this.row.oldValues);
    },
    newValues(): string {
      if (!this.row.newValues) {
        return ``;
      }

      return this.objectToString(this.row.newValues);
    },
    additionalData(): string {
      if (Object.keys(this.row.additionalData).isEmpty()) {
        return ``;
      }

      return this.objectToString(this.row.additionalData);
    },
  },
  methods: {
    objectToString(obj: any): string {
      const result = JSON.stringify(obj, null, 2);

      return result.substring(2, result.length - 1);
    },
  },
});
</script>
