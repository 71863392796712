import { Api } from "@kinherit/sdk";

export const SetXeroTokenHandler = async (_message: {}): Promise<void> => {
  const { url } = await Api.resource(
    "external",
    "/v2/external/xero/request-consent",
  )
    .method("get")
    .result();

  window.open(url, "_blank");
};
