<template>
  <ProfileSummary
    :key="key"
    v-if="brandedKinvault && !loading"
    class="branded-kinvault-details"
    :profile-id="brandedKinvault.profile.id"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #sidebar-above>
      <RouterChildView name="sidebar">
        <BrandedKinvaultDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <BrandedKinvaultDetailsSummary />
      </RouterChildView>
    </template>
  </ProfileSummary>
  <PageNotFound v-else />
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { BrandedKinvaultDetailsMixin } from "@/module/admin.branded-kinvault/mixin/branded-kinvault-details.mixin";
import { BrandedKinvaultDetailsThemeRoute } from "@/module/admin.branded-kinvault/page/details/theme";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import ProfileSummary from "@/shared/component/profile/ProfileSummary.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { In } from "@kinherit/orm/index";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsParams, BrandedKinvaultDetailsRoute } from ".";
import { BrandedKinvaultMasterListRoute } from "..";
import { UpdateBrandedKinvaultSettingsForm } from "../../form/update-branded-kinvault-settings.form";
import { UpdateBrandedKinvaultOwners } from "../../form/update-branded-kinvaults-owners.form";
import BrandedKinvaultDetailsSidebar from "./BrandedKinvaultDetails.Sidebar.vue";
import { BrandedKinvaultDetailsDefaultReferralRoute } from "./default-referral";
import {
  AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
  AdminBrandedKinvaultDetailsInvitationsBillingRoute,
  AdminBrandedKinvaultDetailsInvitationsRoute,
} from "./invitations";
import { BrandedKinvaultDetailsMembersRoute } from "./members";
import { BrandedKinvaultDetailsSummaryRoute } from "./summary";
import BrandedKinvaultDetailsSummary from "./summary/BrandedKinvaultDetails.Summary.vue";
import { BrandedKinvaultDetailsUpdateProfileRoute } from "./update-profile";

export default defineComponent({
  name: BrandedKinvaultDetailsRoute,
  mixins: [BrandedKinvaultDetailsMixin, StyleService.mixin],
  components: {
    PageNotFound,
    RouterChildView,
    BrandedKinvaultDetailsSummary,
    ProfileSummary,
    BrandedKinvaultDetailsSidebar,
  },
  computed: {
    $params(): BrandedKinvaultDetailsParams {
      return this.$route.params as BrandedKinvaultDetailsParams;
    },
    showContextMenu(): boolean {
      return AuthService.hasPermission("branded-kinvault:write");
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: BrandedKinvaultDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Members",
        route: {
          name: BrandedKinvaultDetailsMembersRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.person.icon,
      },
      {
        label: "Theme",
        route: {
          name: BrandedKinvaultDetailsThemeRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.theme.icon,
      },
      //{
      //  label: "Emails",
      //  route: {
      //    name: BrandedKinvaultDetailsEmailLogRoute,
      //    params: ctx.$params,
      //  },
      //  icon: StyleService.icon.email.icon,
      //},
      {
        label: "Invitations",
        route: {
          name: AdminBrandedKinvaultDetailsInvitationsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.subscription.icon,
        nestedRoutes: [
          AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
          AdminBrandedKinvaultDetailsInvitationsBillingRoute,
        ],
      },
      {
        label: "Referral Codes",
        route: {
          name: BrandedKinvaultDetailsDefaultReferralRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.referral.icon,
      },
      //{
      //  label: "Subscriptions",
      //  route: {
      //    name: BrandedKinvaultDetailsSubscriptionsRoute,
      //    params: ctx.$params,
      //  },
      //  icon: StyleService.icon.subscription.icon,
      //  nestedRoutes: [
      //    BrandedKinvaultDetailsSubscriptionsRoute,
      //    BrandedKinvaultDetailsSubscriptionsReferralCodesRoute,
      //  ],
      //},
      //{
      //  label: "Activity",
      //  route: {
      //    name: BrandedKinvaultDetailsActivityRoute,
      //    params: ctx.$params,
      //  },
      //  icon: StyleService.icon.history.icon,
      //},
    ] as TabOptions[],
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.execute(
    //   "admin/branded-kinvault/branded-kinvault/record",
    //   to.params as BrandedKinvaultDetailsParams,
    // );
    await window.Kernel.ActionBus2.adminBrandedKinvault.brandedKinvault.record(
      to.params as BrandedKinvaultDetailsParams,
    );
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.brandedKinvault) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit Profile",
              line1: `Edit ${this.brandedKinvault.profile.fullName} profile`,
              iconLeft: StyleService.icon.profile.icon,
              permissions: "branded-kinvault:write",
              action: this.editProfile,
            },
            {
              title: "Branded Kinvault Settings",
              line1: `Edit ${this.brandedKinvault.profile.fullName} settings`,
              iconLeft: StyleService.icon.edit.icon,
              permissions: "branded-kinvault:write",
              action: this.editSettings,
            },
            {
              title: "Update Owners",
              line1: `Update owners for ${this.brandedKinvault.profile.fullName}`,
              iconLeft: this.$style.icon.organisation.icon,
              permissions: "introducer-company:write",
              action: this.updateOwners,
            },
            {
              title: "Delete",
              line1: `Delete ${this.brandedKinvault.profile.fullName}`,
              iconLeft: StyleService.icon.delete.icon,
              permissions: "branded-kinvault:write",
              action: this.delete,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "iconLeft",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(),
      })();
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: BrandedKinvaultDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
    async editSettings(): Promise<void> {
      const brandedKinvault = this.brandedKinvault;

      if (!brandedKinvault) {
        return;
      }

      try {
        await UpdateBrandedKinvaultSettingsForm(brandedKinvault).dialog({
          dialog: {
            title: `Edit ${brandedKinvault.profile.fullName} Settings`,
          },
        });
      } catch (error) {
        brandedKinvault.$restore();
        throw error;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/branded-kinvault/update",
      //   {
      //     brandedKinvault,
      //   },
      // );

      await window.Kernel.ActionBus2.adminBrandedKinvault.brandedKinvault.update(
        {
          brandedKinvault,
        },
      );

      this.key++;
    },
    async updateOwners(): Promise<void> {
      if (!this.brandedKinvault) {
        return;
      }

      const ids = [
        ...(this.brandedKinvault.$data.ownerCompanies ?? []),
        ...(this.brandedKinvault.$data.memberCompanies ?? []),
      ];

      if (ids.isNotEmpty()) {
        // await window.Kernel.ActionBus.execute(
        //   "core/select/introducer-company/read",
        //   {
        //     query: {
        //       id: In(ids),
        //     },
        //   },
        // );

        await window.Kernel.ActionBus2.core.select.introducerCompany.read({
          query: {
            id: In(ids),
          },
        });
      }

      try {
        await UpdateBrandedKinvaultOwners(this.brandedKinvault).dialog({
          dialog: {
            title: "Update Branded Kinvaults",
          },
        });

        // await window.Kernel.ActionBus.execute(
        //   "admin/branded-kinvault/branded-kinvault/update",
        //   {
        //     brandedKinvault: this.brandedKinvault,
        //   },
        // );
        await window.Kernel.ActionBus2.adminBrandedKinvault.brandedKinvault.update(
          {
            brandedKinvault: this.brandedKinvault,
          },
        );
      } catch (error) {
        this.brandedKinvault.$restore();
        return;
      }
    },
    async delete(): Promise<void> {
      const brandedKinvault = this.brandedKinvault;

      if (!brandedKinvault) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: `Delete ${brandedKinvault.profile.fullName}`,
          message: `Are you sure you want to delete ${brandedKinvault.profile.fullName}?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/branded-kinvault/delete",
      //   {
      //     brandedKinvault,
      //   },
      // );
      await window.Kernel.ActionBus2.adminBrandedKinvault.brandedKinvault.delete(
        {
          brandedKinvault,
        },
      );

      window.Kernel.Router.push({ name: BrandedKinvaultMasterListRoute });
    },
  },
});
</script>
