import { SharedFilterProps } from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadInvoicesForm = () =>
  MasterListFiltersForm({
    name: "read-invoices-form",
    data: () => ({
      search: null as null | string,
    }),
    props: SharedFilterProps,
  })({});
