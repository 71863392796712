import { Between, Like } from "@kinherit/orm/index";
import {
  Api,
  IKnowledgeBaseCategory,
  KnowledgeBaseCategory,
} from "@kinherit/sdk";

export interface ReadKnowledgeBaseCategoryMessage {
  name?: string;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKnowledgeBaseCategory;
    direction: "asc" | "desc";
  };
}

interface ReadKnowledgeBaseCategoryResponse {
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKnowledgeBaseCategoryHandler = async (
  message: ReadKnowledgeBaseCategoryMessage,
): Promise<ReadKnowledgeBaseCategoryResponse> => {
  const request = Api.resource("portal", "/v2/portal/knowledge-base-category")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination ?? false);

  request.buildQuery(KnowledgeBaseCategory).where({
    title: Like(message.name),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
