import { DeleteCpdSessionRegistrationHandler } from "@/module/cpd/action/cpd-session-registration/delete-cpd-session-registration.action";
import { CreateEmailCampaignHandler } from "@/module/cpd/action/cpd-session/create-email-campaign.action";
import { DeleteIntroducerCpdHandler } from "@/module/cpd/action/introducer-cpd/delete-introducer-cpd.action";
import { DownloadIntroducerCpdCsvHandler } from "@/module/cpd/action/introducer-cpd/download-introducer-cpd-csv.action";
import { GenerateCpdCertificateHandler } from "@/module/cpd/action/introducer-cpd/generate-cpd-certificate.action";
import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { ReadCpdSessionRegistrationMatchesHandler } from "./cpd-session-registration/read-cpd-session-registration-matches.action";
import { UpdateCpdSessionRegistrationHandler } from "./cpd-session-registration/update-cpd-session-registration.action";
import { CreateCpdSessionHandler } from "./cpd-session/create-cpd-session.action";
import { DeleteCpdSessionHandler } from "./cpd-session/delete-cpd-session.action";
import { ReadCpdSessionHandler } from "./cpd-session/read-cpd-session.action";
import { RecordCpdSessionHandler } from "./cpd-session/record-cpd-session.action";
import { UpdateCpdSessionHandler } from "./cpd-session/update-cpd-session.action";
import { CreateIntroducerCpdHandler } from "./introducer-cpd/create-introducer-cpd.action";
import { UpdateIntroducerCpdHandler } from "./introducer-cpd/update-introducer-cpd.action";

export const Actions = {
  "cpd/session/read": ReadCpdSessionHandler,
  "cpd/session/create": CreateCpdSessionHandler,
  "cpd/session/update": UpdateCpdSessionHandler,
  "cpd/session/record": RecordCpdSessionHandler,
  "cpd/session/delete": DeleteCpdSessionHandler,
  "cpd/session/registration/matches/read":
    ReadCpdSessionRegistrationMatchesHandler,
  "cpd/session/registration/update": UpdateCpdSessionRegistrationHandler,
  "cpd/session/registration/delete": DeleteCpdSessionRegistrationHandler,
  "cpd/session/introducer-cpd/create": CreateIntroducerCpdHandler,
  "cpd/session/introducer-cpd/update": UpdateIntroducerCpdHandler,
  "cpd/session/introducer-cpd/delete": DeleteIntroducerCpdHandler,
  "cpd/session/introducer-cpd/generate-certificate":
    GenerateCpdCertificateHandler,
  "cpd/session/introducer-cpd/download-csv": DownloadIntroducerCpdCsvHandler,
  "cpd/session/email-campaign/create": CreateEmailCampaignHandler,
};

export const CpdActionBus = DefineActionBus({
  name: "Cpd",
  actions: {
    session: DefineActionBus({
      name: "Session",
      actions: {
        read: ReadCpdSessionHandler,
        create: CreateCpdSessionHandler,
        update: UpdateCpdSessionHandler,
        record: RecordCpdSessionHandler,
        delete: DeleteCpdSessionHandler,
      },
    }),
    register: DefineActionBus({
      name: "SessionRegistration",
      actions: {
        matches: DefineActionBus({
          name: "ReadMatches",
          actions: {
            read: ReadCpdSessionRegistrationMatchesHandler,
          },
        }),
        update: UpdateCpdSessionRegistrationHandler,
        delete: DeleteCpdSessionRegistrationHandler,
      },
    }),
    introducerCpd: DefineActionBus({
      name: "IntroducerCpd",
      actions: {
        create: CreateIntroducerCpdHandler,
        update: UpdateIntroducerCpdHandler,
        delete: DeleteIntroducerCpdHandler,
        generateCertificate: GenerateCpdCertificateHandler,
        downloadCsv: DownloadIntroducerCpdCsvHandler,
      },
    }),
    emailCampaign: DefineActionBus({
      name: "EmailCampaign",
      actions: {
        create: CreateEmailCampaignHandler,
      },
    }),
  },
});
