<template>
  <div class="lead-details-sidebar">
    <Card v-if="lead" title="Status" title-size="is-5">
      <LeadStatus :lead="lead" direction="is-vertical" />
    </Card>
    <DataListWidget v-if="show" :details="ownershipDetails">
      <template #above>
        <div class="level">
          <h5 class="left">Assigned To</h5>
          <Icon
            icon="Edit"
            class="is-clickable"
            tooltip="Edit Assigned To"
            @click="handleAssignedToClick"
          />
        </div>
      </template>
    </DataListWidget>
    <DataListWidget v-if="show" :details="referralDetails">
      <template #above>
        <div class="level">
          <h5 class="left">Referral</h5>
          <Icon
            icon="Edit"
            class="is-clickable"
            tooltip="Edit Assigned To"
            @click="handleReferralClick"
          />
        </div>
      </template>
    </DataListWidget>
  </div>
</template>

<script lang="ts">
import {
  AccountReferralCodeDetailsParams,
  AccountReferralCodeDetailsRoute,
} from "@/module/admin.account-referral-code/page/details";
import {
  IntroducerContactDetailsParams,
  IntroducerContactDetailsRoute,
} from "@/module/introducer.contact/page/details";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import LeadStatus from "@/shared/component/lead/LeadStatus.vue";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { LeadDetailsMixin } from "../../mixin/lead-details.mixin";
import Icon from "@kinherit/framework/component.display/icon";

export default defineComponent({
  name: "LeadDetailsSidebar",
  mixins: [LeadDetailsMixin],
  components: {
    DataListWidget,
    LeadStatus,
    Card,
    Icon,
  },
  emits: ["update-settings", "manage-referral"],
  computed: {
    show(): boolean {
      return null !== this.lead?.referral;
    },
    ownershipDetails(): Array<DataListWidgetDetail> {
      return [
        {
          label: "Created",
          value: {
            text: this.lead?.createdAt?.formatDateTime,
          },
        },
        {
          label: "Lead Owner",
          value: {
            text: this.lead?.ownedBy
              ? `${this.lead?.ownedBy?.profile.fullName}`
              : "Unowned",
            color: this.lead?.ownedBy?.profile.fullName
              ? undefined
              : "has-text-danger",
          },
        },
        {
          label: "Assigned To",
          value: {
            text: this.lead?.assignedTo
              ? `${this.lead?.assignedTo?.profile.fullName}`
              : "Unassigned",
            color: this.lead?.assignedTo?.profile.fullName
              ? undefined
              : "has-text-danger",
          },
        },
      ];
    },
    referralDetails(): Array<DataListWidgetDetail> {
      return [
        {
          label: "Referral Source",
          value: {
            text: this.lead?.referral?.referralCode?.name,
          },
          hide: !this.lead?.referral?.referralCode?.name,
        },
        {
          label: "Referral Code",
          value: {
            text: this.lead?.referral?.referralCode?.code,
            to: {
              name: AccountReferralCodeDetailsRoute,
              params: {
                accountReferralCode: this.lead?.referral?.referralCode?.id,
              } as AccountReferralCodeDetailsParams,
            },
          },
          hide: !this.lead?.referral?.referralCode?.code,
        },
        {
          label: "Introducer Contact",
          value: {
            text: this.lead?.referral?.contact?.profile.fullName,
            to: {
              name: IntroducerContactDetailsRoute,
              params: {
                introducerContact: this.lead?.referral?.contact?.id,
              } as IntroducerContactDetailsParams,
            },
          },
          hide: !this.lead?.referral?.contact?.profile.fullName,
        },
        {
          label: "External Lead ID",
          value: {
            text: this.lead?.externalLeadId,
          },
          hide: !this.lead?.externalLeadId,
        },
        // @todo strip html tags? or use a different component
        {
          label: "Description",
          value: {
            html: this.lead?.description,
          },
          hide: !this.lead?.description,
        },
        {
          label: "Account",
          value: {
            text: "Go to Account",
            to: {
              name: KinvaultKintinDetailsRoute,
              params: {
                kintin: this.lead?.referral?.$data.kintin,
              } as KinvaultKintinDetailsParams,
            },
          },
          hide: !this.lead?.referral?.$data.kintin,
        },
      ];
    },
  },
  methods: {
    handleAssignedToClick() {
      this.$emit("update-settings");
    },
    handleReferralClick() {
      this.$emit("manage-referral");
    },
  },
});
</script>
