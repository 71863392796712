<template>
  <div class="adviser-list">
    <PropertySummary
      v-for="role in properties"
      :key="`adviser-${role.id}`"
      :kintin="kintin"
      :role="role"
      @reload="$emit('reload')"
    />
    <Card v-if="!properties.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { Kintin, Property } from "@kinherit/sdk";
import { defineComponent } from "vue";
import PropertySummary from "../summary-cards/PropertySummary.vue";

export default defineComponent({
  name: "PropertyList",
  components: { Card, PropertySummary },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    properties: {
      type: Array as () => Property[],
      required: true,
    },
  },
});
</script>
