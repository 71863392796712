<template>
  <EmailLogPage v-if="lead" :owner="lead" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LeadDetailsEmailLogRoute } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";

export default defineComponent({
  name: LeadDetailsEmailLogRoute,
  mixins: [LeadDetailsMixin],
  components: {
    EmailLogPage,
  },
});
</script>
