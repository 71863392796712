<template>
  <p>
    <strong>Legacy - ISA (Individual Savings Account)</strong><br />
    I would ask my Trustees to consider utilising the following:
  </p>
  <ol type="i" v-if="data">
    <li v-for="gift in data.isaGifts" :key="gift.id">
      <span v-html="helpers.var(gift.notes)" /> for the benefit of
      <span v-html="helpers.var(helpers.formatRecipient(gift, false))" />
      <span v-html="helpers.var(helpers.beneficiaryTheirConcern(gift))" />
      <span v-if="gift.onSecondDeath">
        , only if my
        <span
          v-html="helpers.var(helpers.formatOfficer(data.partner, false))"
        />
        has predeceased me.
      </span>
    </li>
  </ol>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
