<template>
  <Card :title="address.summary" title-size="is-6" class="address-summary">
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', address, $event)"
      />
      <slot name="buttons" />
    </template>
    <ul>
      <li v-for="(profile, index) in profiles" :key="`person-${index}`">
        {{ profile.fullName ?? profile.organisationName }}
      </li>
    </ul>
  </Card>
</template>

<script lang="ts">
import { Address } from "@kinherit/sdk";
import { Kintin } from "@kinherit/sdk";
import { Profile } from "@kinherit/sdk";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddressSummary",
  props: {
    address: {
      type: Address as PropType<Address>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  components: { Card, Button },
  emits: {
    edit: (address: Address, event: MouseEvent) => !!address && !!event,
  },
  computed: {
    profiles(): Profile[] {
      if (!this.address.summary) {
        return [];
      }

      return Profile.$findBy({
        addresses: {
          summary: this.address.summary,
        },
      });
    },
  },
});
</script>
