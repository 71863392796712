<template>
  <NotePage
    v-if="introducerOutsource"
    :has-write-permission="hasWritePermission"
    :introducer-outsource="introducerOutsource"
    :note-types="[
      'note',
      'introducerNote',
      'kintinCheckNote',
      'kintinCheckFollowUp',
      'communicationNote',
    ]"
  />
</template>

<script lang="ts">
import NotePage from "@/module/core/component/note/NotePage.vue";
import { IntroducerOutsourceDetailsMixin } from "@/module/introducer.outsource/mixin/introducer-outsource-details.mixin";
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";
import { IntroducerOutsourceDetailsNotesRoute } from ".";
import { NotIn } from "@kinherit/orm/index";

export default defineComponent({
  name: IntroducerOutsourceDetailsNotesRoute,
  mixins: [IntroducerOutsourceDetailsMixin],
  components: { NotePage },
  computed: {
    hasWritePermission(): boolean {
      return AuthService.hasPermission("introducer-outsource:write");
    },
    NotIn: () => NotIn,
  },
});
</script>
