import { SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadGlobalSearchForm = (
  data: {
    name: null | string;
    address: null | string;
    emailAddress: null | string;
    phoneNumber: null | string;
    website: null | string;
  } = {
    name: null,
    address: null,
    emailAddress: null,
    phoneNumber: null,
    website: null,
  },
) =>
  MasterListFiltersForm({
    name: "read-global-search-form",
    data: () => data,
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      phoneNumber: [
        FormTextField({
          props: {
            label: "Phone Number",
          },
          models: {
            // value: "phoneNumber",
            value: {
              get: (data) => {
                return data.phoneNumber;
              },
              set: (value: string, data) => {
                data.phoneNumber = value.replace(/\D/g, "");
              },
            },
          },
        }),
      ],
      emailAddress: [
        FormTextField({
          props: {
            label: "Email Address",
          },
          models: {
            value: "emailAddress",
          },
        }),
      ],
      address: [
        FormTextField({
          props: {
            label: "Address",
          },
          models: {
            value: "address",
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      website: [
        FormUrlField({
          props: {
            label: "Website",
            noProtocol: true,
          },
          models: {
            value: "website",
          },
        }),
      ],
    }),
  });
