import { Module } from "@/config/module.config";

Module.register({
  name: "core",
  routes: [],
  actions: {},
  session: true,
  permissions: [],
});

export default {};
