<template>
  <PageHeader htag="h2" text="Orders">
    <template #buttons>
      <Button
        v-if="hasWritePermission"
        text="Create Order"
        @click="$emit('create-order')"
      />
    </template>
  </PageHeader>
  <MasterListPage
    :columns="columns"
    is-nested
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :empty-message="`No orders found.`"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    :sort-by-options="{
      createdAt: 'Created',
    }"
  />
</template>

<script lang="ts">
import { OrderTableColumns } from "@/config/table-columns.config";
import {
  OrderDetailsParams,
  OrderDetailsRoute,
} from "@/module/order/page/details";
import { AuthService } from "@/service/auth.service";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IOrder, Kintin, Order } from "@kinherit/sdk";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "OrdersPage",
  mixins: [AuthService.mixin()],
  components: {
    MasterListPage,
    PageHeader,
    Button,
  },
  emits: ["create-order"],
  props: {
    kintin: {
      type: Object as PropType<Kintin>,
      required: false,
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    columns: OrderTableColumns({
      name: false,
    }),
    rows: Array<Order>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IOrder,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(): Promise<void> {
      // const { orders, pagination } = await window.Kernel.ActionBus.execute(
      //   this.readAction as any,
      //   {
      //     ...this.readParams,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const { orders, pagination } =
        await window.Kernel.ActionBus2.order.order.read({
          kintin: this.kintin,
          sort: this.sort,
          pagination: this.pagination,
          paidWithin: null,
          assignedUser: [],
          paymentType: [],
          referral: [],
          status: [],
          created: null,
        });

      this.rows = orders;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async handleClick(order: Order, event: MouseEvent): Promise<void> {
      window.Kernel.visitRoute(
        {
          name: OrderDetailsRoute,
          params: {
            order: order.id,
          } as OrderDetailsParams,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>
