import { Between, Equal, In, Like } from "@kinherit/orm/index";
import {
  Api,
  Appointment,
  EmailAddress,
  IAppointment,
  Kintin,
  PhoneNumber,
  Profile,
  User,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export interface ReadAppointmentMessage {
  search?: string;
  specialist?: Array<string>;
  dateRange?:
    | "today_end-of-week"
    | "today_tomorrow"
    | "today"
    | "tomorrow"
    | "this-week"
    | "next-week"
    | "last-week"
    | "custom";
  type?: Array<string>;
  customDateRange?: [number, number];
  cancelled?: boolean | null;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  tags?: Array<string>;
  sort?: {
    by: keyof IAppointment;
    direction: "asc" | "desc";
  };
}

interface ReadAppointmentResponse {
  typeCounts: Array<{
    type: string;
    count: number;
    cancelled: boolean;
  }>;
  appointments: Array<Appointment>;
  profiles: Array<Profile>;
  kintins: Array<Kintin>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadAppointmentHandler = async (
  message: ReadAppointmentMessage,
): Promise<ReadAppointmentResponse> => {
  const request = Api.resource("portal", "/v2/portal/appointment")
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "desc",
    })
    .paginate(message.pagination ?? false);

  let dateRange: [number, number] | undefined;

  let startDate: DateTime | undefined;
  let endDate: DateTime | undefined;

  if (message.dateRange && "custom" !== message.dateRange) {
    dateRange = DateTime.dateRange(message.dateRange).map(
      (date) => date.timestamp,
    ) as [number, number];
  } else if ("custom" === message.dateRange) {
    dateRange = [
      message.customDateRange?.[0] ?? 0,
      message.customDateRange?.[1] ?? 0,
    ] as [number, number];
  }

  if (startDate && endDate) {
    dateRange = [
      startDate.setTime(0, 0, 0, 0).timestamp,
      endDate.setTime(23, 59, 59, 999).timestamp,
    ];
  }

  const query = message.search
    ? {
        type: {
          id: In(message.type),
        },
        kintin: {
          friendlyName: Like(message.search),
        },
        specialist: {
          id: In(message.specialist),
        },
        cancelled: Equal(
          null === message.cancelled ? undefined : message.cancelled,
        ),
        appointmentAt: Between(dateRange),
      }
    : {
        type: {
          id: In(message.type),
        },
        profile: {
          fullName: Like(message.search),
        },
        specialist: {
          id: In(message.specialist),
        },
        tags: {
          id: In(message.tags),
        },
        cancelled: Equal(message.cancelled),
        appointmentAt: Between(dateRange),
      };

  request.buildQuery(Appointment).where(query);

  const response = await request.result();

  const typesRequest = Api.resource(
    "portal",
    "/v2/portal/appointment/type",
  ).method("get");

  typesRequest.buildQuery(Appointment).where({
    ...query,
    type: undefined,
  });

  const typesResponse = await typesRequest.result();

  return {
    typeCounts: typesResponse.data.map((type) => ({
      ...type,
      count: Number(type.count),
      cancelled: 1 === type.cancelled,
    })),
    appointments: Appointment.$inflate(
      response.appointment,
      message.sort,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
    kintins: Kintin.$inflate(response.kintin),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    users: User.$inflate(response.user),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
