import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import { Between, Equal, IsNotNull, Like } from "@kinherit/orm/index";
import {
  Api,
  BrandedKinvaultSubscriptionReferral,
  BrandedKinvaultSubscriptionReferralCode,
  EmailAddress,
  IBrandedKinvaultSubscriptionReferral,
  KintinPrice,
  KintinSubscription,
  KintinSubscriptionIntent,
  Profile,
} from "@kinherit/sdk";

export interface ReadBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvault: string;
  status: string | null;
  search: string;
  created: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IBrandedKinvaultSubscriptionReferral;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferrals: BrandedKinvaultSubscriptionReferral[];
  brandedKinvaultSubscriptionReferralCodes: BrandedKinvaultSubscriptionReferralCode[];
  kintinSubscriptionIntent: KintinSubscriptionIntent[];
  kintinSubscription: KintinSubscription[];
  profiles: Profile[];
  emailAddresses: EmailAddress[];
  kintinPrices: KintinPrice[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadBrandedKinvaultSubscriptionReferralHandler = async (
  message: ReadBrandedKinvaultSubscriptionReferralMessage,
): Promise<ReadBrandedKinvaultSubscriptionReferralResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral",
  )
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  const shared = {
    referralCode: {
      brandedKinvault: Equal(message.brandedKinvault),
    },
    createdAt: Between(message.created),
    subscription: {
      id: IsNotNull(),
      status: {
        id: Equal(message.status),
      },
    },
  };

  if (!message.search) {
    request.buildQuery(BrandedKinvaultSubscriptionReferral).where({
      ...shared,
    });
  } else {
    request.buildQuery(BrandedKinvaultSubscriptionReferral).where([
      {
        ...shared,
        subscriptionIntent: {
          primaryProfile: {
            fullName: Like(message.search),
          },
        },
      },
      {
        ...shared,
        subscriptionIntent: {
          primaryProfile: {
            emails: {
              email: Like(message.search),
            },
          },
        },
      },
      {
        ...shared,
        subscriptionIntent: {
          secondaryProfile: {
            fullName: Like(message.search),
          },
        },
      },
      {
        ...shared,
        subscriptionIntent: {
          secondaryProfile: {
            emails: {
              email: Like(message.search),
            },
          },
        },
      },
    ]);
  }

  const response = await request.result();

  return {
    brandedKinvaultSubscriptionReferrals:
      BrandedKinvaultSubscriptionReferral.$inflate(
        response.brandedKinvaultSubscriptionReferral,
        message.sort,
        response.$rootIds,
      ),
    brandedKinvaultSubscriptionReferralCodes:
      BrandedKinvaultSubscriptionReferralCode.$inflate(
        response.brandedKinvaultSubscriptionReferralCode,
      ),
    kintinSubscriptionIntent: KintinSubscriptionIntent.$inflate(
      response.kintinSubscriptionIntent,
    ),
    kintinSubscription: KintinSubscription.$inflate(
      response.kintinSubscription,
    ),
    profiles: Profile.$inflate(response.profile),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    kintinPrices: KintinPrice.$inflate(response.kintinPrice),
    pagination: getPagination(response),
  };
};
