<template>
  <PageHeader htag="h1" text="Global Search" />
  <MasterListPage
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    @row-clicked="handleClick"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    :track-cahnges="false"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  />
</template>

<script lang="ts">
import { ReadGlobalSearchForm } from "@/module/dashboard/form/read-global-search.form";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IProfile, Profile } from "@kinherit/sdk";
import { findColorInvert, stringToColor } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { DashboardSearchRoute } from ".";

const ProfileTypes = {
  appointment: "Appointment",
  cpdSessionRegistration: "CPD Session Registration",
  introducerBillingContact: "Introducer Billing Contact",
  introducerCompany: "Introducer Company",
  introducerContact: "Introducer Contact",
  introducerContactAssistant: "Introducer Contact Assistant",
  lead: "Lead",
  brandedKinvault: "Branded Kinvault",
  person: "Person",
  proPartner: "Pro Partner",
  trustReg: "Trust Reg",
  trustRegOtherContact: "Trust Reg Other Contact",
  trustRegPerson: "Trust Reg Person",
  trustRegPrimaryContact: "Trust Reg Primary Contact",
  user: "User",
};

const mark = (searchTerm: null | string, result?: string | null) => {
  if (!result) {
    return "";
  }

  if (!searchTerm) {
    return result;
  }

  const regex = new RegExp(searchTerm, "gi");
  return result.replace(regex, (match) => `<mark>${match}</mark>`);
};

type Row = {
  name: string | null;
  emails: string;
  phoneNumbers: string;
  addresses: string;
  websites: string;
  createdAt: string;
  updatedAt?: string;
  profile: Profile;
};

export default defineComponent({
  name: DashboardSearchRoute,
  components: { MasterListPage, PageHeader },
  mixins: [AuthService.mixin()],
  data: () => ({
    filters: ReadGlobalSearchForm(),
    columns: [
      {
        mapHtml: (row: Row) => {
          const type = Object.keys(ProfileTypes).find(
            (key) =>
              ![null, undefined].includes(
                row.profile.$data[key as keyof IProfile] as never,
              ),
          );

          const background = stringToColor(type || "unknown");
          const color = findColorInvert(background);

          return `<span class="badge is-block" style="background: ${background}; color: ${color}">${
            type ? ProfileTypes[type as keyof typeof ProfileTypes] : "Unknown"
          }</span>`;
        },
      },
      {
        title: "Name",
        mapHtml: (row: Row) => row.name,
      },
      {
        title: "Email",
        mapHtml: (row: Row) => row.emails,
      },
      {
        title: "Phone",
        mapHtml: (row: Row) => row.phoneNumbers,
      },
      {
        title: "Address",
        mapHtml: (row: Row) => row.addresses,
      },
      {
        title: "Website",
        mapHtml: (row: Row) => row.websites,
      },
      {
        title: "Created",
        field: "createdAt",
      },
      {
        title: "Updated",
        field: "updatedAt",
      },
    ] satisfies Array<TableColumn>,
    rows: Array<Row>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof Row,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("tag:write");
    },
  },
  methods: {
    findColorInvert,
    stringToColor,
    handleClick(row: { profile: Profile }, event: MouseEvent): void {
      const kintin = row.profile.person?.$data.kintin;

      if (kintin) {
        window.Kernel.visitRoute(
          {
            name: KinvaultKintinDetailsRoute,
            params: { kintin } as KinvaultKintinDetailsParams,
          },
          event.ctrlKey,
        );
      }
    },
    async refresh(
      formData: ReturnType<typeof ReadGlobalSearchForm>["localData"],
    ): Promise<void> {
      if (
        !formData.name &&
        !formData.emailAddress &&
        !formData.phoneNumber &&
        !formData.address &&
        !formData.website
      ) {
        this.rows = [];
        this.pagination.currentPage = 1;
        this.pagination.lastPage = 0;
        this.pagination.count = 0;
        this.$forceUpdate();
        return;
      }

      // const data = await window.Kernel.ActionBus.execute(
      //   "dashboard/global-search/profile/read",
      //   {
      //     ...formData,
      //     sort: {
      //       by: this.sort.by as keyof IProfile,
      //       direction: this.sort.direction,
      //     },
      //     pagination: this.pagination,
      //   },
      // );
      const data =
        await window.Kernel.ActionBus2.dashboard.globalSearch.profile.read({
          ...formData,
          sort: {
            by: this.sort.by as keyof IProfile,
            direction: this.sort.direction,
          },
          pagination: this.pagination,
        });

      this.rows = data.profiles
        .map((row) => ({
          name: row.fullName ?? row.organisationName,
          emails: row.emails
            .map((e) => e.email)
            .filter(Boolean)
            .join(", "),
          phoneNumbers: row.phoneNumbers
            .map((e) => e.tel)
            .filter(Boolean)
            .join(", "),
          addresses: row.addresses
            .map((e) => e.summary)
            .filter(Boolean)
            .join(", "),
          websites: row.websites
            .map((e) => e.url)
            .filter(Boolean)
            .join(", "),
          createdAt: row.createdAt.formatDateTime,
          updatedAt: row.updatedAt?.formatDateTime,
          profile: row,
        }))
        .map(
          ({
            name,
            emails,
            phoneNumbers,
            addresses,
            websites,
            createdAt,
            updatedAt,
            profile,
          }) => ({
            name: mark(formData.name, name),
            emails: mark(formData.emailAddress, emails),
            phoneNumbers: mark(formData.phoneNumber, phoneNumbers),
            addresses: mark(formData.address, addresses),
            websites: mark(formData.website, websites),
            createdAt,
            updatedAt,
            profile,
          }),
        );
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
