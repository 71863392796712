import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import { IsNotNull } from "@kinherit/orm";
import {
  Address,
  Adviser,
  Attorney,
  Beneficiary,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  IntroducerContact,
  KintinCheck,
  Option,
  Person,
  Trustee,
  User,
} from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common";
import { defineComponent } from "vue";

export const ClientSummaryMixin = defineComponent({
  name: `ClientSummaryMixin`,
  mixins: [KinvaultKintinDetailsMixin],
  computed: {
    isAdmin(): boolean {
      return AuthService.hasPermission("kintin:write");
    },
    advguardiannotes(): string | null {
      return this.kintin?.callScript.advGuardianNotes ?? null;
    },
    advbeneficiarynotes(): string | null {
      return this.kintin?.callScript.advBeneficiaryNotes ?? null;
    },
    advexclusionnotes(): string | null {
      return this.kintin?.callScript.advExclusionNotes ?? null;
    },
    advifanotes(): string | null {
      return this.kintin?.callScript.advIfaNotes ?? null;
    },
    advgiftnotes(): string | null {
      return this.kintin?.callScript.advGiftNotes ?? null;
    },
    advexecutornotes(): string | null {
      return this.kintin?.callScript.advExecutorNotes ?? null;
    },
    advtrusteennotes(): string | null {
      return this.kintin?.callScript.advTrusteenNotes ?? null;
    },
    advattorneyennotes(): string | null {
      return this.kintin?.callScript.advAttorneyenNotes ?? null;
    },
    advbusinessnotes(): string | null {
      return this.kintin?.callScript.advBusinessNotes ?? null;
    },
    type(): "joint" | "single" | null {
      return (this.kintin?.type.value ?? null) as never;
    },
    referringintroducer(): IntroducerContact | null {
      return this.kintin?.referral?.contact ?? null;
    },
    gifts(): Gift[] {
      return this.kintin?.gifts ?? [];
    },
    exclusions(): Exclusion[] {
      return this.kintin?.exclusions ?? [];
    },
    advbusinessassetvalue(): Currency | null {
      return this.kintin?.callScript?.advBusinessAssetValue ?? null;
    },
    specialistid(): User[] {
      return this.kintin?.estatePlanners ?? [];
    },
    primary(): Person | null {
      return this.kintin?.primaryPerson ?? null;
    },
    secondary(): Person | null {
      return this.kintin?.secondaryPerson ?? null;
    },
    primarySalutation(): string | null {
      const primary = this.primary;
      return primary?.profile.knownAs ?? primary?.profile.firstName ?? null;
    },
    secondarySalutation(): string | null {
      const secondary = this.secondary;
      return (
        secondary?.profile.knownAs ?? secondary?.profile?.firstName ?? null
      );
    },
    accountSalutation(): string | null {
      return this.kintin?.friendlyName ?? null;
    },
    children(): Person[] {
      return [
        ...Person.$findBy({
          kintin: {
            id: this.kintin?.id,
          },
          relationToPrimaryPerson: {
            id: IsNotNull(),
          },
        }),
        ...Person.$findBy({
          kintin: {
            id: this.kintin?.id,
          },
          relationToSecondaryPerson: {
            id: IsNotNull(),
          },
        }),
      ]
        .filter(
          (c) =>
            c.relationToPrimaryPerson?.data?.isChild ||
            c.relationToSecondaryPerson?.data?.isChild,
        )
        .unique("id");
    },
    childrenU18(): Person[] {
      return this.children.filter((p) => p.under18ForPlanningPurposes);
    },
    dependents(): Person[] {
      return this.children.filter(
        (p) => p.under18ForPlanningPurposes || p.requiresCare,
      );
    },
    guardiansAreSameForAllChildren(): boolean {
      const guardians: string[] = [];
      let match = true;

      this.children.forEach((p, i) => {
        if (0 === i) {
          guardians.push(...p.appointedGuardian.pluck("id"));
        } else {
          p.appointedGuardian.forEach((g) => {
            if (!guardians.includes(g.id)) {
              match = false;
            }
          });
        }
      });

      return match;
    },
    mainGuardians(): Guardian[] {
      return (
        this.kintin?.people
          .pluck("appointedGuardian")
          .flat()
          .filter((g: Guardian): Guardian => g)
          .filter((g) => !g.isReserve) ?? []
      );
    },
    reserveGuardians(): Guardian[] {
      return (
        this.kintin?.people
          .pluck("appointedGuardian")
          .flat()
          .filter((g: Guardian): Guardian => g)
          .filter((g) => g.isReserve) ?? []
      );
    },
    primaryAddress(): Address | null {
      const allAddresses = [
        ...(this.kintin?.primaryPerson.profile.addresses ?? []),
        ...(this.kintin?.secondaryPerson?.profile.addresses ?? []),
      ];
      const filteredAddresses = allAddresses.filter(
        (a) => a.primaryResidential || a.primaryMailing,
      );

      return filteredAddresses.first() ?? allAddresses.first() ?? null;
    },
    assetnetTotal(): Currency {
      const callScript = this.kintin?.callScript;

      if (!callScript) {
        return new Currency({ amount: 0 });
      }

      const amount =
        (callScript.advSavingsValue?.amount ?? 0) +
        (callScript.advPropsValue?.amount ?? 0) +
        (callScript.advPolicyValue?.amount ?? 0) +
        (callScript.advPensionValue?.amount ?? 0) +
        (callScript.advOtherAssetValue?.amount ?? 0) +
        (callScript.advBusinessAssetValue?.amount ?? 0) +
        (callScript.advPotentialValue?.amount ?? 0) +
        (callScript.advDebtValue?.amount ?? 0);

      return new Currency({ amount });
    },
    primaryGifts(): Gift[] {
      return Gift.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
        sentBy: {
          id: this.kintin?.primaryPerson.id,
        },
        onSecondDeath: false,
      });
    },
    defaultDocumentPassword(): string {
      const dob = this.primary?.dateOfBirth;

      if (!dob) {
        return `Unknown`;
      }

      const year = dob?.getDate().getFullYear().toString().slice(2);

      let month = (dob?.getDate().getMonth() + 1).toString();
      month = month.length === 1 ? `0${month}` : month;

      let day = dob?.getDate().getDate().toString();
      day = day?.length === 0 ? `0${day}` : day;

      return `${day}${month}${year}`;
    },
    secondaryGifts(): Gift[] {
      if (!this.kintin?.secondaryPerson) {
        return [];
      }

      return Gift.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
        sentBy: {
          id: this.kintin?.secondaryPerson?.id,
        },
        onSecondDeath: false,
      });
    },
    jointGifts(): Gift[] {
      return Gift.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
        onSecondDeath: true,
      });
    },
    concatPrimaryBeneficiaries1(): Beneficiary[] {
      return Beneficiary.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        position: 0,
      });
    },
    concatSecondaryBeneficiaries1(): Beneficiary[] {
      return Beneficiary.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        position: 0,
      });
    },
    concatPrimaryBeneficiaries2(): Beneficiary[] {
      return Beneficiary.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        position: 1,
      });
    },
    concatSecondaryBeneficiaries2(): Beneficiary[] {
      return Beneficiary.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        position: 1,
      });
    },
    concatPrimaryReserveBeneficiaries(): Beneficiary[] {
      return Beneficiary.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        position: 2,
      });
    },
    concatSecondaryReserveBeneficiaries(): Beneficiary[] {
      return Beneficiary.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        position: 2,
      });
    },
    primaryMainExecutors(): Executor[] {
      return Executor.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isReserve: false,
      });
    },
    primaryReserveExecutors(): Executor[] {
      return Executor.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isReserve: true,
      });
    },
    secondaryMainExecutors(): Executor[] {
      return Executor.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isReserve: false,
      });
    },
    secondaryReserveExecutors(): Executor[] {
      return Executor.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isReserve: true,
      });
    },
    primaryAttorneys(): Attorney[] {
      return Attorney.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isReserve: false,
        isCertProv: false,
      });
    },
    primaryReserveAttorneys(): Attorney[] {
      return Attorney.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isReserve: true,
        isCertProv: false,
      });
    },
    primaryCertProv(): Attorney[] {
      return Attorney.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isCertProv: true,
      });
    },
    secondaryAttorneys(): Attorney[] {
      return Attorney.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isReserve: false,
        isCertProv: false,
      });
    },
    secondaryReserveAttorneys(): Attorney[] {
      return Attorney.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isReserve: true,
        isCertProv: false,
      });
    },
    secondaryCertProv(): Attorney[] {
      return Attorney.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isCertProv: true,
      });
    },
    primaryTrustees(): Trustee[] {
      return Trustee.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isReserve: false,
      });
    },
    primaryReserveTrustees(): Trustee[] {
      return Trustee.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson.id,
        },
        isReserve: true,
      });
    },
    secondaryTrustees(): Trustee[] {
      return Trustee.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isReserve: false,
      });
    },
    secondaryReserveTrustees(): Trustee[] {
      return Trustee.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
        isReserve: true,
      });
    },
    primaryfinancialAdvisers(): Adviser[] {
      return Adviser.$findBy({
        forPerson: {
          id: this.kintin?.primaryPerson?.id,
        },
      });
    },
    secondaryfinancialAdvisers(): Adviser[] {
      return Adviser.$findBy({
        forPerson: {
          id: this.kintin?.secondaryPerson?.id,
        },
      });
    },
    alldependents(): Person[] {
      const under18 = [
        ...Person.$findBy({
          kintin: {
            id: this.kintin?.id,
          },
          relationToPrimaryPerson: {
            id: IsNotNull(),
          },
          under18ForPlanningPurposes: true,
        }),
        ...Person.$findBy({
          kintin: {
            id: this.kintin?.id,
          },
          relationToSecondaryPerson: {
            id: IsNotNull(),
          },
          under18ForPlanningPurposes: true,
        }),
      ];

      const requiresCare = [
        ...Person.$findBy({
          kintin: {
            id: this.kintin?.id,
          },
          relationToPrimaryPerson: {
            id: IsNotNull(),
          },
          requiresCare: true,
        }),
        ...Person.$findBy({
          kintin: {
            id: this.kintin?.id,
          },
          relationToSecondaryPerson: {
            id: IsNotNull(),
          },
          requiresCare: true,
        }),
      ];

      return [...under18, ...requiresCare].unique("id");
    },
    childrenWithGuardians(): {
      person: Person;
      main: Guardian[];
      reserve: Guardian[];
    }[] {
      return (
        this.kintin?.people
          .filter((p) => p.appointedGuardian.isNotEmpty())
          .map((person) => ({
            person,
            main: person.appointedGuardian.filter((g) => !g.isReserve),
            reserve: person.appointedGuardian.filter((g) => g.isReserve),
          })) ?? []
      );
    },
    primaryExclusions(): Exclusion[] {
      return Exclusion.$findBy({
        excludedBy: {
          id: this.kintin?.primaryPerson.id,
        },
      });
    },
    secondaryExclusions(): Exclusion[] {
      return Exclusion.$findBy({
        excludedBy: {
          id: this.kintin?.secondaryPerson?.id,
        },
      });
    },
  },
  methods: {
    async saveAdvancedNotes(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/call-script/update",
      //   {
      //     kintin: this.kintin,
      //   },
      // );
      await window.Kernel.ActionBus2.kinvaultKintin.callScript.update({
        kintin: this.kintin,
      });
    },
    forYou(): string {
      if (this.type === "joint") {
        return `For ${this.primarySalutation} you`;
      }

      return `You`;
    },
    generateRelationshipStatus(): string {
      if (this.type === "single") {
        return "You are looking for a single Will for just yourself";
      }
      if (
        this.type === "joint" &&
        this.primary?.maritalStatus?.value === "married" &&
        this.primary?.maritalStatus?.value === "married"
      ) {
        return "You are married and looking for a mirror Will for each of you";
      }
      // if (
      //   this.type === "joint" &&
      //   this.primary?.maritalStatus?.value === "engaged" &&
      //   this.primary?.maritalStatus?.value === "engaged"
      // ) {
      //   return "You are engaged and looking for a mirror Will for each of you";
      // }
      if (
        this.type === "joint" &&
        this.primary?.maritalStatus?.value === "civil" &&
        this.primary?.maritalStatus?.value === "civil"
      ) {
        return "You are in a civil partnership and looking for a mirror Will for each of you";
      }

      // const primaryRel = this.primary?.relationToSecondaryPerson;

      // if (this.type === "joint" && !!primaryRel) {
      //   return "You are about to get married and looking for a mirror Will for each of you";
      // }
      if (this.type === "joint") {
        return "You are in a relationship and looking for a mirror Will for each of you";
      }
      return "[NO VALUE]";
    },
    generateChildStatus(): string | undefined {
      if (this.children.length === 0) {
        return "You have no children";
      }
      if (this.children.length === 1) {
        if (this.childrenU18.length === 1) {
          return `You have one child who is under 18${this.generatePreviousRelationship()}`;
        } else {
          return `You have one child who is over 18${this.generatePreviousRelationship()}`;
        }
      }
      if (this.children.length === 2) {
        if (this.childrenU18.length === 1) {
          return `You have two children${this.generatePreviousRelationship()}, one of which is under 18`;
        }
        if (this.childrenU18.length === 2) {
          return `You have two children under 18${this.generatePreviousRelationship()}`;
        } else {
          return `You have two children over 18${this.generatePreviousRelationship()}`;
        }
      }
      if (this.children.length > 2) {
        return `You have ${this.returnTextFromNumber(
          this.children.length,
        )} children, ${this.generateChildU18Status()}${this.generatePreviousRelationship()}`;
      }
    },
    generateChildU18Status(): string | undefined {
      if (this.childrenU18.length === 0) {
        return " and they are all over 18";
      }
      if (this.childrenU18.length === 1) {
        return " and one of them is under 18";
      }
      if (
        this.childrenU18.length === this.children.length &&
        this.children.length === 2
      ) {
        return " and both of them are under 18";
      }
      if (this.childrenU18.length === this.children.length) {
        return " and all of them are under 18";
      }
      if (this.childrenU18.length > 1) {
        return ` of which ${this.returnTextFromNumber(
          this.childrenU18.length,
        )} of them are under 18`;
      }
    },
    generatePreviousRelationship(): string {
      if (this.checkIsYes("2.5.0b")) {
        if (this.children.length === 1) {
          return " from a previous relationship";
        }
        if (this.children.length > 1) {
          return ", including children from previous relationships";
        }
      }
      return "";
    },
    generateDependents(): string | undefined {
      // Your son James is also a dependant
      if (this.dependents.length === 1) {
        return `${this.dependents[0].profile.firstName} is also a dependant.`;
      }
      if (this.dependents.length > 1) {
        return `${this.listOfFirstNames(this.dependents)} are also dependants.`;
      }
    },
    generateHomeOwner(): string {
      console.log("generateHomeOwner", {
        "3.0.0a Is Yes": this.checkIsYes("3.0.0a"),
        "3.0.2a Is No": this.checkIsNo("3.0.2a"),
      });
      if (this.checkIsYes("3.0.0a") && this.checkIsYes("3.0.2a")) {
        return `You currently own your main residence at ${this.primaryAddress?.summary}, and own additional properties`;
      }
      if (this.checkIsYes("3.0.0a") && this.checkIsNo("3.0.2a")) {
        return `You currently own your main residence at ${this.primaryAddress?.summary}, and don't own any additional properties`;
      }
      if (this.checkIsNo("3.0.0a") && this.checkIsNo("3.0.2a")) {
        return "You currently don't own your own home or any other properties";
      }
      if (this.checkIsNo("3.0.0a") && this.checkIsYes("3.0.2a")) {
        return "You currently don't own your own home but do own other properties";
      }
      return "[HOME OWNERSHIP or ADDITIONAL PROPERTIES NOT DEFINED]";
    },
    generateExclusions(): string {
      return this.exclusions
        .map((e) => e.who)
        .join(",")
        .replace(/, ([^,]*)$/, " and $1");
    },
    shareOrPercentage(b: Beneficiary) {
      if (!b) return "[ERROR]";
      if (b.sharePercentage) {
        return b.sharePercentage + "%";
      }
      if (b.shareFraction) {
        return b.shareFraction;
      }
      return "Equal Share";
    },
    outputGiftDetails(b: Gift) {
      if (!b) return "[ERROR]";

      let rv = "" as string | null;
      switch (b.type.value) {
        case "fixedamount":
        case "repayment":
          rv = `to receive ${b.amount?.format}`;
          break;
        case "business":
          rv = "Business or Agricultural Property";
          break;
        case "property":
          rv = b.asPrimaryResidence
            ? "my primary residence"
            : `the property at ${b.address?.summary}`;
          break;
        case "bankaccount":
          rv = `the contents of ${b.notes}`;
          break;
        default:
          rv = b.notes;
          break;
      }
      if (b.ifPredeceased?.value === "theirconcern") {
        rv +=
          ", and should they be predeceased, to pass to their issue or remoter issue";
      }
      return rv;
    },
    returnTextFromNumber(number: number) {
      if (number === 1) {
        return "one";
      }
      if (number === 2) {
        return "two";
      }
      if (number === 3) {
        return "three";
      }
      if (number === 4) {
        return "four";
      }
      if (number === 5) {
        return "five";
      }
      if (number === 6) {
        return "six";
      }
      if (number === 7) {
        return "seven";
      }
      return "[More than 7]";
    },
    listOfFirstNames(people: Person[]) {
      return people
        .map((person) => {
          return person.type === "charity"
            ? person.profile.organisationName
            : person.type === "company"
              ? person.profile.organisationName
              : person.profile.fullName
                ? person.profile.fullName
                : person.profile.firstName;
        })
        .join(", ")
        .replace(/, ([^,]*)$/, " and $1");
    },
    listOfNames(
      people: (Person | Adviser | Executor | Guardian | Trustee | Attorney)[],
    ) {
      return people
        .map((x) => {
          const person =
            x instanceof Adviser
              ? x.person
              : x instanceof Executor
                ? x.person
                : x instanceof Guardian
                  ? x.person
                  : x instanceof Trustee
                    ? x.person
                    : x instanceof Attorney
                      ? x.person
                      : x;

          if (!person) {
            console.log("person?", person);
            return "[ERROR]";
          }

          return person.type === "charity"
            ? person.profile.organisationName
            : person.type === "company"
              ? person.profile.organisationName
              : person.profile.fullName;
        })
        .join(", ")
        .replace(/, ([^,]*)$/, " and $1");
    },
    beneficiaryName(bene: Beneficiary | Gift) {
      const person = bene instanceof Beneficiary ? bene.person : bene.forPerson;
      const classGift =
        bene instanceof Beneficiary || bene instanceof Gift
          ? bene.classGiftType
          : false;

      if (!person && !classGift) return "ERROR";

      if (classGift) {
        // Replace My with Your: bit of a hack this.
        return (
          bene.classGiftType?.text.replace("My", "Your") ?? "[CLASSGIFT ERROR]"
        );
      }

      if (person?.type === "charity") {
        return person.profile.organisationName;
      }
      if (person?.type === "company") {
        return person?.profile.organisationName;
      }
      if (person?.profile.fullName && bene instanceof Beneficiary) {
        // @to-do - this assumes it's always the primary person we're outputting
        const personType = bene.forPerson.kintinPersonType?.ucFirst() as
          | "Primary"
          | "Secondary";

        const primaryRelation = personType
          ? bene.person?.[`relationTo${personType}Person`]
          : null;

        return (
          "your " +
          this.formatRelationship(primaryRelation ?? null) +
          " " +
          person.profile.fullName
        );
      }
      if (person?.profile.fullName) {
        return person.profile.fullName;
      }
      if (person?.profile.firstName) {
        return person?.profile.firstName;
      }
      return "[UNKNOWN]";
    },
    formatRelationship(relationship: Option | null) {
      return relationship?.text ?? "[RELATIONSHIP NOT SET]";
    },
    charityListOfNames(people: Person[]) {
      return people
        .pluck("profile")
        .pluck("organisationName")
        .filter(Boolean)
        .join(", ", " and ");
    },
    checkValueByStep(step: `${string}.${string}.${string}`): number | null {
      return (
        KintinCheck.$findOneBy({
          kintin: {
            id: this.kintin?.id,
          },
          step,
        })?.checkValue ?? null
      );
    },
    checkIsYes(step: `${string}.${string}.${string}`): boolean {
      return this.checkValueByStep(step) === 1;
    },
    checkIsNo(step: `${string}.${string}.${string}`): boolean {
      return this.checkValueByStep(step) === 0;
    },
    checkIsMaybe(step: `${string}.${string}.${string}`): boolean {
      return this.checkValueByStep(step) === 2;
    },
  },
});
