import { Api } from "@/service/api.service";
import { Order, OrderItem } from "@kinherit/sdk";

export interface CreateOrdersMessage {
  order: Order;
}

export interface CreateOrdersResponse {
  order: Order;
  orderItems: Array<OrderItem>;
}

export const CreateOrderHandler = async (
  message: CreateOrdersMessage,
): Promise<CreateOrdersResponse> => {
  const request = Api.resource("portal", "/v2/portal/order")
    .method("post")

    .body({
      kintin: message.order.$data.kintin,
      status: message.order.$data.status,
      paymentType: message.order.$data.paymentType,
      extrasPrice: message.order.$data.extrasPrice,
      discountValue: message.order.$data.discountValue,
      discountPercentage: message.order.$data.discountPercentage,
      coolingOff: message.order.$data.coolingOff,
      paidAt: message.order.$data.paidAt,
      invoiceNumber: message.order.$data.invoiceNumber,
      notes: message.order.$data.notes,
      paymentSignKey: message.order.$data.paymentSignKey,
      transactionId: message.order.$data.transactionId,
      overrideListPrice: message.order.$data.overrideListPrice,
      feesPayOutIntro: message.order.$data.feesPayOutIntro,
      feesPayoutAgent: message.order.$data.feesPayoutAgent,
      feesPayOutIntroDate: message.order.$data.feesPayOutIntroDate,
      feesPayOutAgentDate: message.order.$data.feesPayOutAgentDate,
      feesPayOutParentAgent: message.order.$data.feesPayOutParentAgent,
      feesPayOutParentAgentDate: message.order.$data.feesPayOutParentAgentDate,
      feesConfirmed: message.order.$data.feesConfirmed,
      discountReason: message.order.$data.discountReason,
      xeroIntroducerBillId: message.order.$data.xeroIntroducerBillId,
      xeroAgentBillId: message.order.$data.xeroAgentBillId,
      billingName: message.order.$data.billingName,
      billingAddress1: message.order.$data.billingAddress1,
      billingAddress2: message.order.$data.billingAddress2,
      billingCity: message.order.$data.billingCity,
      billingRegion: message.order.$data.billingRegion,
      billingPostalCode: message.order.$data.billingPostalCode,
      xeroParentAgentBillId: message.order.$data.xeroParentAgentBillId,
      createdBy: message.order.$data.createdBy,
    });

  const response = await request.result();
  const order = Order.$inflate(response.order).first();

  if (!order) {
    throw new Error("Failed to create order");
  }

  const orderItems = message.order.orderItems;

  await orderItems.forEachAsync(async (orderItem) => {
    // await window.Kernel.ActionBus.execute("order/order-item/create", {
    //   order,
    //   orderItem,
    // });
    await window.Kernel.ActionBus2.order.orderItem.create({
      order: order,
      orderItem: orderItem,
    });
  });

  // await window.Kernel.ActionBus.execute("order/order/record", {
  //   order: order,
  // });
  await window.Kernel.ActionBus2.order.order.record({
    order: order,
  });

  message.order.$delete({
    orderItems: true,
  });

  return {
    order: Order.$findOneOrThrow(order.id),
    orderItems: OrderItem.$findBy({
      order: {
        id: order.id,
      },
    }),
  };
};
