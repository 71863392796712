<template>
  <NotePage
    v-if="kintin"
    :has-write-permission="hasWritePermission"
    :kintin="kintin"
    :note-types="[
      'note',
      'introducerNote',
      'kintinCheckNote',
      'kintinCheckFollowUp',
      'communicationNote',
    ]"
  />
</template>

<script lang="ts">
import NotePage from "@/module/core/component/note/NotePage.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsNotesRoute } from ".";
import { NotIn } from "@kinherit/orm/index";

export default defineComponent({
  name: KinvaultKintinDetailsNotesRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: { NotePage },
  computed: {
    hasWritePermission(): boolean {
      return AuthService.hasPermission("kintin:write");
    },
    NotIn: () => NotIn,
  },
});
</script>
