import { PersonField, SharedFormProps } from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In, NotEqual } from "@kinherit/orm";
import { Executor, Kintin } from "@kinherit/sdk";
import { AddKintinPersonButton } from "./shared.form";

export const UpdateExecutorForm = ({
  data,
  kintin,
}: {
  data: Executor;
  kintin: Kintin;
}) => {
  return defineForm({
    name: "update-executor-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-executor-form-area",
        data,
        template: GridLayout([
          ["person", "forPerson"],
          { is: "hr" },
          ["notes"],
          ["isReserve", "professionalSupport"],
        ]),
        components: () => ({
          person: [
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.primaryPerson,
                label: "Who is the executor?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.primaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.secondaryPerson,
                label: "Who is the executor?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.secondaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
          ],
          forPerson: [
            PersonField({
              reactive: true,
              props: {
                label: (data) =>
                  `Who is ${
                    data.person?.profile?.fullName ?? `this person`
                  } an executor for?`,
                validators: ["required"],
                vModel: {
                  get: (data) => data.forPerson,
                  set: (value, data) => {
                    data.forPerson = value;

                    if (data.forPerson?.id === data.person?.id) {
                      data.person = null as any;
                    }
                  },
                },
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          isReserve: [
            FormCheckboxField({
              props: {
                label: "Add as Reserve executor",
                size: "is-medium",
              },
              models: {
                value: "isReserve",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
                placeholder: "Additional notes about this executor",
              },
              models: {
                value: "notes",
              },
            }),
          ],
          professionalSupport: [
            FormCheckboxField({
              props: {
                label: "Requires Professional Support",
                size: "is-medium",
              },
              models: {
                value: "professionalSupport",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
