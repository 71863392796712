<template>
  <PageHeader htag="h1" text="Introducer Fees" />
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="data"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    :sort-by-options="{
      createdAt: 'Created',
      paidAt: 'Paid',
    }"
    :row-classes="
      (line: any) => ({
        'has-background-success-light':
          (line.row as ReadKintinIntroducerFeeResponse['data'][number]).data
            .order.feesConfirmed === true,
      })
    "
    :tracking-field="
      (row: ReadKintinIntroducerFeeResponse['data'][number]) =>
        row.data.order.id
    "
    click-to-expand
    v-model:value="selected"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #tableControls>
      <Button :text="`Download`" size="is-small" @click="downloadCsv" />
      <Button
        :text="`Bulk Actions ${
          selected.length > 0 ? `(${selected.length})` : ''
        }`"
        :color="selected.length > 0 ? 'is-positive' : null"
        size="is-small"
        :is-disabled="selected.length === 0"
        @click="bulkActions"
      />
    </template>
    <template #actions="{ row }">
      <Button
        @click="updateOrder(row.data.order.id)"
        class="details-button"
        :icon-left="$style.icon.edit.icon"
        aria-label="Edit"
        size="is-small"
        color="is-plain"
        tooltip="Edit"
      />
    </template>
    <template #details="{ row }">
      <IntroducerFeeDetails :data="row" />
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { IntroducerFeesTableColumns } from "@/config/table-columns.config";
import { ReadIntroducerFeeKintinForm } from "@/module/introducer.fee.kintin/form/read-introducer-fee-kintin.form";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  AccountReferralCode,
  IOrder,
  IntroducerCompany,
  IntroducerContact,
  IntroducerContract,
  Kintin,
  Order,
  Profile,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerFeeKintinMasterListRoute } from ".";
import { ReadKintinIntroducerFeeResponse } from "../action/read-introducer-fee-kintin.action";
import IntroducerFeeDetails from "../component/IntroducerFeeDetails.vue";
import { UpdateIntroducerFeeKintinForm } from "../form/update-introducer-fee-kintin.form";

import { UpdateOrderForm } from "@/module/order/form/update-order.form";
import { StyleService } from "@/service/style.service";
export default defineComponent({
  name: IntroducerFeeKintinMasterListRoute,
  components: { MasterListPage, Button, PageHeader, IntroducerFeeDetails },
  mixins: [StyleService.mixin],
  data: () => ({
    filters: ReadIntroducerFeeKintinForm(),
    columns: IntroducerFeesTableColumns(),
    data: [] as ReadKintinIntroducerFeeResponse["data"],
    orders: Array<Order>(),
    kintins: Array<Kintin>(),
    referralCodes: Array<AccountReferralCode>(),
    introducerContacts: Array<IntroducerContact>(),
    introducerCompanies: Array<IntroducerCompany>(),
    introducerContracts: Array<IntroducerContract>(),
    profiles: Array<Profile>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "paidAt" as keyof IOrder,
      direction: "desc" as "desc" | "asc",
    },
    selected: [] as ReadKintinIntroducerFeeResponse["data"],
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadIntroducerFeeKintinForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "introducer-fee/kintin/read",
      //   {
      //     ...formData,
      //     showOrders: formData.showOrders ?? "any",
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data = await window.Kernel.ActionBus2.introducerFeeKintin.read({
        ...formData,
        showOrders: formData.showOrders ?? "any",
        sort: this.sort,
        pagination: this.pagination,
      });

      this.data = data.data;

      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async downloadCsv() {
      // await window.Kernel.ActionBus.execute("introducer-fee/kintin/csv", {
      //   ...this.$data.filters.localData,
      //   sort: this.sort,
      // });
      await window.Kernel.ActionBus2.introducerFeeKintin.csv({
        ...this.$data.filters.localData,
        sort: this.sort,
      });
    },
    async bulkActions(): Promise<void> {
      await UpdateIntroducerFeeKintinForm(this.$data.selected).dialog({
        dialog: {
          title: "Bulk Actions",
          type: "fullscreen",
        },
      });
    },
    async updateOrder(orderId: string) {
      // const orderRecord = await window.Kernel.ActionBus.execute(
      //   "order/order/record",
      //   {
      //     order: orderId as string,
      //   },
      // );

      const orderRecord = await window.Kernel.ActionBus2.order.order.record({
        order: orderId as string,
      });

      if (!orderRecord) {
        return;
      }

      const order = Order.$findOneBy({
        id: orderId,
      });

      if (!order) {
        return;
      }

      try {
        await UpdateOrderForm({
          data: order,
          referralCode: order.kintin?.referral?.referralCode ?? undefined,
        }).dialog({
          dialog: {
            title: "Update Order",
          },
        });
      } catch {
        order.orderItems.forEach((item) => item.$delete());
        // await window.Kernel.ActionBus.execute("order/order/record", {
        //   order,
        // });
        await window.Kernel.ActionBus2.order.order.record({
          order,
        });
        return;
      }

      order.$persist();

      // await window.Kernel.ActionBus.execute("order/order/update", {
      //   order,
      // });
      await window.Kernel.ActionBus2.order.order.update({
        order,
      });
    },
  },
});
</script>
