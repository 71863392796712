<template>
  <div class="account-holder-list" :key="renderKey">
    <AccountHolderSummary
      v-if="$data.primaryPerson"
      :kintin="kintin"
      :person="$data.primaryPerson"
      @edit="edit($data.primaryPerson)"
    />
    <AccountHolderSummary
      v-if="$data.secondaryPerson"
      :kintin="kintin"
      :person="$data.secondaryPerson"
      @edit="edit($data.secondaryPerson)"
    />
  </div>
</template>

<script lang="ts">
import AccountHolderSummary from "@/module/kinvault.kintin/component/summary-cards/AccountHolderSummary.vue";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountHolderList",
  components: { AccountHolderSummary },
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
  },
  data: () => ({
    renderKey: 0,
    primaryPerson: null as Person | null,
    secondaryPerson: null as Person | null,
  }),
  mounted() {
    this.filter();
  },
  methods: {
    async edit(person: Person): Promise<void> {
      try {
        await UpdatePersonForm({
          person: person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: `Edit Person: ${person.profile.firstName} ${person.profile.lastName}`,
          },
          button: {
            ok: {
              text: "Update",
            },
          },
        });
      } catch {
        person.profile.$restore();
        person.$restore();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.person.update({
        person: person,
        kintin: this.kintin,
      });

      // await window.Kernel.ActionBus.execute("kinvault/person/profile/update", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.person.updateProfile({
        person: person,
        kintin: this.kintin,
      });

      this.filter();
    },
    filter() {
      this.primaryPerson = Person.$findOne(this.kintin.primaryPerson.id);
      if (this.kintin.secondaryPerson) {
        this.secondaryPerson = Person.$findOne(this.kintin.secondaryPerson.id);
      }

      this.renderKey++;
    },
  },
});
</script>
