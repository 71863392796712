import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import {
  DynamicLayoutElementConfig,
  GridLayout,
} from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Check, Kintin, KintinCheck } from "@kinherit/sdk";

const Steps = [
  "4.6.3b",
  "4.6.3c",
  "4.4.2",
  "4.4.2a",
  "3.0.10",
  "4.3.1e",
  "4.0.0b",
  "4.0.0c",
  "4.3.1f",
  "4.3.1g",
  "4.3.1h",
];

type WillBuilderConfigFormData = Record<
  | "4_6_3b"
  | "4_6_3c"
  | "4_4_2"
  | "4_4_2a"
  | "3_0_10"
  | "configuredBeneficiaryAge"
  | "4_3_1e"
  | "4_0_0b"
  | "4_0_0c"
  | "4_3_1f"
  | "4_3_1g"
  | "4_3_1h",
  number | null
>;

const SectionHeader = ({
  title,
  message,
  hr,
}: {
  title?: string;
  message?: string;
  hr?: boolean;
} = {}) =>
  [
    {
      attr: {
        class: `column`,
      },
      children: [
        ...(hr
          ? [
              {
                is: "hr",
              },
            ]
          : []),
        ...(title
          ? [
              {
                is: "p",
                text: title,
                attr: {
                  class: `title`,
                },
              },
            ]
          : []),
        ...(message
          ? [
              {
                is: "p",
                text: message,
              },
            ]
          : []),
      ],
    },
  ] as DynamicLayoutElementConfig<string, keyof HTMLElementTagNameMap>;

export const WillBuilderConfigForm = (kintin: Kintin) => {
  const checks = KintinCheck.$findBy({
    kintin: {
      id: kintin.id,
    },
    step: Steps,
  }).groupBy(
    Object.fromEntries(
      Steps.map((step) => [
        step.replaceAll(".", "_"),
        (check: KintinCheck) => check.step === step,
      ]),
    ),
  );

  return defineForm({
    name: "will-builder-config",
    data: () =>
      ({
        ...Object.fromEntries(
          Object.entries(checks).map(([key, value]) => [
            key,
            value.first()?.checkValue ?? null,
          ]),
        ),
        configuredBeneficiaryAge: kintin.callScript.beneficiaryAge,
      }) as WillBuilderConfigFormData,
    formAreas: (data) => [
      defineFormArea({
        name: "will-builder-config",
        data,
        template: GridLayout([
          SectionHeader({
            message:
              "Affects both person 1 & 2: these are the checkboxes within the script which have some sort of effect on the output. (Not saved alongside the Will Revision)",
          }),
          SectionHeader({
            title: "Robin Hood Clause",
          }),
          ["4.6.3b"],
          ["4.6.3c"],
          ["4.4.2"],
          ["4.4.2a"],
          SectionHeader({
            hr: true,
            title: "Assets outside UK",
            message: "Changes revocation and definition to exclude country 'x'",
          }),
          ["3.0.10"],
          SectionHeader({
            hr: true,
            title: "Beneficiary Age",
          }),
          ["configuredBeneficiaryAge"],
          ["4.3.1e"],
          SectionHeader({
            hr: true,
            title: "Guardians",
          }),
          ["4.0.0b"],
          ["4.0.0c"],
          SectionHeader({
            hr: true,
            title: "MOW: Allow Funds for...",
          }),
          ["4.3.1f"],
          ["4.3.1g"],
          ["4.3.1h"],
        ]),
        components: () => ({
          "4.6.3b": [
            FormRadioField({
              reactive: true,
              props: {
                label:
                  checks["4_6_3b"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.6.3b" })?.name,
                message: (data) =>
                  `Step 4.6.3b: ${
                    null === data["4_6_3b"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_6_3b",
              },
            }),
          ],
          "4.6.3c": [
            FormRadioField({
              props: {
                vIf: (data) => data["4_6_3b"] === 1,
                label:
                  checks["4_6_3c"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.6.3c" })?.name,
                message: (data) =>
                  `Step 4.6.3c: ${
                    null === data["4_6_3c"] ? `Incomplete` : `Complete`
                  }`,
                options: ["Encouraged", "Considered", "Required"],
              },
              models: {
                value: "4_6_3c",
              },
            }),
          ],
          "4.4.2": [
            FormRadioField({
              reactive: true,
              props: {
                label:
                  checks["4_4_2"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.4.2" })?.name,
                message: (data) =>
                  `Step 4.4.2: ${
                    null === data["4_4_2"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_4_2",
              },
            }),
          ],
          "4.4.2a": [
            FormRadioField({
              props: {
                vIf: (data) => data["4_4_2"] === 1,
                label:
                  checks["4_4_2a"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.4.2a" })?.name,
                message: (data) =>
                  `Step 4.4.2a: ${
                    null === data["4_4_2a"] ? `Incomplete` : `Complete`
                  }`,
                options: ["Encouraged", "Considered", "Required"],
              },
              models: {
                value: "4_4_2a",
              },
            }),
          ],
          "3.0.10": [
            FormRadioField({
              props: {
                label:
                  checks["3_0_10"].first()?.note.name ??
                  Check.$findOneBy({ step: "3.0.10" })?.name,
                message: (data) =>
                  `Step 3.0.10: ${
                    null === data["3_0_10"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "3_0_10",
              },
            }),
          ],
          configuredBeneficiaryAge: [
            FormNumberField({
              props: {
                label: "Beneficiary Age",
              },
              models: {
                value: {
                  get: (data) => data.configuredBeneficiaryAge,
                  set: (value, data) => {
                    data.configuredBeneficiaryAge = value;
                  },
                },
              },
            }),
          ],
          "4.3.1e": [
            FormRadioField({
              props: {
                label:
                  checks["4_3_1e"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.3.1e" })?.name,
                message: (data) =>
                  `Step 4.3.1e: ${
                    null === data["4_3_1e"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_3_1e",
              },
            }),
          ],
          "4.0.0b": [
            FormRadioField({
              props: {
                label:
                  checks["4_0_0b"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.0.0b" })?.name,
                message: (data) =>
                  `Step 4.0.0b: ${
                    null === data["4_0_0b"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_0_0b",
              },
            }),
          ],
          "4.0.0c": [
            FormRadioField({
              props: {
                label:
                  checks["4_0_0c"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.0.0c" })?.name,
                message: (data) =>
                  `Step 4.0.0c: ${
                    null === data["4_0_0c"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_0_0c",
              },
            }),
          ],
          "4.3.1f": [
            FormRadioField({
              props: {
                label:
                  checks["4_3_1f"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.3.1f" })?.name,
                message: (data) =>
                  `Step 4.3.1f: ${
                    null === data["4_3_1f"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_3_1f",
              },
            }),
          ],
          "4.3.1g": [
            FormRadioField({
              props: {
                label:
                  checks["4_3_1g"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.3.1g" })?.name,
                message: (data) =>
                  `Step 4.3.1g: ${
                    null === data["4_3_1g"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_3_1g",
              },
            }),
          ],
          "4.3.1h": [
            FormRadioField({
              props: {
                label:
                  checks["4_3_1h"].first()?.note.name ??
                  Check.$findOneBy({ step: "4.3.1h" })?.name,
                message: (data) =>
                  `Step 4.3.1h: ${
                    null === data["4_3_1h"] ? `Incomplete` : `Complete`
                  }`,
                options: ["No", "Yes"],
              },
              models: {
                value: "4_3_1h",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
