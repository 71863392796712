<template>
  <div class="kintin-master-list-escalated">
    <PageHeader htag="h1" text="Escalated Cases " />
    <KintinMasterList
      hide-title
      :show-filters="false"
      :is-escalated="true"
      :is-active="true"
      :persist-state="false"
    />
  </div>
</template>

<script lang="ts">
import KintinMasterList from "@/module/kinvault.kintin/page/KintinMasterList.vue";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KintinMasterListEscalatedRoute } from ".";

export default defineComponent({
  name: KintinMasterListEscalatedRoute,
  components: {
    KintinMasterList,
    PageHeader,
  },
});
</script>
