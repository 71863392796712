<template>
  <div class="introducercompany-referrals-page">
    <Tabs :is-fullwidth="false" :config="tabs" />
    <RouterChildView />
  </div>
</template>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsReferralCodesRoute,
  IntroducerCompanyDetailsReferralFormRoute,
  IntroducerCompanyDetailsReferralsIndexRoute,
  IntroducerCompanyDetailsReferralsRoute,
} from ".";

export default defineComponent({
  name: IntroducerCompanyDetailsReferralsIndexRoute,
  mixins: [StyleService.mixin],
  components: { Tabs, RouterChildView },
  data: () => ({
    tabs: [
      {
        label: `Referrals`,
        icon: StyleService.icon.referral.icon,
        route: { name: IntroducerCompanyDetailsReferralsRoute },
      },
      {
        label: `Referral Codes`,
        icon: StyleService.icon.referral.icon,
        route: { name: IntroducerCompanyDetailsReferralCodesRoute },
      },
      {
        label: `Referral Forms`,
        icon: StyleService.icon.add.icon,
        route: { name: IntroducerCompanyDetailsReferralFormRoute },
      },
    ],
  }),
});
</script>
