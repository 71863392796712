import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Kintin, Option } from "@kinherit/sdk";
type FormDataType = {
  options: Array<
    | "0_packingslip"
    | "1_signingcoverletter"
    | "2_will"
    | "3_signingconfirmation"
    | "4_lpa"
    | "5_dos"
  >;
  primaryProducts: Array<string>;
  secondaryProducts: Array<string>;
};

export const CreatePackingSlipForm = (kintin: Kintin) =>
  defineForm({
    name: "create-packing-slip-form",
    data: () =>
      ({
        options: Array<
          | "0_packingslip"
          | "1_signingcoverletter"
          | "2_will"
          | "3_signingconfirmation"
          | "4_lpa"
          | "5_dos"
        >(),
        primaryProducts: Array<string>(),
        secondaryProducts: Array<string>(),
      }) as FormDataType,
    formAreas: (data) => [
      defineFormArea({
        name: "create-packing-slip-form-area",
        data,
        template: GridLayout([
          ["title"],
          ["options"],
          ["text"],
          kintin.type.value === "single"
            ? ["primaryProducts"]
            : ["primaryProducts", "secondaryProducts"],
        ]),
        components: () => ({
          title: [
            FormTitle({
              props: {
                text: "Include the following sections:",
                htag: "h4",
              },
            }),
          ],
          text: [
            FormElement({
              props: {
                html: `<p><strong>Products to output on packing slip and cover letter:</strong><br>Also sometimes used to change the text in signing instructions</p>`,
              },
            }),
          ],
          options: [
            { text: "Packing slip", value: "0_packingslip" as const },
            {
              text: "Cover letter",
              value: "1_signingcoverletter" as const,
            },
            { text: "Your Will Documents", value: "2_will" as const },
            {
              text: "Will Signing Confirmation",
              value: "3_signingconfirmation" as const,
            },
            {
              text: "Your Lasting Power of Attorney Documents",
              value: "4_lpa" as const,
            },
            { text: "DOS Instructions", value: "5_dos" as const },
          ].map((option) =>
            FormCheckboxField({
              props: {
                label: option.text,
              },
              models: {
                value: {
                  get: (data) => data.options.includes(option.value),
                  set: (value, data) => {
                    if (value) {
                      data.options.push(option.value);
                    } else {
                      data.options = data.options.remove(option.value);
                    }
                  },
                },
              },
            }),
          ),
          primaryProducts: [
            FormElement({
              props: {
                is: "h4",
                html: kintin.primaryPerson.profile.fullName as string,
              },
            }),
            ...Option.$findBy({
              group: "packingSlipProducts",
            })
              .sortBy("data.order")
              .map((option) =>
                FormCheckboxField<FormDataType>({
                  props: {
                    label: option.text,
                  },
                  models: {
                    value: {
                      get: (data) => data.primaryProducts.includes(option.id),
                      set: (value, data) => {
                        if (value) {
                          data.primaryProducts.push(option.id);
                        } else {
                          data.primaryProducts = data.primaryProducts.remove(
                            option.id,
                          );
                        }
                      },
                    },
                  },
                }),
              ),
          ],
          secondaryProducts: [
            FormElement({
              props: {
                is: "h4",
                html: kintin.secondaryPerson?.profile.fullName as string,
              },
            }),
            ...Option.$findBy({
              group: "packingSlipProducts",
            })
              .sortBy("data.order")
              .map((option) =>
                FormCheckboxField<FormDataType>({
                  props: {
                    label: option.text,
                  },
                  models: {
                    value: {
                      get: (data) => data.secondaryProducts.includes(option.id),
                      set: (value, data) => {
                        if (value) {
                          data.secondaryProducts.push(option.id);
                        } else {
                          data.secondaryProducts =
                            data.secondaryProducts.remove(option.id);
                        }
                      },
                    },
                  },
                }),
              ),
          ],
        }),
      }),
    ],
  });
