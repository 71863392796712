import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

export const UpdateKnowledgeBaseCategoryForm = (
  category: KnowledgeBaseCategory,
) =>
  defineForm({
    name: "update-knowledge-base-category-form",
    data: () => category,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([["title"], ["slug"]]),
        components: () => ({
          title: [
            FormTextField({
              props: {
                label: "title",
                validators: ["required"],
              },
              models: {
                value: "title",
              },
            }),
          ],
          slug: [
            FormTextField({
              props: {
                label: "Slug",
              },
              models: {
                value: "slug",
              },
            }),
          ],
        }),
      }),
    ],
  });
