<template>
  <RouterChildView> </RouterChildView>
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsDocumentsRoute } from ".";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";

export default defineComponent({
  name: KinvaultKintinDetailsDocumentsRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: { RouterChildView },
});
</script>
