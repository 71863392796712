import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

// type Message = {
//   owner: Model<{ id: string }>;
//   emailLog: EmailLog;
//   files: Array<File>;
//   requiredFiles: Record<string, File>;
// };

// type Response = {
//   emailLog: EmailLog;
// };

// export class CreateEmailLogHandler<
//   M extends Message,
//   R extends Response,
// > extends ActionBase {
//   constructor(private message: M) {
//     super();
//   }

//   public async execute(): Promise<R> {
export const CreateEmailLogHandler = async (message: {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
}): Promise<{
  emailLog: EmailLog;
}> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer-company-email-log/{owner}/email-log",
    [IntroducerContact.$name]:
      "/v2/portal/introducer-contact-email-log/{owner}/email-log",
    [Kintin.$name]: "/v2/portal/kintin-email-log/{owner}/email-log",
    [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-log",
    [Person.$name]: "/v2/portal/officer-email-log/{owner}/email-log",
    [BrandedKinvault.$name]:
      "/v2/portal/branded-kinvault-email-log/{owner}/email-log",
  } as const;

  const route = routes[message.owner.$name];

  const owner = message.owner;

  const request = Api.resource("portal", route, {
    owner: message.owner,
  })
    .method("post")

    .body({
      template: message.emailLog.$data.template,
      status: message.emailLog.$data.status,
      data: message.emailLog.$data.data,
      preloadedFiles: message.emailLog.$data.preloadedFiles,
      cc: message.emailLog.$data.cc,
      bcc: message.emailLog.$data.bcc,
      to: message.emailLog.$data.to,
      from: message.emailLog.$data.from,
      html: message.emailLog.$data.html,
      markdown: message.emailLog.$data.markdown,
      title: message.emailLog.$data.title,
      subject: message.emailLog.$data.subject,
      sentAt: message.emailLog.$data.sentAt,
      introducerCompany: owner instanceof IntroducerCompany ? owner.id : null,
      introducerContact: owner instanceof IntroducerContact ? owner.id : null,
      lead: owner instanceof Lead ? owner.id : null,
      kintin: owner instanceof Kintin ? owner.id : null,
      person: owner instanceof Person ? owner.id : null,
      brandedKinvault: owner instanceof BrandedKinvault ? owner.id : null,
    });

  const response = await request.result();

  const emailLog = EmailLog.$inflate(response.emailLog).first();

  if (!emailLog) {
    throw new Error("Failed to create email log");
  }

  // await window.Kernel.ActionBus.execute("core/email-log/attachment/upload", {
  //   owner: message.owner,
  //   emailLog,
  //   files: message.files,
  // });
  await window.Kernel.ActionBus2.core.emailLog.uploadAttachment({
    owner: message.owner,
    emailLog,
    files: message.files,
  });

  // await window.Kernel.ActionBus.execute(
  //   "core/email-log/named-attachment/create",
  //   {
  //     owner: message.owner,
  //     emailLog,
  //     files: message.requiredFiles,
  //   },
  // );
  await window.Kernel.ActionBus2.core.emailLog.createNamedAttachment({
    owner: message.owner,
    emailLog,
    files: message.requiredFiles,
  });

  message.emailLog.$delete();

  return {
    emailLog,
  };
};
