import { Module } from "@/config/module.config";
import { Routes } from "./page";

Module.register({
  name: "admin.log",
  routes: Routes,
  actions: {},
  session: true,
  permissions: [],
});

export default {};
