<template>
  <MasterListPage
    v-if="$data.filters"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    title="Failed Logins"
    :sort-by-options="{
      createdAt: 'Created At',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #reason="{ row }">
      <Badge
        v-if="row.reason === 'invalid-password'"
        type="danger"
        text="Incorrect Password"
        size="is-small"
        color="is-danger"
        is-rounded
      ></Badge>
      <Badge
        v-else-if="row.reason === 'invalid-username'"
        type="danger"
        text="Incorrect Username"
        size="is-small"
        color="is-kinherit-ochre"
        is-rounded
      ></Badge>
      <Badge
        v-else
        type="warning"
        text="Unknown"
        size="is-small"
        is-rounded
      ></Badge>
    </template>
    <template #details="{ row }: { row: FailedLoginAttempt }">
      {{ row.userAgent }}
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadFailedLoginAttemptsForm } from "@/module/admin.log/form/read-failed-login-attempts.form";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { FailedLoginAttempt, IFailedLoginAttempt } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { FailedLoginAttemptsMasterListRoute } from ".";

export default defineComponent({
  name: FailedLoginAttemptsMasterListRoute,
  components: { MasterListPage, Badge },
  data: () => ({
    filters: ReadFailedLoginAttemptsForm(),
    columns: [
      {
        map: (row) => row.createdAt.formatDateTime,
        title: "Created At",
      },
      {
        field: "ipAddress",
        title: "IP Address",
      },
      {
        field: "origin",
        title: "Origin",
      },
      {
        field: "username",
        title: "Username",
      },
      {
        title: "Reason",
        slot: "reason",
      },
    ] as Array<TableColumn<FailedLoginAttempt>>,
    rows: Array<FailedLoginAttempt>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IFailedLoginAttempt,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadFailedLoginAttemptsForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "admin/failed-login-attempts/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data =
        await window.Kernel.ActionBus2.adminLog.failedLoginAttempts.read({
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = data.failedLoginAttempts;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
