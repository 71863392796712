import { DefineActionBus } from "@kinherit/framework/action-bus-2/bus";
import { ReadIntroducerBillingContactHandler } from "./read-introducer-billing-contact.action";

export const Actions = {
  "introducer/billing-contact/read": ReadIntroducerBillingContactHandler,
};

export const IntroducerBillingContactActionBus = DefineActionBus({
  name: "Introducer.BillingContact",
  actions: {
    read: ReadIntroducerBillingContactHandler,
  },
});
