<template>
  <PageHeader text="IFA Call Script" htag="h2" />
  <CallScriptSection title="Answer">
    <b>Hello is this {{ leadFirstName }} / {{ leadFullName }}?</b><br />
    <em>Pause and wait for a reply</em><br />
    <br />
    <b>
      My name is {{ fullName }} and I’m one of the Legal Assistants at Kinherit,
      the law firm specializing in Wills and Estate Planning. </b
    ><br />
    <em>Pause and wait for a reply</em><br />
    <br />
    <b>Hi {{ leadFirstName }} how are you</b><br />
    <em>Pause and wait for a reply</em><br />
    <br />
    <b><em>[RAPPORT]</em></b
    ><br />
    <b><em>[CONTROL]</em></b
    ><br />
  </CallScriptSection>
  <CallScriptSection title="Introduction">
    <b>
      I’m ringing on behalf of your Estate Planner
      {{ estatePlannerFullName }}, who has asked me to arrange your
      complimentary consultation because you have been introduced to Kinherit by
      {{ introducerContactFullName }} at {{ referralSource }}. Were you
      expecting my call? </b
    ><br />
    <em>Pause</em> <br />
    <br />
    <b>
      {{ estatePlannerFirstName }} has asked me to explain what you should
      expect from them, Kinherit and what we in turn expect from you. </b
    ><br /><br />
    <b>So I know how much detail to go into, have you written a Will before?</b
    ><br />
    <em>
      Pause **Add Note** – If no full explanation, if yes check the validity of
      the Will and if a Will review is needed</em
    >
    <br />
    <br />
    <b>
      Is it okay if I explain how we work and how we treat the information you
      provide to us and a bit about the process? It should answer your questions
      about what you should expect from us and what we in turn request from you. </b
    ><br />
    <em>Pause</em>
    <br />
    <br />
    <b>
      Then we can get a suitable time and date for you to have your
      complimentary meeting with {{ estatePlannerFirstName }}
    </b>
    <br /><br />
    <b>
      Before I go any further I must inform you that all our telephone calls and
      Zoom meetings are recorded so that we retain an accurate record of our
      meetings and your wishes, in the event that your wishes are ever
      challenged. </b
    ><br />
    <em>Pause ** add note **</em>
  </CallScriptSection>
  <CallScriptSection title="Check Details">
    <b>
      1, I see from my notes that you and your partner [Name] were introduced by
      {{ introducerContactFullName }} can I assume this planning is for you
      both? </b
    ><br />
    <em>Pause and wait for a reply **Add Note**</em><br /><br />
    <b>
      2, I see from my notes that you were introduced by
      {{ introducerContactFullName }} but can I check, if the planning is just
      for you or do you have a partner? </b
    ><br />
    <em>Pause and wait for a reply **Add Note**</em><br /><br />
    <b>
      Can I ask - is there something in particular that prompted your discussion
      with {{ introducerContactFirstName }} about starting your Estate Planning? </b
    ><br />
    <em>Pause and wait for a reply **Add Note**</em>
  </CallScriptSection>
  <CallScriptSection title="How we work">
    <b>
      All of our consultations are offered on a complimentary basis without
      obligation. We only ask for payment once you have instructed us to issue
      you an invoice for the creation of documents. </b
    ><br /><br />
    <b>
      We hold 90% of our consultations over Zoom, which means you get to meet
      {{ estatePlannerFirstName }}, our clients find this works best and allows
      easy communication. </b
    ><br /><br />
    <b>
      You will have two consultations and the first meeting takes about 40
      minutes </b
    ><br /><br />
    <b>Is that okay with you?</b><br />
    <em>Pause and wait for a reply</em><br /><br />
    <b>
      {{ estatePlannerFirstName }} will ask lots of questions to fully
      understand your situation and to cover all the financial, legal and
      practical sides of your planning. You do not need to prepare anything, we
      will guide you through the process. </b
    ><br /><br />
    <b>
      After your meeting, you’ll receive a report from your Estate Planner via
      email, which summarises what you have talked about and sets out their
      recommendations for you and your situation. </b
    ><br /><br />
    <b>
      We send your report via e-mail, password-protected for your security. Now
      is a good time to check and confirm your e-mail address. </b
    ><br />
    <em>Pause **Add Note** and wait for a reply check your email</em>
    <br /><br />
    <b>Thank you</b><br /><br />
    <b>
      Your follow-up meeting a few days later usually takes 20 - 30 minutes.
      {{ estatePlannerFirstName }} will go through the recommendations they’ve
      made for you, answer any questions you may have, and help you make some
      key decisions. </b
    ><br /><br />
    <b>
      There’s no obligation to continue, but through making payment you are
      instructing us to draft your documents. </b
    ><br /><br />
    <b>
      All of our prices are on our website and we have some examples of generic
      situations in the Letter of Engagement, which I will send to you, but your
      Estate Planner will recommend what is required to achieve what you want.
      All prices are inclusive of VAT so no surprises. </b
    ><br /><br />
    <b>All of our clients also receive a Kinvault.</b><br /><br />
    <b>
      Did {{ introducerContactFirstName }} explain to you about your
      Complimentary Kinvault? </b
    ><br />
    <em>Pause and wait for a reply</em></CallScriptSection
  >
  <CallScriptSection title="Kinvault">
    <b>
      The Kinvault is a ‘go to’ digital vault for your legal, financial &
      practical information that helps you organise and hand over your important
      life admin to your family when you’re no longer around or no longer able
      to do so. </b
    ><br /><br />
    <b>
      {{ estatePlannerFirstName }} will explain it further when you meet, and
      following this call I will send you a link to our Kinvault video, which
      shows you in more detail how your Complimentary Kinvault will help. </b
    ><br /><br />
    <b>Is that ok?</b><br />
    <em>Pause and wait for a reply</em></CallScriptSection
  >
  <CallScriptSection title="Privacy">
    <b>
      Kinherit takes the privacy of your information very seriously and
      following this call, I will send you your Letter of Engagement which
      provides you with our full Privacy Policy. </b
    ><br />
    <em>Pause and wait for a reply</em><br /><br />
    <b>
      We will draft your documents, following your instructions and using the
      information that you have confirmed is true and accurate. You will have at
      least two opportunities to review the information. </b
    ><br /><br />
    <b>
      What we ask of you is to take your time and inform us immediately if any
      information needs changing. </b
    ><br />
    <em>Pause and wait for a reply</em></CallScriptSection
  >
  <CallScriptSection title="Booking">
    <b>
      Before I look at {{ estatePlannerFirstName }} ’s diary are there any
      current medical or well-being issues that make your planning urgent that
      we should be aware of? </b
    ><br />
    <em>Pause **Add Note** and wait for a reply</em>
    <br /><br />
    <b>Ok thank you</b><br /><br />
    <b>
      {{ estatePlannerFirstName }} makes a few slots available for complimentary
      meetings each week – looking at their diary, </b
    ><em>[pause]</em><br /><br />
    <b>I could get you in at</b><br />
    <b>Time Date?</b><br />
    <b>Or</b><br />
    <b>Time Date</b><br />
    <em>Pause and wait for a reply</em><br /><br />
    <b>That’s all booked</b><br /><br />
    <b>
      It is really important that if you are unable to attend your consultations
      to contact me or speak with one of the other Legal Assistants. Where
      possible, we request a minimum of 3 days notice as
      {{ estatePlannerFirstName }} has a limited number of appointments each
      week. </b
    ><br />
    <em>Pause and wait for a reply</em>
  </CallScriptSection>
  <CallScriptSection title="Questions">
    <b>
      Lastly so that {{ estatePlannerFirstName }} has some basic information
      before your call I have a few questions, to start your case file.
    </b>
    <ul>
      <li>
        1, As this planning is for you both can I confirm your marital status?
      </li>
      <li>
        2, Although this planning is just for you can I confirm your marital
        status?
      </li>
      <li>Are you a homeowner?</li>
      <li>Do you own any other properties?</li>
      <li>
        Are you a business owner or own any business assets such as shares?
      </li>
      <li>Do you own any Agricultural assets?</li>
      <li>Lastly, Do you have any children under the age of 18?</li>
    </ul>
    <b>
      {{ estatePlannerFirstName }} is the perfect estate planner for you, they
      are suitably qualified and help 100’s of clients like you each year.
    </b></CallScriptSection
  >
  <CallScriptSection title="Confirmation">
    <b>
      I will now send you a confirmation email which includes the Zoom link to
      your Letter of Engagement with further information about the process plus
      some more information on Kinherit. </b
    ><br /><br />
    <b>{{ estatePlannerFirstName }} will join you at [Time / Date]</b
    ><br /><br />
    <b>
      As a reminder, if you are unable to attend your consultations please
      contact me as soon as possible my telephone number may have come up on
      your phone and it will be on my confirmation e-mail, which I will send
      shortly. </b
    ><br /><br />
    <b>Do you have any final questions?</b><br /><br />
    <b>
      I am sure we will speak again later on in the process should you want any
      documents drafted as I help
      {{ estatePlannerFirstName }} throughout your planning. </b
    ><br /><br />
    <b>Thank you</b></CallScriptSection
  >
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { IntroducerContact, Profile } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { LeadDetailsIfaCallScriptRoute } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";

export default defineComponent({
  name: LeadDetailsIfaCallScriptRoute,
  mixins: [LeadDetailsMixin, StyleService.mixin, AuthService.mixin()],
  components: { PageHeader, CallScriptSection },
  computed: {
    firstName(): string {
      return this.$auth.loggedInUser?.profile.firstName || "[FIRST NAME]";
    },
    fullName(): string {
      return this.$auth.loggedInUser?.profile.fullName || "[Full NAME]";
    },
    leadFirstName(): string {
      return this.lead?.profile.firstName || "[FIRST NAME]";
    },
    leadFullName(): string {
      return this.lead?.profile.fullName || "[Full NAME]";
    },
    email(): string {
      return this.lead?.profile.emails.first()?.email || "[EMAIL]";
    },
    estatePlannerProfile(): Profile | null {
      return this.lead?.ownedBy?.profile || null;
    },
    estatePlannerFirstName(): string {
      return this.estatePlannerProfile?.firstName || "[EP FIRST NAME]";
    },
    estatePlannerFullName(): string {
      return this.estatePlannerProfile?.fullName || "[EP FULL NAME]";
    },
    introducerContact(): IntroducerContact | null {
      return this.lead?.referral?.contact || null;
    },
    introducerContactFirstName(): string {
      return (
        this.introducerContact?.profile.firstName || "[INTRODUCER FIRST NAME]"
      );
    },
    introducerContactFullName(): string {
      return (
        this.introducerContact?.profile.fullName || "[INTRODUCER FULL NAME]"
      );
    },
    referralSource(): string {
      return this.lead?.referral?.referralCode?.name ?? "[REFERRAL SOURCE]";
    },
  },
});
</script>
