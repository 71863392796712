<template>
  <Tabs class="kintin-documents-sidebar" :config="items" is-vertical />
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsDocumentsRoute } from ".";
// import { KintinDetailsCreditNotesRoute } from "./credit-notes";
import { KinvaultKintinDetailsFileVaultRoute } from "./file-vault";
import { KintinDetailsOrderRoute } from "./orders";
import {
  KinvaultKintinDetailsPostPaymentMergeContactsRoute,
  KinvaultKintinDetailsPostPaymentRoute,
} from "./post-payment";
import { KintinDetailsProcessRoute } from "./process";
import { KintinDetailsSubscriptionRoute } from "./subscriptions";
import { KintinDetailsUploadFormRoute } from "./upload-forms";

export default defineComponent({
  name: KinvaultKintinDetailsDocumentsRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    Tabs,
  },
  data: (ctx) => ({
    items: [
      {
        label: "Process",
        route: {
          name: KintinDetailsProcessRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.order.icon,
      },
      {
        label: "Orders",
        route: {
          name: KintinDetailsOrderRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.order.icon,
      },
      {
        label: "Subscriptions",
        route: {
          name: KintinDetailsSubscriptionRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.subscription.icon,
      },
      {
        label: "Post Payment",
        route: {
          name: KinvaultKintinDetailsPostPaymentRoute,
          params: ctx.$params,
        },
        nestedRoutes: [KinvaultKintinDetailsPostPaymentMergeContactsRoute],
        icon: StyleService.icon.postPayment.icon,
      },
      {
        label: "Upload Forms",
        route: {
          name: KintinDetailsUploadFormRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.uploadForm.icon,
      },
      {
        label: "File Vault",
        route: {
          name: KinvaultKintinDetailsFileVaultRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.lock.icon,
      },
      //{
      //  label: "Credit Notes",
      //  route: {
      //    name: KintinDetailsCreditNotesRoute,
      //    params: ctx.$params,
      //  },
      //  icon: StyleService.icon.creditNote.icon,
      //},
    ],
  }),
});
</script>

<style lang="scss">
.kintin-documents-sidebar {
  .tabs__tab-content {
    display: none;
  }
}
</style>
