<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
    }"
  >
    <PageHeader htag="h2" text="Property & Assets">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>
    <Tabs
      v-model:tab="tab"
      is-lazy
      position="both"
      :config="
        ['Property', 'Savings', 'Pensions/Policies', 'Business', 'Totals'].map(
          (x) => ({ label: x }),
        )
      "
    >
      <!-- Tab Property -->
      <template #property>
        <PropertyTab @save="saveHandler" />
      </template>
      <!-- Tab Savings -->
      <template #savings>
        <SavingsTab @save="saveHandler" />
      </template>
      <!-- Tab Pensions & Policies / IFA -->
      <template #pensions>
        <PensionsTab />
      </template>
      <!-- Tab Business  -->
      <template #business>
        <BusinessTab />
      </template>
      <!-- Tab Totals -->
      <template #totals>
        <TotalsTab
          @save="saveHandler"
          @save-and-continue="saveAndContinueHandler"
        />
      </template>
    </Tabs>
  </div>
</template>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Tabs from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import {
  KintinDetailsCallScriptParams,
  KintinDetailsCallScriptRoute,
} from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptWhoGetsWhatRoute } from "../who-gets-what";
import BusinessTab from "./BusinessTab.vue";
import PensionsTab from "./PensionsTab.vue";
import PropertyTab from "./PropertyTab.vue";
import SavingsTab from "./SavingsTab.vue";
import TotalsTab from "./TotalsTab.vue";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    PageHeader,
    Button,
    PropertyTab,
    SavingsTab,
    PensionsTab,
    BusinessTab,
    TotalsTab,
    Tabs,
  },
  data: () => ({
    isFocused: false,
    tab: 0,
  }),
  methods: {
    async saveAndContinueHandler() {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptWhoGetsWhatRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      console.log("SaveHandler", this.kintin.callScript);

      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/call-script/update",
      //   {
      //     kintin: this.kintin,
      //   },
      // );
      await window.Kernel.ActionBus2.kinvaultKintin.callScript.update({
        kintin: this.kintin,
      });

      this.$snackBar.success.saved();
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
