<template>
  <HistoryPage type="lead" :id="$params.lead" />
</template>

<script lang="ts">
import HistoryPage from "@/shared/component/history/HistoryPage.vue";
import { defineComponent } from "vue";
import { LeadDetailsChangeLogRoute } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

export default defineComponent({
  name: LeadDetailsChangeLogRoute,
  components: { HistoryPage },
  mixins: [LeadDetailsMixin],
});
</script>
