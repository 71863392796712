<template>
  <Card :title="name" :title-size="'is-4'" class="attorney-summary">
    <DataListWidget
      no-card
      is-compact
      :details="[
        {
          label: 'For',
          value: role.forPerson.profile.fullName,
        },
        {
          valueOnly: 'Health & Welfare',
          hide: !role.isHealthWealth,
        },
        {
          valueOnly: 'Property & Finance',
          hide: !role.isPropFinance,
        },
        {
          label: 'Organisation',
          value: role.forPerson.profile.organisationName,
          hide: null === role.forPerson.profile.organisationName,
        },
        {
          valueOnly: role.notes,
          hide: null === role.notes,
        },
      ]"
    />
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
      />
      <Button
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="$emit('delete', $event)"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Attorney, Kintin } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AttorneySummary",
  props: {
    role: {
      type: Attorney as PropType<Attorney>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  components: { Card, DataListWidget, Button },
  emits: ["edit", "delete"],
  computed: {
    name(): string {
      const person = this.role.person;
      const profile = person.profile;

      let name = profile.fullName;

      if (person.profile.knownAs) {
        if (null === name) {
          name = person.profile.knownAs;
        } else {
          name += ` (${person.profile.knownAs})`;
        }
      }

      if (this.role.isReserve) {
        name += " [Reserve]";
      }

      return name as string;
    },
  },
});
</script>
