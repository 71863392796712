import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { reactive } from "vue";

export const WillBuilderSectionChangesForm = ({
  custom,
  generated,
}: {
  custom?: string;
  generated: string;
}) => {
  const localData = reactive({
    activeTab: 0,
  });

  return defineForm({
    name: `will-builder-section-changes`,
    data: () => ({
      currentContent: custom ?? generated,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "changes",
        data,
        components: () => ({
          default: [
            FormTabs({
              props: {
                config: [
                  {
                    label: "Edit",
                  },
                  {
                    label: "Generated",
                  },
                ],
              },
              slots: {
                edit: [
                  FormRichTextField({
                    models: {
                      value: "currentContent",
                    },
                    props: {
                      mode: "willbuilder",
                      height: "calc(100vh - 300px)",
                    },
                  }),
                ],
                generated: [
                  FormElement({
                    props: {
                      is: "div",
                      html: generated,
                    },
                  }),
                ],
              },
              models: {
                tab: {
                  get: () => localData.activeTab,
                  set: (value) => {
                    localData.activeTab = value;
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
