import { getPagination } from "@kinherit/framework/action-bus-2/helper";
import { Between, Like } from "@kinherit/orm/index";
import { Api, FailedLoginAttempt, IFailedLoginAttempt } from "@kinherit/sdk";

export interface ReadFailedLoginAttemptsMessage {
  created?: null | [number, number];
  origin?: null | string;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IFailedLoginAttempt;
    direction: "asc" | "desc";
  };
}

interface ReadFailedLoginAttemptResponse {
  failedLoginAttempts: FailedLoginAttempt[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadFailedLoginAttemptsHandler = async (
  message: ReadFailedLoginAttemptsMessage,
): Promise<ReadFailedLoginAttemptResponse> => {
  const request = Api.resource("portal", "/v2/portal/failed-login-attempt")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(FailedLoginAttempt).where({
    createdAt: Between(message.created),
    origin: Like(message.origin),
  });

  const response = await request.result();

  return {
    failedLoginAttempts: FailedLoginAttempt.$inflate(
      response.failedLoginAttempt,
      message.sort,
      response.$rootIds,
    ),
    pagination: getPagination(response),
  };
};
