<template>
  <Card v-if="knowledgeBaseDocument" title="Files">
    <template #buttons>
      <Button color="is-success" @click="uploadFile">Upload</Button>
    </template>
    <Table :key="renderKey" :rows="rows" :columns="columns">
      <template #actions="{ row }">
        <div class="buttons is-right">
          <Button color="is-info" @click="updateFile(row)"> Rename </Button>
          <Button color="is-success" @click="downloadFile(row)">
            Download
          </Button>
          <Button color="is-danger" @click="deleteFile(row)">Delete</Button>
        </div>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { AuthService } from "@/service/auth.service";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { OpenTextDialog } from "@kinherit/framework/global/dialog";
import { FileLog } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { KnowledgeBaseDocumentDetailsContentRoute } from ".";
import { CreateKnowledgeBaseFileLogForm } from "../../form/create-knowledge-base-document-file-log.form";
import { KnowledgeBaseDocumentMixin } from "../../mixin/knowledge-base-document.mixin";

export default defineComponent({
  name: KnowledgeBaseDocumentDetailsContentRoute,
  components: { Card, Button, Table },
  mixins: [AuthService.mixin(), KnowledgeBaseDocumentMixin()],
  data: () => ({
    columns: [
      {
        title: "File Name",
        map: (row: FileLog) => row.fileName,
      },
      {
        title: "Created",
        map: (row: FileLog) => row.createdAt.formatYMD,
      },
      {
        slot: "actions",
      },
    ] as TableColumn<FileLog>[],
    rows: [] as FileLog[],
    renderKey: 0,
  }),
  mounted() {
    this.rows = this.knowledgeBaseDocument?.fileLog ?? [];
    this.renderKey++;
  },
  methods: {
    async refresh(): Promise<void> {
      if (!this.knowledgeBaseDocument) {
        return;
      }

      // await window.Kernel.ActionBus.execute("knowledge-base/document/record", {
      //   knowledgeBaseDocument: this.knowledgeBaseDocument,
      // });
      await window.Kernel.ActionBus2.knowledgeBase.document.record({
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      this.rows = this.knowledgeBaseDocument.fileLog;
      this.renderKey++;
    },
    async uploadFile(): Promise<void> {
      const { files } = await CreateKnowledgeBaseFileLogForm().dialog({
        dialog: {
          title: "Upload File",
        },
      });

      const [file] = files;

      if (!this.knowledgeBaseDocument || !file) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "knowledge-base/document/file-log/create",
      //   {
      //     file: file,
      //     knowledgeBaseDocument: this.knowledgeBaseDocument,
      //   },
      // );
      await window.Kernel.ActionBus2.knowledgeBase.document.fileLog.create({
        file: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      await this.refresh();
    },
    async deleteFile(file: FileLog): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete File",
          message: `Are you sure you want to delete "${file.fileName}"?`,
        },
      });

      if (!this.knowledgeBaseDocument) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "knowledge-base/document/file-log/delete",
      //   {
      //     fileLog: file,
      //     knowledgeBaseDocument: this.knowledgeBaseDocument,
      //   },
      // );
      await window.Kernel.ActionBus2.knowledgeBase.document.fileLog.delete({
        fileLog: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      await this.refresh();
    },
    async updateFile(file: FileLog): Promise<void> {
      const fileName = await OpenTextDialog({
        dialog: {
          title: "Update File",
          message: "Enter the new file name",
        },
        value: file.fileName,
        input: {},
      });

      if (!this.knowledgeBaseDocument) {
        return;
      }

      file.fileName = fileName;
      file.$persist();

      // await window.Kernel.ActionBus.execute(
      //   "knowledge-base/document/file-log/update",
      //   {
      //     file: file,
      //     knowledgeBaseDocument: this.knowledgeBaseDocument,
      //   },
      // );
      await window.Kernel.ActionBus2.knowledgeBase.document.fileLog.update({
        file: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      await this.refresh();
    },
    async downloadFile(file: FileLog): Promise<void> {
      if (!this.knowledgeBaseDocument) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "knowledge-base/document/file-log/download",
      //   {
      //     file: file,
      //     knowledgeBaseDocument: this.knowledgeBaseDocument,
      //   },
      // );
      await window.Kernel.ActionBus2.knowledgeBase.document.fileLog.download({
        file: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });
    },
  },
});
</script>
