import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsAccountingIndexRoute =
  "IntroducerCompanyDetailsAccountingIndex";
export const IntroducerCompanyDetailsAccountingIndexURI =
  "/introducer/company/:introducerCompany/accounting";
export type IntroducerCompanyDetailsAccountingParams = RouteParamObject<
  typeof IntroducerCompanyDetailsAccountingURI
>;

export const IntroducerCompanyDetailsAccountingRoute =
  "IntroducerCompanyDetailsAccounting";
export const IntroducerCompanyDetailsAccountingURI =
  "/introducer/company/:introducerCompany/accounting/xero";

export const IntroducerCompanyDetailsBillingRoute =
  "IntroducerCompanyDetailsBilling";
export const IntroducerCompanyDetailsBillingURI =
  "/introducer/company/:introducerCompany/accounting/billing";
export type IntroducerCompanyDetailsBillingParams = RouteParamObject<
  typeof IntroducerCompanyDetailsBillingURI
>;

export const IntroducerCompanyDetailsAccountingBreadCrumb = (params: any) => ({
  text: "Accounting",
  route: {
    name: IntroducerCompanyDetailsAccountingRoute,
    params,
  },
});
export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsAccountingIndexRoute,
    path: IntroducerCompanyDetailsAccountingIndexURI,
    component: () => import("./Index.vue"),
    redirect: {
      name: IntroducerCompanyDetailsAccountingRoute,
    },
    children: [
      {
        name: IntroducerCompanyDetailsAccountingRoute,
        path: IntroducerCompanyDetailsAccountingURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "IntroducerCompanyDetailsAccounting" */ "./IntroducerCompanyDetails.Accounting.vue"
            ),
        },
        meta: {
          breadcrumbs: (params) => [
            IntroducerCompanyMasterListBreadCrumb,
            IntroducerCompanyDetailsBreadCrumb(params),
            IntroducerCompanyDetailsAccountingBreadCrumb(params),
            {
              text: "Xero",
              route: {
                name: IntroducerCompanyDetailsAccountingRoute,
                params,
              },
            },
          ],
        },
      },
      {
        name: IntroducerCompanyDetailsBillingRoute,
        path: IntroducerCompanyDetailsBillingURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "IntroducerCompanyDetailsAccounting" */ "./IntroducerCompanyDetails.Billing.vue"
            ),
        },
        meta: {
          breadcrumbs: (params) => [
            IntroducerCompanyMasterListBreadCrumb,
            IntroducerCompanyDetailsBreadCrumb(params),
            IntroducerCompanyDetailsAccountingBreadCrumb(params),
            {
              text: "Billing",
              route: {
                name: IntroducerCompanyDetailsBillingRoute,
                params,
              },
            },
          ],
        },
      },
    ],
  },
];
