<template>
  <div class="appointment-master-list">
    <PageHeader htag="h1" :text="title" />
    <Card>
      <MasterListFilters
        v-if="null !== $data.filters && showFilters"
        :filters="$data.filters"
        @submit="search"
        @change="change"
        isNested
      />
      <div class="buttons">
        <Button
          v-for="(count, key) in callTypeCounts"
          @click="filterCallType(key)"
          :key="key"
          :color="`is-${callTypeColors[key]}`"
          class="js-badge-filter mr-1"
          :class="{
            'is-outlined': !filters?.localData?.selected?.includes(key),
          }"
          :text="`${key} (${count})`"
          size="is-small"
        />
      </div>
      <Table
        v-for="(rows, date) in rowGroups"
        :key="`row-group-${date.replace(` `, ``)}`"
        :title="date"
        is-scrollable
        enable-per-page
        :show-header-controls="date === firstGroup"
        :show-footer-controls="date === lastGroup"
        v-model:page="pagination.currentPage"
        @update:page="() => refresh('table', date, 'bottom')"
        v-model:perPage="pagination.perPage"
        @update:perPage="() => refresh('table', date, 'top')"
        :total-pages="pagination.lastPage"
        :total-records="pagination.count"
        :columns="columns"
        :rows="rows"
        :row-classes="rowClasses"
        is-fullwidth
        is-narrow
        is-hoverable
        v-model:sortBy="sort.by"
        @update:sortBy="() => refresh('table', date, 'top')"
        v-model:sortDirection="sort.direction"
        @update:sortDirection="() => refresh('table', date, 'top')"
        :sort-by-options="{
          createdAt: 'Created',
          appointmentAt: 'Date',
        }"
        :empty-message="'No appointments found'"
        :persist-state="persistState"
      >
        <template #type="{ row }">
          <badge
            :text="row.type?.text"
            :color="
              ('is-' +
                callTypeColors[getAppointmentType(row)]) as ThemeColorType
            "
            size="is-small"
            is-compact
          />
        </template>
        <template #notes="{ row }: { row: Appointment }">
          <ShowNotesButton
            v-if="row.kintin"
            :has-write-permission="hasWritePermission"
            :kintin="row.kintin"
            is-communication-note
            :count="row.kintin.noteCount"
            :show-account-button="true"
          />
        </template>
        <template #outcome="{ row }">
          <Button
            :text="row.outcome?.text"
            :color="('is-' + row.outcome?.class) as ThemeColorType"
            size="is-small"
            @click="() => handleSetOutcomeClick(row)"
          />
        </template>
        <template #specialist="{ row }">
          <Avatar size="is-small" :name="row.specialist?.profile?.fullName" />
        </template>
        <template #tel="{ row }">
          <a :href="'tel:' + row.profile?.phoneNumbers.first()?.tel">
            {{ row.profile?.phoneNumbers.first()?.tel ?? "-" }}
          </a>
        </template>
      </Table>
    </Card>
  </div>
</template>

<script lang="ts">
import { ReadAppointmentForm } from "@/module/appointment/form/read-appointment.form";
import ShowNotesButton from "@/module/core/component/note/ShowNotesButton.vue";
import { AuthService } from "@/service/auth.service";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Badge } from "@kinherit/framework/component.display/badge";
import { Table } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { ThemeColorType } from "@kinherit/framework/theme/prop/color";
import { In, NotIn } from "@kinherit/orm";
import { Appointment, IAppointment, Option, Tag } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";
import { defineComponent, type PropType } from "vue";
import { AppointmentMasterListRoute } from ".";
import { UpdateAppointmentOutcomeForm } from "../form/update-appointment-outcome.form";

type AppointmentTypeCounts = {
  Enquiry: number;
  Cancelled: number;
  Option: number;
  Info: number;
  Other: number;
  Kinvault: number;
  Will: number;
};

type AppointmentTypes = keyof AppointmentTypeCounts;

export default defineComponent({
  name: AppointmentMasterListRoute,
  components: {
    Card,
    Table,
    MasterListFilters,
    Avatar,
    ShowNotesButton,
    Badge,
    Button,
    PageHeader,
  },
  mixins: [AuthService.mixin()],
  props: {
    persistState: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Object as PropType<Tag>,
      default: null,
    },
    specialist: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    dateRange: {
      type: String as PropType<
        | "today_end-of-week"
        | "today_tomorrow"
        | "today"
        | "tomorrow"
        | "this-week"
        | "next-week"
        | "last-week"
        | "custom"
        | null
      >,
      default: null,
    },
  },
  data: () => ({
    filters: ReadAppointmentForm(),
    columns: [
      {
        title: "EP",
        slot: "specialist",
        width: "3em",
      },
      {
        title: "Time",
        sort: true,
        map: (v: Appointment) => v.appointmentAt.format("HH:II"),
        width: "4em",
      },
      {
        title: "Type",
        slot: "type",
        width: "12em",
      },
      {
        title: "Client",
        sort: true,
        map: (v: Appointment) =>
          `${v.cancelled ? "[CANCELLED]" : ""} ${
            v.profile?.fullName ?? v.kintin?.friendlyName ?? `-`
          }`,
        class: "has-text-weight-bold",
      },
      {
        title: "Email",
        sort: true,
        map: (v: Appointment) => v.profile?.emails.first()?.email ?? `-`,
      },
      {
        title: "Tel",
        sort: true,
        slot: "tel",
        width: "12em",
      },
      {
        title: "Notes",
        slot: "notes",
        width: "5em",
      },
      {
        title: "Outcome",
        slot: "outcome",
        width: "14em",
      },
    ] as Array<TableColumn>,
    rowGroups: {} as Record<string, Array<Appointment>>,
    callTypeCounts: {
      Enquiry: 0,
      Cancelled: 0,
      Option: 0,
      Info: 0,
      Other: 0,
      Kinvault: 0,
      Will: 0,
    } as AppointmentTypeCounts,
    callTypeColors: {
      Enquiry: "brown" as const,
      Info: "blue" as const,
      Option: "purple" as const,
      Kinvault: "teal" as const,
      Will: "green" as const,
      Other: "grey" as const,
      Cancelled: "red" as const,
    },
    firstGroup: "" as string,
    lastGroup: "" as string,
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 100,
      count: 0,
    },
    sort: {
      by: "appointmentAt" as keyof IAppointment,
      direction: "asc" as "desc" | "asc",
    },
  }),
  computed: {
    title(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "Appointments";
    },
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("appointment:write");
    },
  },
  mounted(): void {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
    if (this.specialist.length > 0) {
      this.filters.localData.specialist = this.specialist;
    }
    if (this.dateRange) {
      this.filters.localData.dateRange = this.dateRange;
    }
    this.refresh("component");
  },
  methods: {
    tableTitle(timestamp: number): string {
      return DateTime.fromDate(new Date(timestamp)).formatDate;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      const appointment = row as Appointment;
      return {
        "has-background-warning-light": ["build", "rebook"].includes(
          appointment.outcome?.value,
        ),
        "has-background-success-light":
          appointment.outcome?.value === "converted",
        "has-background-warning-danger":
          appointment.outcome?.value === "qualifiedout",
        "is-cancelled": appointment.cancelled,
      };
    },
    async change(): Promise<void> {
      if (this.pagination.perPage <= 25) {
        this.pagination.currentPage = 1;
        await this.refresh("component");
      }
    },
    async search(): Promise<void> {
      this.pagination.currentPage = 1;
      await this.refresh("component");
    },
    async refresh(
      caller: "table" | "component",
      date?: string,
      position?: "top" | "bottom",
    ): Promise<void> {
      if (
        caller === "table" &&
        position === "top" &&
        date !== Object.keys(this.rowGroups).first()
      ) {
        return;
      }

      if (
        caller === "table" &&
        position === "bottom" &&
        date !== Object.keys(this.rowGroups).last()
      ) {
        return;
      }

      // const { appointments, pagination, typeCounts } =
      //   await window.Kernel.ActionBus.execute("appointment/read", {
      //     ...(this.filters?.localData ?? {}),
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   });

      const { appointments, pagination, typeCounts } =
        await window.Kernel.ActionBus2.appointment.read({
          ...(this.filters?.localData ?? {}),
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rowGroups = {};

      appointments.forEach((appointment: Appointment) => {
        const date = DateTime.fromDate(
          new Date(appointment.appointmentAt.timestamp),
        ).formatDate;

        if (!this.rowGroups[date]) {
          this.rowGroups[date] = [];
        }
        this.rowGroups[date].push(appointment);
      });

      if (Object.keys(this.rowGroups).isEmpty()) {
        this.rowGroups[new DateTime().formatDate] = [];
      }

      this.callTypeCounts = {
        Enquiry: 0,
        Info: 0,
        Option: 0,
        Kinvault: 0,
        Will: 0,
        Other: 0,
        Cancelled: 0,
      };

      typeCounts.forEach((typeCount) => {
        this.callTypeCounts[this.getAppointmentType(typeCount)] +=
          typeCount.count;
      });

      this.firstGroup = Object.keys(this.rowGroups).first() ?? "";
      this.lastGroup = Object.keys(this.rowGroups).last() ?? "";

      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    getAppointmentType(
      appointment:
        | Appointment
        | {
            type: string;
            cancelled: boolean;
          },
    ): AppointmentTypes {
      const type =
        typeof appointment.type === "string"
          ? appointment.type
          : appointment.type?.text;

      if (appointment.cancelled) {
        return "Cancelled";
      }

      if (
        type === "Options Call" ||
        type === "Zoom Options Call" ||
        type === "Options Review" ||
        type === "Zoom Options Review" ||
        type === "Will Re-write Options Call" ||
        type === "Zoom Will Re-write Options Call"
      ) {
        return "Option";
      }

      if (
        type === "Information Call" ||
        type === "Zoom Information Call" ||
        type === "Kinvault Hosted Call" ||
        type === "Zoom Kinvault Hosted Call" ||
        type === "Phone Information Call (Charles Cameron)" ||
        type === "Zoom Information Call (Charles Cameron)" ||
        type === "Will Re-write Info Call" ||
        type === "Zoom Will Re-write Info Call"
      ) {
        return "Info";
      }

      if (type === "Will Witnessing") {
        return "Will";
      }

      if (
        type === "Enquiry" ||
        type === "Zoom Enquiry" ||
        type === "Longer Enquiry" ||
        type === "Longer Zoom Enquiry"
      ) {
        return "Enquiry";
      }

      if (
        type === "Kinvault Call" ||
        type === "Officer Call" ||
        type === "Zoom Kinvault Call" ||
        type === "Zoom Officer Call"
      ) {
        return "Kinvault";
      }

      return "Other";
    },
    filterCallType(callType: AppointmentTypes): void {
      let callTypes: Array<Option> = [];

      const appointmentGroups = {
        Enquiry: [
          "Enquiry",
          "Zoom Enquiry",
          "Longer Enquiry",
          "Longer Zoom Enquiry",
        ],
        Cancelled: ["Cancelled"],
        Option: [
          "Options Call",
          "Zoom Options Call",
          "Options Review",
          "Zoom Options Review",
          "Will Re-write Options Call",
          "Zoom Will Re-write Options Call",
        ],
        Info: [
          "Information Call",
          "Zoom Information Call",
          "Kinvault Hosted Call",
          "Zoom Kinvault Hosted Call",
          "Phone Information Call (Charles Cameron)",
          "Zoom Information Call (Charles Cameron)",
          "Will Re-write Info Call",
          "Zoom Will Re-write Info Call",
        ],
        Will: ["Will Witnessing"],
        Kinvault: [
          "Kinvault Call",
          "Officer Call",
          "Zoom Kinvault Call",
          "Zoom Officer Call",
        ],
      };

      switch (callType) {
        case "Enquiry":
          callTypes = Option.$findBy({
            text: In(appointmentGroups.Enquiry),
            group: "appointmentType",
          });
          break;
        case "Cancelled":
          callTypes = Option.$findBy({
            text: In(appointmentGroups.Cancelled),
            group: "appointmentType",
          });
          break;
        case "Option":
          callTypes = Option.$findBy({
            text: In(appointmentGroups.Option),
            group: "appointmentType",
          });
          break;
        case "Info":
          callTypes = Option.$findBy({
            text: In(appointmentGroups.Info),
            group: "appointmentType",
          });
          break;
        case "Will":
          callTypes = Option.$findBy({
            text: In(appointmentGroups.Will),
            group: "appointmentType",
          });
          break;
        case "Kinvault":
          callTypes = Option.$findBy({
            text: In(appointmentGroups.Kinvault),
            group: "appointmentType",
          });
          break;
        case "Other":
          callTypes = Option.$findBy({
            text: NotIn(Object.values(appointmentGroups).flat()),
            group: "appointmentType",
          });
          break;
      }

      if (!this.filters || !this.filters.localData) {
        return;
      }

      if (this.filters.localData.selected?.includes(callType)) {
        this.filters.localData.type = [];
        this.filters.localData.selected = null;
        this.filters.localData.cancelled = false;
        this.refresh("component");
        return;
      }

      this.filters.localData.type = callTypes.pluck("id");
      this.filters.localData.selected = callType;
      this.filters.localData.cancelled = "Cancelled" === callType;
      this.filters.controls.incrementFormRenderKey();

      this.refresh("component");
    },
    async handleSetOutcomeClick(appointment: Appointment): Promise<void> {
      await UpdateAppointmentOutcomeForm(appointment).dialog({
        dialog: {
          title: "Set Appointment Outcome",
        },
      });

      // await window.Kernel.ActionBus.execute("appointment/update", {
      //   appointment,
      // });
      await window.Kernel.ActionBus2.appointment.update({
        appointment,
      });
    },
  },
});
</script>
