<template>
  <div class="will will-highlight">
    <Will
      v-show="options.document === 'will'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowNrb
      v-show="options.document === 'mow-nrb'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowIip
      v-show="options.document === 'mow-iip'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowRes
      v-show="options.document === 'mow-res'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowBusiness
      v-show="options.document === 'mow-business'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
  </div>
</template>

<script lang="ts">
import MowBusiness from "@/module/kinvault.kintin/component/will-builder/document/MowBusiness.vue";
import MowIip from "@/module/kinvault.kintin/component/will-builder/document/MowIIP.vue";
import MowNrb from "@/module/kinvault.kintin/component/will-builder/document/MowNRB.vue";
import MowRes from "@/module/kinvault.kintin/component/will-builder/document/MowRES.vue";
import Will from "@/module/kinvault.kintin/component/will-builder/document/Will.vue";
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentInnerContainer",
  mixins: [WillBuilderService.mixin],
  components: {
    MowBusiness,
    MowIip,
    MowNrb,
    MowRes,
    Will,
  },
  emits: {
    "edit-section": (section: {
      section: WillBuilderSections;
      custom?: string;
      generated: string;
    }) => !!section,
  },
});
</script>
