import { SharedFormProps, UserField } from "@/config/form.config";
import {
  CreateCommunicationNote,
  CreateIntroducerNote,
  CreateNote,
  CreateNotification,
} from "@/config/model.config";
import { AuthService } from "@/service/auth.service";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import {
  DynamicLayoutElementConfig,
  GridLayout,
} from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { Kintin, Lead, Note, Option, User } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export const UpdateNoteForm = ({
  data,
  kintin,
  lead,
}: {
  data: Note | "create-note" | "create-introducer-note";
  kintin?: Kintin;
  lead?: Lead;
}) => {
  let notificationsState: "disabled" | undefined = "disabled";
  let entity: Note | null = null;
  let type: "note" | "introducer-note" = "note";

  if ("string" === typeof data) {
    notificationsState = undefined;

    entity = CreateNote({
      type:
        "create-note" === data
          ? "note"
          : "create-introducer-note" === data
            ? "introducerNote"
            : "communicationNote",
    });

    if (data === "create-introducer-note") {
      entity.introducerNote = CreateIntroducerNote();
    }

    // .$persist();

    const loggedInUser = AuthService.loggedInUser;

    entity.name = `Note from ${loggedInUser?.profile.fullName}`;
    // entity.$persist();

    const users: Array<User> = [];

    if (
      kintin &&
      !kintin.estatePlanners.pluck("id").includes(loggedInUser?.id as string)
    ) {
      users.push(...kintin.estatePlanners);
    }

    if (lead && lead.assignedTo && loggedInUser?.id !== lead.assignedTo.id) {
      users.push(lead.assignedTo);
    }

    // entity.$persist();

    users.forEach((user) => {
      const notification = CreateNotification({
        user: user.id,
        note: entity?.id,
      });
      entity?.addNotifications(notification);

      // notification.$persist();
    });
  } else {
    entity = data;
  }

  if (
    (data instanceof Note && data.introducerNote) ||
    "create-introducer-note" === data
  ) {
    type = "introducer-note";
  }

  let template: DynamicLayoutElementConfig<string> = GridLayout([
    ["type", "name"],
    ["pinned", "isPublic"],
    ["notifications"],
    ["dueAt"],
    ["notes"],
  ]);

  switch (type) {
    case "introducer-note":
      template = GridLayout([
        ["type", "name"],
        ["pinned"],
        ["notifications"],
        ["dueAt"],
        ["dueAtNotifySales", "dueAtNotifySpecialist"],
        ["notes"],
      ]);
      break;
  }

  return defineForm({
    name: "note-form",
    props: SharedFormProps,
    data: () => entity as Note,
    formAreas: (data) => [
      defineFormArea({
        name: "note-form-area",
        data,
        template,
        components: () => ({
          notifications: [
            UserField({
              props: {
                label: "Notifications",
                isMultiSelect: true,
                state: notificationsState,
                message:
                  undefined === notificationsState
                    ? undefined
                    : "Notifications are only available when creating a new note.",
                vModel: {
                  get: (data) => data.notifications.pluck("user"),
                  set: (value: Array<User>, data, controls) => {
                    data.notifications.forEach((notification) => {
                      if (!value.pluck("id").includes(notification.user.id)) {
                        notification.$delete();
                      }
                    });

                    value.forEach((user) => {
                      if (
                        !data.notifications
                          .pluck("$data")
                          .pluck("user")
                          .includes(user.id)
                      ) {
                        const notification = CreateNotification({
                          user: user.id,
                          note: data.id,
                        });

                        data.addNotifications(notification);
                      }
                    });
                  },
                },
                reference: "notifications",
              },
              query: {
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          pinned: [
            FormCheckboxField({
              props: {
                label: "Pinned",
                message: "Pin this note to the top of the list",
              },
              models: {
                value: "pinned",
              },
            }),
          ],
          isPublic: [
            FormCheckboxField({
              props: {
                label: "Public",
                message: "Warning: Public notes are visible to introducers",
              },
              models: {
                value: "isPublic",
              },
            }),
          ],
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                label: "Notes",
              },
              models: {
                value: "notes",
              },
            }),
          ],
          dueAt: [
            FormDateField({
              props: {
                label: "Due At",
                showClearButton: true,
              },
              models: {
                value: "dueAt",
              },
              slots: {
                right: [
                  FormCheckboxField({
                    props: {
                      label: "Completed",
                      class: "ml-3",
                      reference: "completedAt",
                      state: (data) =>
                        null === data.dueAt ? "disabled" : undefined,
                    },
                    models: {
                      value: {
                        get: (data) => data.completedAt !== null,
                        set: (value, data) => {
                          data.completedAt =
                            true === value ? new DateTime() : null;
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          dueAtNotifySales: [
            FormCheckboxField({
              props: {
                label: "Notify Sales",
                message: "Notify assigned Sales when this note is due",
              },
              models: {
                value: "introducerNote.dueAtNotifySales",
              },
            }),
          ],
          dueAtNotifySpecialist: [
            FormCheckboxField({
              props: {
                label: "Notify Estate Planner",
                message: "Notify assigned Estate Planner when this note is due",
              },
              models: {
                value: "introducerNote.dueAtNotifySpecialist",
              },
            }),
          ],
          type: [
            FormSelectField({
              props: {
                label: "Type",
                state: notificationsState,
                options: {
                  tel: "Phone Call",
                  sms: "Text Message",
                  email: "Email",
                  other: "Other",
                },
              },
              models: {
                value: {
                  get: (data) =>
                    data.communicationNote?.communicationType ?? "other",
                  set: (value, data, controls) => {
                    if (!data.communicationNote && "other" !== value) {
                      data.communicationNote = CreateCommunicationNote({});
                    } else if (data.communicationNote && "other" === value) {
                      data.communicationNote.$delete();
                      data.communicationNote = null;
                    }

                    if (data.communicationNote) {
                      data.communicationNote.communicationType = value;
                      data.communicationNote.outcome = null;
                    }

                    controls.rebuildForm();
                  },
                },
              },
            }),
            FormRadioField({
              props: {
                vIf: (data) =>
                  ![null, undefined].includes(
                    data.communicationNote?.communicationType as any,
                  ),
                direction: "is-vertical",
                options: (data) => {
                  const communicationType =
                    data.communicationNote?.communicationType;

                  let type = communicationType as string;

                  if ("email" === communicationType) {
                    if (lead) {
                      type = "lead-email";
                    } else {
                      type = "officer-email";
                    }
                  }

                  const options = Option.$findBy({
                    group: "communicationNoteOutcome",
                    data: {
                      type,
                    },
                  });

                  return Object.fromEntries(
                    options.map((option) => [option.id, option.text]),
                  );
                },
              },
              models: {
                value: {
                  get: (data) => data.communicationNote?.outcome?.id ?? null,
                  set: (option: string | null, data) => {
                    if (!data.communicationNote) {
                      return;
                    }

                    if (!option) {
                      data.communicationNote.outcome = null;
                      return;
                    }

                    data.communicationNote.outcome = Option.$findOne(option);
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
