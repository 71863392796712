import {
  AccountReferralCodeField,
  BrandedKinvaultField,
  IntroducerContactField,
  KintinTypeField,
  OptionsSelectField,
} from "@/config/form.config";
import {
  CreateAccountReferral,
  CreateKintin,
  CreateKintinAccess,
  CreatePerson,
  CreateUser,
} from "@/config/model.config";
import {
  FormTabs,
  TabOptions,
} from "@kinherit/framework/component.display/tabs";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormSwitchField } from "@kinherit/framework/component.input/switch-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import {
  AccountReferralCode,
  BrandedKinvault,
  EmailAddress,
  IntroducerContact,
  Kintin,
  KintinAccess,
  PhoneNumber,
  User,
} from "@kinherit/sdk";
import { DashLoader, DateTime, Uuid } from "@kinherit/ts-common";
import { reactive } from "vue";

type Data =
  | {
      kintin: Kintin;
      users: {
        primary: User;
        secondary: User | null;
      };
      access: {
        primary: KintinAccess;
        secondary: KintinAccess | null;
      };
      brandedKinvault: BrandedKinvault | null;
    }
  | {
      kintin: Kintin;
      users: {
        primary: User;
        secondary: User;
      };
      access: {
        primary: KintinAccess;
        secondary: KintinAccess;
      };
      brandedKinvault: BrandedKinvault | null;
    };

export const CreateKintinForm = (kintin: Kintin = CreateKintin()) => {
  const localData = reactive({
    singleTabs: [
      {
        label: "Settings",
      },
      {
        label: "Primary Person",
      },
    ] as Array<TabOptions>,
    jointTabs: [
      {
        label: "Settings",
      },
      {
        label: "Primary Person",
      },
      {
        label: "Secondary Person",
      },
    ] as Array<TabOptions>,
    activeTab: 0,
    referralCode: null as null | AccountReferralCode,
  });

  return defineForm({
    name: "create-kintin-form",
    data: (): Data => ({
      kintin,
      users: {
        primary: CreateUser(),
        secondary: null,
      },
      access: {
        primary: CreateKintinAccess({
          accessType: "owner",
        }),
        secondary: null,
      },
      brandedKinvault: null,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-kintin-form-area",
        data,
        components: () => {
          const personTab = (personType: "primary" | "secondary") => ({
            template: GridLayout([
              ["subtitle1"],
              ["firstName", "lastName"],
              ["email", "phone"],
              { is: "hr" } as never,
              ["subtitle2"],
              ["accessType", "allowAccess"],
            ]),
            components: {
              subtitle1: [
                FormTitle({
                  props: {
                    isSubtitle: true,
                    text: `${personType.ucFirst()} Person Details`,
                  },
                }),
              ],
              firstName: [
                FormTextField<Data>({
                  props: {
                    label: "First Name",
                    validators: (data) =>
                      data.kintin[`${personType}Person`] ? ["required"] : [],
                  },
                  models: {
                    value: {
                      get: (data) =>
                        data.kintin[`${personType}Person`]?.profile.firstName ??
                        "",
                      set: (value, data) => {
                        const person = data.kintin[`${personType}Person`];
                        const user = data.users[personType];

                        if (person && user) {
                          person.profile.firstName = value;
                          user.profile.firstName = value;

                          // person.$persist();
                          // user.$persist();
                        }
                      },
                    },
                  },
                }),
              ],
              lastName: [
                FormTextField<Data>({
                  props: {
                    label: "Last Name",
                    validators: (data) =>
                      data.kintin[`${personType}Person`] ? ["required"] : [],
                  },
                  models: {
                    value: {
                      get: (data) =>
                        data.kintin[`${personType}Person`]?.profile.lastName ??
                        "",
                      set: (value, data) => {
                        const person = data.kintin[`${personType}Person`];
                        const user = data.users[personType];

                        if (person && user) {
                          person.profile.lastName = value;
                          user.profile.lastName = value;

                          // person.$persist();
                          // user.$persist();
                        }
                      },
                    },
                  },
                }),
              ],
              email: [
                FormEmailField<Data>({
                  props: {
                    label: "Email",
                    validators: (data) =>
                      data.kintin[`${personType}Person`] ? ["required"] : [],
                    message:
                      "Your Email - this will used for signing in, correspondence and notifications.",
                  },
                  models: {
                    value: {
                      get: (data) =>
                        data.kintin[
                          `${personType}Person`
                        ]?.profile.emails.first()?.email ?? "",
                      set: (value, data, controls) => {
                        const person = data.kintin[`${personType}Person`];
                        const user = data.users[personType];

                        if (!(person && user)) {
                          return;
                        }

                        const email = person.profile.emails.first();

                        if (["", null, undefined].includes(value)) {
                          if (email) {
                            person?.profile.removeEmails(email);
                            email.$delete();
                          }
                          user.credential.username = "";
                        } else if (email) {
                          email.email = value;
                          user.credential.username = value;
                          // email.$persist();
                        } else {
                          person.profile.addEmails(
                            new EmailAddress({
                              id: Uuid.generate(),
                              email: value,
                              primary: false,
                              createdAt: new DateTime().formatMachine,
                            }),
                            // .$persist()
                          );
                          user.credential.username = value;

                          // person.profile.$persist();
                        }
                        controls.rebuildForm();
                      },
                    },
                  },
                }),
              ],
              phone: [
                FormTextField<Data>({
                  props: {
                    label: "Phone",
                  },
                  models: {
                    value: {
                      get: (data) =>
                        data.kintin[
                          `${personType}Person`
                        ]?.profile.phoneNumbers.first()?.tel ?? "",
                      set: (value, data, controls) => {
                        const phone =
                          data.kintin[
                            `${personType}Person`
                          ]?.profile.phoneNumbers.first();

                        if (["", null, undefined].includes(value)) {
                          if (phone) {
                            data.kintin[
                              `${personType}Person`
                            ]?.profile.removePhoneNumbers(phone);
                            phone.$delete();
                          }
                        } else if (phone) {
                          phone.tel = value;
                          // phone.$persist();
                        } else {
                          data.kintin[
                            `${personType}Person`
                          ]?.profile.addPhoneNumbers(
                            new PhoneNumber({
                              id: Uuid.generate(),
                              tel: value,
                              primary: false,
                              createdAt: new DateTime().formatMachine,
                            }),
                            // .$persist()
                          );
                          // data.kintin.primaryPerson?.profile.$persist();
                        }
                        controls.rebuildForm();
                      },
                    },
                  },
                }),
              ],
              subtitle2: [
                FormTitle({
                  props: {
                    isSubtitle: true,
                    text: `${personType.ucFirst()} Person Access`,
                  },
                }),
              ],
              accessType: [
                FormSelectField<Data>({
                  props: {
                    label: "Type",
                    options: {
                      readonly: "Read Only",
                      owner: "Owner",
                      edit: "Edit",
                      adviser: "Adviser",
                    },
                  },
                  models: {
                    value: {
                      get: (data) => data.access[personType]?.accessType ?? "",
                      set: (value, data) => {
                        const access = data.access[personType];

                        if (access) {
                          access.accessType = value;
                          // access.$persist();
                        }
                      },
                    },
                  },
                }),
              ],
              allowAccess: [
                FormSwitchField<Data>({
                  props: {
                    label: "Allow",
                  },
                  models: {
                    value: {
                      get: (data) => data.access[personType]?.allowAccess ?? "",
                      set: (value, data) => {
                        const access = data.access[personType];

                        if (access) {
                          access.allowAccess = value;
                          // access.$persist();
                        }
                      },
                    },
                  },
                }),
              ],
            },
          });

          return {
            default: [
              FormTabs<Data>({
                props: {
                  config: (data) =>
                    data.kintin.type?.value !== "joint"
                      ? localData.singleTabs
                      : localData.jointTabs,
                  isLazy: true,
                },
                models: {
                  tab: {
                    get: () => localData.activeTab,
                    set: (value) => (localData.activeTab = value),
                  },
                },
                slots: {
                  settings: {
                    template: GridLayout([
                      ["subtitle1"],
                      ["type", "stage"],
                      ["process", "status"],
                      ["affiliation"],
                      ["subtitle2"],
                      ["referralCode", "introducerCompany"],
                      ["introducerContact"],
                    ]),
                    components: {
                      subtitle1: [
                        FormTitle({
                          props: {
                            isSubtitle: true,
                            text: "Settings",
                          },
                        }),
                      ],
                      type: [
                        KintinTypeField({
                          selectField: true,
                          props: {
                            validators: ["required"],
                            vModel: {
                              get: (data) => data.kintin.type,
                              set: (value, data) => {
                                data.kintin.type = value;

                                if (
                                  value?.value === "joint" &&
                                  !data.kintin.secondaryPerson
                                ) {
                                  data.kintin.secondaryPerson = CreatePerson({
                                    kintin: data.kintin.id,
                                  });
                                  // .$persist();
                                  data.users.secondary = CreateUser();
                                  data.access.secondary = CreateKintinAccess();
                                } else {
                                  data.kintin.secondaryPerson?.$delete();
                                  data.kintin.secondaryPerson = null;
                                  data.users.secondary?.$delete();
                                  data.users.secondary = null;
                                  data.access.secondary?.$delete();
                                  data.access.secondary = null;
                                }
                              },
                            },
                          },
                        }),
                      ],
                      stage: [
                        OptionsSelectField({
                          group: "kintinStage",
                          vModel: "kintin.stage",
                          props: {
                            validators: ["required"],
                          },
                        }),
                      ],
                      process: [
                        OptionsSelectField({
                          group: "process",
                          vModel: "kintin.process",
                          props: {
                            validators: ["required"],
                          },
                        }),
                      ],
                      status: [
                        OptionsSelectField({
                          group: "kintinStatus",
                          vModel: "kintin.status",
                          props: {
                            validators: ["required"],
                          },
                        }),
                      ],
                      affiliation: [
                        BrandedKinvaultField<Data>({
                          props: {
                            label: "Affiliation",
                            vModel: "brandedKinvault",
                            message: (data) =>
                              null === data.brandedKinvault
                                ? `This account will be invisible unless the branded kinvault filter is cleared.`
                                : null,
                            messageColor: (data) =>
                              data.brandedKinvault
                                ? undefined
                                : "has-text-danger",
                          },
                        }),
                      ],
                      subtitle2: [
                        FormTitle({
                          props: {
                            isSubtitle: true,
                            text: "Referral",
                          },
                        }),
                      ],
                      referralCode: [
                        AccountReferralCodeField({
                          props: {
                            size: "is-normal",
                            reference: "kintin.referral.referralCode",
                            vModel: {
                              get: (data) => data.kintin.referral?.referralCode,
                              set: (
                                value: null | AccountReferralCode,
                                data,
                                controls,
                              ) => {
                                if (!data.kintin) {
                                  return;
                                }

                                localData.referralCode = value;

                                if (value && !data.kintin.referral) {
                                  data.kintin.referral = CreateAccountReferral({
                                    id: Uuid.generate(),
                                    contact: null as any,
                                    referralCode: value.id,
                                    createdAt: new DateTime().formatMachine,
                                  });
                                  // .$persist();
                                }

                                if (data.kintin.referral) {
                                  data.kintin.referral.referralCode =
                                    value as AccountReferralCode;
                                }

                                controls.rebuildForm();
                              },
                            },
                          },
                          query: {},
                        }),
                      ],
                      introducerContact: [
                        IntroducerContactField({
                          props: {
                            size: "is-normal",
                            reference: "kintin.referral.contact",
                            vModel: {
                              get: (data) => data.kintin.referral?.contact,
                              set: (
                                value: null | IntroducerContact,
                                data,
                                controls,
                              ) => {
                                DashLoader.set(
                                  data,
                                  ["kintin", "referral", "contact"],
                                  value,
                                );

                                controls.rebuildForm();
                              },
                            },
                          },
                          query: {
                            companies: {
                              referralCodes: {
                                id: Equal(localData.referralCode?.id),
                              },
                            },
                          },
                        }),
                      ],
                    },
                  },
                  primary: personTab("primary"),
                  secondary: personTab("secondary"),
                },
              }),
            ],
          };
        },
      }),
    ],
  });
};
