<template>
  <div v-if="options.selecting">
    <MasterListPage
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :title="computedTitle"
      :is-clickable="false"
      :persist-state="false"
    >
      <template #by="{ row }">
        <Avatar :name="row.createdBy?.profile?.fullName" />
      </template>
      <template #approvedAt="{ row }: { row: WillRevision }">
        <span v-if="row.approvedAt" class="is-small badge is-success"
          >{{ row.approvedAt.formatDate }} /
          {{ row.approvedBy?.profile.fullName }}</span
        >
        <span v-else class="is-small badge is-danger">Awaiting Approval</span>
      </template>
      <template #actions="{ row }: { row: WillRevision }">
        <div
          class="buttons is-right"
          :style="{
            'flex-wrap': 'nowrap',
          }"
        >
          <Button
            @click="openWillRevision(row)"
            class="details-button"
            :icon-left="$style.icon.edit.icon"
            aria-label="Open"
            size="is-small"
            color="is-plain"
            tooltip="Open"
          />
        </div>
      </template>
    </MasterListPage>
  </div>
  <Card v-if="!options.selecting">
    <WillBuilder v-model:tab="computedTab" />
  </Card>
</template>

<script lang="ts">
import WillBuilder from "@/module/kinvault.kintin/component/will-builder/output/WillBuilder.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { StyleService } from "@/service/style.service";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Equal } from "@kinherit/orm/index";
import { Person, WillRevision } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsWillRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsWillRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    WillBuilderService.mixin,
    StyleService.mixin,
  ],
  components: {
    WillBuilder,
    Button,
    MasterListPage,
    Card,
    Avatar,
  },
  data: () => ({
    tab: 0,
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    rows: [] as WillRevision[],
    columns: [
      {
        title: "Created",
        sort: true,
        map: (v: WillRevision) => v.createdAt.formatDate,
      },
      {
        title: "By",
        sort: true,
        slot: "by",
      },
      {
        title: "Approved at",
        slot: "approvedAt",
        class: "is-narrow",
      },
      {
        title: "Notes",
        mapHtml: (v: WillRevision) =>
          v.notes ? v.notes?.slice(0, 100) + "..." : "No Notes",
      },
      {
        slot: "actions",
        class: "is-narrow",
      },
    ] as TableColumn<WillRevision>[],
  }),
  async mounted() {
    if (!this.kintin) {
      return;
    }

    // await window.Kernel.ActionBus.execute(
    //   "kinvault/kintin/will-revision/data/record",
    //   {
    //     kintin: this.kintin.id,
    //   },
    // );
    await window.Kernel.ActionBus2.kinvaultKintin.willRevision.recordData({
      kintin: this.kintin.id,
    });

    // await window.Kernel.ActionBus.execute(
    //   "kinvault/kintin/will-revision/checks/read",
    //   {
    //     kintin: this.kintin,
    //   },
    // );
    await window.Kernel.ActionBus2.kinvaultKintin.willRevision.readChecks({
      kintin: this.kintin,
    });

    await this.loadLatestWillRevision();
  },
  computed: {
    computedTitle(): string {
      let person: Person | null = null;

      if (this.tab === 0) {
        person = this.kintin?.primaryPerson ?? null;
      } else {
        person = this.kintin?.secondaryPerson ?? null;
      }

      if (!person) {
        if (this.tab === 0) {
          return `Primary Person`;
        } else {
          return `Secondary Person`;
        }
      }

      return person.profile.fullName ?? "";
    },
    computedTab: {
      get() {
        return this.tab;
      },
      set(value: number) {
        this.tab = value;
        this.loadLatestWillRevision();
      },
    },
    computedType(): "primary" | "secondary" {
      return this.tab === 0 ? "primary" : "secondary";
    },
  },
  methods: {
    async refresh() {
      // const { willRevisions } = await window.Kernel.ActionBus.execute(
      //   "kinvault/will-revision/read",
      //   {
      //     pagination: {
      //       currentPage: this.pagination.currentPage,
      //       perPage: this.pagination.perPage,
      //     },
      //     query: {
      //       kintin: {
      //         id: Equal(this.kintin?.id),
      //       },
      //       ownedBy: {
      //         value: Equal(this.computedType),
      //       },
      //     },
      //     sort: {
      //       by: "createdAt",
      //       direction: "desc",
      //     },
      //   },
      // );

      const { willRevisions } =
        await window.Kernel.ActionBus2.kinvaultWillRevision.read({
          pagination: {
            currentPage: this.pagination.currentPage,
            perPage: this.pagination.perPage,
          },
          query: {
            kintin: {
              id: Equal(this.kintin?.id),
            },
            ownedBy: {
              value: Equal(this.computedType),
            },
          },
          sort: {
            by: "createdAt",
            direction: "desc",
          },
        });

      this.rows = willRevisions;
    },
    async openWillRevision(willRevision: WillRevision) {
      this.setWillRevision(willRevision);
      this.options.selecting = false;
      this.rows = [];
    },
    async loadLatestWillRevision() {
      if (!this.kintin) {
        return;
      }

      // const { willRevisions } = await window.Kernel.ActionBus.execute(
      //   "core/select/will-revision/read",
      //   {
      //     query: {
      //       kintin: {
      //         id: Equal(this.kintin.id),
      //       },
      //       ownedBy: {
      //         value: Equal(this.computedType),
      //       },
      //     },
      //     pagination: {
      //       currentPage: 0,
      //       perPage: 1,
      //     },
      //     sort: {
      //       by: "createdAt",
      //       direction: "desc",
      //     },
      //   },
      // );
      const { willRevisions } =
        await window.Kernel.ActionBus2.core.select.willRevision.read({
          query: {
            kintin: {
              id: Equal(this.kintin.id),
            },
            ownedBy: {
              value: Equal(this.computedType),
            },
          },
          pagination: {
            currentPage: 0,
            perPage: 1,
          },
          sort: {
            by: "createdAt",
            direction: "desc",
          },
        });

      if (willRevisions.length === 0) {
        this.setWillRevision(null);
        return;
      }

      this.setWillRevision(willRevisions[0]);
    },
    setWillRevision(willRevision: WillRevision | null) {
      if (!this.kintin) {
        return;
      }

      this.init({
        kintin: this.kintin,
        type: this.tab === 0 ? "primary" : "secondary",
        willRevision: willRevision,
      });
    },
  },
});
</script>
