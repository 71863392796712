<template>
  <table class="table-box">
    <thead>
      <th>
        <strong><span v-html="helpers.var(title)" /></strong>
        <em>(this may span multiple pages)</em>
      </th>
    </thead>
    <tbody>
      <tr v-for="beneficiary in beneficiaries" :key="beneficiary.id">
        <td>
          <span v-html="helpers.var(helpers.formatBeneficiary(beneficiary))" />
        </td>
      </tr>
      <tr v-if="beneficiariesIssue.isNotEmpty()">
        <td>
          The ISSUE and REMOTER ISSUE of
          <span
            v-html="
              helpers.var(
                beneficiariesIssue.map(helpers.formatBeneficiary).join(' and '),
              )
            "
          />
        </td>
      </tr>
      <tr
        v-for="reserveBeneficiary in reserveBeneficiaries"
        :key="reserveBeneficiary.id"
      >
        <td>
          <span
            v-html="helpers.var(helpers.formatBeneficiary(reserveBeneficiary))"
          />
        </td>
      </tr>
      <tr v-if="reserveBeneficiariesIssue.isNotEmpty()">
        <td>
          The ISSUE and REMOTER ISSUE of
          <span
            v-html="
              helpers.var(
                reserveBeneficiariesIssue
                  .map(helpers.formatBeneficiary)
                  .join(' and '),
              )
            "
          />
        </td>
      </tr>
      <tr>
        <td>
          Plus any other persons nominated by the trustees as herein defined
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { Beneficiary } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `BeneficiariesOutput`,
  mixins: [WillBuilderService.mixin],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    beneficiaries(): Beneficiary[] {
      return [
        ...(this.data?.beneficiariesFirstLine ?? []),
        ...(this.data?.beneficiariesSecondLine ?? []),
      ];
    },
    beneficiariesIssue(): Beneficiary[] {
      return this.beneficiaries.filterBy("ifPredeceased.value", "theirconcern");
    },
    reserveBeneficiaries(): Beneficiary[] {
      return this.data?.reserveBeneficiaries ?? [];
    },
    reserveBeneficiariesIssue(): Beneficiary[] {
      return this.reserveBeneficiaries.filterBy(
        "ifPredeceased.value",
        "theirconcern",
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.table-box {
  border: thick double black;
  width: 15cm;
  margin: auto;
  tbody,
  thead {
    tr {
      td,
      th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }
    }
  }
}
</style>
