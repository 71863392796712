<template>
  <DataListWidget title="CPD Session Summary" :details="cpdSummary" />
</template>

<script lang="ts">
import { CpdDetailsMixin } from "@/module/cpd/mixin/cpd-details.mixin";
import { defineComponent } from "vue";
import { CpdDetailsSummaryRoute } from ".";

import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";

export default defineComponent({
  name: CpdDetailsSummaryRoute,
  components: {
    DataListWidget,
  },
  mixins: [CpdDetailsMixin],

  computed: {
    cpdSummary(): DataListWidgetDetail[] {
      if (!this.cpdSession) return [];
      return [
        {
          label: "Title",
          value: this.cpdSession.title,
        },
        {
          label: "Date",
          value: this.cpdSession.date.formatDateTime,
        },
        {
          label: "Duration",
          value: `${this.cpdSession.duration.toMinutes()} minutes`,
        },
        {
          label: "Outcomes",
          value: {
            html: this.cpdSession.outcomes,
          },
        },
        {
          label: "Allow Bookings",
          value: this.cpdSession.allowBookings ? "Yes" : "No",
        },
      ];
    },
  },
  methods: {
    sessionToList(session: any) {
      return session?.outcomes?.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
  },
});
</script>
