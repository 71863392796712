<template>
  <DataListWidget title="About" :details="details" />
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { AccountReferralCodeDetailsMixin } from "../../mixin/account-referral-code-details.mixin";

export default defineComponent({
  name: "AccountReferralCodeDetailsSidebar",
  mixins: [AccountReferralCodeDetailsMixin],
  components: {
    DataListWidget,
  },
  computed: {
    details(): Array<DataListWidgetDetail> {
      if (!this.referralCode) {
        return [];
      }

      const result = Array<DataListWidgetDetail>();

      if (this.referralCode.category?.value) {
        result.push({
          label: "Category",
          value: this.referralCode.category?.text,
        });
      }

      if (this.referralCode.notes) {
        result.push({
          label: "Notes",
          value: {
            html: this.referralCode.notes,
          },
        });
      }

      return result;
    },
  },
});
</script>
