<template>
  <div class="attorney-list">
    <AttorneySummary
      v-for="role in attorneys.sortBy('sortOrder', 'asc')"
      :key="`attorney-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!attorneys.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import AttorneySummary from "@/module/kinvault.kintin/component/summary-cards/AttorneySummary.vue";
import { UpdateAttorneyForm } from "@/module/kinvault.kintin/form/update-attorney.form";
import Card from "@kinherit/framework/component.layout/card";
import { Attorney, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AttorneyList",
  components: { AttorneySummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    attorneys: {
      type: Array as () => Attorney[],
      required: true,
    },
  },
  methods: {
    async edit(attorney: Attorney): Promise<void> {
      try {
        await UpdateAttorneyForm({
          data: attorney,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Attorney",
          },
        });
      } catch {
        attorney.$restore();
        return;
      }

      const kintin = this.kintin;

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
      //   person: attorney.person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.person.update({
        person: attorney.person,
        kintin: this.kintin,
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/attorney/update", {
      //   attorney,
      //   kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.attorney.update({
        attorney,
        kintin,
      });

      this.$emit("reload");
    },
    async remove(attorney: Attorney): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Attorney",
          message: `Are you sure you want to remove this attorney?`,
        },
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/attorney/delete", {
      //   attorney,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus2.kinvaultKintin.attorney.delete({
        attorney,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
