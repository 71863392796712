<template>
  <UploadFormPage v-if="accountReferral" :accountReferral="accountReferral" />
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import UploadFormPage from "@/shared/component/upload-form/UploadFormPage.vue";
import { AccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KintinDetailsUploadFormRoute } from ".";

export default defineComponent({
  name: KintinDetailsUploadFormRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    UploadFormPage,
  },
  computed: {
    accountReferral(): AccountReferral | null {
      return this.kintin?.referral ?? null;
    },
  },
});
</script>
