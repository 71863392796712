import { IntroducerContractField } from "@/config/form.config";
import { CreateIntroducerContract } from "@/config/model.config";
import { OptionService } from "@/module/core/service/option.service";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { IntroducerContract } from "@kinherit/sdk";

export const UpdateIntroducerContractForm = (
  data: IntroducerContract = CreateIntroducerContract(),
) => {
  const types = OptionService.getOptions("introducerContractType", true);
  const vats = OptionService.getOptions("introducerContractVat", true);

  const defaultVatId = vats.find((vat) => vat.text === "N/A")?.id;

  if (!defaultVatId) {
    throw new Error("Default vat option not found");
  }

  enum ContractTypes {
    ParentAgentIntroducer = "parentagentintroducer",
    AgentIntroducer = "agentintroducer",
    StandardIntroducer = "introducer",
    KinvaultAccess = "kinvaultaccess",
    WhitelabelAgreement = "whitelabel",
    ChildNetworkAgreement = "parentnetwork",
    MasterNetworkAgreement = "masternetwork",
  }

  const percentageOptions = {
    null: "N/A",
    "0": "0%",
    "1": "1%",
    "2": "2%",
    "2_5": "2.5%",
    "3": "3%",
    "3_5": "3.5%",
    "4": "4%",
    "5": "5%",
    "7_5": "7.5%",
    "10": "10%",
    "15": "15%",
    "17_5": "17.5%",
    "18": "18%",
    "20": "20%",
    "25": "25%",
  };

  const timespanOptions = {
    null: "N/A",
    "0-12": "Months 0-12 (yr 1)",
    "0-24": "Months 0-24 (yrs 1-2)",
    "0-36": "Months 0-36 (yrs 1-3)",
    "0-48": "Months 0-48 (yrs 1-4)",
    "0-60": "Months 0-60 (yrs 1-5)",
    "0-72": "Months 0-72 (yrs 1-6)",
    "13-24": "Months 13-24 (yr 2)",
    "13-36": "Months 13-36 (yrs 2-3)",
    "13-48": "Months 13-48 (yrs 2-4)",
    "13-60": "Months 13-60 (yrs 2-5)",
    "13-72": "Months 13-60 (yrs 2-6)",
    "25-36": "Months 25-36 (yr 3)",
    "25-48": "Months 25-48 (yrs 3-4)",
    "25-60": "Months 25-60 (yrs 3-5)",
    "25-72": "Months 25-72 (yrs 3-6)",
    "37-48": "Months 37-48 (yr 4)",
    "37-60": "Months 37-60 (yr 4-5)",
    "37-72": "Months 37-72 (yrs 4-6)",
    "49-60": "Months 49-60 (yrs 5)",
    "49-72": "Months 49-72 (yrs 5-6)",
    "0-6": "Months 0-6 (first 6 months)",
    "0-120": "Months 0-120 (yrs 1-10)",
  };

  return defineForm({
    name: "update-introducer-contract-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "generic",
        data,
        template: GridLayout([["type", "dates"]]),
        components: () => ({
          type: [
            FormRadioField({
              models: {
                value: {
                  get: (data) => data.type?.id ?? null,
                  set: (value: string | undefined, data, controls) => {
                    data.type = types.find((t) => t.id === value) ?? null;
                    controls.rebuildForm();
                  },
                },
              },
              props: {
                label: "Select type",
                reference: "type",
                options: OptionService.getOptions(
                  "introducerContractType",
                  false,
                ),
                direction: "is-vertical",
              },
            }),
          ],
          dates: [
            FormDateField({
              models: {
                value: "datedAt",
              },
              props: {
                label: "Agreement starts",
                message: "Apply agreement from this date",
                class: "mb-2",
              },
            }),
            FormDateField({
              models: {
                value: "sentAt",
              },
              props: {
                label: "Agreement sent on",
                message: "When sent",
                class: "mb-2",
              },
            }),
            FormDateField({
              models: {
                value: "signedAt",
              },
              props: {
                label: "Agreement signed on",
                message: "When signed",
                class: "mb-2",
              },
            }),
            FormCheckboxField({
              models: {
                value: "isSigned",
              },
              props: {
                label: "Signed & returned",
                class: "mb-2",
              },
            }),
            FormDateField({
              models: {
                value: "datedTill",
              },
              props: {
                label: "Agreement ends",
                message: "Stop agreement on this date",
                class: "mb-2",
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: `${ContractTypes.ParentAgentIntroducer}-${ContractTypes.AgentIntroducer}`,
        data,
        show: (data) =>
          data.type?.value === ContractTypes.AgentIntroducer ||
          data.type?.value === ContractTypes.ParentAgentIntroducer,
        template: GridLayout([
          ["ai_5a_percent", "ai_5a_months"],
          ["ai_5b_percent", "ai_5b_months"],
          ["ai_5c_percent", "ai_5c_months"],
          ["vat"],
        ]),
        components: () => ({
          ai_5a_percent: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.ai_5a_percent === null
                      ? `null`
                      : data.ai_5a_percent.toString().replace(".", "_"),
                  set: (id: string | undefined, data, controls) => {
                    if ("null" === id || undefined === id) {
                      data.ai_5a_percent = null;
                    } else {
                      data.ai_5a_percent = parseFloat(id.replace("_", "."));
                    }

                    controls.rebuildTemplateBindings();
                  },
                },
              },
              props: {
                reference: "ai_5a_percent",
                label: "Key Terms (5a) %",
                options: percentageOptions,
              },
            }),
          ],
          ai_5a_months: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.ai_5a_months === null ? `null` : data.ai_5a_months,
                  set: (value: string | undefined, data) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.ai_5a_months = null;
                    } else {
                      data.ai_5a_months = value;
                    }
                  },
                },
              },
              props: {
                reference: "ai_5a_months",
                label: "Timespan (Months)",
                options: timespanOptions,
                state: (data) =>
                  data.ai_5a_percent === null ? "disabled" : undefined,
              },
            }),
          ],
          ai_5b_percent: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.ai_5b_percent === null
                      ? `null`
                      : data.ai_5b_percent.toString().replace(".", "_"),
                  set: (value: string | undefined, data, controls) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.ai_5b_percent = null;
                    } else {
                      data.ai_5b_percent = parseFloat(value.replace("_", "."));
                    }

                    controls.rebuildTemplateBindings();
                  },
                },
              },
              props: {
                reference: "ai_5b_percent",
                label: "Key Terms (5b) %",
                options: percentageOptions,
              },
            }),
          ],
          ai_5b_months: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.ai_5b_months === null ? `null` : data.ai_5b_months,
                  set: (value: string | undefined, data) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.ai_5b_months = null;
                    } else {
                      data.ai_5b_months = value;
                    }
                  },
                },
              },
              props: {
                reference: "ai_5b_months",
                label: "Timespan (Months)",
                options: timespanOptions,
                state: (data) =>
                  data.ai_5b_percent === null ? "disabled" : undefined,
              },
            }),
          ],
          ai_5c_percent: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.ai_5c_percent === null
                      ? `null`
                      : data.ai_5c_percent.toString().replace(".", "_"),
                  set: (value: string | undefined, data, controls) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.ai_5c_percent = null;
                    } else {
                      data.ai_5c_percent = parseFloat(value.replace("_", "."));
                    }

                    controls.rebuildTemplateBindings();
                  },
                },
              },
              props: {
                reference: "ai_5c_percent",
                label: "Key Terms (5c) %",
                options: percentageOptions,
              },
            }),
          ],
          ai_5c_months: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.ai_5c_months === null ? `null` : data.ai_5c_months,
                  set: (value: string | undefined, data) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.ai_5c_months = null;
                    } else {
                      data.ai_5c_months = value;
                    }
                  },
                },
              },
              props: {
                reference: "ai_5c_months",
                label: "Timespan (Months)",
                options: timespanOptions,
                state: (data) =>
                  data.ai_5c_percent === null ? "disabled" : undefined,
              },
            }),
          ],
          vat: [
            FormSelectField({
              models: {
                value: {
                  get: (data) => data.vat?.id ?? defaultVatId,
                  set: (value: string | undefined, data) => {
                    data.vat = vats.find((v) => v.id === value) ?? null;
                  },
                },
              },
              props: {
                label: "VAT",
                reference: "vat",
                options: OptionService.getOptions(
                  "introducerContractVat",
                  false,
                ),
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: `${ContractTypes.StandardIntroducer}`,
        show: (data) => data.type?.value === ContractTypes.StandardIntroducer,
        data,
        template: GridLayout([["i_4c_percent", "i_4c_months"], ["vat"]]),
        components: () => ({
          i_4c_percent: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.i_4c_percent === null
                      ? `null`
                      : data.i_4c_percent.toString().replace(".", "_"),
                  set: (value: string | undefined, data, controls) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.i_4c_percent = null;
                    } else {
                      data.i_4c_percent = parseFloat(value.replace("_", "."));
                    }

                    controls.rebuildTemplateBindings();
                  },
                },
              },
              props: {
                reference: "i_4c_percent",
                label: "Key Terms (4c/5c) %",
                options: percentageOptions,
              },
            }),
          ],
          i_4c_months: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.i_4c_months === null ? `null` : data.i_4c_months,
                  set: (value: string | undefined, data) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.i_4c_months = null;
                    } else {
                      data.i_4c_months = value;
                    }
                  },
                },
              },
              props: {
                reference: "i_4c_months",
                label: "Timespan (Months)",
                options: timespanOptions,
                state: (data) =>
                  data.i_4c_percent === null ? "disabled" : undefined,
              },
            }),
          ],
          vat: [
            FormSelectField({
              models: {
                value: {
                  get: (data) => data.vat?.id ?? defaultVatId,
                  set: (value: string | undefined, data) => {
                    data.vat = vats.find((v) => v.id === value) ?? null;
                  },
                },
              },
              props: {
                label: "VAT",
                reference: "vat",
                options: OptionService.getOptions(
                  "introducerContractVat",
                  false,
                ),
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: `${ContractTypes.WhitelabelAgreement}`,
        show: (data) => data.type?.value === ContractTypes.WhitelabelAgreement,
        data,
        template: GridLayout([["prePaidClients"]]),
        components: () => ({
          prePaidClients: [
            FormNumberField({
              models: {
                value: "prePaidClients",
              },
              props: {
                label: "Prepaid Clients",
                reference: "prePaidClients",
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: `${ContractTypes.ChildNetworkAgreement}`,
        show: (data) =>
          data.type?.value === ContractTypes.ChildNetworkAgreement,
        data,
        template: GridLayout([
          ["masterNetworkAgreement"],
          ["i_4c_percent", "i_4c_months"],
        ]),
        components: () => ({
          masterNetworkAgreement: [
            IntroducerContractField({
              props: {
                vModel: {
                  get: (data) => data.parentContract ?? null,
                  set: (value: IntroducerContract | null, data, controls) => {
                    data.parentContract = value;
                    Object.assign(data, {
                      i_4c_percent: value?.i_4c_percent ?? null,
                      i_4c_months: value?.i_4c_months ?? null,
                    });
                    controls.rebuildForm();
                  },
                },
                label: "Master Network Contract",
                reference: "masternetwork",
              },
              query: {
                type: {
                  value: Equal("masternetwork"),
                },
              },
            }),
          ],
          i_4c_percent: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.i_4c_percent === null
                      ? `null`
                      : data.i_4c_percent.toString().replace(".", "_"),
                  set: (id: string | undefined, data, controls) => {
                    if ("null" === id || undefined === id) {
                      data.i_4c_percent = null;
                    } else {
                      data.i_4c_percent = parseFloat(id.replace("_", "."));
                    }

                    controls.rebuildTemplateBindings();
                  },
                },
              },
              props: {
                reference: "i_4c_percent",
                label: "Key Terms (4c/5c) %",
                options: percentageOptions,
              },
            }),
          ],
          i_4c_months: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.i_4c_months === null ? `null` : data.i_4c_months,
                  set: (value: string | undefined, data) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.i_4c_months = null;
                    } else {
                      data.i_4c_months = value;
                    }
                  },
                },
              },
              props: {
                reference: "i_4c_months",
                label: "Timespan (Months)",
                options: timespanOptions,
                state: (data) =>
                  data.i_4c_percent === null ? "disabled" : undefined,
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: `${ContractTypes.MasterNetworkAgreement}`,
        show: (data) =>
          data.type?.value === ContractTypes.MasterNetworkAgreement,
        data,
        template: GridLayout([["i_4c_percent", "i_4c_months"]]),
        components: () => ({
          i_4c_percent: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.i_4c_percent === null
                      ? `null`
                      : data.i_4c_percent.toString().replace(".", "_"),
                  set: (id: string | undefined, data, controls) => {
                    if ("null" === id || undefined === id) {
                      data.i_4c_percent = null;
                    } else {
                      data.i_4c_percent = parseFloat(id.replace("_", "."));
                    }

                    controls.rebuildTemplateBindings();
                  },
                },
              },
              props: {
                reference: "i_4c_percent",
                label: "Key Terms (4c/5c) %",
                options: percentageOptions,
              },
            }),
          ],
          i_4c_months: [
            FormSelectField({
              models: {
                value: {
                  get: (data) =>
                    data.i_4c_months === null ? `null` : data.i_4c_months,
                  set: (value: string | undefined, data) => {
                    if (
                      "null" === value ||
                      undefined === value ||
                      null === value
                    ) {
                      data.i_4c_months = null;
                    } else {
                      data.i_4c_months = value;
                    }
                  },
                },
              },
              props: {
                reference: "i_4c_months",
                label: "Timespan (Months)",
                options: timespanOptions,
                state: (data) =>
                  data.i_4c_percent === null ? "disabled" : undefined,
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: "notes",
        data,
        template: GridLayout([["notes"]]),
        components: () => ({
          notes: [
            FormTextField({
              models: {
                value: "notes",
              },
              props: {
                label: "General notes",
                reference: "notes",
                isTextArea: true,
              },
            }),
          ],
        }),
      }),
    ],
  });
};
