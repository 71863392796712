import { XeroContactField } from "@/config/form.config";
import { FormButton } from "@kinherit/framework/component.input/button";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { XeroContact } from "@kinherit/sdk/index";

export const IntroducerCompanyAccountForm = () =>
  defineForm({
    name: `introducer-company-account-form`,
    data: () => ({
      contact: null as null | XeroContact,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: `introducer-company-account-form-area`,
        data,
        template: GridLayout([["contact"]]),
        components: () => ({
          contact: [
            XeroContactField({
              props: {
                label: "Select Xero Contact",
                vModel: "contact",
              },
              slots: {
                right: [
                  FormButton({
                    props: {
                      text: "Save",
                      color: "is-success",
                      ariaLabel: "Save",
                    },
                    emits: {
                      click: (_, __, controls) => {
                        controls.emit("submit");
                      },
                    },
                  }),
                ],
              },
            }),
          ],
        }),
      }),
    ],
  });
